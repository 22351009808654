import { ContentChild, Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[appFlexTable]'
})
export class FlexTableDirective implements OnInit {

    @ContentChild('fthead') head;

    @Output()
    callback: EventEmitter<any> = new EventEmitter<any>();

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        //
    }

    @HostListener('scroll', ['$event'])
    onWindowScroll() {
        const table: HTMLElement = this.el.nativeElement as HTMLElement;

        const scrollBarPosition = table.scrollTop + table.offsetHeight;
        const maxScrollPosition = table.scrollHeight;

        if (scrollBarPosition >= maxScrollPosition) {
            this.callback.emit('load more');
        }
    }
}
