<div class="choice-base-pool-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{'SurveyCreator.SelectTheBaseSurveyTemplate' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <app-data-grid [columns]="columns"
                       [items]="items"
                       [request]="request"
                       (loadData)="getData($event)"
                       (itemRadioSelected)="selectItem($event)"
                       [noTabs]="false"
                       [radioSelect]="true"
                       [selection]="false">
        </app-data-grid>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="closeDialog()" [mat-dialog-close]="false" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button [mat-dialog-close]="true" mat-button color="primary" (click)="choiceData()"
                class="dolineo-button blue-button">{{'Common.Choose' | translate}}</button>
    </div>
</div>