import { Component } from '@angular/core';

@Component({
    selector: 'app-no-permission-sys-admin',
    templateUrl: './no-permission-sys-admin.component.html',
    styleUrls: ['./no-permission-sys-admin.component.scss']
})
export class NoPermissionSysAdminComponent {

    constructor() {
        //
    }
}

