import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[highlightActiveBox]'
})
export class HighlightDirective {
    private _show = false;
    private _wasInside = false;
    @Output() isInside = new EventEmitter();
    @Output() outEvent = new EventEmitter();

    @Input() set wasInside(wasInside: boolean) {
        this._wasInside = wasInside;
    }

    @Input() set show(show: boolean) {
        this._show = show;
    }

    @HostListener('click')
    clickInside() {
        this.el.nativeElement.style.border = '1px solid #0E66C9';
        this.el.nativeElement.style.borderRadius = '3px';
        this._wasInside = true;
        this.isInside.emit(true);
    }

    @HostListener('document:click')
    clickout() {
        if (!this._wasInside && this._show) {
            this.el.nativeElement.style.border = '1px solid #EBEBEB';
            this.el.nativeElement.style.borderRadius = '3px';
            this.isInside.emit(false);
        }
        this._wasInside = false;
    }

    constructor(private el: ElementRef) {
    }
}