import { RouterModule, Routes } from '@angular/router';
import { TranslationLoaderResolver } from './shared/services/translation-loader-resolver.service';

const AppRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('../app/login/login.module').then(m => m.LoginModule),
        resolve: { model: TranslationLoaderResolver }
    },
    {
        path: 'app',
        loadChildren: () => import('../app/layout/layout.module').then(m => m.LayoutModule),
        resolve: { model: TranslationLoaderResolver }
    },
    { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

export const AppRoutingModule = RouterModule.forRoot(AppRoutes);
