<h1 mat-dialog-title tabIndex="0">
    {{ 'PoolCreator.GenerateQuestionsByOpenAiAutomatically' | translate}}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content>
    <div class="open-ai-popup" *ngIf="!isSubmitted">
        <div class="open-ai-popup--tabs">
            <div class="tab-links">
                <a (click)="selectTab('text')"
                   [ngClass]="{'active': activeTab == 'text'}">
                    {{ 'PoolCreator.PasteText' | translate}}
                </a>
                <a (click)="selectTab('file')"
                   [ngClass]="{'active': activeTab == 'file'}">
                    {{ 'PoolCreator.SendFile' | translate}}
                </a>
            </div>
            <span class="general-hint">
            {{'PoolCreator.MoreAboutAi' | translate}}
                <img src="/assets/icons/info.svg" alt="format-selection"
                     loading="lazy"
                     matTooltip="{{ 'PoolCreator.GenerateQuestionsByOpenAiInfo' | translate}}"
                     matTooltipPosition="below"
                     matTooltipClass='below-top'
                     class="dolineo-icon-blue">
            </span>
        </div>

        <ng-container *ngIf="activeTab == 'text'">
            <div class="open-ai-popup--source-text">
                <span class="open-ai-popup--source-text__extra">{{ 'PoolCreator.SourceText' | translate }} *</span>
                <mat-form-field appearance="none">
                <textarea [(ngModel)]="sourceText"
                          matInput></textarea>
                </mat-form-field>
                <mat-error *ngIf="sourceTextError" style="margin-top: -30px; margin-bottom: 15px; font-size: 12px;">{{'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
            </div>
        </ng-container>

        <ng-container *ngIf="activeTab == 'file'">
            <div class="open-ai-popup--source-file">
                <div class="upload-file">
                    <button style="padding: 0;" mat-flat-button (click)="selectFile()"
                            [ngClass]="{'opacity-30 cursor-default': fileIsSelected || fileName}">
                        <i class="dolineo-icon dolineo-icon-download2"
                           style="margin-bottom: 5px; margin-right: 5px;"></i>{{ 'Common.LoadFile' | translate }}
                    </button>
                    <input type="file" #sourceFile style="display: none;" (change)="validateFile($event)"/>
                </div>
                <div class="row" *ngIf="!fileIsSelected && !fileName">
                    <div class="col-12">
                        <ngx-file-drop dropZoneLabel="{{ 'PoolCreator.UploadSourceFile' | translate }}"
                                       (click)="selectFile()"
                                       class="cursor-pointer"
                                       (onFileDrop)="dropped($event)"
                                       multiple="false"
                                       directory="false">
                        </ngx-file-drop>
                    </div>
                </div>

                <mat-error *ngIf="errorMessage !== ''"
                           style="margin-top: 10px; font-size: 12px;">{{ errorMessage | translate}}</mat-error>
                <mat-error *ngIf="sourceTextError" style="margin-top: 10px; font-size: 12px;">{{'Common.FiledCanNotBeEmpty' | translate }}</mat-error>

                <ng-container *ngIf="fileName">
                    <div class="file-container">
                        <div class="flex-items">
                            <span> {{ fileName }} </span>
                            <div>
                                <i (click)="removeFile()"
                                   matTooltip="{{'Common.Delete' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-trash"></i>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <div class="open-ai-popup--params">
            <div class="open-ai-popup--params__header">
                <img src="/assets/icons/question.svg" alt="format-selection">
                <span>{{'PoolCreator.QuestionParameters' | translate}}</span>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="flex-items flex-items-center">
                        <div style="margin-right: 10px; padding-left: 28px;"
                             class="p-bottom-5">{{'PoolCreator.QuestionType' | translate}}</div>
                        <mat-form-field style="width:184px;">
                            <mat-select [(ngModel)]="questionType"placeholder="">
                                <mat-option value="single-choice">{{ 'PoolCreator.QuestionsTypeSingle' | translate}}</mat-option>
                                <mat-option value="multiple-choice">{{ 'PoolCreator.QuestionsTypeMultiple' | translate }}</mat-option>
                                <mat-option value="true-false">{{ 'PoolCreator.QuestionsTypeTrueFalse' | translate }}</mat-option>
                                <mat-option value="list2">{{ 'PoolCreator.QuestionsTypeList2' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-6">
                    <div class="flex-items flex-items-center align-baseline">
                        <div style="margin-right: 5px;"
                             class="p-bottom-5">{{'PoolCreator.GenerateQuestions' | translate}}</div>
                        <mat-form-field style="width: 52px;">
                            <input matInput
                                   [(ngModel)]="questionCount"
                                   digitOnly
                                   min="0"
                                   max="25"
                                   placeholder="{{'Common.Number' | translate}} *">
                        </mat-form-field>
                        <div style="margin-left: 5px;"
                             class="p-bottom-5">{{ 'PoolCreator.Questions' | translate }}</div>
                    </div>
                    <mat-error *ngIf="questionCountError && !maxQuestionCount && !questionCount" style="font-size: 12px; margin-top: -17px;">{{'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                    <mat-error *ngIf="questionCountError && maxQuestionCount != null && questionCount" style="font-size: 12px; margin-top: -17px;">{{ 'PoolCreator.TextIsToShort' | translate }} {{maxQuestionCount}}</mat-error>
                </div>
            </div>
        </div>

    </div>

    <div class="open-ai-popup" *ngIf="isSubmitted">
        <div class="working-robot">
            <img src="assets/images/roboty_ai.svg" style="margin-bottom: 15px;">
            <div class="alert" style="margin-bottom: 15px; color: #959D9F;">{{ 'PoolCreator.OpenAiPleaseWait' | translate }}</div>
            <mat-progress-spinner mode="indeterminate" [diameter]="35"></mat-progress-spinner>
        </div>
    </div>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close(false)" mat-button
            [disabled]="isSubmitted"
            class="cancel-button">{{ 'Common.Cancel' | translate}}</button>
    <button mat-button color="primary" (click)="generateQuestions()"
            [disabled]="isSubmitted"
            class="dolineo-button blue-button">{{ 'PoolCreator.Generate' | translate }}</button>
</div>