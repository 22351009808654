import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-sort-dialog',
    templateUrl: './sort-dialog.component.html',
    styleUrls: ['./sort-dialog.component.scss']
})
export class SortDialogComponent {

    sort: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<SortDialogComponent>) {
        this.sort = data.sortSelected;
    }

    setStatus(sort: any) {
        this.sort = sort
    }

    accept() {
        this.dialogRef.close(
            {
                sort: this.sort,
            }
        )
    }
}
