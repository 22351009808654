<div class="survey-question-summary">
    <div class="row" *ngIf="question.questionType !== 'spacer_text'">
        <div class="col question-number">
            <span>{{ currentQuestionIndex + 1 }}</span> {{'Common.Of' | translate}} {{ numberOfAllQuestions }}
        </div>
    </div>

    <div class="row" style="margin-bottom: 0;" *ngIf="question.questionType !== 'spacer_text'">
        <div class="col">
            <div class="question">
                <span [innerHTML]="question.question | parseUrl">&nbsp;</span>
                <span *ngIf="question.isRequired" style="color: #ef5350;"> * </span>
                <div class="image" style="margin-top: 31px;"
                     *ngIf="question.imagePath !== null && question.imagePath !== ''">
                    <ng-container>
                        <a class="survey-image" (click)="showImage(question.question, apiUrl + question.imagePath)">
                            <img [src]="apiUrl + question.imagePath" alt="imagePath">
                            <div class="bg">
                                <img src="/assets/icons/zoom_in.svg" alt="zoom_in">
                            </div>
                        </a>
                    </ng-container>
                </div>

                <div class="image"
                     *ngIf="question.jsonParams && question.jsonParams !== null && question.jsonParams.fileUrl !== null && question.jsonParams.fileUrl !== ''">
                    <a href="{{ question.jsonParams.fileUrl }}" target="_blank">
                        {{'Common.SeeThePicture' | translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>

    <ng-container>
        <div class="row">
            <div class="col" [ngStyle]="{'margin-bottom' : question.questionType === 'spacer_text' ? '0' : '20px'}"
                 [ngClass]="{'inherit-flex-grow': question.questionType === 'matrix_single' || question.questionType === 'matrix_multiple' }">
                <!--Pojedzyńcza-->
                <ng-container *ngIf="question.questionType === 'single'">
                    <mat-radio-group>
                        <ng-container *ngFor="let answer of question.answers; let aIdx = index">
                            <div class="answers" *ngIf="answer.answer !== '' && answer.answer !== null"
                                 style="margin-bottom: 10px; display: flex; align-items: center">
                                <mat-radio-button [value]="answer.id"
                                                  disabled
                                                  [checked]="answersIds.answersIds.indexOf(answer.id) !== -1"
                                                  color="primary">
                                    <span [innerHTML]="answer.answer | parseUrl">&nbsp;</span>
                                </mat-radio-button>
                            </div>
                        </ng-container>

                        <div class="answer other-answer"
                             style="display: flex; "
                             *ngIf="question.hasOther">
                            <mat-radio-button color="primary"
                                              disabled [checked]="answersIds.answerValue !== ''"
                                              [value]="0">
                            </mat-radio-button>
                            <span *ngIf="answersIds.answerValue !== ''">{{answersIds.answerValue}}</span>
                            <mat-form-field class="full-width"
                                            style="margin-top: -3px"
                                            *ngIf="answersIds.answerValue === ''"
                                            [floatLabel]="'never'">
                                <input matInput type="text"
                                       disabled
                                       placeholder="{{'Survey.OtherAnswer' | translate}}">
                            </mat-form-field>
                        </div>
                    </mat-radio-group>
                </ng-container>

                <!--Wielokrotna-->
                <ng-container *ngIf="question.questionType === 'multiple'">
                    <ng-container *ngFor="let answer of question.answers; let aIdx = index">
                        <div class="answers" style="margin-bottom: 9px;"
                             *ngIf="answer.answer !== '' && answer.answer !== null">
                            <mat-checkbox color="primary"
                                          disabled
                                          *ngIf="answersIds.answersIds.indexOf(answer.id) === -1"
                                          [value]="answer.id">
                                <span [innerHTML]="answer.answer | parseUrl">&nbsp;</span>
                            </mat-checkbox>

                            <img src="/assets/icons/custome_check_checkbox.svg"
                                 alt="custome_check_checkbox"
                                 style="height: 17px; margin-right: 18px;"
                                 *ngIf="answersIds.answersIds.indexOf(answer.id) !== -1">
                            <span *ngIf="answersIds.answersIds.indexOf(answer.id) !== -1"
                                  [innerHTML]="answer.answer | parseUrl"
                                  style="line-height: 24px; rgba(0, 0, 0, 0.87);"></span>
                        </div>
                    </ng-container>

                    <div class="answers other-answer"
                         style="margin-bottom: 10px; display: flex;"
                         *ngIf="question.hasOther">
                        <mat-checkbox color="primary"
                                      disabled
                                      *ngIf="answersIds.answerValue === ''"
                                      [value]="'0'">
                        </mat-checkbox>

                        <img src="/assets/icons/custome_check_checkbox.svg"
                             alt="custome_check_checkbox"
                             style="height: 17px; margin-right: 18px;"
                             *ngIf="answersIds.answerValue !== ''">
                        <span *ngIf="answersIds.answerValue !== ''">{{answersIds.answerValue}}</span>
                        <mat-form-field class="full-width"
                                        style="margin-top: -3px"
                                        *ngIf="answersIds.answerValue === ''"
                                        [floatLabel]="'never'">
                            <input matInput type="text"
                                   disabled
                                   placeholder="{{'Survey.OtherAnswer' | translate}}">
                        </mat-form-field>
                    </div>
                </ng-container>

                <!--List-->
                <ng-container *ngIf="question.questionType === 'list'">
                    <div class="sub-question-container sub-question-container__list">
                        <div class="sub-question-container--col">
                            <div class="sub-question-container--col--answer">
                                <mat-form-field>
                                    <mat-select panelClass="select-min-width" disabled class="blue-arrow"
                                                [(ngModel)]="answersIds.answersIds[0]">
                                        <ng-container *ngFor="let answer of question.answers">
                                            <mat-option *ngIf="answer.answer"
                                                        [value]="answer.id">{{ answer.answer }}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!--Skala-->
                <ng-container *ngIf="question.questionType === 'range'">
                    <div class="range__description">
                        <div class="range__description--left">
                            {{question.surveyQuestionParams.scaleMinDescription}}
                        </div>

                        <div class="range__description__radio-buttons">
                            <mat-radio-group>
                                <ng-container>
                                    <mat-radio-button
                                            disabled
                                            *ngFor="let item of [].constructor(question.surveyQuestionParams.scaleMax); let scaleMaxIndex = index"
                                            [value]="scaleMaxIndex"
                                            [checked]="answersIds.answerValue == scaleMaxIndex+1"
                                            color="primary">
                                        {{scaleMaxIndex + 1}}
                                    </mat-radio-button>
                                </ng-container>
                            </mat-radio-group>
                        </div>

                        <div class="range__description--right">
                            {{question.surveyQuestionParams.scaleMaxDescription}}
                        </div>

                        <div class="range__description__text--mobile">
                            <div class="range__description__text--mobile--left">
                                {{question.surveyQuestionParams.scaleMinDescription}}
                            </div>
                            <div class="range__description__text--mobile--right">
                                {{question.surveyQuestionParams.scaleMaxDescription}}
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!--Pojedyńcza siatka-->
                <ng-container *ngIf="question.questionType === 'matrix_single'">
                    <div class="matrix">
                        <div class="matrix__head">
                            <ng-container *ngFor="let answer of question.subQuestions[0].answers;">
                                <div *ngIf="answer.answer">
                                    <span>{{answer.answer}}</span>
                                </div>
                            </ng-container>
                        </div>

                        <ng-container *ngFor="let subQuestion of question.subQuestions; let sIdx = index">
                            <div *ngIf="subQuestion.question"
                                 [ngClass]="{'odd': sIdx%2 == 0}"
                                 class="matrix__content">
                                <div class="matrix__content--left">
                                    <span>{{subQuestion.question}}</span>
                                </div>
                                <div class="matrix__content--right">
                                    <mat-radio-group>
                                        <ng-container *ngFor="let answer of subQuestion.answers">
                                            <div *ngIf="answer.answer"
                                                 class="matrix__content--right__answer">
                                                <mat-radio-button [value]="answer.id"
                                                                  disabled
                                                                  [checked]="answersIds.subQuestions[sIdx].answersIds.indexOf(answer.id) !== -1"
                                                                  color="primary">
                                                </mat-radio-button>
                                            </div>
                                        </ng-container>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <!--Wielokrotna siatka-->
                <ng-container *ngIf="question.questionType === 'matrix_multiple'" class="matrix">
                    <div class="matrix">
                        <div class="matrix__head">
                            <ng-container *ngFor="let answer of question.subQuestions[0].answers;">
                                <div *ngIf="answer.answer">
                                    <span>{{answer.answer}}</span>
                                </div>
                            </ng-container>
                        </div>

                        <ng-container *ngFor="let subQuestion of question.subQuestions; let sIdx = index">
                            <div *ngIf="subQuestion.question"
                                 [ngClass]="{'odd': sIdx%2 == 0}"
                                 class="matrix__content">
                                <div class="matrix__content--left">
                                    <span>{{subQuestion.question}}</span>
                                </div>
                                <div class="matrix__content--right">
                                    <ng-container *ngFor="let answer of subQuestion.answers; let aIdx = index">
                                        <div *ngIf="answer.answer"
                                             class="matrix__content--right__answer">
                                            <mat-checkbox [value]="answer.id"
                                                          disabled
                                                          *ngIf="answersIds.subQuestions[sIdx].answersIds.indexOf(answer.id) === -1"
                                                          color="primary">
                                            </mat-checkbox>
                                            <img src="/assets/icons/custome_check_checkbox.svg"
                                                 alt="custome_check_checkbox"
                                                 style="height: 17px;"
                                                 *ngIf="answersIds.subQuestions[sIdx].answersIds.indexOf(answer.id) !== -1">
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <!--Otwarte pytanie-->
                <ng-container *ngIf="question.questionType === 'open'">
                    <span>{{answersIds.answerValue}}</span>
                </ng-container>

                <!-- Blok tekstu-->
                <ng-container *ngIf="question.questionType === 'spacer_text'">
                    <div class="quill-content" [innerHTML]="question.description | safe: 'html'">
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
