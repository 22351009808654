<div class="change-password">
    <h1 mat-dialog-title tabIndex="0">
        {{'Profile.ChangePassHeader' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           *ngIf="!data.disableClose"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <form [formGroup]="form">
            <mat-form-field class="full-width" *ngIf="data.currentUser === true">
                <input formControlName="current_password" matInput type="password"
                       placeholder="{{'Profile.CurrPass' | translate}} *">
                <mat-error>{{ validation.current_password }}</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
                <input formControlName="password" matInput type="password"
                       placeholder="{{'Profile.NewPass' | translate}} *">
                <mat-error>{{ validation.password }}</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input formControlName="confirm_password" matInput type="password"
                       placeholder="{{'Profile.RepeatPass' | translate}} *">
                <mat-error>{{ validation.new_password }}</mat-error>
            </mat-form-field>
        </form>

        <span class="label-required">{{ 'Profile.PasswordRequires' | translate }}</span>

        <div *ngIf="hasError" class="password-error alert alert-danger text-center">{{ errorMessage }}</div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" mat-button class="cancel-button"
                *ngIf="!data.disableClose">
            {{'Common.Cancel' | translate}}
        </button>
        <button (click)="logout()" mat-button class="cancel-button"
                *ngIf="data.disableClose">
            {{'Menu.Logout' | translate}}
        </button>
        <button [disabled]="isLoading" (click)="save()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Save' | translate}}</button>
    </div>
</div>