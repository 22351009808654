import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'approve-points-task-dialog.component',
    templateUrl: './approve-points-task-dialog.component.html',
    styleUrls: ['./approve-points-task-dialog.component.scss']
})
export class ApprovePointsTaskDialogComponent {

    selected: null;
    points: [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ApprovePointsTaskDialogComponent>) {
        this.points = data.points;
    }

    select (range: any) {
        this.selected = range;
        this.points.forEach((r: any) => {
            r.selected = false;
        });
        range.selected = true;
    }

    close() {
        this.dialogRef.close(this.selected)
    }
}
