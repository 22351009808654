import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    //
    public updateCompanyWidgetshortcutUserUrl(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/dashboard/update-widget-user-shortcut-create/${id}`, body, { headers });
    }

    // public updateCompanyDashboardSection(id: number, body: any): Observable<any> {
    //     const headers = this.defaultHeaders;
    //     return this.http.post<any>(`${this.basePath}/dashboard/section/${id}`, body, { headers });
    // }
    //
    public deleteCompanyDashboardSectionById(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/dashboard/section/${id}`, { headers });
    }
    
    public deleteCompanyDashboardWidgetById(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/dashboard/widget/${id}`, { headers });
    }

    public getCompanySections(isCreator = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/sections` + (isCreator ? '?isCreator=1' : ''), { headers });
    }

    public getWidgets(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widgets`, { headers });
    }

    public getMainWidget(widgetId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget/${widgetId}`, { headers });
    }

    public getCompanyGroups(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/groups`, { headers });
    }

    public getCompanyprofileFields(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/profile-fields`, { headers });
    }

    public getCompanyPositions(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/positions`, { headers });
    }

    public getCompanySurveys(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/surveys`, { headers });
    }

    public getFavoriteData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-favorite`, { headers });
    }

    public getKnowledgeBaseData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-knowledge-base`, { headers });
    }

    public getFeedbackData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-feedback`, { headers });
    }

    public getEmployeeFeedbackData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-employee-feedback`, { headers });
    }

    public getCertificatesData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-certificates`, { headers });
    }

    public getWaitingForRealizationData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-waiting-for-realization`, { headers });
    }

    public getEmployeeCertificatesData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-employee-certificates`, { headers });
    }

    public getEmployeeSubscriptionData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-dolineo-subscription`, { headers });
    }

    public getTasksData(filter: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-tasks` + (filter ? '?filter=' + filter : ''), { headers });
    }

    public getEmployeeOnboardingData(isPreboarding: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-employee-onboarding` + (isPreboarding ? '?isPreboarding=1' : ''), { headers });
    }

    public getNewEmployeesData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-new-employees`, { headers });
    }

    public getCompanyUsersData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-message-for-team`, { headers });
    }

    public getEmployeeLogsData(recently: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-employee-logs` + (recently ? '?recently=1' : ''), { headers });
    }

    public getOnboardingData(isPreboarding: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-onboarding` + (isPreboarding ? '?isPreboarding=1' : ''), { headers });
    }

    public getGamificationsData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-gamifications`, { headers });
    }

    public getnewPathsTrainingsData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-new-paths-trainings`, { headers });
    }

    public uploadDashboard(body: any): Observable<any> {
        const headers = new HttpHeaders();
        return this.http.post<any>(`${this.basePath}/dashboard`, body, { headers });
    }

    public getUserLink(widgetId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/dashboard/widget-user-shortcut/${widgetId}`, { headers });
    }
}
