<div class="rejected-tasks-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{'Task.InformationAboutRejectedTasks' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <ng-container *ngFor="let log of data.approvalStatusLog">
            <div class="rejected-tasks-dialog__row" *ngIf="log.status === 'rejected'">
                <span class="rejected-tasks-dialog__row--date">
                    {{log.date| date : 'dd.MM.YYYY, HH:mm'}}
                    <span class="dot"></span>
                    {{log.approver}}
                </span>

                <span class="rejected-tasks-dialog__row--text">
                    {{log.comment}}
                </span>
            </div>
        </ng-container>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button mat-button color="primary" (click)="dialogRef.close()"
                class="dolineo-button blue-button">{{'Common.Close' | translate}}</button>
    </div>
</div>