import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[createStickyFooterDirective]'
})
export class CreateStickyFooterDirective implements AfterViewInit {
    @Input() isInsidePopup: boolean;

    @HostListener('document:click', ['$event'])
    documentClick(event: any): void {
        try {
            if (event.target.classList.contains('toggle-sidebar-btn') || event.target.parentElement?.parentElement?.classList.contains('toggle-sidebar-btn')) {
                setTimeout(() => {
                    this.setCssInContainer();
                }, 250);
            }
        } catch (exc) {
            //
        }
    }

    @HostListener('window:resize', ['$event'])
    @HostListener('window:scroll', ['$event'])
    onResize() {
        setTimeout(() => {
            this.setCssInContainer();
        }, 250);
    }

    private container: HTMLElement;

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit() {
        this.container = this.el.nativeElement as HTMLElement;
        setTimeout(() => {
            this.setCssInContainer();
        }, 250);
    }

    setCssInContainer() {
        if (this.container.parentElement) {
            this.container.style.width = this.container.parentElement.parentElement.parentElement.clientWidth + 'px';
        }
        this.container.style.position = 'fixed';
        this.container.style.bottom = '0px';
        if (this.isInsidePopup) {
            this.container.style.right = '0px';
            this.container.style.left = '0px';
            this.container.style.margin = '0 auto';
        }
    }
}
