import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-choice-base-graphic',
    templateUrl: './choice-base-graphic.component.html',
    styleUrls: ['./choice-base-graphic.component.scss']
})
export class ChoiceBaseGraphicComponent implements OnInit {

    baseGraphics: { fileId: number, filePath: string, fileName: string }[] = [];
    public apiUrl = environment.filesPath;
    chosenGraphic: any = null;
    width: number = 0;
    height: number = 0;

    constructor(public dialogRef: MatDialogRef<ChoiceBaseGraphicComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.baseGraphics = [...data.baseGraphics]
        if (data.width && data.height) {
            this.width = data.width
            this.height = data.height
        }
    }

    ngOnInit(): void {
        //
    }

    setChosenGraphic(graphic: any) {
        this.chosenGraphic = graphic;
    }

    accept() {
        this.dialogRef.close(this.chosenGraphic);
    }
}
