import { Component } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';
import { ApiService } from '../../../api/api.service';

@Component({
    selector: 'app-no-permission',
    templateUrl: './no-permission.component.html',
    styleUrls: ['./no-permission.component.scss']
})
export class NoPermissionComponent {

    constructor(public _helper: HelperService,
                private _api: ApiService,
                private _router: Router) {
        this._helper.createTitle(['Common.AccessDenied'])
        this._api.User.self().subscribe((loggedUser) => {
            if (JSON.stringify(loggedUser) !== localStorage.getItem('dolineouser')) {
                localStorage.setItem('dolineouser', JSON.stringify(loggedUser));
                this._helper.refreshUser();
            }
        });
    }

    goToMainPage() {
        if (this._helper.isSuperAdmin() || this._helper.isSysAdmin()) {
            this._router.navigateByUrl('/app/superadmin/clients');
        } else {
            this._router.navigateByUrl(this._helper.getHomePageUrl());
        }
    }
}
