import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { capitalizeFirstLetter } from '../../helpers';

@Component({
    selector: 'app-add-remove-visibility-dialog',
    templateUrl: './add-remove-visibility-dialog.component.html',
    styleUrls: ['./add-remove-visibility-dialog.component.scss']
})
export class AddRemoveVisibilityDialogComponent {

    header: string;
    content: string;

    constructor(public dialogRef: MatDialogRef<AddRemoveVisibilityDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if (this.data.type === 'remove') {
            this.header = 'AddRemoveVisibility.Deny' + capitalizeFirstLetter(this.data.selectedTable) + 'Access';
        } else {
            this.header = 'AddRemoveVisibility.ShareWith' + capitalizeFirstLetter(this.data.selectedTable);
        }

        this.content = 'AddRemoveVisibility.Selected' + capitalizeFirstLetter(this.data.selectedTable);
    }
}
