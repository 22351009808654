import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appResizableHeight]'
})
export class ResizableHeightDirective implements OnInit {

    @Input() resizableGrabHeight = 24;
    @Input() resizableMinHeight = 20;
    @Input() resizableMaxHeight = 300;

    dragging = false;

    constructor(private el: ElementRef) {

        function preventGlobalMouseEvents() {
            document.body.style.cursor = 'row-resize';
        }

        function restoreGlobalMouseEvents() {
            document.body.style.cursor = 'default';
        }

        const newHeight = (height) => {
            const newHeight = this.resizableMaxHeight < Math.max(this.resizableMinHeight, height) ? this.resizableMaxHeight : Math.max(this.resizableMinHeight, height);
            el.nativeElement.style.height = `${newHeight}px`;
        }

        const mouseMoveG = (evt) => {
            if (!this.dragging) {
                return;
            }
            newHeight(evt.clientY - el.nativeElement.offsetTop)
            evt.stopPropagation();
        };

        const mouseUpG = (evt) => {
            this.dragging = false;
            restoreGlobalMouseEvents();
            evt.stopPropagation();
            document.removeEventListener('mouseup', mouseUpG, true);
        };

        const mouseDown = (evt) => {
            if (evt?.target.classList.contains('resize-height')) {
                preventGlobalMouseEvents();
                this.dragging = true;
                evt.stopPropagation();
                document.addEventListener('mouseup', mouseUpG, true);
            }
        };

        document.addEventListener('mousemove', mouseMoveG, true);
        document.addEventListener('mouseup', mouseUpG, true);
        el.nativeElement.addEventListener('mousedown', mouseDown, true);
    }

    ngOnInit(): void {
        this.el.nativeElement.style['border-bottom'] = this.resizableGrabHeight + 'px solid transparent';
    }
}
