import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ManagerService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public trainingsUsers(id: number, trainingId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, userId?: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/training/${trainingId}/users?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}&userId=${userId}`, {headers});
    }

    public trainingsUsersXls(id: number, trainingId: number, params: string, userId?: number): string {
        return `/user/${id}/team/training/${trainingId}/users/xls?${params}&userId=${userId}`;
    }

    public pathsUsers(id: number, pathId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, userId?: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/path/${pathId}/users?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}&userId=${userId}`, {headers});
    }

    public pathsUsersXls(id: number, pathId: number, params: string, userId?:number): string {
        return `/user/${id}/team/path/${pathId}/users/xls?${params}&userId=${userId}`;
    }

    // public paths(idManager: number, idUser: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
    //     const headers = this.defaultHeaders;
    //     return this.http.get<any>(`${this.basePath}/user/${idManager}/team/path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${idUser}&${params}`, {headers});
    // }
    //
    // public trainings(idManager: number, idUser: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
    //     const headers = this.defaultHeaders;
    //     return this.http.get<any>(`${this.basePath}/user/${idManager}/team/training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${idUser}&${params}`, {headers});
    // }

    public pools(type='TEST', idUser: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public surveys(idUser: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/surveys?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public getSurveysXLS(idUser: number, sortBy: string, order: string, params: string): string {
        return `/user/${idUser}/team/surveys/xls?sortBy=${sortBy}&order=${order}&${params}`;
    }

    public poolsXls(type='TEST', idUser: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, ids: any[], body: any = {}): Observable<any> {
        let url = `${this.basePath}/user/${idUser}/team/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;
        if (params !== '') {
            url += '&';
        }
        url += 'xls=1';
        if (ids.length > 0) {
            ids.forEach( i => {
                url += '&id[]=' + i;
            });
        }
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(url, body, {observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true'});
    }

    public activities(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/activity?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public activitiesXls(id: number, params: string): string {
        return `/user/${id}/activity/xls?${params}`;
    }

    public certificates(idManager: number, idUser: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idManager}/team/certificate?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${idUser}&${params}`, {headers});
    }

    public downloadXls(idManager: number, idUser: number, type: 'path' | 'training' | 'certificate' | 'user', params: string, body: any = {}): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/user/${idManager}/team/${type}/xls?userId=${idUser}&${params}`, body, {observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true'});
    }

}
