<h1 mat-dialog-title tabIndex="0">
    {{ 'NotificationSystemModal.Header' | translate}}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       (click)="dialogRef.close(false)"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
    ></i>
</h1>

<div mat-dialog-content>
    <div class="selected-count">
        <span>{{ 'Common.SelectedUsers' | translate}}</span>
        <span class="round">{{data.count}}</span>
    </div>

    <div class="dynamic-markers mb-4">
        <div class="title">{{ 'NotificationSystemModal.DynamicMarkers' | translate}}</div>

        <button *ngFor="let marker of markers" mat-flat-button color="accent"
                (click)="addContentPlaceholder(marker.name)">
            {{ marker.name }}
        </button>
    </div>

    <form class="mb-2 w-100" [formGroup]="simpleForm">
        <mat-form-field>
            <input matInput
                   appUpperCaseFirstLetter
                   placeholder="{{ 'NotificationSystemModal.Content' | translate}}"
                   #contentInput
                   formControlName="content"
                   maxlength="150"
                   (click)="changeLastVisitedElement($event.target)"
                   (keydown.enter)="$event.preventDefault()">
            <mat-hint align="end">{{contentInput.value?.length || 0}} / 150</mat-hint>
            <mat-error *ngIf="simpleForm.get('content').touched && simpleForm.get('content').invalid"
            >{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close(false)" mat-button
            class="cancel-button">{{(data.cancelText || 'Common.Cancel') | translate}}</button>
    <button mat-button color="primary" (click)="sendSystemNotification()"
            class="dolineo-button blue-button">
        {{ 'Common.Send' | translate}}
    </button>
</div>
