import { FormControl } from '@angular/forms';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from '../../api/api.service';

export const positionNameValidator = (api: ApiService, id: number) => {
    let hasError = false;
    return (input: FormControl) => {
        const value = input.value.trim();
        return of(value).pipe(
            filter(name => name.length > 0),
            distinctUntilChanged(),
            switchMap((name: string) => api.Admin.checkPositionName({ name, id })),
            map(() => null),
            catchError(() => {
                hasError = true;
                return of({ positionExists: true })
            })
        );
    };
};
