<div class="image-preview">
    <h1 mat-dialog-title tabIndex="0">
        <span class="title">
            {{ data.title | translate }} <span *ngIf="data.question">{{ data.question }}</span>
        </span>
        <span>
            <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
               matTooltip="{{'Common.Close' | translate}}"
               matTooltipPosition="below"
               matTooltipClass='below-top'
               (click)="dialogRef.close()"></i>
        </span>
    </h1>

    <div mat-dialog-content>
        <img [src]="imageUrl" style="max-width: 100%" alt="image">
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button mat-button color="primary" (click)="dialogRef.close()"
                class="dolineo-button blue-button">{{'Common.Close' | translate}}</button>
    </div>
</div>