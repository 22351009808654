import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class LeaveCreatePoolGuard implements CanDeactivate<unknown> {
    guard: boolean = true;

    public allowDeactivation(guard: boolean) {
        this.guard = guard;
    }

    constructor(private _dialog: MatDialog) {
    }

    canDeactivate(component: unknown,
                  currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return Observable.create((observer: Observer<boolean>) => {
            if (this.guard) {
                this._dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        title: 'Common.LeaveThePage',
                        content: 'PoolCreator.GuardContent',
                        okText: 'Common.ComeOut'
                    },
                    width: '486px'
                }).afterClosed().subscribe(result => {
                    observer.next(result);
                    observer.complete();
                }, () => {
                    observer.next(false);
                    observer.complete();
                });
            } else {
                observer.next(true);
                observer.complete();
            }
        });
    }
}
