import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestInterface } from '../../intefaces/pagination.interface';

@Component({
    selector: 'app-choice-base-survey-dialog',
    templateUrl: './choice-base-survey-dialog.component.html',
    styleUrls: ['./choice-base-survey-dialog.component.scss']
})
export class ChoiceBaseSurveyDialogComponent implements OnInit, OnDestroy {
    public filesPath = environment.filesPath;
    columns: any[] = [];
    items: any[] = [];
    filtersString: string = '';
    public request: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };
    gridExtraParams = {
        isDolineo: 0,
        isDraft: 0,
        withDolineo: 1
    };
    public searchRequest: any = null;
    selectedItem = null;
    protected basePath = environment.apiPath;

    constructor(public dialogRef: MatDialogRef<ChoiceBaseSurveyDialogComponent>,
                private _api: ApiService,
                private _dialog: MatDialog,
                private _helper: HelperService,
                private _translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.createTable();
    }

    createTable() {
        this.columns = [
            {
                index: 'name',
                name: 'Common.SurveyName',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                class: 'ft-auto',
                css: {
                    'min-width': '195px',
                    'padding-right': '20px',
                    'word-wrap': 'anywhere'
                }
            },
            {
                index: 'authorName',
                name: 'Common.Author',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '182px',
                    'width': '182px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'numberOfQuestions',
                name: 'Common.Questions',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'min-width': '117px',
                    'width': '117px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'isTemplate',
                name: 'Common.Template',
                value: null,
                defaultValue: null,
                visible: true,
                type: 'ARRAY',
                sortable: true,
                searchable: true,
                multiple: false,
                options: [
                    { id: '1', value: 'Common.Yes' },
                    { id: '0', value: 'Common.No' }
                ],
                css: {
                    'min-width': '100px',
                    'width': '100px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.isTemplate ? this._translate.instant('Common.Yes') : this._translate.instant('Common.No');
                }
            }
        ];
    }

    ngOnInit(): void {
        this.getData(this.filtersString);
    }

    ngOnDestroy() {
        // document.body.classList.add('nav-collapsed');
    }

    getData(filtersString?: string): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        if (this._helper.isSuperAdmin() || this._helper.isSysAdmin()) {
            this.filtersString = filtersString ? filtersString + '&base=1&isDolineo=1' : 'base=1&isDolineo=1';
        } else {
            this.filtersString = filtersString ? filtersString + '&base=1&isDolineo=0' : 'base=1&isDolineo=0';
        }

        req.waitingForData = true;

        this.searchRequest = this._api.Survey.selfSurvey(
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.filtersString
        ).subscribe(res => {
                this.items = [...res.elements];
                req.pagination.totalElementsCount = this.items.length;
                req.pagination.totalPages = this.items.length;
                req.waitingForData = false;
            },
            () => {
                req.waitingForData = false;
            }
        )
    }

    choiceData() {
        if (!this.selectedItem) {
            return
        }

        this._api.Survey.getSurvey(this.selectedItem.id).subscribe(value => {
            value.surveyQuestions.forEach(q => {
                q.id = null;
                q.used = 0;
                q.answers.forEach(a => {
                    a.id = null;
                });
                if (q.image && q.image.url) {
                    q.image.id = null;

                    const request = new XMLHttpRequest();
                    request.open('GET', this.filesPath + q.image.url, true);
                    request.responseType = 'blob';
                    request.onload = () => {
                        const reader = new FileReader();
                        reader.readAsDataURL(request.response);
                        reader.onload = () => {
                            q.image.base = reader.result.toString().split(',')[1];
                        };
                    };
                    request.onerror = () => {
                        //
                    };
                    request.send();
                }
            });
            value.id = null;
            value.isDraft = 1;

            this._api.Library.baseSubject.next({
                ...value,
                id: null,
                name: this._helper.stripFileName(`${this._translate.instant('Common.CopyName')}${value.name}`, 60),
                cover: value.cover ? this.basePath + value.cover : null,
                status: 'draft',
                isUsed: 0
            });
            this.dialogRef.close();
        })
    }

    selectItem(item: any) {
        this.selectedItem = item;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}