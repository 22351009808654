// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    hmr: false,
    filesPath: 'https://dev.dolineo.com/api',
    apiPath: 'https://dev.dolineo.com/api',
    clientId: 'bb6e6fca44631fad08bf5159e11a7fb2',
    clientSecret: '8f1374402b6a2f8d420dccdb68300a58dc9080c3c842ad3de27711ed552e4e76058ee361b98bb40907f03dba9c5a37ed6bcc880c5fbc779cbbb08112e3ab76fd',
    domain: 'dev.dolineo.com',
    tokenSalt: 'DPUHMiMFgKNv6',
    reCaptchaSiteKey: '6LekD2EnAAAAAJSqDEL_MrnGCBiFwiPHBdTQUC4T',
    useRecaptcha: true,
    appName: 'dolineo',
    storageRegion: 'eu-west-1',
    bucketName: 'smart-dolineo-dev',
    stripePricingTableId: '',
    stripPublishableKey: ''
};
