<div class="approve-reject-task-dialog">
    <h1 mat-dialog-title tabIndex="0">
        <ng-container *ngIf="!data.taskTitle">
            {{data.title | translate}}
        </ng-container>

        <span *ngIf="data.taskTitle">
            {{data.title | translate}}:
            <span class="color-gray">{{data.taskTitle}}</span>
        </span>
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close(false)"></i>
    </h1>

    <div mat-dialog-content>
        <span *ngIf="data.extraContent" class="approve-reject-task-dialog__extra">
            {{data.extraContent | translate}}
            <span class="number-selected">{{data.length}}</span>
        </span>
        <p [innerHTML]="data.content | translate"></p>

        <div class="approve-reject-task-dialog__textarea" *ngIf="data.showTextArea">
            <span>{{ (data.devTips ? 'QuestionnaireCreator.RequireConfirmationOfDevTipsRejectInfoReason' : 'Onboarding.TheReasonForTheRejectionOfTheTask') | translate}}</span>
            <mat-form-field appearance="none">
                <textarea matInput
                          [(ngModel)]="comment"
                          maxlength="500"></textarea>
                <div class="error" *ngIf="data.devTips && error">{{ 'Common.FiledCanNotBeEmpty' | translate }}</div>
                <mat-hint align="end"> {{comment.length}} / 500
                </mat-hint>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close(false)" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button mat-button color="primary" (click)="close()"
                class="dolineo-button blue-button">{{'Common.IConfirm' | translate}}</button>
    </div>
</div>
