import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '../../validators/noWhitespaceValidator';

@Component({
    selector: 'app-send-manual-system-notification-dialog',
    templateUrl: './send-manual-system-notification-dialog.component.html',
    styleUrls: ['./send-manual-system-notification-dialog.component.scss']
})
export class SendManualSystemNotificationDialogComponent implements OnInit {

    public simpleForm: FormGroup;

    markers: any[] = [
        {
            id: 'Fullname',
            name: this._translate.instant('NotificationCreator.Fullname'),
        },
        {
            id: 'Firstname',
            name: this._translate.instant('NotificationCreator.Firstname'),
        }
    ];
    setSelectionRange: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<SendManualSystemNotificationDialogComponent>,
                private _translate: TranslateService,
                private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.simpleForm = this.initForm();
    }

    initForm(): FormGroup {
        return this.fb.group({
            content: ['', [Validators.required, noWhitespaceValidator]],
        });
    }

    addContentPlaceholder(marker) {
        const content = this.simpleForm.get('content').value;
        if ((content.length + marker.length) < 150) {
            this.simpleForm.get('content').setValue(this.setSelectionRange ? content.slice(0, this.setSelectionRange) + ' ' +  marker + ' ' + content.slice(this.setSelectionRange) : content + ' ' +  marker);
            this.setSelectionRange = null;
        }
    }

    changeLastVisitedElement(elementName, place: any = null) {
        if (place) {
            this.setSelectionRange = place.selectionStart
        }
    }

    sendSystemNotification() {
        this.simpleForm.markAllAsTouched();
        if (this.simpleForm.get('content').value.replace(/<\/?[^>]+(>|$)/g, '') === '') {
            return '';
        }

        let content = this.simpleForm.get('content').value;
        this.markers.forEach(mark => {
            content = content.indexOf(mark.name) > -1 ? content.replaceAll(mark.name, `[${mark.id}]`) : content;
        })

        this.dialogRef.close(content)
    }
}
