import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-filter-status-type-dialog',
    templateUrl: './filter-status-type-dialog.component.html',
    styleUrls: ['./filter-status-type-dialog.component.scss']
})
export class FilterStatusTypeDialogComponent {

    types: string[] = [];
    statues: string[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<FilterStatusTypeDialogComponent>) {
        this.types = [...data.typesSelected]
        this.statues = [...data.statusesSelected]
    }

    setStatus(status: string) {
        if (status === this.data.statuses[0]) {
            this.statues = [this.data.statuses[0]]
            return
        }
        this.statues = this.statues.filter(value => value !== this.data.statuses[0])

        const isInside = this.statues.find(value => value === status)
        if (isInside) {
            this.statues = this.statues.filter(value => value !== status)
        } else {
            this.statues.push(status)
        }

        if (this.statues.length === 0) {
            this.statues = [this.data.statuses[0]]
        }
    }

    setType(type: string) {
        if (type === this.data.types[0]) {
            this.types = [this.data.types[0]]
            return
        }
        this.types = this.types.filter(value => value !== this.data.types[0])

        const isInside = this.types.find(value => value === type)
        if (isInside) {
            this.types = this.types.filter(value => value !== type)
        } else {
            this.types.push(type)
        }

        if (this.types.length === 0) {
            this.types = [this.data.types[0]]
        }
    }

    accept() {
        this.dialogRef.close(
            {
                status: this.statues.includes(this.data.statuses[0]) ? [] : this.statues,
                type: this.types.includes(this.data.types[0]) ? [] : this.types,
            }
        )
    }

    removeFilters() {
        this.types = [this.data.types[0]]
        this.statues = [this.data.statuses[0]]

        this.dialogRef.close(
            {
                status: [],
                type: []
            }
        )
    }
}
