import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit, HostListener
} from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-next-training',
    templateUrl: './next-training.component.html',
    styleUrls: ['./next-training.component.scss'],
    animations: [
        trigger('changeDivSize', [
            state('initial', style({
                width: '0%'
            })),
            state('final', style({
                width: '100%'
            })),
            transition('initial=>final', animate('15000ms')),
            transition('final=>initial', animate('15000ms'))
        ]),
    ]
})
export class NextTrainingComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() name = '';
    @Output() onConfirm = new EventEmitter();
    @Output() onCancel = new EventEmitter();

    @ViewChild('loader') loader: ElementRef;

    private wasInside = false;

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }

    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.cancel();
        }
        this.wasInside = false;

    }

    timer = 15;
    interval = null;
    currentState = 'initial';

    constructor(private eRef: ElementRef,
                private authService: AuthService,
                private _translate: TranslateService) {
        clearInterval(this.interval);
    }

    ngOnInit() {
        //
    }

    ngAfterViewInit() {
        this.start();
    }

    ngOnDestroy() {
        this.stop();
    }

    start() {
        this.stop();
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                clearInterval(this.interval);
                this.confirm();
            }
        }, 1000);
    }

    stop() {
        clearInterval(this.interval);
        this.currentState = 'final';
    }

    confirm() {
        this.stop();
        this.onConfirm.emit();
    }

    cancel() {
        this.stop();
        this.onCancel.emit();
    }
}