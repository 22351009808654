import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { LibraryService } from '../../api/services/library.service';
import { debounceTime } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

@Directive({
    selector: '[appShowHiddenCarousel]'
})
export class ShowHiddenCarouselDirective implements OnInit, AfterViewInit {
    @Input() lengthTile: number;

    items: number;

    constructor(private element: ElementRef,
                private deviceService: DeviceDetectorService,
                private libraryService: LibraryService) {
    }

    ngOnInit() {
        if (this.deviceService.isDesktop()) {
            this.libraryService.isNavBar.pipe(debounceTime(1000)).subscribe(() => {
                this.resize();
            })
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.resize();
        })
    }

    resize() {
        const $el = this.element.nativeElement;
        const elParent = $el.parentElement as HTMLElement;
        if (!elParent) {
            return
        }
        const arrow = $(elParent).find('.owl-nav');
        const more = $(elParent).find('.my-progress--section--footer__text');
        const owlWidth = $(elParent).find('.owl-item.active').outerWidth(true);
        const tilesWidth = this.lengthTile * owlWidth - 50;

        if (tilesWidth > elParent.offsetWidth && !this.deviceService.isMobile()) {
            more.css('display', 'flex')
            arrow.css('display', 'flex')
        } else {
            more.css('display', 'none')
            arrow.css('display', 'none')
        }
    }
}
