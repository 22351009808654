<div *ngIf="layout" class="subDomain">
    <div class="subDomain__left">
        <div class="subDomain__left__header">
            <div class="logo-container">
                <img src="{{apiUrl + layout.logoPath}}" alt="logo" [ngStyle]="{'height': layout.logoHeight+'px'}"
                     class="logo"
                     *ngIf="layout.logoPath">
            </div>
            <div class="lang-container" *ngIf="layout.showLanguages">
                <div [matMenuTriggerFor]="lang" class="cursor-pointer">
                    <span class="current-lang">{{(currentLang === 'uk' ? 'ua' : currentLang) | uppercase}}</span>
                    <span>
                        <img src="/assets/icons/angle-down.svg" alt="angle-down">
                    </span>
                </div>
                <mat-menu #lang="matMenu">
                    <button mat-menu-item *ngFor="let lang of layout.languages"
                            (click)="setLang(lang.shortName)"
                            class="language-button">
                        <span class="lang-check">
                            <i *ngIf="currentLang === lang.shortName"
                               class="dolineo-icon dolineo-icon-check"></i>
                        </span>
                        <span class="lang-name">{{ 'Language.' + lang.shortName | translate}}</span>
                        <span class="lang-code">{{(lang.shortName === 'uk' ? 'ua' : lang.shortName) | uppercase}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="subDomain__left__container">
            <form [formGroup]="loginForm" (ngSubmit)="useRecaptcha ? captchaRef.execute() : submit(loginForm)"
                  class="form-validation">
                <fieldset>
                    <div class="form__header">
                        <h1>{{'Login.Login' | translate}}</h1>
                    </div>
                    <div class="form-group">
                        <i class="dolineo-icon dolineo-icon-user-circle"></i>
                        <mat-form-field class="full-width">
                            <input matInput type="email"
                                   name="email"
                                   id="email"
                                   placeholder="{{'Login.Email' | translate}}"
                                   formControlName="email">
                            <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <i class="dolineo-icon dolineo-icon-lock2"></i>
                        <mat-form-field class="full-width">
                            <input matInput type="password" name="password"
                                   id="password"
                                   placeholder="{{'Login.Password' | translate}}"
                                   formControlName="password">
                            <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div [translate]="'Account.InvalidLogin'" class="password-error alert alert-danger text-center"
                         style="display: none;"></div>
                    <div [translate]="'Account.Inactive'" class="account-disabled alert alert-danger text-center"
                         style="display: none;"></div>

                    <div class="alert alert-correct text-center" *ngIf="authService.correctResetPassword">
                        {{'Account.PassChanged' | translate}}
                    </div>

                    <div class="alert alert-correct text-center" *ngIf="authService.correctActivationPassword">
                        {{'Account.Activated' | translate}}
                    </div>

                    <button mat-raised-button
                            type="submit"
                            id="login-button"
                            color="primary"
                            name="login-button">{{'Login.Login' | translate}}</button>

                    <button mat-raised-button type="button" color="primary"
                            *ngIf="integrationBtn"
                            (click)="openIntegrationPage()"
                            name="login-button">
                        {{integrationBtn || 'Button integracji'}}
                    </button>

                    <div class="forgot-password">
                        <a [routerLink]="['/forgotpassword']">{{'Login.ForgotPassword' | translate}}</a>
                    </div>
                </fieldset>
            </form>
        </div>
        <div class="subDomain__left__footer">
            <div class="footer-logo">
                <img [src]="logoUrl" [alt]="logoAlt">
            </div>
            <div class="footer-documents">
                <a href="{{apiUrl + policyUrl}}" target="_blank" *ngIf="policyUrl">
                    {{'Login.PrivacyPolicy' | translate}}
                </a>
                <a href="{{apiUrl + termsUrl}}" target="_blank" *ngIf="termsUrl">
                    {{'Login.Regulations' | translate}}
                </a>
                <a *ngIf="layout.helpdeskEmail" href="mailto:{{layout.helpdeskEmail}}">
                    {{'Help desk:'}} {{layout.helpdeskEmail}}</a>
            </div>
        </div>
    </div>
    <div class="subDomain__right"
         [ngStyle]="{'background-image': layout.imagePath? 'url(' + apiUrl + layout.imagePath + ')': '', 'color': layout.color }"
         style="text-align: {{layout.textAlign}};">
        <div class="header">
            {{header}}
        </div>
        <div class="description" [innerHTML]="description">
        </div>
    </div>
</div>
<re-captcha *ngIf="useRecaptcha"
            #captchaRef="reCaptcha"
            [siteKey]="reCaptchaSiteKey"
            size="invisible"
            badge="bottomleft"
            (resolved)="reCaptchaResolved($event)"
></re-captcha>
