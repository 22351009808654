import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FilterService } from './services/filter.service';
import { SorterService } from './services/sorter.service';
import { DialogService } from './services/dialog.service';
import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { AuthService } from './services/auth.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuardService } from './guards/authGuard';
import { PendingRequestsInterceptor } from './interceptors/pending-requests.interceptor';
import { MyProgressGuard } from './guards/my-progress.guard';
import { SuperadminGuard } from './guards/superadmin.guard';
import { AdminGuard } from './guards/admin.guard';
import { CalendarGuard } from './guards/calendar.guard';
import { ConfigurationGuard } from './guards/configuration.guard';
import { LibraryTrainingsGuard } from './guards/library-trainings.guard';
import { LibraryPathsGuard } from './guards/library-paths.guard';
import { FavouriteGuard } from './guards/favourite.guard';
import { PoolGuard } from './guards/pool.guard';
import { PathGuard } from './guards/path.guard';
import { LoaderService } from '../shared/components/loader/loader.service';
import { TeamGuard } from './guards/team.guard';
import { CertificatesTemplatesGuard } from './guards/certificates-templates.guard';
import { PathsCreatorGuard } from './guards/paths-creator.guard';
import { TrainingsCreatorGuard } from './guards/trainings-creator.guard';
import { PoolsCreatorGuard } from './guards/pools-creator.guard';
import { OrganizationGuard } from './guards/organization.guard';
import { ArchiveGuard } from './guards/archive.guard';
import {ReportService} from '../shared/services/report.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule
    ],
    exports: [
        RouterModule,
        BrowserAnimationsModule
    ],
    providers: [
        SorterService,
        FilterService,
        DialogService,
        AuthService,
        AuthGuardService,
        MyProgressGuard,
        AdminGuard,
        SuperadminGuard,
        ConfigurationGuard,
        CalendarGuard,
        LibraryTrainingsGuard,
        LibraryPathsGuard,
        FavouriteGuard,
        LoaderService,
        PathGuard,
        PoolGuard,
        TeamGuard,
        CertificatesTemplatesGuard,
        PoolsCreatorGuard,
        TrainingsCreatorGuard,
        PathsCreatorGuard,
        OrganizationGuard,
        ArchiveGuard,
        ReportService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PendingRequestsInterceptor,
            multi: true,
        },
    ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        super(parentModule);
    }
}
