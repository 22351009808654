import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'showAtMost'
})
export class ShowAtMostPipe implements PipeTransform {

    transform(value: any[], name: string): string {
        let returnString = ``;

        value.forEach((item: any) => {
            returnString += `${item[name]}, `;
        });
        returnString = this.checkString(returnString);

        return returnString;
    }

    private checkString(text: string): string {
        if (text.charAt(text.length - 2) === ',') {
            text = text.substring(0, text.length - 2) + ' ';
        }
        return text;
    }
}
