<div class="category-add-edit">
    <h1 mat-dialog-title tabIndex="0">
        {{ (data ? 'SubjectAreas.Edit' : 'SubjectAreas.AddArea') | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <form [formGroup]="form">
            <mat-form-field class="full-width">
                <input matInput #categoryName formControlName="name"
                       placeholder="{{'SubjectAreas.AreaName' | translate}} *" maxlength="60">
                <mat-error *ngIf="form.get('name').hasError('required')">
                    {{ 'Common.FiledCanNotBeEmpty' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('name').hasError('categoryExists')">
                    {{ 'SubjectAreas.AreaExist' | translate }}
                </mat-error>
                <mat-hint align="end">{{categoryName.value.length}} / 60</mat-hint>
            </mat-form-field>
        </form>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button mat-flat-button (click)="dialogRef.close()" color="primary"
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button mat-flat-button (click)="saveCategory()" color="primary"
                class="dolineo-button blue-button">{{ (data ? 'Common.Update' : 'Common.Add') | translate}}</button>
    </div>
</div>