import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})

export class WelcomeComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  isMobile: boolean;

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    this.prepareWidgetData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        name: null !== this.items.name && this.items.name !== undefined ? JSON.parse(this.items.name)[this._helper.getUser().interfaceLanguageCode] : '',
        sizeClass: this.isMobile ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
        description: null !== this.items.description && this.items.description !== undefined ? JSON.parse(this.items.description)[this._helper.getUser().interfaceLanguageCode] : '',
        notificationsCount: this.preview ? 3 : this.getNotificationsCount(),
        upcomingDatesCount: this.preview ? 4 : this.getUpcomingDatesCountCount(),
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getNotificationsCount(){
    this._api.User.notificationsUnreadCount(this._helper.getUser().id).subscribe((res) => {
      this.widget.notificationsCount = res;
    });
  }

  getUpcomingDatesCountCount(){
    return 0;
  }

}