import { Pipe, PipeTransform } from '@angular/core';
import {truncateString} from '../helpers';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {

  transform(value: any, limit: number): string {
    return truncateString(value, limit);
  }
}
