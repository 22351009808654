import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-include-icon',
    templateUrl: './include-icon.component.html',
    styleUrls: ['./include-icon.component.scss']
})
export class IncludeIconComponent implements OnInit {

    public icons = [];
    public filesPath = environment.filesPath;
    public selectedIcon = {
        fileId: null,
        filePath: null
    };

    constructor(public dialogRef: MatDialogRef<IncludeIconComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.icons = this.data.icons;
    }

    onClickIcon(icon: any): void {
        this.selectedIcon = icon;
    }

    acceptIcon(): void {
        if (this.selectedIcon.fileId === null) {
            return;
        }
        this.dialogRef.close(this.selectedIcon);
    }

}
