<div class="survey-question-stats" id="{{question.id}}">
    <div class="row">
        <div class="col question-number">
            <span class="question-number--blue"
                  *ngIf="question.questionType !== 'spacer_text'">{{ currentQuestionIndex + 1 }}.</span>
            <span class="badge">{{ displayQuestionType(question.questionType) }}</span>
        </div>
    </div>

    <div class="row" style="margin-bottom: 0;" *ngIf="question.questionType !== 'spacer_text'">
        <div class="col">
            <div class="question">
                <span [innerHTML]="question.question | parseUrl">&nbsp;</span>
                <span *ngIf="question.isRequired" style="color: #ef5350;"> * </span>
                <div class="image" style="margin-top: 31px;"
                     *ngIf="question.imagePath !== null && question.imagePath !== ''">
                    <ng-container>
                        <a class="survey-image" (click)="showImage(question.question, apiUrl + question.imagePath)">
                            <img [src]="apiUrl + question.imagePath" alt="img">
                            <div class="bg">
                                <img src="/assets/icons/zoom_in.svg" alt="zoom_in">
                            </div>
                        </a>
                    </ng-container>
                </div>

                <div class="image"
                     *ngIf="question.jsonParams && question.jsonParams !== null && question.jsonParams.fileUrl !== null && question.jsonParams.fileUrl !== ''">
                    <a href="{{ question.jsonParams.fileUrl }}"
                       target="_blank">
                        {{'Common.SeeThePicture' | translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>

    <ng-container>
        <div class="row">
            <div class="col"
                 style="display: flex; flex-direction: column">
                <ng-container *ngIf="question.totalAnswers > 0">
                    <!--Pojedzyńcza lub lista-->
                    <ng-container *ngIf="question.questionType === 'single' || question.questionType === 'list'">
                        <div class="survey-question-stats__pie-chart">
                            <ngx-charts-pie-chart
                                    [view]="viewPie"
                                    [scheme]="colorScheme"
                                    [doughnut]="true"
                                    [arcWidth]="0.40"
                                    [results]="pieChartDate">
                                <ng-template #tooltipTemplate let-model="model">
                                    <span>{{getTooltipPieChart(model.name)}}</span>
                                </ng-template>
                            </ngx-charts-pie-chart>

                            <div class="survey-question-stats__pie-chart--stats">
                                <ng-container *ngIf="question.hasOther!== 1">
                                    <div class="survey-question-stats__pie-chart--stats--row"
                                         *ngFor="let pie of pieChartDate; let pIdx = index">
                                        <div class="chart-pie__circle"
                                             [ngStyle]="{'background-color': pie.color}"></div>
                                        <span style="display: flex; min-width: 80px; max-width: 80px;">
                                            <span class="bold">
                                                {{question.answers[pIdx].totalAnswersPercentage}}%
                                            </span>({{question.answers[pIdx].totalAnswers}})
                                        </span>
                                        <span style="margin-left: 10px"> {{question.answers[pIdx].answer}} </span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="question.hasOther === 1">
                                    <div class="survey-question-stats__pie-chart--stats--row"
                                         *ngFor="let pie of pieChartDate | slice:0:question.answers.length; let pIdx = index">
                                        <div class="chart-pie__circle"
                                             [ngStyle]="{'background-color': pie.color}"></div>
                                        <span style="display: flex; min-width: 80px; max-width: 80px;">
                                            <span class="bold">
                                                {{question.answers[pIdx].totalAnswersPercentage}}%
                                            </span>({{question.answers[pIdx].totalAnswers}})
                                        </span>
                                        <span style="margin-left: 10px"> {{question.answers[pIdx].answer}} </span>
                                    </div>
                                </ng-container>

                                <div class="survey-question-stats__pie-chart--stats--row"
                                     *ngIf="question.hasOther=== 1">
                                    <div class="chart-pie__circle"
                                         [ngStyle]="{'background-color': pieChartDate[pieChartDate.length-1].color}"></div>
                                    <span style="display: flex; min-width: 80px; max-width: 80px;">
                                        <span class="bold">
                                            {{question.totalOtherAnswersPercentage}}%
                                        </span>({{question.totalOtherAnswers}})
                                    </span>
                                    <span style="margin-left: 10px"> {{'Survey.OtherAnswer' | translate}} </span>
                                    <span class="cursor-pointer"
                                          *ngIf="question.totalOtherAnswers >0"
                                          (click)="showOtherAnswer()"
                                          style="margin-left: 10px; color: #0E66C9"> {{'Common.ShowAll' | translate}} </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!--Wielokrotna-->
                    <ng-container *ngIf="question.questionType === 'multiple'">
                        <div class="survey-question-stats__pie-chart">
                            <div style="height: 100%">
                                <ngx-charts-bar-horizontal
                                        [scheme]="colorScheme"
                                        [view]="[280, (barHorizontalDate.length *39)]"
                                        [xAxisTicks]="[0,10,20,30,40,50,60,70,80,90,100]"
                                        [xAxis]="true"
                                        [roundEdges]="false"
                                        [results]="barHorizontalDate">
                                    <ng-template #tooltipTemplate let-model="model">
                                        {{getTooltipHorizontalBar(model.name)}}
                                    </ng-template>
                                </ngx-charts-bar-horizontal>
                            </div>
                            <div class="survey-question-stats__pie-chart--stats" #barHorizontalStats>
                                <ng-container *ngIf="question.hasOther!== 1">
                                    <div class="survey-question-stats__pie-chart--stats--row"
                                         *ngFor="let pie of barHorizontalDate; let pIdx = index">
                                        <div class="chart-pie__circle"
                                             [ngStyle]="{'background-color': pie.color}"></div>
                                        <span style="display: flex; min-width: 80px; max-width: 80px;">
                                            <span class="bold">
                                                {{question.answers[pIdx].totalAnswersPercentage}}%
                                            </span>({{question.answers[pIdx].totalAnswers}})
                                        </span>
                                        <span style="margin-left: 10px"> {{question.answers[pIdx].answer}} </span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="question.hasOther === 1">
                                    <div class="survey-question-stats__pie-chart--stats--row"
                                         *ngFor="let pie of barHorizontalDate | slice:0:question.answers.length; let pIdx = index">
                                        <div class="chart-pie__circle"
                                             [ngStyle]="{'background-color': pie.color}"></div>
                                        <span style="display: flex; min-width: 80px; max-width: 80px;">
                                            <span class="bold">
                                                {{question.answers[pIdx].totalAnswersPercentage}}%
                                            </span>({{question.answers[pIdx].totalAnswers}})
                                        </span>
                                        <span style="margin-left: 10px"> {{question.answers[pIdx].answer}} </span>
                                    </div>
                                </ng-container>

                                <div class="survey-question-stats__pie-chart--stats--row"
                                     *ngIf="question.hasOther=== 1">
                                    <div class="chart-pie__circle"
                                         [ngStyle]="{'background-color': barHorizontalDate[barHorizontalDate.length-1].color}"></div>
                                    <span style="display: flex; min-width: 80px; max-width: 80px;">
                                        <span class="bold">
                                            {{question.totalOtherAnswersPercentage}}%
                                        </span>({{question.totalOtherAnswers}})
                                    </span>
                                    <span style="margin-left: 10px"> {{'Survey.OtherAnswer' | translate}} </span>
                                    <span class="cursor-pointer"
                                          *ngIf="question.totalOtherAnswers >0"
                                          (click)="showOtherAnswer()"
                                          style="margin-left: 10px; color: #0E66C9"> {{'Common.ShowAll' | translate}} </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!--Skala-->
                    <ng-container *ngIf="question.questionType === 'range'">
                        <div class="range">
                            <ngx-charts-bar-vertical
                                    [yAxisTicks]="[0,20,40,60,80,100]"
                                    [scheme]="colorSchemeVerticalBar"
                                    [view]="[650-((10 - barVerticalDate.length)*55), 250]"
                                    [xAxis]="true"
                                    [yAxis]="true"
                                    [showDataLabel]="true"
                                    [roundEdges]="false"
                                    [showGridLines]="true"
                                    [dataLabelFormatting]="formatDataLabel"
                                    [results]="barVerticalDate">
                                <ng-template #tooltipTemplate let-model="model">
                                    {{getTooltipVerticalBar(model.name)}}
                                </ng-template>
                            </ngx-charts-bar-vertical>
                            <div class="range__footer">
                                <span>{{question.surveyQuestionParams.scaleMinDescription}}</span>
                                <span style="text-align: end;">{{question.surveyQuestionParams.scaleMaxDescription}}</span>
                            </div>
                        </div>

                        <div class="range__footer--mobile">
                            <span style="max-width: 160px">{{question.surveyQuestionParams.scaleMinDescription}}</span>
                            <span style="max-width: 160px; text-align: end;">{{question.surveyQuestionParams.scaleMaxDescription}}</span>
                        </div>
                    </ng-container>

                    <!--Pojedyńcza siatka-->
                    <ng-container *ngIf="question.questionType === 'matrix_single'">
                        <div class="matrix__scale--mobile">
                            <div class="number">
                                <span>{{'Min. (0%)'}}</span>
                                <span>{{'Max. (' + question.maxPercentage + '%)'}}</span>
                            </div>
                            <div class="scale">
                            </div>
                        </div>
                        <div class="matrix">
                            <div class="matrix__scale">
                                <div class="number">
                                    <span>{{'Min. (0%)'}}</span>
                                    <span>{{'Max. (' + question.maxPercentage + '%)'}}</span>
                                </div>
                                <div class="scale">
                                </div>
                            </div>
                            <div class="matrix__head">
                                <ng-container *ngFor="let answer of question.subQuestions[0].answers;">
                                    <div *ngIf="answer.answer">
                                        <span>{{answer.answer}}</span>
                                    </div>
                                </ng-container>
                            </div>

                            <ng-container *ngFor="let subQuestion of question.subQuestions; let sIdx = index">
                                <div *ngIf="subQuestion.question"
                                     class="matrix__content">
                                    <div class="matrix__content--left"
                                         [ngClass]="{'odd': sIdx%2 == 0}">
                                        <span>{{subQuestion.question}}</span>
                                    </div>
                                    <div class="matrix__content--right">
                                        <ng-container *ngFor="let answer of subQuestion.answers; let aIdx = index">
                                            <div *ngIf="answer.answer"
                                                 class="matrix__content--right__answer"
                                                 [ngStyle]="{'background-color': 'rgba(130, 184, 235, '+ (answer.totalAnswersPercentage/question.maxPercentage) +')'}">
                                                <span style="font-weight: 500; padding-right: 5px">
                                                    {{answer.totalAnswersPercentage}}%
                                                </span> ({{answer.totalAnswers}})
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <!--Wielokrotna siatka-->
                    <ng-container *ngIf="question.questionType === 'matrix_multiple'" class="matrix">
                        <div class="matrix__scale--mobile">
                            <div class="number">
                                <span>{{'Min. (0%)'}}</span>
                                <span>{{'Max. (' + question.maxPercentage + '%)'}}</span>
                            </div>
                            <div class="scale">
                            </div>
                        </div>
                        <div class="matrix">
                            <div class="matrix__scale">
                                <div class="number">
                                    <span>{{'Min. (0%)'}}</span>
                                    <span>{{'Max. (' + question.maxPercentage + '%)'}}</span>
                                </div>
                                <div class="scale">
                                </div>
                            </div>
                            <div class="matrix__head">
                                <ng-container *ngFor="let answer of question.subQuestions[0].answers;">
                                    <div *ngIf="answer.answer">
                                        <span>{{answer.answer}}</span>
                                    </div>
                                </ng-container>
                            </div>

                            <ng-container *ngFor="let subQuestion of question.subQuestions; let sIdx = index">
                                <div *ngIf="subQuestion.question"
                                     class="matrix__content">
                                    <div class="matrix__content--left"
                                         [ngClass]="{'odd': sIdx%2 == 0}">
                                        <span>{{subQuestion.question}}</span>
                                    </div>
                                    <div class="matrix__content--right">
                                        <ng-container *ngFor="let answer of subQuestion.answers; let aIdx = index">
                                            <div *ngIf="answer.answer"
                                                 class="matrix__content--right__answer"
                                                 [ngStyle]="{'background-color': 'rgba(130, 184, 235, '+ (answer.totalAnswersPercentage/question.maxPercentage) +')'}">
                                                <span style="font-weight: 500; padding-right: 5px">
                                                    {{answer.totalAnswersPercentage}}%
                                                </span>
                                                ({{answer.totalAnswers}})
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <!--Otwarte pytanie-->
                    <ng-container *ngIf="question.questionType === 'open'">
                        <div class="survey-question-stats__open" *ngIf="question.otherAnswers.length >0">
                            <div *ngFor="let other of question.otherAnswers | slice:0:5; let aIdx = index"
                                 class="survey-question-stats__open--row"
                                 [ngClass]="{'odd': aIdx%2 == 0}">
                                <span>{{aIdx + 1 }}. </span>
                                <span>{{other.answer}}</span>
                            </div>
                        </div>
                        <div style="margin-bottom: 32px" *ngIf="question.otherAnswers.length > 5">
                            <span class="survey-question-stats__open--footer"
                                  (click)="showOtherAnswer()">
                                {{'Common.ShowAll' | translate}}
                            </span>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="question.totalAnswers === 0 && question.questionType !== 'spacer_text'">
                    <div class="empty">
                        {{'Common.NoDataToDisplay' | translate}}
                    </div>
                </ng-container>

                <!-- Blok tekstu-->
                <ng-container *ngIf="question.questionType === 'spacer_text'">
                    <div class="quill-content" [innerHTML]="question.description | safe: 'html'">
                    </div>
                </ng-container>

                <div class="survey-question-stats__footer" *ngIf="question.questionType !== 'spacer_text'">
                    <span class="survey-question-stats__footer--number">
                        {{'Survey.TotalNumberOfResponsesToTheQuestion' | translate}}: {{question.totalAnswers}} {{'Common.OutOf' | translate}} {{totalAssigned}}
                    </span>
                    <div class="survey-question-stats__footer--required" *ngIf="question.isRequired">
                        <span style="color: #ef5350;"> * </span>
                        {{'Common.AnswerRequired' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
