<div class="categories" *hasAccess="['ROLE_SUPERADMIN', 'ROLE_SYSTEMADMIN']; perms ['admin:categories:manage']">
    <div class="categories__header">
        <div class="categories__header__left">
            <div class="table-length">
                <span matTooltip="{{(filtersString?'Common.FilteredElements': 'Common.LoadedElements') | translate}}"
                      matTooltipPosition="above"
                      matTooltipClass='above'>
                    {{items.length}}
                </span>
                <span style="white-space: pre"> {{'Common.Of' | translate}} </span>
                <span matTooltip="{{'Common.AllElements' | translate}}"
                      matTooltipPosition="above"
                      matTooltipClass='above'>
                    {{request.pagination.totalElementsCount}}
                </span>
            </div>
            <button (click)="addEditCategory(null)"
                    mat-button color="primary"
                    class="btn-sm btn-dolineo-icon">
                <img src="assets/images/default/icon_plus.svg" alt="icon_plus">
                <span>{{'SubjectAreas.AddNewArea' | translate}}</span>
            </button>
        </div>
        <div class="categories__header__right">
            <button (click)="removeFilters(); $event.preventDefault()" mat-flat-button color="primary"
                    *ngIf="filtersString" class="btn-sm rounded-45 clear-table-filters">
                {{'Common.ClearFilters' | translate}}
                <i class="material-icons">close</i>
            </button>
        </div>
    </div>

    <app-data-grid [columns]="columns"
                   [items]="items"
                   [request]="request"
                   (loadData)="getData($event)"
                   (reorder)="reorderCategory($event)"
                   [noTabs]="false"
                   [dragAndDrop]="true"
                   [selection]="false">
    </app-data-grid>
</div>
