import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { OnboardingList } from '../../onboarding-creator/onboarding-creator.interfaces';
import { FinishedOnboardingList } from '../../onboarding/onboarding.interfaces';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public getCompanyOnboardings(companyId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${companyId}/onboarding?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyOnboardingsXLS(companyId: number, params: string): string {
        return `/superadmin/company/${companyId}/onboarding/xls?${params}`;
    }

    public selfOnboardings(pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/admin/onboarding?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public selfOnboardingsXLS(params: string): string {
        return `/admin/onboarding/xls?${params}`;
    }

    public getOnboarding(id: number, params: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/onboarding/${id}${params}`, { headers });
    }

    public onboardingCheckName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/onboarding/check-name`, body, { headers });
    }

    public addOnboarding(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/onboarding`, body, { headers });
    }

    public addTask(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/admin/task`, body, { headers });
    }

    public editTask(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/task/${id}`, body, { headers });
    }

    public getTask(id: number, params: string = '', clone: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        if (clone) {
            params = '?clone=1';
        }
        return this.http.get<any>(`${this.basePath}/admin/task/${id}${params}`, { headers });
    }

    public approvalByUsers(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/content/carers/assigned_list?type=task`, { headers });
    }

    public userChecklist(id: number, isPreboarding: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/onboarding/${id}/user-checklist` + (isPreboarding ? '?isPreboarding=1' : ''), { headers });
    }

    public assign(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/onboarding/${id}/assign`, body, { headers });
    }

    public assignedList(isPreboarding: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/onboarding/assigned` + (isPreboarding ? '?isPreboarding=1' : ''), { headers });
    }

    public assignedCount(isPreboarding: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/onboarding/assigned/count` + (isPreboarding ? '?isPreboarding=1' : ''), { headers });
    }

    public getUserDetailsOnboarding(userId: number, isPreboarding: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/onboarding` + (isPreboarding ? '?isPreboarding=1' : ''), { headers });
    }

    public getOnboardingPlayer(idUser: number, onboardingId: number, preview: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/onboarding/${onboardingId}` + (preview ? '?preview=1' : ''), { headers });
    }

    public getOnboardingDashboard(userId: number, isPreboarding: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/onboarding/dashboard` + (isPreboarding ? '?isPreboarding=1' : ''), { headers });
    }

    public finishedOnboardings(userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, isPreboarding: number): Observable<FinishedOnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<FinishedOnboardingList>(`${this.basePath}/user/${userId}/onboarding/finished?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&isPreboarding=${isPreboarding}`, { headers });
    }

    public getTaskPlayer(userId: number, taskId: number, isPreview: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        const preview = isPreview? '?preview=1': '';
        return this.http.get<any>(`${this.basePath}/user/${userId}/task/${taskId}${preview}`, { headers });
    }

    public finishedTask(userId: number, taskId: number, onboardingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/task/${taskId}/${onboardingId}/finish`, {}, { headers });
    }

    public uploadFileTotask(userId: number, taskId: number, onboardingId: number, body: any): Observable<any> {
        return this.http.post<any>(`${this.basePath}/user/${userId}/task/${taskId}/${onboardingId}/upload`, body, {
            reportProgress: true,
            observe: 'events'
        });
    }

    public uploadFileToCoordinatorTask(userId: number, taskId: number, onboardingId: number, body: any): Observable<any> {
        return this.http.post<any>(`${this.basePath}/user/${userId}/coordinator-task/${taskId}/${onboardingId}/upload`, body, {
            reportProgress: true,
            observe: 'events'
        });
    }

    downloadAllTaskFile(userId: number, taskId: number): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.get<HttpResponse<Blob>>(`${this.basePath}/user/${userId}/task/${taskId}/attachments`, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    getOnboardingsOrganization(companyId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${companyId}/organization/onboardings?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    onboardingsOrganizationXLS(companyId: number, params: string): string {
        return `/company/${companyId}/organization/onboardings/xls?${params}`;
    }

    onboardingsOrganizationReportXLS(companyId: number, onboardingId: number, body: any): Observable<any> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/company/${companyId}/organization/onboardings/${onboardingId}/report/xls`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    getOnboardingsTeam(userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${userId}/team/onboardings?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    onboardingsTeamXLS(userId: number, params: string): string {
        return `/user/${userId}/team/onboardings/xls?${params}`;
    }

    onboardingsTeamReportXLS(userId: number, onboardingId: number, body: any): Observable<any> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/user/${userId}/team/onboardings/${onboardingId}/report/xls`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    getOnboardingsMyProfile(userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${userId}/team/onboardings?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${userId}&${params}`, { headers });
    }

    onboardingsMyProfileXLS(userId: number, params: string): string {
        return `/user/${userId}/team/onboardings/xls?${params}`;
    }

    getOnboardingsUserTeam(myId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${myId}/team/onboardings?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${userId}&${params}`, { headers });
    }

    onboardingsUserTeamXLS(myId: number, params: string): string {
        return `/user/${myId}/team/onboardings/xls?${params}`;
    }

    getOnboardingsUserOrganization(orgId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${orgId}/organization/onboardings?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${userId}&${params}`, { headers });
    }

    onboardingsUserOrganizationXLS(orgId: number, params: string): string {
        return `/company/${orgId}/organization/onboardings/xls?${params}`;
    }

    getOnboardingUsersTeam(userId: number, onboardingId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${userId}/team/onboardings/${onboardingId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    onboardingUsersTeamXLS(userId: number, onboardingId: number, params: string): string {
        return `/user/${userId}/team/onboardings/${onboardingId}/xls?${params}`;
    }

    getOnboardingUsersCoordinatorsTasksTeam(userId: number, onboardingId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${userId}/team/onboardings-users-coordinators-tasks/${onboardingId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    onboardingUsersCoordinatorsTasksTeamXLS(userId: number, onboardingId: number, params: string): string {
        return `/user/${userId}/team/onboardings-users-coordinators-tasks/${onboardingId}/xls?${params}`;
    }

    getOnboardingUsersOrganization(orgId: number, onboardingId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${orgId}/organization/onboardings/${onboardingId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    onboardingUsersOrganizationXLS(orgId: number, onboardingId: number, params: string): string {
        return `/company/${orgId}/organization/onboardings/${onboardingId}/xls?${params}`;
    }

    getOnboardingCoordinatorsTasksOrganization(orgId: number, onboardingId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${orgId}/organization/onboardings-users-coordinators-tasks/${onboardingId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    onboardingCoordinatorsTasksOrganizationXLS(orgId: number, onboardingId: number, params: string): string {
        return `/company/${orgId}/organization/onboardings-users-coordinators-tasks/${onboardingId}/xls?${params}`;
    }

    getOnboardingElementsTeam(myId: number, onboardingId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${myId}/team/onboarding/${onboardingId}/${userId}/elements?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    onboardingElementsTeamXLS(myId: number, onboardingId: number, userId: number, params: string): string {
        return `/user/${myId}/team/onboarding/${onboardingId}/${userId}/elements/xls?${params}`;
    }

    getOnboardingElementsOrganization(orgId: number, onboardingId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${orgId}/organization/onboarding/${onboardingId}/${userId}/elements?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    onboardingElementsOrganizationXLS(orgId: number, onboardingId: number, userId: number, params: string): string {
        return `/company/${orgId}/organization/onboarding/${onboardingId}/${userId}/elements/xls?${params}`;
    }

    getOnboardingNameOrganization(orgId: number, onboardingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${orgId}/organization/onboarding/${onboardingId}`, { headers });
    }

    getOnboardingNameTeam(userId: number, onboardingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/onboarding/${onboardingId}`, { headers });
    }

    getOnboardingApprovalElementsOrg(orgId: number, onboardingId: number, userId: number, params: string, pageNumber: number, pageSize: number, sortBy: string, order: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${orgId}/organization/onboardings/${onboardingId}/tasks/${userId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    getOnboardingApprovalElementsTeam(myId: number, onboardingId: number, userId: number, params: string, pageNumber: number, pageSize: number, sortBy: string, order: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${myId}/team/onboardings/${onboardingId}/tasks/${userId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    getOnboardingApprovalPreviewOrg(orgId: number, onboardingId: number, userId: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${orgId}/organization/onboardings/${onboardingId}/${userId}${params}`, { headers });
    }

    getOnboardingApprovalPreviewTeam(myId: number, onboardingId: number, userId: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${myId}/team/onboardings/${onboardingId}/${userId}${params}`, { headers });
    }

    getTasksToApprove(taskId: number): Observable<any>{
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/task/${taskId}/users-to-approve`, { headers });
    }

    getOnboardingCoordinatorsTasksOrg(orgId: number, onboardingId: number, userId: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${orgId}/organization/onboardings/${onboardingId}/coordinators-tasks/${userId}${params}`, { headers });
    }

    getOnboardingCoordinatorsTasksTeam(myId: number, onboardingId: number, userId: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${myId}/team/onboardings/${onboardingId}/coordinators-tasks/${userId}${params}`, { headers });
    }

    public approvalTask(userId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/task/approve`, body, { headers });
    }

    public rejectTask(userId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/task/reject`, body, { headers });
    }

    public finishCoordinatorTask(userId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/coordinator-task/finish`, body, { headers });
    }

    public deleteTask(taskId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/user/task/${taskId}`, { headers });
    }
}
