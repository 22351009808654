import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { OnboardingList } from '../../onboarding-creator/onboarding-creator.interfaces';
import { FinishedOnboardingList } from '../../onboarding/onboarding.interfaces';
import {GamificationList} from '../../gamification-creator/gamification-creator.interfaces';

@Injectable({
    providedIn: 'root'
})
export class GamificationService {
    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public getCompanyGamifications(companyId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${companyId}/gamification?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyGamificationsXLS(companyId: number, params: string): string {
        return `/superadmin/company/${companyId}/gamification/xls?${params}`;
    }

    public selfGamifications(pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<GamificationList> {
        const headers = this.defaultHeaders;
        return this.http.get<GamificationList>(`${this.basePath}/admin/gamification?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public selfGamificationsXLS(params: string): string {
        return `/admin/gamification/xls?${params}`;
    }

    public getGamification(id: number, params: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/gamification/${id}${params}`, { headers });
    }

    public gamificationCheckName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/gamification/check-name`, body, { headers });
    }

    public addGamification(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/gamification`, body, { headers });
    }

    public updateCover(gamificationId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/gamification/${gamificationId}/update-cover`, body, { headers });
    }

    public addTask(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/admin/task`, body, { headers });
    }

    public editTask(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/task/${id}`, body, { headers });
    }

    public getTask(id: number, params: string = '', clone: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        if (clone) {
            params = '?clone=1';
        }
        return this.http.get<any>(`${this.basePath}/admin/task/${id}${params}`, { headers });
    }

    public approvalByUsers(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/content/carers/assigned_list?type=task`, { headers });
    }

    public userChecklist(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/gamification/${id}/user-checklist`, { headers });
    }

    public assign(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/gamification/${id}/assign`, body, { headers });
    }

    public assignedList(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/gamification/${id}/assigned`, { headers });
    }

    public assignedCount(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/gamification/${id}/assigned/count`, { headers });
    }



    public getUserDetailsGamification(userId: number, gamificationId: number, preview: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/gamification/${gamificationId}` + (preview ? '?preview=1' : ''), { headers });
    }

    public getGamificationPlayer(idUser: number, gamificationId: number, preview: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/gamification/${gamificationId}` + (preview ? '?preview=1' : ''), { headers });
    }

    public getGamificationDashboard(userId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/gamification/dashboard`, { headers });
    }

    public getMyGamifications(userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/gamifications?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}`, { headers });
    }

    public getTaskPlayer(userId: number, taskId: number, isPreview: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        const preview = isPreview? '?preview=1': '';
        return this.http.get<any>(`${this.basePath}/user/${userId}/task/${taskId}${preview}`, { headers });
    }

    public finishedTask(userId: number, taskId: number, gamificationId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/task-gamification/${taskId}/${gamificationId}/finish`, {}, { headers });
    }

    public uploadFileTotask(userId: number, taskId: number, gamificationId: number, body: any): Observable<any> {
        return this.http.post<any>(`${this.basePath}/user/${userId}/task-gamification/${taskId}/${gamificationId}/upload`, body, {
            reportProgress: true,
            observe: 'events'
        });
    }

    
    downloadAllTaskFile(userId: number, taskId: number): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.get<HttpResponse<Blob>>(`${this.basePath}/user/${userId}/task/${taskId}/attachments`, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    getGamificationsOrganization(companyId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${companyId}/organization/gamifications?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    gamificationsOrganizationXLS(companyId: number, params: string): string {
        return `/company/${companyId}/organization/gamifications/xls?${params}`;
    }

    gamificationsOrganizationReportXLS(companyId: number, gamificationId: number, body: any): Observable<any> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/company/${companyId}/organization/gamifications/${gamificationId}/report/xls`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    getGamificationsTeam(userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${userId}/team/gamifications?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    gamificationsTeamXLS(userId: number, params: string): string {
        return `/user/${userId}/team/gamifications/xls?${params}`;
    }

    gamificationsTeamReportXLS(userId: number, gamificationId: number): string {
        return `/user/${userId}/team/gamifications/${gamificationId}/report/xls`;
    }

    getGamificationsMyProfile(userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${userId}/team/gamifications?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${userId}&${params}`, { headers });
    }

    gamificationsMyProfileXLS(userId: number, params: string): string {
        return `/user/${userId}/team/gamifications/xls?${params}`;
    }

    getGamificationsUserTeam(myId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${myId}/team/gamifications?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${userId}&${params}`, { headers });
    }

    gamificationsUserTeamXLS(myId: number, params: string): string {
        return `/user/${myId}/team/gamifications/xls?${params}`;
    }

    getGamificationsUserOrganization(orgId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${orgId}/organization/gamifications?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&userId=${userId}&${params}`, { headers });
    }

    gamificationsUserOrganizationXLS(orgId: number, params: string): string {
        return `/company/${orgId}/organization/gamifications/xls?${params}`;
    }

    getGamificationUsersTeam(userId: number, gamificationId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${userId}/team/gamifications/${gamificationId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    gamificationUsersTeamXLS(userId: number, gamificationId: number, params: string): string {
        return `/user/${userId}/team/gamifications/${gamificationId}/xls?${params}`;
    }

    
    getGamificationUsersOrganization(orgId: number, gamificationId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${orgId}/organization/gamifications/${gamificationId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    gamificationUsersOrganizationXLS(orgId: number, gamificationId: number, params: string): string {
        return `/company/${orgId}/organization/gamifications/${gamificationId}/xls?${params}`;
    }

    
    getGamificationElementsTeam(myId: number, gamificationId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/user/${myId}/team/gamification/${gamificationId}/${userId}/elements?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    gamificationElementsTeamXLS(myId: number, gamificationId: number, userId: number, params: string): string {
        return `/user/${myId}/team/gamification/${gamificationId}/${userId}/elements/xls?${params}`;
    }

    getGamificationElementsOrganization(orgId: number, gamificationId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OnboardingList> {
        const headers = this.defaultHeaders;
        return this.http.get<OnboardingList>(`${this.basePath}/company/${orgId}/organization/gamification/${gamificationId}/${userId}/elements?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    gamificationElementsOrganizationXLS(orgId: number, gamificationId: number, userId: number, params: string): string {
        return `/company/${orgId}/organization/gamification/${gamificationId}/${userId}/elements/xls?${params}`;
    }

    getGamificationNameOrganization(orgId: number, gamificationId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${orgId}/organization/gamification/${gamificationId}`, { headers });
    }

    getGamificationNameTeam(userId: number, gamificationId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/gamification/${gamificationId}`, { headers });
    }

    getGamificationApprovalElementsOrg(orgId: number, gamificationId: number, userId: number, params: string, pageNumber: number, pageSize: number, sortBy: string, order: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${orgId}/organization/gamifications/${gamificationId}/tasks/${userId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    getGamificationApprovalElementsTeam(myId: number, gamificationId: number, userId: number, params: string, pageNumber: number, pageSize: number, sortBy: string, order: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${myId}/team/gamifications/${gamificationId}/tasks/${userId}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    getGamificationApprovalPreviewOrg(orgId: number, gamificationId: number, userId: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${orgId}/organization/gamifications/${gamificationId}/${userId}${params}`, { headers });
    }

    getGamificationApprovalPreviewTeam(myId: number, gamificationId: number, userId: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${myId}/team/gamifications/${gamificationId}/${userId}${params}`, { headers });
    }

    getTasksToApprove(taskId: number): Observable<any>{
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/task/${taskId}/users-to-approve`, { headers });
    }

    getGamificationCoordinatorsTasksOrg(orgId: number, gamificationId: number, userId: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${orgId}/organization/gamifications/${gamificationId}/coordinators-tasks/${userId}${params}`, { headers });
    }

    getGamificationCoordinatorsTasksTeam(myId: number, gamificationId: number, userId: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${myId}/team/gamifications/${gamificationId}/coordinators-tasks/${userId}${params}`, { headers });
    }

    public approvalTask(userId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/task/approve`, body, { headers });
    }

    public rejectTask(userId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/task/reject`, body, { headers });
    }

}
