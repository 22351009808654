<h1 mat-dialog-title tabIndex="0">
    {{ 'NotificationEmailModal.Header' | translate}}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       (click)="dialogRef.close()"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'>
    </i>
</h1>

<div mat-dialog-content>
    <div class="selected-count">
        <span>{{ 'Common.SelectedUsers' | translate}}</span>
        <span class="round">{{data.count}}</span>
    </div>

    <div class="dynamic-markers">
        <div class="title">{{ 'NotificationEmailModal.DynamicMarkers' | translate}}</div>

        <button *ngFor="let marker of markers" mat-flat-button color="accent"
                (click)="addContentPlaceholder(marker.name)">
            {{ marker.name }}
        </button>
    </div>

    <form class="mb-4 w-100" [formGroup]="simpleForm">
        <mat-form-field>
            <input matInput
                   appUpperCaseFirstLetter
                   placeholder="{{ 'NotificationEmailModal.Title' | translate}}"
                   #titleInput
                   formControlName="title"
                   maxlength="150"
                   (click)="changeLastVisitedElement('title', $event.target)"
                   (keydown.enter)="$event.preventDefault()">
            <mat-hint align="end">{{titleInput.value?.length || 0}} / 150</mat-hint>
            <mat-error *ngIf="simpleForm.get('title').touched && simpleForm.get('title').invalid">
                {{ 'Common.FiledCanNotBeEmpty' | translate }}
            </mat-error>
        </mat-form-field>
    </form>

    <div class="editor-wrapper">
        <label>{{ 'NotificationCreator.Content' | translate}}</label>
        <div id="quill-editor" (click)="changeLastVisitedElement('content')"></div>
        <mat-error *ngIf="contentError === true"
                   style="font-size: 12px;">{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
    </div>

    <mat-checkbox class="mt-4 ml-2"
                  [(ngModel)]="setSender"
                  color="primary">
        {{ 'NotificationEmailModal.SetSender' | translate}}
    </mat-checkbox>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close(false)" mat-button
            class="cancel-button">{{(data.cancelText || 'Common.Cancel') | translate}}</button>
    <button mat-button color="primary" (click)="sendEmails()"
            class="dolineo-button blue-button">
        {{ 'Common.Send' | translate}}
    </button>
</div>