<div class="empty-tile" id="tile"
     [ngClass]="{'empty-tile__aqua' : type === 'path', 'empty-tile__blue' : type === 'training'}">
    <div *ngIf="type === 'path'" class="empty-tile--box">
        <img src="assets/icons/path.svg" alt="path" class="empty-tile--box__aqua">
        <div class="empty-tile--box--text">
            {{text | translate}}
        </div>
        <button mat-flat-button (click)="goToPage()" color="primary"
                class="dolineo-button blue-button">
            {{ 'EmptyTile.ChooseAPath' | translate}}
            <img src="../../../../assets/icons/arrow_right.svg" alt="arrow_right">
        </button>
    </div>

    <div *ngIf="type === 'training'" class="empty-tile--box">
        <img src="assets/icons/trainings.svg" alt="trainings" class="empty-tile--box__blue">
        <div class="empty-tile--box--text">
            {{text | translate}}
        </div>
        <button mat-flat-button (click)="goToPage()" color="primary"
                class="dolineo-button blue-button">
            {{ 'EmptyTile.ChooseATraining' | translate}}
            <img src="../../../../assets/icons/arrow_right.svg" alt="arrow_right">
        </button>
    </div>
</div>
