<div class="close-dialog" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>

<div class="download-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{'Common.SelectingColumnsToBeExported' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <mat-checkbox (change)="selectAll($event)" color="primary">
            {{'Common.All' | translate}}
        </mat-checkbox>
        <div *ngFor="let col of data">
            <mat-checkbox [(ngModel)]="col.selected" color="primary">
                {{col.name | translate}}
            </mat-checkbox>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()"
                mat-button
                class="cancel-button">
            {{'Common.Cancel' | translate}}
        </button>
        <button mat-button
                color="primary"
                (click)="download()"
                class="dolineo-button blue-button">
            {{'Common.Download' | translate}}
        </button>
    </div>
</div>
