import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientLoginPage } from '../../shared/intefaces/client-login-page';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppLocaleService } from '../../core/services/locale.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../shared/services/helper.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    loginForm: FormGroup;
    public validation = {
        email: ''
    };
    currentLang: string;
    subDomain: string;
    layout: ClientLoginPage = null;
    subscriptions: Subscription = new Subscription();
    resetStatus: boolean;
    public apiUrl = environment.filesPath;

    public header = '';
    public description = '';
    public policyUrl = '';
    public termsUrl = '';
    logoUrl = '';
    logoAlt = '';

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private _api: ApiService,
                private _toastrService: ToastrService,
                private _translate: TranslateService,
                private _helper: HelperService,
                private _localeService: AppLocaleService) {
        if (environment.appName === 'dolineo') {
            this.logoUrl = 'assets/images/logo/logo_dolineo.svg';
            this.logoAlt = 'dolineo_icon';
        } else if (environment.appName === 'ontigo') {
            this.logoUrl = 'assets/images/logo/logo_ontigo.svg';
            this.logoAlt = 'ontigo_icon';
        }
        this.startedValue()
    }

    startedValue() {
        this.subDomain = window.location.hostname.replace(environment.domain, '').replace('.', '').toLowerCase();

        this.subscriptions.add(this._api.layout.subscribe((value: ClientLoginPage) => {
            this.layout = value;

            if (this.layout !== null) {
                this.setLang(this.getBrowserLang());
            }
        }));
    }

    ngOnInit() {
        this.initForm();

        this.validation.email = this._translate.instant('Common.InvalidEmail');
    }

    getBrowserLang() {
        const selectedLang = localStorage.getItem('startLanguage');
        if (!selectedLang) {
            let language: string = window.navigator.language.split('-')[0];
            if (!language) {
                language = 'pl'
            }
            return language;
        }
        return selectedLang;
    }

    initForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required]]
        });
    }

    submit() {
        if (!this.loginForm.valid) {
            return;
        }
        const sub = this.subDomain ? this.subDomain : '';
        const value = { ...this.loginForm.value, subdomain: sub }
        this.loginForm.disable();
        this.subscriptions.add(this.authService.forgotPassword(value).subscribe((status: boolean) => {
            if (status) {
                this.resetStatus = true;
            } else {
                this._toastrService.error(this._translate.instant('Err.ErrRetry'))
            }
        }, (err) => this.onError(err)).add(() => {
            this.loginForm.enable();
        }));
    }

    onError(err) {
        if (err.error.message === 'User account is disabled' || err.error.message === 'Forbidden') {
            $('.account-disabled').slideDown(200);
            setTimeout(() => {
                $('.account-disabled').slideUp(200);
            }, 3000);
        } else {
            this._toastrService.error(this._translate.instant('Err.UnexpectedError'));
        }
    }

    setLang(lang: string) {
        if (this.currentLang !== lang) {
            this.currentLang = lang;
            this._translate.setDefaultLang(lang);
            this._translate.use(lang);
            this.subscriptions.add(this._translate.reloadLang(lang).subscribe(() => {
                this._helper.createTitle(['ForgotPassword.RecoverPassword']);

            }));
            localStorage.setItem('startLanguage', lang);
            this._localeService.setLocale(lang);
            if (this.layout) {
                this.header = this.layout.headers[lang] ? this.layout.headers[lang] : '';
                this.description = this.layout.descriptions[lang] ? this.layout.descriptions[lang] : ''
            }
        }
    }
}
