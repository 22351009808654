import { Component, OnDestroy } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnDestroy {

    constructor(public _helper: HelperService,
                private _router: Router) {
        this._helper.footerVisible = false;
        this._helper.createTitle(['NotFound.PageNotFound'])
    }

    goToMainPage() {
        if (this._helper.isSuperAdmin() || this._helper.isSysAdmin()) {
            this._router.navigateByUrl('/app/superadmin/clients');
        } else {
            this._router.navigateByUrl(this._helper.getHomePageUrl());
        }
    }

    ngOnDestroy() {
        this._helper.footerVisible = true;
    }
}
