<div class="gauge-chart" *ngIf="percent && color && leftPosition">
    <div class="gauge-chart__piece"
         [style]="color"></div>
    <div class="gauge-chart__piece--empty"
         [style]="percent"></div>
    <div class="gauge-chart__white" [style]="leftPosition"></div>
    <div class="gauge-chart__spacer gauge-chart__spacer--20"></div>
    <div class="gauge-chart__spacer gauge-chart__spacer--40"></div>
    <div class="gauge-chart__spacer gauge-chart__spacer--60"></div>
    <div class="gauge-chart__spacer gauge-chart__spacer--80"></div>
    <div class="bottom-trapezoid"></div>
</div>
