<div class="add-from-library">
    <h1 mat-dialog-title tabIndex="0">
        {{'PathCreator.AddFromLibrary' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="add-from-library__empty" *ngIf="(hasPaths !== null && !hasPaths) &&
        (hasTraining !== null && !hasTraining) &&
        (hasSurveys !== null && !hasSurveys) &&
        (hasTests !== null && !hasTests) && waiting">
            {{'PathCreator.ThereAreNoItemsToDisplay' | translate}}
        </div>
        <mat-accordion>
            <mat-expansion-panel [expanded]="step === 0" hideToggle *ngIf="hasPaths">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(0, 'paths'); $event.stopPropagation()">
                        <div class="panel-header--arrow">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 0? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 0}">
                        </div>

                        <span *ngIf="!chosenPaths.length">
                           {{'Common.Paths' | translate}}
                       </span>

                        <div class="chosen-element" *ngIf="chosenPaths.length">
                            <span class="gray-header">{{'Common.Paths' | translate}}</span>
                            <div class="flex-items">
                                <span class="cut-text">{{chosenPaths | showAtMost: 'name'}}</span>
                                <div class="number-selected">
                                    {{chosenPaths.length}}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grid">
                    <app-data-grid [columns]="pathsColumns"
                                   [items]="paths"
                                   [request]="requestPaths"
                                   (loadData)="getPaths($event)"
                                   [noTabs]="false"
                                   (itemCheckboxSelected)="selectedAddItems($event, 'paths')"
                                   [selection]="true">
                    </app-data-grid>
                </div>
                <span *ngIf="isInvalid && !chosenPaths.length" class="error">
                    {{'PathCreator.NoItemWasSelected' | translate}}
                </span>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 1" hideToggle
                                 *ngIf="hasTraining">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(1, 'training'); $event.stopPropagation()">
                        <div class="panel-header--arrow">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 1? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 1}">
                        </div>

                        <span *ngIf="!chosenTraining.length">
                           {{'Common.Trainings' | translate}}
                       </span>

                        <div class="chosen-element" *ngIf="chosenTraining.length">
                            <span class="gray-header">{{'Common.Trainings' | translate}}</span>
                            <div class="flex-items">
                                <span class="cut-text">{{chosenTraining | showAtMost: 'name'}}</span>
                                <div class="number-selected">
                                    {{chosenTraining.length}}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids">
                    <app-data-grid [columns]="trainingsColumns"
                                   [items]="trainings"
                                   [request]="requestTrainings"
                                   (loadData)="getTrainings($event)"
                                   [noTabs]="false"
                                   (itemCheckboxSelected)="selectedAddItems($event, 'trainings')"
                                   [selection]="true">
                    </app-data-grid>
                </div>
                <span *ngIf="isInvalid && !chosenTraining.length" class="error">
                    {{'PathCreator.NoItemWasSelected' | translate}}
                </span>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 2" hideToggle
                                 *ngIf="hasTests">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(2, 'test'); $event.stopPropagation()">
                        <div class="panel-header--arrow">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 2? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 2}">
                        </div>

                        <span *ngIf="!chosenTests.length">
                           {{'Common.Pools' | translate}}
                       </span>
                        <div class="chosen-element" *ngIf="chosenTests.length">
                            <span class="gray-header">{{'Common.Pools' | translate}}</span>
                            <div class="flex-items">
                                <span class="cut-text">{{chosenTests | showAtMost: 'name'}}</span>
                                <div class="number-selected">
                                    {{chosenTests.length}}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids">
                    <app-data-grid [columns]="testsColumns"
                                   [items]="tests"
                                   [request]="requestTest"
                                   (loadData)="getTests($event)"
                                   [noTabs]="false"
                                   (itemCheckboxSelected)="selectedAddItems($event, 'tests')"
                                   [selection]="true">
                    </app-data-grid>
                </div>
                <span *ngIf="isInvalid && !chosenTests.length" class="error">
                    {{'PathCreator.NoItemWasSelected' | translate}}
                </span>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 3" hideToggle
                                 *ngIf="hasSurveys">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(3, 'surveys'); $event.stopPropagation()">
                        <div class="panel-header--arrow">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 3? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 3}">
                        </div>

                        <span *ngIf="!chosenSurveys.length">
                           {{'Common.Surveys' | translate}}
                       </span>
                        <div class="chosen-element" *ngIf="chosenSurveys.length">
                            <span class="gray-header">{{'Common.Surveys' | translate}}</span>
                            <div class="flex-items">
                                <span class="cut-text">{{chosenSurveys | showAtMost: 'name'}}</span>
                                <div class="number-selected">
                                    {{chosenSurveys.length}}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids">
                    <app-data-grid [columns]="surveysColumns"
                                   [items]="surveys"
                                   [request]="requestSurvey"
                                   (loadData)="getSurveys($event)"
                                   [noTabs]="false"
                                   (itemCheckboxSelected)="selectedAddItems($event, 'surveys')"
                                   [selection]="true">
                    </app-data-grid>
                </div>
                <span *ngIf="isInvalid && !chosenSurveys.length" class="error">
                    {{'PathCreator.NoItemWasSelected' | translate}}
                </span>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 4" hideToggle
                                 *ngIf="hasKnowledgeBaseEntry">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(4, 'knowledgeBaseEntry'); $event.stopPropagation()">
                        <div class="panel-header--arrow">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 4? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 4}">
                        </div>

                        <span *ngIf="!chosenKnowledgeBaseEntry.length">
                           {{'Common.Articles' | translate}}
                       </span>
                        <div class="chosen-element" *ngIf="chosenKnowledgeBaseEntry.length">
                            <span class="gray-header">{{'Common.Articles' | translate}}</span>
                            <div class="flex-items">
                                <span class="cut-text">{{chosenKnowledgeBaseEntry | showAtMost: 'name'}}</span>
                                <div class="number-selected">
                                    {{chosenKnowledgeBaseEntry.length}}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids">
                    <app-data-grid [columns]="knowledgeBaseEntryColumns"
                                   [items]="knowledgeBaseEntry"
                                   [request]="requestKnowledgeBaseEntry"
                                   (loadData)="getKnowledgeBase($event)"
                                   [noTabs]="false"
                                   (itemCheckboxSelected)="selectedAddItems($event, 'knowledgeBaseEntry')"
                                   [selection]="true">
                    </app-data-grid>
                </div>
                <span *ngIf="isInvalid && !chosenKnowledgeBaseEntry.length" class="error">
                    {{'PathCreator.NoItemWasSelected' | translate}}
                </span>
            </mat-expansion-panel>

        </mat-accordion>
        <span *ngIf="isInvalid && step<0 && !(chosenTests.length || chosenTests.length || chosenTraining.length || chosenSurveys.length || chosenKnowledgeBaseEntry.length)"
              class="error-footer">
            {{'PathCreator.NoItemWasSelected' | translate}}
        </span>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button (click)="addElements()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Add' | translate}}</button>
    </div>
</div>
