<div class="certificate-preview-component" [ngClass]="{'preview-creator': previewCreator == true}">
    <div *ngIf="!dataSend" class="certificate-preview-component__default-data">
        <img src="/assets/icons/cert_grey.svg" class="certificate-preview-component__preview-image" alt="cert_grey">
        <span class="certificate-preview-component__preview-text">{{ 'CertificateCreator.CertificatePreviewDefaultText' | translate}}</span>
    </div>

    <img *ngIf="certData.backgroundBase64"
         src="{{certData.backgroundBase64}}"
         alt="backgroundBase64"
         class="certificate-preview-component__preview-background">

    <div class="certificate-preview-component__content-container">
        <img *ngIf="certData.dolineoLogoPosition"
             src="{{ appLogoUrl }}"
             alt="{{ appLogoAlt }}"
             class="certificate-preview-component__logo"
             [ngClass]="certData.dolineoLogoPosition">

        <img *ngIf="certData.orgLogoPosition && logoUrl"
             [src]="logoUrl"
             class="certificate-preview-component__logo"
             [ngClass]="certData.orgLogoPosition">

        <div class="certificate-preview-component__text" [innerHTML]="certData.text"></div>
    </div>
</div>

<div *ngIf="certData.secondPage" class="certificate-preview-component mt-2">
    <img *ngIf="certData.backgroundBase64"
         src="{{certData.backgroundBase64}}"
         alt="backgroundBase64"
         class="certificate-preview-component__preview-background">
    <div class="certificate-preview-component__content-container">
        <div class="certificate-preview-component__text" [innerHTML]="certData.secondPageContent"></div>
    </div>
</div>