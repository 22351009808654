import { FormControl } from '@angular/forms';
import { delay, catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CategoryService } from '../../api/services/category.service';

export const categoryValidator = (categoryService: CategoryService, id: number = null) => {
    let hasError = false;
    return (input: FormControl) => {
        const value = input.value.trim();
        return of(value).pipe(
            delay(500),
            filter(name => name.length > 0),
            distinctUntilChanged(),
            switchMap((name: string) => categoryService.checkCategoryName({ name, id })),
            map(() => null),
            catchError(() => {
                hasError = true;
                return of({ categoryExists: true })
            })
        );
    };
};
