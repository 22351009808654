<div class="container-fluid feedback-user-report" *ngIf="data">

    <div class="feedback-user-report__container">
        <div class="feedback-user-report__switch-download">
            <mat-slide-toggle color="primary"
                              [checked]="data.userShareReportForSupervisor"
                              *ngIf="data.canChangeUserShareReportForSupervisor"
                              (change)="changeShare($event)">
                {{'QuestionnaireCreator.ShareTheReportWithYourSupervisor' | translate}}
            </mat-slide-toggle>

            <button mat-button
                    [disabled]="false"
                    (click)="downloadReport()"
                    class="download-report-button">
                <img src="/assets/icons/download.svg"
                     loading="lazy"
                     alt="download">
                {{'FeedbackUserReport.DownloadThePDFReport' | translate}}
            </button>

            <button *ngIf="data.requireConfirmationOfResults && !isConfirmed"
                    (click)="confirmAssessment()"
                    class="btn confirm-button">{{'FeedbackUserReport.ConfirmAssessment' | translate}}</button>
            <div class="assesment-confirmed" *ngIf="isConfirmed">
                <img src="assets/icons/checked-green.svg" alt="checked-green">
                <div>
                    <span class="label">{{'QuestionnaireCreator.ConfirmedAssesment' | translate}}</span>
                    <span class="value">{{ data.confirmedDate | date : 'dd.MM.YYYY'}}</span>
                </div>
            </div>

        </div>

        <div class="top-section">
            <div class="top-section__left">
                <div class="section-name">{{'FeedbackUserReport.EvaluatedEmployee' | translate | uppercase}}</div>
                <div class="white-box">
                    <ngx-avatar [name]="data.user.name"
                                [src]="data.user.avatar"
                                size="72">
                    </ngx-avatar>
                    <div class="my-self-esteem">
                        <span class="my-self-esteem__user-name">{{data.user.name}}</span>
                        <span class="my-self-esteem__user-position">{{data.user.position}}</span>
                        <a class="my-self-esteem__user-email"
                           href="mailto:{{data.user.email}}?subject={{data.feedback.name}}">{{data.user.email}}</a>
                        <ng-container *ngIf="data.user.supervisor">
                            <div class="my-self-esteem__spacer"></div>
                            <span class="my-self-esteem__user-supervisor">
                            <span class="gray-text">{{'Common.Supervisor' | translate}}:</span> {{data.user.supervisor}}
                        </span>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="top-section__right">
                <div class="section-name">{{'QuestionnaireCreator.Competences' | translate | uppercase}}</div>
                <div class="white-box">
                    <div class="charts-gauges__box">
                        <div class="charts-gauges__box--left">
                            <div class="chart-gauge__box">
                                <app-gauge-chart
                                        [data]="setGaugeDate(getPercent(data.competences.min?.avg),
                                    'linear-gradient(228.53deg, #DB4F59 18.52%, rgba(219, 79, 89, 0) 121.96%)',
                                    50)">
                                </app-gauge-chart>

                                <div class="chart-gauge__center-box">
                                <span class="chart-gauge__center-box--rate">
                                    {{data.competences.min?.avg | number : '1.2-2'}}
                                </span>
                                    <span class="chart-gauge__center-box--percent">
                                    {{getPercent(data.competences.min?.avg)}}%
                                </span>
                                </div>

                                <div class="chart-gauge__bottom-box">
                                    {{'Feedback.Scale' | translate}}
                                    {{data.competences.minValue ? data.competences.minValue : 0}}
                                    - {{data.competences.maxValue}}
                                </div>
                            </div>
                            <div class="rated-item__box">
                            <span class="rated-item__box--title">
                                {{'FeedbackStats.LowestRatedcompetences'| translate}}
                            </span>
                                <span class="rated-item__box--name" [innerHTML]="data.competences.min?.description"></span>
                            </div>
                        </div>

                        <div class="charts-gauges__box--right">
                            <div class="chart-gauge__box">
                                <app-gauge-chart
                                        [data]="setGaugeDate(getPercent(data.competences.max?.avg),
                                    'linear-gradient(226.81deg, #35BA65 22.83%, rgba(53, 186, 101, 0) 123.44%)',
                                    50)">
                                </app-gauge-chart>
                                <div class="chart-gauge__center-box">
                                <span class="chart-gauge__center-box--rate">
                                    {{data.competences.max?.avg | number : '1.2-2'}}
                                </span>
                                    <span class="chart-gauge__center-box--percent">
                                    {{getPercent(data.competences.max?.avg)}}%
                                </span>
                                </div>

                                <div class="chart-gauge__bottom-box">
                                    {{'Feedback.Scale' | translate}}
                                    {{data.competences.minValue ? data.competences.minValue : 0}}
                                    - {{data.competences.maxValue}}
                                </div>
                            </div>
                            <div class="rated-item__box">
                            <span class="rated-item__box--title">
                                {{'FeedbackStats.TopRatedcompetences'| translate}}
                            </span>
                                <span class="rated-item__box--name" [innerHTML]="data.competences.max?.description"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-competences-section">
            <div class="section-name">{{'FeedbackUserReport.MyCompetenceAverageRating' | translate | uppercase}}</div>
            <div class="my-competences-avg white-box">
                <div class="my-competences-avg__left">
                    <div class="average-rating__top">
                        <img [src]="'/assets/images/feedback/feedback_report.png'"
                             alt="feedback_report">
                        <div class="feedback-avg-box">
                            <span class="feedback-avg-box__value">{{data.avg.avg| number : '1.2-2'}}</span>
                            <span class="feedback-avg-box__text">{{'FeedbackUserReport.AverageRating' | translate}}</span>
                        </div>
                    </div>

                    <div class="average-rating__spacer"></div>

                    <div class="average-rating__bottom">
                        <div *ngIf="data.avg.self"
                             class="average-rating__row">
                            <div class="average-rating__dot average-rating__dot--self"></div>
                            <span class="average-rating__name">{{'Feedback.SelfEsteem' | translate}}</span>
                            <span class="average-rating__value">{{data.avg.self | number : '1.2-2'}}</span>
                        </div>

                        <div *ngIf="data.avg.supervisor"
                             class="average-rating__row">
                            <div class="average-rating__dot average-rating__dot--supervisor"></div>
                            <span class="average-rating__name">{{'Common.Supervisor' | translate}}</span>
                            <span class="average-rating__value">{{data.avg.supervisor | number : '1.2-2'}}</span>
                        </div>

                        <div *ngIf="data.avg.team"
                             class="average-rating__row">
                            <div class="average-rating__dot average-rating__dot--team"></div>
                            <span class="average-rating__name">{{'Common.Team' | translate}}</span>
                            <span class="average-rating__value">{{data.avg.team | number : '1.2-2'}}</span>
                        </div>

                        <div *ngIf="data.avg.employee"
                             class="average-rating__row">
                            <div class="average-rating__dot average-rating__dot--employee"></div>
                            <span class="average-rating__name">{{'Common.Workers' | translate}}</span>
                            <span class="average-rating__value">{{data.avg.employee | number : '1.2-2'}}</span>
                        </div>

                        <div *ngIf="data.avg.coworker"
                             class="average-rating__row">
                            <div class="average-rating__dot average-rating__dot--coworker"></div>
                            <span class="average-rating__name">{{'Common.Associates' | translate}}</span>
                            <span class="average-rating__value">{{data.avg.coworker | number : '1.2-2'}}</span>
                        </div>

                        <div *ngIf="data.avg.others"
                             class="average-rating__row">
                            <div  [className]="'average-rating__dot ' + avgDotClass"></div>
                            <span class="average-rating__name">{{'Common.FeedbackOthers' | translate}}</span>
                            <span class="average-rating__value">{{data.avg.others | number : '1.2-2'}}</span>
                        </div>
                    </div>
                </div>

                <div class="my-competences-avg__right">
                    <ng-container *ngIf="data.avg.competences.labels.length > 2 && data.avg.showChart">
                        <canvas baseChart
                                [datasets]="data.avg.competences.datasets"
                                [options]="radarChartOptions"
                                [labels]="data.avg.competences.labels"
                                height="400"
                                [chartType]="'radar'"
                                class="radar-chart">
                        </canvas>
                    </ng-container>

                    <div *ngIf="data.avg.competences.labels.length <= 2 || !data.avg.showChart" class="empty-radar">
                        <img src="assets/images/feedback/empty-feedback-chart.png"
                             alt="empty-feedback-chart">
                        <span [innerHTML]="'FeedbackReport.NotEnoughDataToDisplayTheGraph' | translate"></span>
                    </div>
                </div>
            </div>
        </div>


        <div class="report-frequency white-box">
            <ng-container *ngIf="data.reportDescIsVisible && data.reportShowDescriptions">
                <h3>{{'QuestionnaireCreator.Report' | translate}}</h3>
                <div [innerHTML]="data.reportDesc | safe: 'html'"
                     class="inner-text-html quill-content"
                     *ngIf="data.reportDescIsVisible && data.reportShowDescriptions">
                </div>
            </ng-container>

            <h3>{{'FeedbackUserReport.Frequency' | translate}}</h3>
            <div [innerHTML]="data.reportDescAttendaceTable | safe: 'html'"
                 class="inner-text-html quill-content"
                 *ngIf="data.reportDescAttendanceTableIsVisible && data.reportShowDescriptions"></div>
            <div class="report-frequency__content">
                <div class="report-frequency-table">
                    <div class="report-frequency-table__header">
                        <div class="report-frequency-table__title"></div>
                        <div class="report-frequency-table__value">{{'QuestionnaireCreator.Invitees' | translate}}</div>
                        <div class="report-frequency-table__value">{{'FeedbackUserReport.HasGivenAFeedback' | translate}}</div>
                        <div class="report-frequency-table__value">
                            <ng-container
                                    *ngIf="data.allowToRefuseFeedbackToOthers">{{'FeedbackUserReport.Rejected' | translate}}</ng-container>
                        </div>
                    </div>
                    <div class="report-frequency-table__row "
                         [ngClass]="{'report-frequency-table__row--gray': idx%2 == 0}"
                         *ngFor="let att of data.attendanceTable; let idx = index;">
                        <div class="report-frequency-table__title">
                            {{att.title | translate}}
                        </div>
                        <div class="report-frequency-table__value">
                            {{att.invited}}
                        </div>
                        <div class="report-frequency-table__value">
                            {{att.finished}}
                        </div>
                        <div class="report-frequency-table__value">
                            <ng-container *ngIf="data.allowToRefuseFeedbackToOthers">{{att.rejected}}</ng-container>
                        </div>
                    </div>
                </div>

                <div class="report-frequency__summary">
                    <h6>{{'FeedbackUserReport.Summary' | translate}}</h6>
                    <div class="report-frequency__summary--rows">
                        <div class="report-frequency__summary--row">
                            <div class="summary-text">{{'QuestionnaireCreator.Invitees' | translate}}:</div>
                            <div class="summary-value">{{data.attendanceSummary.invited}}</div>
                        </div>

                        <div class="report-frequency__summary--row">
                            <div class="summary-text">{{'FeedbackUserReport.HasGivenAFeedback' | translate}}:</div>
                            <div class="summary-value">{{data.attendanceSummary.finished}}</div>
                        </div>

                        <div class="report-frequency__summary--row" *ngIf="data.allowToRefuseFeedbackToOthers">
                            <div class="summary-text">{{'FeedbackUserReport.Rejected' | translate}}:</div>
                            <div class="summary-value">{{data.attendanceSummary.rejected}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="area-details white-box" *ngIf="data.competencesInAreas.length > 0">
            <h3>{{'FeedbackUserReport.AssessmentOfCompetenciesInTheAreas' | translate}}</h3>
            <div [innerHTML]="data.reportDescArea | safe: 'html'"
                 class="inner-text-html quill-content"
                 *ngIf="data.reportDescAreaIsVisible && data.reportShowDescriptions"></div>

            <ng-container *ngFor="let area of data.competencesInAreas; let aidx = index;">
                <div class="area-details__row"
                     *ngIf="area.chartData.length">
                    <div class="area-details__row--name">
                        <span class="f-w-300">{{'QuestionnaireCreator.Area' | translate | uppercase}}
                            :</span> {{area.name}}
                    </div>

                    <div class="bar-horizontal-container" #containerRef>
                        <div class="bar-horizontal-container__left">
                            <div *ngFor="let chartData of area.chartData" class="bar-horizontal-container__row">

                                <div *ngIf="chartData.name !== 'others'" class="bar-horizontal-container__dot average-rating__dot--{{ chartData.name }}"></div>
                                <div *ngIf="chartData.name === 'others'" [className]="'bar-horizontal-container__dot ' + avgDotClass"></div>

                                <div class="bar-horizontal-container__row__label">{{getTranslateAttendance(chartData.name) | translate}}</div>
                                <ng-container *ngIf="!data.reportHideNumbers">
                                    <span class="bar-horizontal-container__row__value">{{chartData.value| number : '1.2-2'}}</span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="bar-horizontal-container__bar-chart">
                            <span  matTooltip="{{ 'FeedbackUserReport.OrganizationAvg' | translate }}"
                                   matTooltipPosition="above"
                                   matTooltipClass='above'
                                   *ngIf="data.organizationResultsInUserReport" class="bar-horizontal-container__bar-chart--avg-cursor" [ngStyle]="{'left': getAvg((containerRef.offsetWidth > 694 ? 750 : containerRef.offsetWidth), xAxisTicks.length,area.organizationAvg) + 'px'}"></span>
                            <span *ngIf="data.organizationResultsInUserReport" class="bar-horizontal-container__bar-chart--number" [ngStyle]="{'left': getAvg((containerRef.offsetWidth > 694 ? 750 : containerRef.offsetWidth), xAxisTicks.length,area.organizationAvg,true) + 'px'}">{{area.organizationAvg | number : '1.2-2'}}</span>
                            <ngx-charts-bar-horizontal
                                    [results]="area.chartData"
                                    [view]="containerRef.offsetWidth > 694 ? [750,(area.chartData.length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(area.chartData.length*24)+barHorizontalHeight]"
                                    [scheme]="{ domain: area.chartColors }"
                                    [barPadding]="2"
                                    [xAxis]="true"
                                    [animations]="false"
                                    [yAxis]="false"
                                    [roundEdges]="false"
                                    [tooltipDisabled]="true"
                                    [showXAxisLabel]="false"
                                    [xScaleMax]="data.competences.maxValue"
                                    [xAxisTickFormatting]="xAxisTickFormattingHiddeNumber"
                                    [xAxisTicks]="xAxisTicks"
                                    [showYAxisLabel]="false">
                            </ngx-charts-bar-horizontal>
                        </div>
                    </div>
                    <div *ngIf="data.organizationResultsInUserReport && aidx === 0" class="bar-horizontal-container__bar-chart-legend">
                        <span class="bar-horizontal-container__bar-chart-legend--avg-legend-cursor"></span>{{ 'FeedbackUserReport.OrganizationAvg' | translate }}
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="report-section-description white-box">
            <h3>{{'FeedbackUserReport.DetailedAssessmentOfCompetencies' | translate}}</h3>
            <div *ngIf="data.reportDescCompetenceIsVisible && data.reportShowDescriptions"
                 [innerHTML]="data.reportDescCompetence | safe: 'html'"
                 class="inner-text-html quill-content"></div>
        </div>

        <div class="competence-details white-box" *ngIf="data.avg.avg">
            <ng-container *ngFor="let area of data.areas; let aidx = index;">
                <ng-container *ngFor="let competence of area.competences; let cidx = index;">
                    <div class="competence-details__container">
                        <div class="competence-details__title">
                            {{'QuestionnaireCreator.Competence' | translate | uppercase}} {{competence.index}}
                        </div>
                        <div class="competence-details__description">
                            {{competence.description}}
                        </div>

                        <div *ngIf="competence.areaOutOfAverage === 1">{{ 'FeedbackReport.CompetenceOutOfAverage' | translate }}</div>
                        <div *ngIf="!competence.chartData?.length && competence.hasOnlyOpenQuestions === 0 && !competence.areaOutOfAverage">{{ 'FeedbackReport.NotEnoughDataToDisplayTheGraph' | translate }}</div>
                        <div *ngIf="!competence.chartData?.length && competence.hasOnlyOpenQuestions === 1 && !competence.areaOutOfAverage">{{ 'FeedbackReport.CompetenceContainsOnlyOpenQuestions' | translate }}</div>
                        <div *ngIf="competence.chartData?.length" class="bar-horizontal-container" #containerRef>
                            <div class="bar-horizontal-container__left">
                                <div class="bar-horizontal-container__avg" *ngIf="!data.reportHideNumbers">
                                    <span>{{'FeedbackUserReport.AverageRating' | translate}}</span>
                                    <span>{{competence.avg.avg| number : '1.2-2'}}</span>
                                </div>
                                <div *ngFor="let chartData of competence.chartData"
                                     class="bar-horizontal-container__row">

                                    <div *ngIf="chartData.name !== 'others'" class="bar-horizontal-container__dot average-rating__dot--{{ chartData.name }}"></div>
                                    <div *ngIf="chartData.name === 'others'" [className]="'bar-horizontal-container__dot ' + avgDotClass"></div>

                                    <div class="bar-horizontal-container__row__label">{{getTranslateAttendance(chartData.name) | translate}}</div>
                                    <ng-container *ngIf="!data.reportHideNumbers">
                                        <span class="bar-horizontal-container__row__value">{{chartData.value| number : '1.2-2'}}</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="bar-horizontal-container__bar-chart">
                                <span  matTooltip="{{ 'FeedbackUserReport.OrganizationAvg' | translate }}"
                                       matTooltipPosition="above"
                                       matTooltipClass='above'
                                       *ngIf="data.organizationResultsInUserReport" class="bar-horizontal-container__bar-chart--avg-cursor" [ngStyle]="{'left': getAvg((containerRef.offsetWidth > 694 ? 750 : containerRef.offsetWidth), xAxisTicks.length,competence.organizationAvg) + 'px'}"></span>
                                <span *ngIf="data.organizationResultsInUserReport" class="bar-horizontal-container__bar-chart--number" [ngStyle]="{'left': getAvg((containerRef.offsetWidth > 694 ? 750 : containerRef.offsetWidth), xAxisTicks.length,competence.organizationAvg,true) + 'px'}">{{competence.organizationAvg | number : '1.2-2'}}</span>
                                <ngx-charts-bar-horizontal
                                        [results]="competence.chartData"
                                        [view]="containerRef.offsetWidth > 694 ? [750,(competence.chartData.length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(competence.chartData.length*24)+barHorizontalHeight]"
                                        [scheme]="{ domain: competence.chartColors }"
                                        [barPadding]="2"
                                        [xAxis]="true"
                                        [animations]="false"
                                        [yAxis]="false"
                                        [roundEdges]="false"
                                        [tooltipDisabled]="true"
                                        [showXAxisLabel]="false"
                                        [xScaleMax]="data.competences.maxValue"
                                        [xAxisTickFormatting]="xAxisTickFormattingHiddeNumber"
                                        [xAxisTicks]="xAxisTicks"
                                        [showYAxisLabel]="false">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="report-section-description white-box">
            <h3>{{'FeedbackUserReport.BehaviorDetails' | translate}}</h3>
            <div *ngIf="data.reportDescBehaviorIsVisible && data.reportShowDescriptions"
                 [innerHTML]="data.reportDescBehavior | safe: 'html'"
                 class="inner-text-html quill-content"></div>
        </div>

        <ng-container *ngFor="let area of data.areas; let aidx = index;">
            <div class="behaviour-details white-box"
                 *ngFor="let competence of area.competences; let cidx = index;">
                <div class="behaviour-details__row" *ngFor="let behaviour of competence.behaviours; let bidx = index;">
                    <div class="behaviour-details__area" *ngIf="cidx === 0 && bidx === 0">
                        <span class="behaviour-details__area--left">
                            <span class="f-w-300">{{'QuestionnaireCreator.Area' | translate | uppercase}}: </span>
                            {{area.name}}
                        </span>

                        <div class="behaviour-details__area--right">
                            <span>{{'QuestionnaireCreator.Competences'| translate}}</span>
                            <div>{{area.competences.length}}</div>
                        </div>
                    </div>

                    <ng-container *ngIf="bidx === 0">
                        <div class="behaviour-details__competence--number">
                            {{'QuestionnaireCreator.Competence' | translate | uppercase}} {{competence.index}}
                        </div>
                        <div class="behaviour-details__competence--name">
                            {{competence.description}}
                        </div>
                    </ng-container>

                    <div class="behaviour-details__behavior">
                        <div class="behaviour-details__behavior--name">
                            {{behaviour.description}}
                            <ng-container
                                    *ngIf="behaviour.questionType === 'open'">{{ 'FeedbackStats.OpenQuestion' | translate }}</ng-container>
                            <span class="red-star" *ngIf="behaviour.isRequired"> * </span>
                        </div>

                        <div *ngIf="behaviour.avg === null && behaviour.questionType === 'close';else second">{{ 'FeedbackReport.CanNotDisplayTheGraph' | translate }}</div>

                        <ng-template #second>
                            <div *ngIf="(behaviour.questionType !== 'open' && !behaviour.distribution && behaviour.avg === null)
                                    || (behaviour.questionType === 'open' && behaviour.answers.length === 0)">{{ 'Common.NoAnswer' | translate }}</div>
                            <div *ngIf="behaviour.avg === null && behaviour.distribution && behaviour.questionType !== 'open'">{{ 'Common.NoAnswer' | translate }}</div>
                        </ng-template>

                        <ng-container *ngIf="behaviour.avg !== null && behaviour.questionType !== 'open'">
                            <div class="bar-horizontal-container" #containerRef
                                 *ngIf="behaviour.chartData?.length && behaviour.questionType !=='open'">
                                <div class="bar-horizontal-container__left">
                                    <div class="bar-horizontal-container__avg" *ngIf="!data.reportHideNumbers">
                                        <span>{{'FeedbackUserReport.AverageRating' | translate}}</span>
                                        <span>{{behaviour.avg.avg| number : '1.2-2'}}</span>
                                    </div>

                                    <div *ngFor="let chartData of behaviour.chartData"
                                         class="bar-horizontal-container__row">
                                        <div *ngIf="chartData.name !== 'others'" class="bar-horizontal-container__dot average-rating__dot--{{ chartData.name }}"></div>
                                        <div *ngIf="chartData.name === 'others'" [className]="'bar-horizontal-container__dot ' + avgDotClass"></div>

                                        <div class="bar-horizontal-container__row__label">{{getTranslateAttendance(chartData.name) | translate}}</div>
                                        <ng-container *ngIf="!data.reportHideNumbers">
                                            <span class="bar-horizontal-container__row__value">{{(behaviour.fromZero ? chartData.value - 1 : chartData.value)| number : '1.2-2'}}</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="bar-horizontal-container__bar-chart">
                                    <span matTooltip="{{ 'FeedbackUserReport.OrganizationAvg' | translate }}"
                                            matTooltipPosition="above"
                                            matTooltipClass='above'
                                            *ngIf="data.organizationResultsInUserReport" class="bar-horizontal-container__bar-chart--avg-cursor" [ngStyle]="{'left': getAvg((containerRef.offsetWidth > 694 ? 750 : containerRef.offsetWidth), behaviour.xAxisTicks.length,behaviour.organizationAvg) + 'px'}"></span>
                                    <span *ngIf="data.organizationResultsInUserReport" class="bar-horizontal-container__bar-chart--number" [ngStyle]="{'left': getAvg((containerRef.offsetWidth > 694 ? 750 : containerRef.offsetWidth), behaviour.xAxisTicks.length,behaviour.organizationAvg,true) + 'px'}">{{behaviour.organizationAvg | number : '1.2-2'}}</span>
                                    <ngx-charts-bar-horizontal
                                            [results]="behaviour.chartData"
                                            [view]="containerRef.offsetWidth > 694 ? [750,(behaviour.chartData.length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(behaviour.chartData.length*24)+barHorizontalHeight]"
                                            [scheme]="{ domain: behaviour.chartColors }"
                                            [barPadding]="2"
                                            [xAxis]="true"
                                            [animations]="false"
                                            [yAxis]="false"
                                            [roundEdges]="false"
                                            [tooltipDisabled]="true"
                                            [showXAxisLabel]="false"
                                            [xAxisTickFormatting]="behaviour.fromZero? xAxisTickFormattingFromZero : xAxisTickFormattingHiddeNumber"
                                            [xScaleMax]="behaviour.maxValue"
                                            [xAxisTicks]="behaviour.xAxisTicks"
                                            [showYAxisLabel]="false">
                                    </ngx-charts-bar-horizontal>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="behaviour.questionType !=='open' && data.reportShowDistributionOfResponses">
                            <div class="response-distribution-outer"  [ngClass]="{'feedback-report-vertical-table' : countElements(behaviour.answers) > 10}">
                                <div class="response-distribution__headers">
                                    <div class="response-distribution__headers--left">
                                        {{'FeedbackUserReport.ResponseDistribution' | translate}}
                                    </div>
                                    <div class="response-distribution__headers--table" *ngIf="countElements(behaviour.answers) > 10">
                                        <div *ngFor="let distribution of behaviour.distributionArray"
                                             class="response-distribution__left--row">
                                            {{getTranslateAttendance(distribution.name) | translate}}
                                            <ng-container *ngIf="!data.reportHideNumbers">
                                                <span>{{distribution.avg}}</span>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="response-distribution__headers--table" *ngIf="countElements(behaviour.answers) <= 10">
                                        <ng-container *ngIf="behaviour.displayAs !=='range'">
                                            <div class="answer-text"
                                                 *ngFor="let answer of behaviour.answers">
                                                {{(answer.answer.length > 80) ? (answer.answer | slice:0:80) + '...' : (answer.answer)}}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="behaviour.displayAs ==='range'">
                                            <div class="answer-text answer-text__left">
                                                {{behaviour.scaleMinDescription}}&nbsp;
                                            </div>
                                            <div class="answer-text answer-text__right">
                                                {{behaviour.scaleMaxDescription}}&nbsp;
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="response-distribution__headers--right" *ngIf="behaviour.noValueAnswers">
                                        {{'Common.NoValueAnswers' | translate}}
                                    </div>
                                    <div class="response-distribution__headers--right" *ngIf="behaviour.emptyResponses">
                                        {{'Common.NoAnswer' | translate}}
                                    </div>
                                </div>

                                <div class="response-distribution"
                                     *ngIf="behaviour.questionType !=='open' && data.reportShowDistributionOfResponses && behaviour.distribution">

                                    <div class="response-distribution__left" *ngIf="countElements(behaviour.answers) <= 10">
                                        <div *ngFor="let distribution of behaviour.distributionArray"
                                             class="response-distribution__left--row">
                                            {{getTranslateAttendance(distribution.name) | translate}}
                                            <ng-container *ngIf="!data.reportHideNumbers">
                                                <span>{{distribution.avg}}</span>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="response-distribution__left" *ngIf="countElements(behaviour.answers) > 10">
                                        <ng-container *ngIf="behaviour.displayAs !=='range'">
                                            <div class="answer-text"
                                                 *ngFor="let answer of behaviour.answers">
                                                {{(answer.answer.length > 80) ? (answer.answer | slice:0:80) + '...' : (answer.answer)}}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="behaviour.displayAs ==='range'">
                                            <div class="answer-text answer-text__left">
                                                {{behaviour.scaleMinDescription}}&nbsp;
                                            </div>
                                            <div class="answer-text answer-text__right">
                                                {{behaviour.scaleMaxDescription}}&nbsp;
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="response-distribution__table">

                                        <ng-container *ngIf="countElements(behaviour.answers) > 10" >
                                            <ng-container *ngFor="let distribution of behaviour.distributionArray" >
                                                <div class="feedback-report-vertical-table-column">
                                                    <div class="vertical-table-column-title">
                                                        {{getTranslateAttendance(distribution.name) | translate}}
                                                        <ng-container *ngIf="!data.reportHideNumbers">
                                                            <span>{{distribution.avg}}</span>
                                                        </ng-container>
                                                    </div>
                                                    <table>
                                                        <tr *ngFor="let distributionValue of distribution.valueArray; let i = index">
                                                            <td  [ngStyle]="{'height': distribution.items[i] == 2 ? '40px' : '20px'}">
                                                                {{distributionValue ? distributionValue : '-'}}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <table *ngIf="countElements(behaviour.answers) <= 10">
                                            <tr *ngFor="let distribution of behaviour.distributionArray">
                                                <td *ngFor="let distributionValue of distribution.valueArray">
                                                    {{distributionValue ? distributionValue : '-'}}
                                                </td>
                                            </tr>
                                        </table>

                                        <div class="response-distribution__table--under">
                                            <ng-container *ngIf="behaviour.displayAs ==='range'">
                                                <div *ngFor="let scale of [].constructor(behaviour.maxValue); let scaleMaxIndex = index"
                                                     class="answer-value">
                                                    {{behaviour.fromZero ? scaleMaxIndex : scaleMaxIndex + 1}}
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="behaviour.displayAs !=='range'">
                                                <div [ngStyle]="answer.answer.length > 80 ? {'line-height': '40px', 'height': '40px'} : {}" *ngFor="let answer of behaviour.answers"
                                                     class="answer-value">
                                                    {{answer.value}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="response-distribution__right" *ngIf="behaviour.noValueAnswers">
                                        <table>
                                            <tr *ngFor="let noValueAnswer of behaviour.noValueAnswersArray">
                                                <td>
                                                    {{noValueAnswer > 0 ? noValueAnswer : '-'}}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="response-distribution__right" *ngIf="behaviour.emptyResponses">
                                        <table>
                                            <tr *ngFor="let emptyResponse of behaviour.emptyResponsesArray">
                                                <td>
                                                    {{emptyResponse > 0 ? emptyResponse : '-'}}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="behaviour.questionType ==='open'">
                            <div class="table-answer-open">
                                <div *ngFor="let answer of behaviour.answers; let aidx = index;"
                                     class="table-answer-open__row"
                                     [ngClass]="{'table-answer-open__row--odd': aidx%2 == 0}">
                                    <span class="table-answer-open__row--number">{{aidx + 1}}.</span>
                                    <div class="table-answer-open__row--value">{{answer.answer}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="report-section-description white-box"
             *ngIf="data.devTips.userIndividualDevTipIsVisible && data.reportShowDescriptions">
            <h3>{{'QuestionnaireCreator.DevelopmentTips' | translate}}</h3>
            <div [innerHTML]="data.reportDescDevTip | safe: 'html'" class="inner-text-html quill-content"></div>
        </div>

        <div class="report-section-description individual-dev-tip white-box"
             *ngIf="devTipsIsVisible && data.devTips.userIndividualDevTip">
            <h3>{{'QuestionnaireCreator.IndividualDevelopmentTips' | translate}}</h3>

            <div [innerHTML]="data.devTips.userIndividualDevTip | safe: 'html'"
                 class="inner-text-html quill-content"></div>
        </div>
    </div>
</div>
