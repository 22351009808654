import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { ApiService } from '../../../api/api.service';

@Component({
    selector: 'app-maintenance-mode-page',
    templateUrl: './maintenance-mode-page.component.html',
    styleUrls: ['./maintenance-mode-page.component.scss']
})
export class MaintenanceModePageComponent implements OnInit, OnDestroy {

    contentText: string;

    constructor(private _helper: HelperService,
                private api: ApiService) {
        this._helper.footerVisible = false;
    }

    ngOnInit(): void {
        this.api.User.getMaintenanceMode().subscribe(value => {
            this.contentText = value.description;
        })
    }

    ngOnDestroy() {
        this._helper.footerVisible = true;
    }
}
