import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-product-groups-dialog',
    templateUrl: './product-groups-dialog.component.html',
    styleUrls: ['./product-groups-dialog.component.scss']
})
export class ProductGroupsDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ProductGroupsDialogComponent>) {
    }
}
