<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>
<div class="assign-expiry-date">
    <div mat-dialog-content>
        <form [formGroup]="form">
            <div class="assign-expiry-date__rows">
                <div class="assign-expiry-date__rows__row calendar">
                    <div *ngIf="disabled" class="calendar-disabled"></div>
                    <mat-calendar [(selected)]="selected" (selectedChange)="onSelectedChange($event)"></mat-calendar>
                </div>
                <div class="assign-expiry-date__rows__row days">

                    <div>
                        <div style="position: relative;">
                            <mat-slide-toggle style="position: relative; top: 2px;"
                                              formControlName="hasDays"
                                              color="primary">
                            </mat-slide-toggle>
                        </div>
                    </div>

                    <div class="days--text">
                        {{'AssignDialog.ExpiryDate' | translate}}
                        <span style="position: relative; width: 65px; display: inline-block; height: 41px;">
                            <mat-form-field style="position: absolute; top: 10px; width: 60px;">
                                <input digitOnly [decimal]="true" matInput
                                       [readonly]="!disabled"
                                       placeholder="{{'Common.Number' | translate}} *"
                                       formControlName="days"  style="width: 50px;">
                            </mat-form-field>
                        </span>
                        <span *ngIf="data.type === 'training'">{{'AssignDialog.ExpiryDateDaysInfoTraining' | translate}}</span>
                        <span *ngIf="data.type === 'path'">{{'AssignDialog.ExpiryDateDaysInfoPath' | translate}}</span>
                    </div>

                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <ng-container>
            <button mat-flat-button (click)="dialogRef.close()" color="primary"
                    class="cancel-button">{{'Common.Cancel' | translate}}</button>
            <button mat-button color="primary" *ngIf="disabled"
                    class="dolineo-button blue-button" (click)="save()" [disabled]="!form.valid">{{'Common.Save' | translate}}</button>
        </ng-container>
    </div>
</div>
