import {Component, Inject, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../../../api/api.service';
import {HelperService} from '../../services/helper.service';
import {ApproveRejectTaskDialogComponent} from '../approve-reject-task-dialog/approve-reject-task-dialog.component';
import {
    displayTrainingType as _displayTrainingType,
    downloadFile,
    downloadZip,
    getIcon,
    normalizedLetter
} from '../../helpers';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import * as moment from 'moment';
import {
    OnboardingCoordinatorTaskConfirmationDialogComponent
} from '../onboarding-coordinator-task-confirmation-dialog/onboarding-coordinator-task-confirmation-dialog.component';

@Component({
    selector: 'app-onboarding-coordinator-task-dialog',
    templateUrl: './onboarding-coordinator-task-dialog.component.html',
    styleUrls: ['./onboarding-coordinator-task-dialog.component.scss']
})
export class OnboardingCoordinatorTaskDialogComponent implements OnInit {
    apiUrl = environment.filesPath;
    onboarding: { id: number, name: string }
    coordinatorTasks: any;
    userName: string;
    showButtons: boolean;
    fromComponent: string;
    userId: number;
    params: string;
    checkList: number[] = [];
    public task: any = null;
    expanded: boolean = true;
    public filenamePreview: string = '';
    preview: boolean;
    onlyForMe: boolean
    tasks: any[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _api: ApiService,
                private _toastrService: ToastrService,
                private _helper: HelperService,
                private _dialog: MatDialog,
                private toastrService: ToastrService,
                private _translate: TranslateService,
                public dialogRef: MatDialogRef<OnboardingCoordinatorTaskDialogComponent>) {
        this.onboarding = this.data.onboarding;
        this.userName = this.data.userName;
        this.fromComponent = this.data.fromComponent;
        this.userId = this.data.userId;
        this.params = this.data.params;
        this.preview = this.data.preview;
    }

    ngOnInit(): void {
        this.getData();
    }

    getData(showTasks: boolean = false) {
        switch (this.fromComponent) {
            case 'org':
                this._api.Onboarding.getOnboardingCoordinatorsTasksOrg(this._helper.getUser().companyId, this.onboarding.id, this.userId, this.params).subscribe(val => {
                    this.setData(val)
                })
                break;
            case 'team':
                this._api.Onboarding.getOnboardingCoordinatorsTasksTeam(this._helper.getUser().id, this.onboarding.id, this.userId, this.params).subscribe(val => {
                    this.setData(val)
                })
                break;
        }
    }

    changeToogle(event: { source: any, checked: boolean }) {

        this.onlyForMe = event.checked
        if (this.onlyForMe) {
            this.tasks = [...this.coordinatorTasks.tasks].filter(val => val.coordinatorId === this._helper.getUser().id)
        } else {
            this.tasks = [...this.coordinatorTasks.tasks]
        }
    }

    changeCheckbox(event: { source: any, checked: boolean }, id: number) {
        if (event.checked) {
            this.checkList.push(id)
        } else {
            this.checkList = this.checkList.filter(val => val !== id)
        }
    }

    previewTask(id) {
        this.checkList = [];
        this._api.Onboarding.getTaskPlayer(this.userId, id).subscribe(task => {
            this.task = {...task}
        })
    }

    finishTask(task: any) {
        // this.finish([task.id]);
        let params = {};
        if (task.attachmentRequired || task.attachmentRequired === null) {
            params = {
                width: '40vw',
            }
        }
        this._dialog.open(OnboardingCoordinatorTaskConfirmationDialogComponent, {
            disableClose: true,
            // height: '80vh',
            // panelClass: 'filter-popup',
            data: {
                task: task
            },
            ...params
        }).afterClosed().subscribe((returnData) => {
            if (returnData) {

                if (returnData.selectedFile) {
                    const formData = new FormData();
                    formData.append('file', returnData.selectedFile, returnData.selectedFile.name);
                    formData.append('userId', this.userId.toString());
                    this._api.Onboarding.uploadFileToCoordinatorTask(this._helper.getUser().id, task.id, this.onboarding.id, formData).subscribe(() => {
                        this.finish([task.id], true);
                    });
                } else {
                    this.finish([task.id]);
                }
            }
        })
    }

    finishTaskList() {
        this._dialog.open(OnboardingCoordinatorTaskConfirmationDialogComponent, {
            disableClose: true,
            // width: '40vw',
            // height: '80vh',
            // panelClass: 'filter-popup',
            data: {
                hasMany: true,
                count: this.checkList.length
            }
        }).afterClosed().subscribe((returnData) => {
            if (returnData) {
                this.finish([...this.checkList]);
            }
        })
    }


    finish(ids, reload = false) {
        this._api.Onboarding.finishCoordinatorTask(this._helper.getUser().id, {
            ids: [...ids],
            onboardingId: this.onboarding.id,
            userId: this.userId
        }).subscribe(() => {
            this._toastrService.success(this._translate.instant('Onboarding.FinishedCoordinatorTasksToastr'))
            this.tasks.forEach(val => {
                if (ids.includes(val.id)) {
                    val.finishedAt = moment();
                    val.finishedBy = this._helper.getUser().name + ' ' + this._helper.getUser().surname;
                }
            })
            if (this.checkList.length > 0) {
                this.checkList = [];
            }
            if (reload) {
                this.getData();
            }
        })
    }


    nextUser() {
        this.userId = this.coordinatorTasks.nextUserId
        this.getData();
    }

    previousUser() {
        this.userId = this.coordinatorTasks.prevUserId
        this.getData();
    }

    setData(val: any) {
        this.coordinatorTasks = {...val}
        this.userName = val.username;
        this.tasks = [...val.tasks];
        this.showButtons = val.total > 1 && this.data.showButtons;
        this.checkList = [];
    }

    setExpanded() {
        this.expanded = !this.expanded;
    }

    downloadFile(attachement: any) {
        this._api.download(attachement.fileName).subscribe(data => {
            downloadFile(data, attachement.origFileName)
            this.toastrService.success(this._translate.instant('KnowledgeBase.DownloadedAttachment'));
        })
    }

    downloadAll() {
        this._api.Onboarding.downloadAllTaskFile(this._helper.getUser().id, this.task.id).subscribe((res: HttpResponse<Blob>) => {
            const dateFile = moment().format('YYYYMMDD_HHmm')
            const taskName = this.task.name.replace(/\s+/g, '_')
            const fileName = normalizedLetter(`${taskName}_${dateFile}`);
            downloadZip(fileName, res);
            this.toastrService.success(this._translate.instant('KnowledgeBase.DownloadedAttachments'));
        })
    }

    downloadInputFile() {
        //
    }

    backToTasks() {
        this.task = null;
    }

    getIconName(mimeType: string): string {
        return getIcon(mimeType);
    }

    downloadAttachment(attachement: any) {
        this._api.download(attachement.fileName).subscribe(data => {
            downloadFile(data, attachement.origFileName)
            this.toastrService.success(this._translate.instant('KnowledgeBase.DownloadedAttachment'));
        })
    }

    isPast(date: string): boolean {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        const d2 = new Date(date);
        d2.setHours(0, 0, 0, 0);
        return !(new Date(date).getTime() >= d.getTime())
    }

}
