import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationError, NavigationCancel, NavigationStart } from '@angular/router';
import { APPCONFIG } from './config';
import { LayoutService } from './layout/layout.service';
import * as jQuery from 'jquery';

// 3rd
import 'styles/material2-theme.scss';
import 'styles/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/app.scss';
import { HelperService } from './shared/services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppLocaleService } from './core/services/locale.service';
import { ApiService } from './api/api.service';
import { environment } from '../environments/environment';
import { interval, Subject } from 'rxjs';
import {AuthService} from './core/services/auth.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { filter } from 'rxjs/operators';
import {ReportService} from './shared/services/report.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [LayoutService],
})
export class AppComponent implements OnInit, OnDestroy {
    public AppConfig: any;
    public loading = false;

    private langKey: string = 'currentLang';

    selfAutoUpdateSubject = new Subject<void>();

    constructor(private router: Router,
                private helper: HelperService,
                private dialog: MatDialog,
                private _api: ApiService,
                private translate: TranslateService,
                private localeService: AppLocaleService,
                private auth: AuthService,
                private reportService: ReportService) {

        const subdomain = window.location.hostname.replace(environment.domain, '').replace('.', '').toLowerCase();
        if (environment.production === true && subdomain !== '') {
            this._api.getLoginPage(subdomain).subscribe(value => {
                this._api.layout.next(value);
            }, () => {
                window.open('https://' + environment.domain, '_self');
            });
        } else {
            this._api.getGlobalLoginPage().subscribe(value => {
                this._api.layout.next(value);
            });
        }

        this.refreshSideBar();
    }



    ngOnInit() {
        this.AppConfig = APPCONFIG;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.localeService.history.push(event.urlAfterRedirects);
            }
        })
        const lang = JSON.parse(localStorage.getItem('dolineouser'))?.interfaceLanguageCode || localStorage.getItem(this.langKey) || 'pl';
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
        this.localeService.setLocale(lang);
        localStorage.setItem(this.langKey, lang);

        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = false;
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    window.scrollTo(0, 0);
                    break;
                }
                default: {
                    window.scrollTo(0, 0);
                    break;
                }
            }
        });

        interval(60000)
            .pipe(
                filter(() => this.auth.isAuthenticated),
                switchMap(() => this._api.User.self(true)),
                takeUntil(this.selfAutoUpdateSubject)
            )
            .subscribe((loggedUser) => {
                const tmp = JSON.parse(localStorage.getItem('dolineouser'));
                const copy = JSON.parse(JSON.stringify(loggedUser));
                if (tmp.id === loggedUser.id) {
                    loggedUser.fileToken = tmp.fileToken;
                    loggedUser.avatarUrl = tmp.avatarUrl;
                    loggedUser.lastActivity = tmp.lastActivity;
                }
                if (JSON.stringify(loggedUser) !== localStorage.getItem('dolineouser')) {
                    localStorage.setItem('dolineouser', JSON.stringify(loggedUser));
                    this.helper.refreshUser();
                }
                if (tmp.id === loggedUser.id) {
                    loggedUser.fileToken = copy.fileToken;
                    loggedUser.avatarUrl = copy.avatarUrl;
                    loggedUser.lastActivity = copy.lastActivity;
                    localStorage.setItem('dolineouser', JSON.stringify(loggedUser));
                }
            });
    }

    ngOnDestroy() {
        this.selfAutoUpdateSubject.next();
        this.selfAutoUpdateSubject.complete();
    }

    refreshSideBar() {
        if (localStorage.getItem('dolineouser') && !(this.helper.isSuperAdmin() || this.helper.isSysAdmin())) {
            this._api.User.self().subscribe((loggedUser) => {
                if (JSON.stringify(loggedUser) !== localStorage.getItem('dolineouser')) {
                    localStorage.setItem('dolineouser', JSON.stringify(loggedUser));
                    this.helper.refreshUser();
                }
            });
        }
    }

    refreshUserData() {
        console.log('refresh', new Date());
    }
}

