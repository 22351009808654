import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { LoaderService } from '../../components/loader/loader.service';
import { ChoiceBaseGraphicComponent } from '../choice-base-graphic/choice-base-graphic.component';
import { ApiService } from '../../../api/api.service';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-crop-image',
    templateUrl: './crop-image.component.html',
    styleUrls: ['./crop-image.component.scss']
})
export class CropImageComponent implements OnInit {

    @ViewChild('file') file;

    imageChangedEvent: any = '';
    croppedImage: any = '';

    public aspectRatio: number = 4 / 3;
    public resizeToWidth: number = 256;
    public resizeToHeight: number = 256;
    public getImageName: boolean = false;
    public fileIsSelected: boolean;
    public errorMessage: string = '';
    public imageName: string = '';
    public noImageError: boolean = false;
    public isSVG: boolean = false;
    public cropperIsReady: boolean = false;
    baseGraphics: any[] = [];
    fileExtension: OutputFormat;

    constructor(public dialogRef: MatDialogRef<CropImageComponent>,
                private loaderService: LoaderService,
                private _api: ApiService,
                private dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.aspectRatio = this.data.aspectRatio;
        this.resizeToWidth = this.data.resizeToWidth;
        this.resizeToHeight = this.data.resizeToHeight;
        this.getImageName = this.data.getImageName;
        this.imageName = this.data.imageName ? this.data.imageName : '';
        this.croppedImage = this.data.base64 ? this.data.base64 : null;

        if (this.data.showBaseGraphic) {
            this.getBaseGraphics();
        }
    }

    getBaseGraphics() {
        const type = this.data.type ? this.data.type : '';
        this._api.Admin.getBaseGraphics(type).subscribe(graphics => {
            this.baseGraphics = [...graphics.elements];
        })
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    validateFile(event: any): void {
        const file = event.target.files[0];
        this.errorMessage = '';

        if (file.name.toLowerCase().indexOf('.png') === -1
            && file.name.toLowerCase().indexOf('.jpg') === -1
            && file.name.toLowerCase().indexOf('.jpeg') === -1
            && file.name.toLowerCase().indexOf('.webp') === -1
            && !this.isSVG) {
            this.imageChangedEvent = null;
            this.imageName = '';
            this.errorMessage = 'CropImg.AcceptableFileFormatsAreJPGJPEGAndPNG';
        } else if (file.name.toLowerCase().indexOf('.svg') === -1 && this.isSVG) {
            this.imageChangedEvent = null;
            this.imageName = '';
            this.errorMessage = 'CropImg.AcceptableFileFormatIsSVG';
        } else if (file.size > 10506316) {
            this.errorMessage = 'CropImg.TheMaximumFileSizeIs10MB';
            return;
        } else {
            const fileExtension = file.name.split('.').at(-1)
            if( fileExtension === 'jpg'){
                this.fileExtension = 'jpeg'
            } else {
                this.fileExtension = file.name.split('.').at(-1)
            }

            this.loaderService.show();

            this.fileIsSelected = true;
            this.imageName = file.name;

            if (this.isSVG) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.imageChangedEvent = reader.result as string;
                    this.loaderService.hide();
                };
            } else {
                this.imageChangedEvent = event;
            }
        }
    }

    selectFile(isSVG: boolean = false): void {
        this.isSVG = isSVG;
        this.file.nativeElement.click();
    }

    cropperReady() {
        this.loaderService.hide();

        this.cropperIsReady = true;
    }

    loadImageFailed() {
        this.loaderService.hide();
    }

    imageLoaded() {
        this.fileIsSelected = true;
        this.noImageError = false;
    }

    acceptCrop(): void {
        if (this.croppedImage !== null || this.imageChangedEvent !== null) {
            if (this.getImageName) {
                this.dialogRef.close({
                    imageName: this.imageName,
                    base64: this.croppedImage || this.imageChangedEvent
                });
            } else {
                this.dialogRef.close(this.croppedImage);
            }
        } else {
            this.dialogRef.close(null);
        }
    }

    removeFile() {
        this.errorMessage = '';
        this.fileIsSelected = false;
        this.imageChangedEvent = null;
        this.cropperIsReady = false;
        this.imageName = null;
        this.croppedImage = null;
        if (this.file)
            this.file.nativeElement.value = null;
    }

    downloadFile() {
        let source = this.isSVG ? `data:svg;base64,${this.croppedImage}` : `${this.croppedImage}`;
        if (this.data.coverPath) {
            source = environment.apiPath + this.data.coverPath;
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                const reader = new FileReader();
                reader.onloadend =  () => {
                    const link = document.createElement('a');
                    link.href = this.isSVG ? `data:svg;base64,${reader.result}` : `${reader.result}`;
                    link.download = `${this.imageName}`
                    link.click();
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', source);
            xhr.responseType = 'blob';
            xhr.send();
        } else {
            const link = document.createElement('a');
            link.href = source;
            link.download = `${this.imageName}`
            link.click();
        }
    }

    openBaseGraphics() {
        if (!this.baseGraphics.length) {
            return
        }

        this.dialog.open(ChoiceBaseGraphicComponent, {
            width: '789px',
            hasBackdrop: true,
            disableClose: true,
            data: {
                baseGraphics: this.baseGraphics,
                width: this.resizeToWidth + 6,
                height: this.resizeToHeight + 6
            }
        }).afterClosed().subscribe((value) => {
            if (value) {
                this.dialogRef.close(value);
            }
        });
    }
}
