import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IntegrationInterface } from '../../integration/interfaces/integration.interface';

@Injectable()
export class ApiAppService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public getApps(sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/app-api?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public nameValidation(name: string, id: number = null): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            name,
            id
        };
        return this.http.post<any>(`${this.basePath}/app-api/name-validation`, body, { headers });
    }

    public create(data: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/app-api`, data, { headers });
    }

    public update(id:number, data: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/app-api/${id}`, data, { headers });
    }

    public delete(id:number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/app-api/${id}`, { headers });
    }

    public get(id:number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/app-api/${id}`, { headers });
    }

    public setIsActive(id:number, isActive: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/app-api/${id}/active`, {isActive}, { headers });
    }

    public regenarateToken(id:number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/app-api/${id}/regenerate-token`, {}, { headers });
    }


}
