import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';;
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {SendManualEmailDialogComponent} from '../../dialogs/send-manual-email-dialog/send-manual-email-dialog.component';

@Component({
  selector: 'message-for-team',
  templateUrl: './message-for-team.component.html',
  styleUrls: ['./message-for-team.component.scss']
})

export class MessageForTeamComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  users: any;
  waiting = true;
  defaultElmentsColor: string = 'FF8233';
  isMobile: boolean;

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _dialog: MatDialog,
              private _toastrService: ToastrService,
              private _translate: TranslateService,
              private _router: Router) {}

  ngOnInit() {
    if(!this.preview){
    }else{
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  sendMessage(widget){
    this._api.Dashboard.getCompanyUsersData().subscribe((res) => {
      this.users = res;
      this.waiting = false;
      this.prepareWidgetData()

      this._dialog.open(SendManualEmailDialogComponent, {
        disableClose: true,
        data: {
          count: this.users.length
        },
        width: '800px',
      }).afterClosed().subscribe((data) => {
        if (data && data !== false) {
          data.userIds = this.users;
          this._api.Notification.sendManualEmails(data).subscribe((res) => {
            if (res) {
              this._toastrService.success(this._translate.instant('Common.TheSendingOfEmailNotificationsHasStarted'));
            }
          });
        }
      });
    });

  }
}