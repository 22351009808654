<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>

<div class="approval-tasks-dialog" *ngIf="coordinatorTasks">
    <h1 mat-dialog-title tabIndex="0">
        <span>{{(this.data.isPreboarding ? 'Preboarding.CoordinatorTasks' : 'Onboarding.CoordinatorTasks') | translate}}:
            <span class="color-gray">{{onboarding.name}}</span>
        </span>
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="approval-tasks-dialog__top">
            <div class="approval-tasks-dialog__top--user">
                <span (click)="backToTasks()" *ngIf="task">
                    <i class="material-icons">navigate_before</i>
                    {{'Onboarding.Back' | translate}}
                </span>
                <h1>{{userName}}</h1>
            </div>
            <div class="approval-tasks-dialog__top--buttons" *ngIf="!task">
                <ng-container >
                    <mat-slide-toggle color="primary"
                                      [checked]="onlyForMe"
                                      (change)="changeToogle($event)">
                        {{'Onboarding.ShowOnlyCoordinatorTasksForMe' | translate}}
                    </mat-slide-toggle>

                    <div class="navigate-buttons" *ngIf="showButtons">
                        <button mat-button
                                (click)="previousUser()"
                                class="dolineo-button blue-button">
                            <img src="assets/icons/arrow_left.svg" alt="arrow_left"/>
                        </button>
                        <span> {{coordinatorTasks.userIndex}} {{'Common.Of' | translate}} {{coordinatorTasks.total}} </span>
                        <button mat-button
                                (click)="nextUser()"
                                class="dolineo-button blue-button">
                            <img src="assets/icons/arrow_right.svg" alt="arrow_right"/>
                        </button>
                    </div>
                </ng-container>


            </div>
        </div>

        <div class="approval-tasks-dialog__content">
            <div class="approval-tasks-dialog__box" *ngIf="!task && coordinatorTasks.tasks?.length">
                <div class="approval-tasks-dialog__box--top">
                    <button mat-flat-button
                            [disabled]="!checkList.length"
                            (click)="finishTaskList()"
                            class="dolineo-button white-button">{{'Onboarding.CoordinatorTasksSetDone' | translate}}</button>
                </div>

                <div class="approval-tasks-dialog__row" *ngFor="let task of tasks">
                    <div class="approval-tasks-dialog__row--left">
                        <mat-checkbox color="primary" (change)="changeCheckbox($event, task.id)"
                                      *ngIf="task.attachmentRequired === 0 && !task.finishedAt && task.canUserDone">
                        </mat-checkbox>
                        <span>{{task.name}}</span>
                    </div>

                    <div class="approval-tasks-dialog__row--center" style="width: auto;">
                        <i class="dolineo-icon dolineo-icon-hover dolineo-icon-show_attempt_details"
                           matTooltip="{{'Onboarding.TaskPreview' | translate}}"
                           matTooltipPosition="below"
                           matTooltipClass='below-top'
                           (click)="previewTask(task.id)"
                        ></i>
                    </div>

                    <div class="approval-tasks-dialog__row approval-tasks-dialog__row--coordinator">
                        {{ 'OnboardingCreator.AssignedCoordinator' | translate }}: <span>{{ task.coordinatorName }}</span>
                    </div>

                    <div class="approval-tasks-dialog__row--buttons" style="flex: 1">
                        <ng-container *ngIf="!task.finishedAt && task.canUserDone">
                            <button mat-flat-button
                                    (click)="finishTask(task)"
                                    class="dolineo-button white-button">{{'Onboarding.CoordinatorTasksSetDone' | translate}}</button>
                        </ng-container>
                        <div class="approval-tasks-dialog__row--buttons__info flex-table" *ngIf="task.finishedAt">
                            <span class="attachment" *ngIf="task.attachment" (click)="downloadAttachment(task.attachment)">
                                <i class="dolineo-icon dolineo-icon-blue dolineo-icon-download"></i>
                                {{ 'Common.Attachment' | translate }}
                            </span>
                            <span>
                                {{ task.finishedAt | date : 'dd.MM.YYYY' }}
                                <span class="dot"></span>
                                {{ task.finishedBy }}
                            </span>
                            <span class="badge badge-success">
                                {{'Task.FinishedAt'| translate | uppercase}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="approval-tasks-dialog__task" *ngIf="task">
                <div class="task-player__title">
                    <h1>{{task.name}}</h1>
                </div>

                <div class="task-player__info">
                    <div class="task-player__info--accept">
                        <img src="assets/icons/info.svg"
                             loading="lazy"
                             alt="info">
                        {{ 'OnboardingCreator.CoordinatorTask' | translate}}
                    </div>

                </div>

                <div class="task-player__files-to-download" *ngIf="task?.attachements?.length">
                    <mat-expansion-panel hideToggle [expanded]="true">
                        <mat-expansion-panel-header (click)="setExpanded()">
                            <div class="panel-header">
                                <div class="panel-header--arrow-text">
                                    <img src="/assets/icons/blue_arrow_right.svg"
                                         matTooltip="{{'Common.Collapse' | translate}}"
                                         matTooltipPosition="below"
                                         [ngClass]="{'rotate-arrow': expanded}"
                                         matTooltipClass='below-top'
                                         alt="blue_arrow_right">
                                    <span>{{'KnowledgeBase.FileToDownload' | translate}}</span>
                                    <div class="circle-blue">{{task.attachements.length}}</div>
                                </div>

                                <button class="download-button" mat-flat-button
                                        type="button"
                                        (click)="downloadAll();$event.stopPropagation()"
                                        *ngIf="task.attachements.length>1">
                                    <i class="dolineo-icon dolineo-icon-blue dolineo-icon-download"></i>
                                    {{'Common.DownloadAttachments'| translate}}
                                </button>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="files-to-download__row"
                             *ngFor="let att of task.attachements">
                            <i [class]="getIconName(att.mimeType)" style="margin-right: 12px"></i>
                            <span (click)="downloadFile(att)" class="cursor-pointer"
                                  [innerHTML]="att.origFileName"></span>
                        </div>
                    </mat-expansion-panel>
                </div>

                <div class="task-player__content">
                    <p class="quill-content" [innerHTML]="task.description | safe: 'html'"></p>
                </div>

                <div *ngIf="task.attachmentRequired" class="task-player__upload">
                    <div class="file-container" *ngIf="filenamePreview">
                        <div class="flex-items">
                            <span> {{ filenamePreview }} </span>
                            <div>
                                <i (click)="downloadInputFile()"
                                   matTooltip="{{'Common.Download' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-download2"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Close' | translate}}</button>
    </div>
</div>
