import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { LoaderService } from './loader.service';
import { Observable } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewInit {

    isVisible$: Observable<boolean>;

    constructor(private loaderService: LoaderService,
                private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.isVisible$ = this.loaderService.status();
        this.cdr.detectChanges();
    }
}