import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

    @ViewChild('file') file;

    public uploadInProgress = false;
    public selectedFile: File = null;
    public uploadedPercentage = 0;
    public filetype = '';
    public errorMessage = null;

    constructor(public dialogRef: MatDialogRef<UploadFileComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _api: ApiService,
                private _toastrService: ToastrService,
                private _translate: TranslateService) {
    }

    ngOnInit() {
        if (this.data.fileType === 'scorm')
            this.filetype = 'SCORM';
        else this.filetype = 'z prezentacją multimedialną';
    }

    acceptFile(): void {

        this.uploadInProgress = true;
        this.errorMessage = null;
        if (this.data.fileType === 'scorm') {
            this._api.Company.addScorm(this.selectedFile).subscribe((event: HttpEvent<any>) => {

                switch (event.type) {
                    case HttpEventType.Sent:
                        this.uploadedPercentage = 0;
                        break;
                    case HttpEventType.Response:
                        this.dialogRef.close(event.body);
                        break;
                    case 1: {
                        if (Math.round(this.uploadedPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
                            this.uploadedPercentage = Math.round(event['loaded'] / event['total'] * 100);
                        }
                        break;
                    }
                }

            }, (err) => {
                this.uploadInProgress = false;
                if (err.error.code === 500) {
                    this._toastrService.error(this._translate.instant('Err.Server'))
                }
                if (err.status === 415) {
                    this._toastrService.error('err.error.message')
                    this.errorMessage = err.error.message;
                }
            }, () => {
                this.uploadInProgress = false;
            });
        } else {
            this._api.Company.addFile(this.selectedFile).subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.uploadedPercentage = 0;
                        break;
                    case HttpEventType.Response:
                        this.dialogRef.close(event.body);
                        break;
                    case 1: {
                        if (Math.round(this.uploadedPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
                            this.uploadedPercentage = Math.round(event['loaded'] / event['total'] * 100);
                        }
                        break;
                    }
                }
            }, (err) => {
                this.uploadInProgress = false;
                if (err.error.code === 500) {
                    this._toastrService.error(this._translate.instant('Err.UnexpectedError'))
                }
            }, () => {
                this.uploadInProgress = false;
            });
        }
    }

    removeFile(): void {
        this.selectedFile = null;
        this.errorMessage = null;
        this.file.nativeElement.value = '';
    }

    selectFile(): void {
        if (!this.selectedFile) {
            this.file.nativeElement.click();
        }
    }

    getFileSize(): string {
        return (this.selectedFile.size / 1024 / 1024).toFixed(2) + 'MB';
    }

    getFileName(): string {
        return this.selectedFile.name;
    }

    onFileInputChange(): void {
        this.selectedFile = this.file.nativeElement.files[0];
    }
}
