import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HelperService} from '../../shared/services/helper.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private _helper: HelperService,
                private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this._helper.hasPermissions([
                'admin:users:manage',
                'admin:organization:details:view',
                'admin:notifications:manage',
                'admin:categories:manage',
                'admin:roles:manage',
                'admin:groups:manage',
                'admin:logs:view'
            ]
        )) {
            return true;
        } else {
            this.router.navigate(['app/no-permission']);
            return false;
        }
    }
}
