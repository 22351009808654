import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {environment} from '../../../../environments/environment';
import { downloadFile } from '../../helpers';
import { saveAs } from 'file-saver';
import {ApiService} from '../../../api/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-onboarding-coordinator-task-confirmation-dialog',
    templateUrl: './onboarding-coordinator-task-confirmation-dialog.component.html',
    styleUrls: ['./onboarding-coordinator-task-confirmation-dialog.component.scss']
})
export class OnboardingCoordinatorTaskConfirmationDialogComponent implements OnInit {
    @ViewChild('file') file;

    public selectedFile: File = null;
    public apiUrl = environment.filesPath;
    public fileErrorMessage = '';
    public fileError: boolean = false;
    public filenamePreview: string = '';
    public validation = {
        fileSize: this._translate.instant('Common.TheSizeOfTheSelectedFileExceeds').replace('{{size}}', '250mb'),
        fileExt: this._translate.instant('Common.InvalidFileFormat')
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                public dialogRef: MatDialogRef<OnboardingCoordinatorTaskConfirmationDialogComponent>,
                private _api: ApiService,
                private toastrService: ToastrService,) {
    }

    ngOnInit(): void {
    }

    selectFile(): void {
        if (!this.selectedFile) {
            this.file.nativeElement.click();
        }
    }

    removeTooltipWhenDrag() {
        $(document).find('.below-top').css('display', 'none');
    }

    onFileChange(file = null) {
        this.selectedFile = file === null ? this.file.nativeElement.files[0] : file;

        if (!this.selectedFile) {
            return;
        }

        if (this.selectedFile.size > 262144000) {
            this.fileErrorMessage = this.validation.fileSize;
            this.fileError = true;
            this.selectedFile = null;
            this.filenamePreview = '';
            this.file.nativeElement.value = '';
            return;
        }

        const isCorrect = this.selectedFile.name.toLowerCase().indexOf('.pdf') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.doc') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.docx') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.ppt') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.pptx') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.xls') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.xlsx') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.png') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.jpg') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.jpeg') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.svg') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.mp4') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.webm') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.avi') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.mov') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.mvw') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.wmv') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.ogv') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.mp3') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.zip') > -1 ||
            this.selectedFile.name.toLowerCase().indexOf('.rar') > -1;

        if (isCorrect) {
            this.filenamePreview = this.selectedFile.name;
            this.fileErrorMessage = '';
            this.fileError = false;
        } else {
            this.fileErrorMessage = this.validation.fileExt;
            this.fileError = true;
            this.selectedFile = null;
        }
    }

    public dropped(files: NgxFileDropEntry[]) {
        if (files.length > 0 && files[0].fileEntry.isFile && files[0].relativePath.indexOf('/') === -1) {
            const fileEntry = files[0].fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                this.onFileChange(file);
            });
        }
    }

    removeFile() {
        this.selectedFile = null;
        this.fileError = false;
        this.fileErrorMessage = '';
        this.filenamePreview = '';
        this.file.nativeElement.value = '';
    }

    downloadInputFile() {
        if (this.selectedFile) {
            saveAs(this.selectedFile, this.selectedFile.name);
        } else {
            this._api.download(this.data.task.attachment.fileName).subscribe(blob => {
                downloadFile(blob, this.data.task.attachment.origFileName)
            }, () => {
                this.toastrService.error(this._translate.instant('Err.UnexpectedError'));
            })
        }
    }


    confirm() {
        if (this.data.task && (this.data.task.attachmentRequired || this.data.task.attachmentRequired === null)) {
            this.dialogRef.close({
                confirmed: true,
                selectedFile: this.selectedFile
            });
        } else {
            this.dialogRef.close(true);
        }
    }
}
