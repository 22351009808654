<div class="back-button" [ngClass]="{white: color === 'white', 'no-pointer': !showButtonBack}">
    <button mat-flat-button class="back-button"
            (click)="goBack()"
            *ngIf="showButtonBack"
            matTooltip="{{tooltipText | translate}}"
            matTooltipPosition="below"
            matTooltipClass='{{matTooltipClass}}'>
        <i class="material-icons">navigate_before</i>
    </button>
    <h2 [ngClass]="{'mobile-margin': !showButtonBack}">{{text | translate}}</h2>
</div>
