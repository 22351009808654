<div *ngIf="layout" class="subDomain">
    <div class="subDomain__left">
        <div class="subDomain__left__header">
            <div class="logo-container">
                <img src="{{apiUrl + layout.logoPath}}" alt="logo" [ngStyle]="{'height': layout.logoHeight+'px'}"
                     class="logo"
                     *ngIf="layout.logoPath">
            </div>
            <div class="lang-container" *ngIf="layout.showLanguages">
                <div [matMenuTriggerFor]="lang" class="cursor-pointer">
                    <span class="current-lang">{{(currentLang === 'uk' ? 'ua' : currentLang) | uppercase}}</span>
                    <span>
                        <img src="/assets/icons/angle-down.svg" alt="angle-down">
                    </span>
                </div>
                <mat-menu #lang="matMenu">
                    <button mat-menu-item *ngFor="let lang of layout.languages"
                            (click)="setLang(lang.shortName)"
                            class="language-button">
                        <span class="lang-check"><i *ngIf="currentLang === lang.shortName"
                                                    class="dolineo-icon dolineo-icon-check"></i></span>
                        <span class="lang-name">{{ 'Language.' + lang.shortName | translate}}</span>
                        <span class="lang-code">{{(lang.shortName === 'uk' ? 'ua' : lang.shortName)  | uppercase}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="subDomain__left__container">
            <form [formGroup]="loginForm" (ngSubmit)="submit()" class="form-validation"
                  *ngIf="!resetStatus; else correntReset">
                <fieldset>
                    <div class="form__header">
                        <h1>{{'ForgotPassword.RecoverPassword' | translate}}</h1>
                        <p>{{'ForgotPassword.PleaseEnterYourEmailAddressBelow'| translate}}</p>
                        <p>{{'ForgotPassword.WeWillSendAMessageToYourEMailWithInstructionsToChangeThePassword' | translate}}</p>
                    </div>

                    <div class="form-group">
                        <i class="dolineo-icon dolineo-icon-user-circle"></i>
                        <mat-form-field class="full-width">
                            <input matInput type="email" name="email" placeholder="{{'Login.Email' | translate}}"
                                   formControlName="email">
                            <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                        </mat-form-field>
                    </div>

                    <button mat-raised-button type="submit" color="primary">
                        {{'ForgotPassword.RecoverPassword' | translate}}
                    </button>
                    <div class="forgot-password">
                        <a [routerLink]="['/login']">{{'Login.GoBackToTheLoginPage' | translate}}</a>
                    </div>
                </fieldset>
            </form>
            <ng-template #correntReset>
                <form class="form-validation">
                    <fieldset>
                        <div class="form__header">
                            <h1>{{'ForgotPassword.AMessageWithAnInstructionToChangeThePasswordHasBeenSent' | translate}}</h1>
                            <p>{{'ForgotPassword.CheckYourMailbox'| translate}}
                                <span style="font-weight: 700">{{loginForm.get('email').value}}</span>
                            </p>
                            <p>{{'ForgotPassword.ClickTheLinkProvidedToChangeYourPassword' | translate}}</p>
                            <p>{{'ForgotPassword.TheLinkIsActiveFor30Minutes' | translate}}</p>
                            <p style="margin-top: 15px">
                                {{'ForgotPassword.InCaseOfProblemsContactThePlatformAdministrator' | translate}}
                            </p>
                        </div>

                        <button mat-raised-button [routerLink]="['/login']" color="primary">
                            {{'Login.GoBackToTheLoginPage' | translate}}
                        </button>
                    </fieldset>
                </form>
            </ng-template>
        </div>
        <div class="subDomain__left__footer">
            <div class="footer-logo">
                <img [src]="logoUrl" [alt]="logoAlt">
            </div>
            <div class="footer-documents">
                <a href="{{apiUrl + policyUrl}}" target="_blank" *ngIf="policyUrl">
                    {{'Login.PrivacyPolicy' | translate}}
                </a>
                <a href="{{apiUrl + termsUrl}}" target="_blank" *ngIf="termsUrl">
                    {{'Login.Regulations' | translate}}
                </a>
                <a *ngIf="layout.helpdeskEmail" href="mailto:{{layout.helpdeskEmail}}">
                    {{'Help desk:'}} {{layout.helpdeskEmail}}</a>
            </div>
        </div>
    </div>
    <div class="subDomain__right"
         [ngStyle]="{'background-image': layout.imagePath? 'url(' + apiUrl + layout.imagePath + ')': '', 'color': layout.color }"
         style="text-align: {{layout.textAlign}};">
        <div class="header">
            {{header}}
        </div>
        <div class="description" [innerHTML]="description">
        </div>
    </div>
</div>
