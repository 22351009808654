import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import Quill from 'quill';
import { HelperService } from '../../services/helper.service';
import { BackButtonService } from '../../services/back-button.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { setValueHeader } from '../../helpers';
import { RequestInterface } from '../../intefaces/pagination.interface';
const QuillBlock = Quill.import('blots/block');
class QuillBlockDivBlock extends QuillBlock {
    static blockName = 'div';
    static tagName = 'div';
}
Quill.register(QuillBlockDivBlock);

import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    private prefix = '';
    private editor: Quill = null;
    public formMode = false;

    public formTitle = '';
    public notifications = [];

    form: FormGroup;

    contentError: boolean = false;
    public searchRequest: any = null;
    public request: RequestInterface = {
        sort: {
            field: 'description',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    public notification = {
        notificationId: null,
        id: null,
        title: '',
        content: '',
        type: ''
    };

    columns: any[] = [];
    localStorageFilter: string = this.helper.getUser().id + 'notifications'

    constructor(protected api: ApiService,
                protected helper: HelperService,
                protected backButtonService: BackButtonService,
                private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.createTable();
        if (localStorage.getItem(this.localStorageFilter)) {
            this.columns = [...setValueHeader(this.localStorageFilter, this.columns)];
            this.request.sort = { ...JSON.parse(localStorage.getItem(this.localStorageFilter)).sort }
        }

        if (this.helper.isSuperAdmin() || this.helper.isSysAdmin()) {
            this.prefix = 'super';
        }

        this.getData();

        this.form = this.formBuilder.group({
            title: ['', Validators.required]
        });
    }

    getData(): void {
        this.backButtonService.reload = false;
        this.api.Admin.getNotifications(this.prefix).subscribe((notifications) => {
            this.notifications = notifications;
        });
    }

    createTable() {
        this.columns = [
            {
                index: 'description',
                name: 'Common.Description',
                searchable: false,
                sortable: false,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '250px',
                },
                class: 'ft-auto'
            },
            {
                type: 'MENU',
                visible: true,
                items: [
                    {
                        name: 'Common.Edit',
                        icon: '<i class="dolineo-icon dolineo-icon-hover dolineo-icon-pencil2"></i>',
                        visible: (v) => {
                            return !v.isDefault;
                        },
                        click: (row) => {
                            this.edit(row);
                        }
                    },
                ],
                css: {
                    'width': '100px',
                    'min-width': '100px'
                }
            }
        ];
    }

    edit(notification): void {
        this.backButtonService.reload = true;
        this.api.Admin.getNotificationById(notification.notificationId, notification.id, this.prefix).subscribe((data) => {
            setTimeout(() => {
                this.formTitle = data.typeDescription;
                this.notification.title = data.title;
                this.notification.type = data.type;
                this.notification.id = notification.id;
                this.notification.notificationId = notification.notificationId;
                document.getElementById('quill-editor').innerHTML = data.content.replaceAll('ql-cursor', '');
                this.editor = new Quill('#quill-editor', {
                    theme: 'snow',
                    scrollingContainer: '.mat-horizontal-content-container',
                    clipboard: {
                        matchVisual: false
                    }
                });
                this.form.get('title').setValue(data.title);
            }, 0);
            this.contentError = false;
            this.formMode = true;
        });
    }

    save(): void {
        this.backButtonService.reload = false;
        const content: HTMLElement = document.getElementById('quill-editor').firstChild as HTMLElement;
        this.notification.content = content.innerHTML.replaceAll('ql-cursor', '');

        this.form.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }

        this.notification.title = this.form.value.title;

        if (this.notification.content.trim().length <= 15) {
            this.contentError = true;
            return;
        }

        this.api.Admin.saveNotification(this.notification.notificationId, this.notification.id, this.notification, this.prefix).subscribe(() => {
            this.cancel();
            this.getData();
        });
    }

    cancel(): void {
        this.backButtonService.reload = false;
        this.notification = {
            notificationId: null,
            id: null,
            title: '',
            content: '',
            type: ''
        };
        this.formTitle = '';
        this.formMode = false;
    }

    addPlaceholder(field, text) {
        if (field === 'title') {
            this.form.get('title').setValue(this.form.value.title + text);
        } else if (field === 'content') {
            const selection = this.editor.getSelection(true);
            this.editor.insertText(selection.index, text);
        }
    }
}
