<div class="cols-menu">
    <h3> {{'ConfigTableDialog.ColumnSettings' | translate}} </h3>
    <div class="cols-menu__container">
        <span (click)="$event.stopPropagation(); checkAll();"
              class="header-span">{{'Common.ShowAll' | translate}}</span>
        <span (click)="saveDefault()" class="header-span">{{'ConfigTableDialog.RestoreDefaults' | translate}}</span>
        <div cdkDropList (cdkDropListDropped)="drop($event)" class="drop-down">
            <mat-checkbox *ngFor="let col of tempColumns; let i = index;"
                          [(ngModel)]="col.visible"
                          cdkDrag
                          color="primary"
                          (click)="$event.stopPropagation();">
                <img src="/assets/icons/drag-handler.svg"
                     matTooltip="{{'Common.DragAndDrop' | translate}}"
                     cdkDragHandle
                     class="cursor-pointer"
                     matTooltipPosition="below"
                     matTooltipClass='below-top'
                     alt="drag-handler">
                {{col.name  | translate}}
            </mat-checkbox>
        </div>
    </div>

    <div class="cols-menu__footer">
        <button mat-button class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button mat-button (click)="save()" color="primary"
                class="dolineo-button blue-button">{{'Common.Accept' | translate}}</button>
    </div>
</div>
