import { Directive, Input, HostListener } from '@angular/core';

@Directive({
    selector: 'img[default]',
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '[src]': 'src'
    }
})

export class ImagePreloadDirective {
    @Input() src: string;
    @Input() default: string;

    @HostListener('error', ['$event'])
    updateUrl() {
        this.src = this.default;
    }

    @HostListener('load', ['$event'])
    load() {
        // this.className = 'image-loaded';
    }
}
