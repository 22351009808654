<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>
<div class="sort-dialog">
    <div mat-dialog-content>
        <span class="sort-dialog__header">{{'SortDialog.Sorting' |translate}}</span>
        <div class="sort-dialog__container">
            <div class="sort-dialog__status" *ngIf="data.sorts">
                <div *ngFor="let so of data.sorts"
                     (click)="setStatus(so)"
                     class="sort-dialog__status__content cursor-pointer">
                    <div style="display: flex">
                        <img src="assets/icons/checked.svg" alt="checked" *ngIf="sort.id === so.id">
                    </div>
                    <span>{{so.name |translate}}</span>
                </div>
            </div>
        </div>

        <div class="sort-dialog__footer">
            <button (click)="dialogRef.close()"
                    mat-button
                    class="cancel-button">
                {{'Common.Cancel' | translate}}
            </button>
            <button mat-button
                    color="primary"
                    (click)="accept()"
                    class="dolineo-button blue-button">
                {{'Common.Apply' | translate}}
            </button>
        </div>
    </div>
</div>
