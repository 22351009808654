import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TrainingInterface } from '../../../trainings-creator/interfaces/training.interface';
import { HttpEventType } from '@angular/common/http';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { LeaveCreateTrainingGuard } from '../../../core/guards/leave-create-training.guard';
import { LeaveCreatePathGuard } from '../../../core/guards/leave-create-path.guard';
import { ToastrService } from 'ngx-toastr';
import { LeaveCreatePoolGuard } from '../../../core/guards/leave-create-pool.guard';
import { TranslateService } from '@ngx-translate/core';
import { LeaveCreateSurveyGuard } from '../../../core/guards/leave-create-survey.guard';
import { LeaveCreateArticleGuard } from '../../../core/guards/leave-create-article.guard';
import { LeaveCreateOnboardingGuard } from '../../../core/guards/leave-create-onboarding.guard';
import { LeaveCreateQuestionnaireGuard } from '../../../core/guards/leave-create-questionnaire.guard';
import {LeaveCreateGamificationGuard} from '../../../core/guards/leave-create-gamification.guard';
import {AuthService} from '../../../core/services/auth.service';
import {LoaderService} from '../../components/loader/loader.service';
import {LeaveCreatePreboardingGuard} from '../../../core/guards/leave-create-preboarding.guard';

@Component({
    selector: 'app-publish-path-training-dialog',
    templateUrl: './publish-path-training-dialog.component.html',
    styleUrls: ['./publish-path-training-dialog.component.scss']
})
export class PublishPathTrainingDialogComponent {
    waiting: boolean = false;
    isGlobalAdmin: boolean = false;
    hasPermission: boolean = false;

    title: string = '';
    publicationText: string = '';
    publicationAndAssignText: string = '';
    info: string = '';

    constructor(public dialogRef: MatDialogRef<PublishPathTrainingDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private helper: HelperService,
                private _api: ApiService,
                private _toastrService: ToastrService,
                private _router: Router,
                private translateService: TranslateService,
                private canLeaveTraining: LeaveCreateTrainingGuard,
                private canLeavePath: LeaveCreatePathGuard,
                private canLeaveSurvey: LeaveCreateSurveyGuard,
                private canLeavePool: LeaveCreatePoolGuard,
                private canLeaveCreateOnboarding: LeaveCreateOnboardingGuard,
                private canLeaveCreatePreboarding: LeaveCreatePreboardingGuard,
                private canLeaveCreateQuestionnaire: LeaveCreateQuestionnaireGuard,
                private canLeaveCreateGamification: LeaveCreateGamificationGuard,
                private canLeaveArticle: LeaveCreateArticleGuard,
                private _auth: AuthService,
                private loader: LoaderService) {
        this.isGlobalAdmin = this.helper.isSuperAdmin() || this.helper.isSysAdmin();
        this.setText();
    }

    setText() {
        if (this.data.type === 'test') {
            this.title = 'PublishPathTraining.SaveTest';
            this.publicationText = 'PublishPathTraining.SaveTest';
            this.publicationAndAssignText = 'PublishPathTraining.SaveTheTestAndAssignUsers';
            this.info = 'PublishPathTraining.IfYouSignUpYourUsersTheyWillSeeTheTest';
            this.hasPermission = this.helper.hasPermission('content:pools:assign');
        } else if (this.data.type === 'survey') {
            this.title = 'PublishPathTraining.SaveSurvey';
            this.publicationText = 'PublishPathTraining.SaveSurvey';
            this.publicationAndAssignText = 'PublishPathTraining.SaveTheSurveyAndAssignUsers';
            this.info = 'PublishPathTraining.IfYouSignUpYourUsersTheyWillSeeTheSurvey';
            this.hasPermission = this.helper.hasPermission('content:survey:assign');
        } else if (this.data.type === 'path') {
            this.title = 'PublishPathTraining.PublishPath';
            this.publicationText = 'PublishPathTraining.ShareThePathOnTheSystem';
            this.publicationAndAssignText = 'PublishPathTraining.ShareThePathOnTheSystemAndSaveUsers';
            this.info = 'PublishPathTraining.IfYouSignUpYourUsersTheyWillSeeThePath';
            this.hasPermission = this.helper.hasPermission('content:path:assign');
        } else if (this.data.type === 'article') {
            this.title = 'PublishPathTraining.PublishArticle';
            this.publicationText = 'PublishPathTraining.ShareTheArticleOnTheSystem';
            this.publicationAndAssignText = 'PublishPathTraining.ShareThePathOnTheSystemAndAssignToCategory';
            this.info = 'PublishPathTraining.AccessToArticlesForUsersIsDoneByAssigningToACategory';
            this.hasPermission = this.helper.hasPermission('content:article-category:assign');
            if (this.data.articleType === 'help' && this.isGlobalAdmin) {
                this.isGlobalAdmin = false;
                this.hasPermission = true;
            }
        } else if (this.data.type === 'onboarding') {
            this.title = 'PublishPathTraining.PublishOnboarding';
            this.publicationText = 'PublishPathTraining.ShareTheOnboardingOnTheSystem';
            this.publicationAndAssignText = 'PublishPathTraining.ShareTheOnboardingOnTheSystemAndSaveUsers';
            this.info = 'PublishPathTraining.IfYouSignUpYourUsersTheyWillSeeTheOnboarding';
            this.hasPermission = this.helper.hasPermission('content:onboarding:assign');
        } else if (this.data.type === 'preboarding') {
            this.title = 'PublishPathTraining.PublishPreboarding';
            this.publicationText = 'PublishPathTraining.ShareThePreboardingOnTheSystem';
            this.publicationAndAssignText = 'PublishPathTraining.ShareThePreboardingOnTheSystemAndSaveUsers';
            this.info = 'PublishPathTraining.IfYouSignUpYourUsersTheyWillSeeThePreboarding';
            this.hasPermission = this.helper.hasPermission('content:preboarding:assign');
        }  else if (this.data.type === 'gamification') {
            this.title = 'PublishPathTraining.PublishGamification';
            this.publicationText = 'PublishPathTraining.ShareTheGamificationOnTheSystem';
            this.publicationAndAssignText = 'PublishPathTraining.ShareTheGamificationOnTheSystemAndSaveUsers';
            this.info = 'PublishPathTraining.IfYouSignUpYourUsersTheyWillSeeTheGamification';
            this.hasPermission = this.helper.hasPermission('content:gamification:assign');
        } else if (this.data.type === 'feedback') {
            this.title = 'QuestionnaireCreator.SaveTheQuestionnaire';
            this.publicationText = 'QuestionnaireCreator.SaveTheQuestionnaire';
            this.publicationAndAssignText = 'QuestionnaireCreator.SaveTheQuestionnaireAndAssignParticipants';
            this.info = 'QuestionnaireCreator.YouCanAssignUsersToTheQuestionnaireAtAnyTime';
            this.hasPermission = this.helper.hasPermission('content:feedback:assign');
        } else {
            this.title = 'PublishPathTraining.PublishTraining';
            this.publicationText = 'PublishPathTraining.ShareTheTrainingOnTheSystem';
            this.publicationAndAssignText = 'PublishPathTraining.ShareTheTrainingOnTheSystemAndSaveUsers';
            this.info = 'PublishPathTraining.IfYouSignUpYourUsersTheyWillSeeTheTraining';
            this.hasPermission = this.helper.hasPermission('content:training:assign');
        }
    }

    save(isSessionStorage: boolean) {
        this.waiting = true;
        if (this.data.type === 'test') {
            this.canLeavePool.allowDeactivation(false);
            this.savePool(isSessionStorage);
        } else if (this.data.type === 'survey') {
            this.canLeaveSurvey.allowDeactivation(false);
            this.saveSurvey(isSessionStorage);
        } else if (this.data.type === 'path') {
            this.canLeavePath.allowDeactivation(false);
            this.savePath(isSessionStorage);
        } else if (this.data.type === 'article') {
            this.canLeaveArticle.allowDeactivation(false);
            this.saveArticle(isSessionStorage);
        } else if (this.data.type === 'onboarding') {
            this.canLeaveCreateOnboarding.allowDeactivation(false);
            this.saveOnboarding(isSessionStorage);
        } else if (this.data.type === 'preboarding') {
            this.canLeaveCreatePreboarding.allowDeactivation(false);
            this.savePreboarding(isSessionStorage);
        } else if (this.data.type === 'gamification') {
            this.canLeaveCreateGamification.allowDeactivation(false);
            this.saveGamification(isSessionStorage);
        } else if (this.data.type === 'feedback') {
            this.canLeaveCreateQuestionnaire.allowDeactivation(false);
            this.saveFeedback(isSessionStorage);
        } else {
            this.canLeaveTraining.allowDeactivation(false);
            this.saveTraining(isSessionStorage);
        }
    }

    savePool(isSessionStorage: boolean) {
        if (this.data.payload.id > 0) {
            this._api.Admin.poolUpdate(this.data.payload, this.data.payload.id).subscribe((res) => {
                if (isSessionStorage) {
                    sessionStorage.setItem('test', JSON.stringify({ id: res.item.id, name: this.data.payload.name }));
                }
                this.waiting = false;
                this.dialogRef.close({ id: res.item.id, name: this.data.payload.name });
            });
        } else {
            this._api.Admin.poolCreate(this.data.payload).subscribe((res) => {
                if (isSessionStorage) {
                    sessionStorage.setItem('test', JSON.stringify({ id: res.item.id, name: this.data.payload.name }));
                }
                this.waiting = false;
                this.dialogRef.close({ id: res.item.id, name: this.data.payload.name });
            });
        }
    }

    saveSurvey(isSessionStorage: boolean) {
        if (this.data.payload.id > 0) {
            this._api.Survey.surveyUpdate(this.data.payload).subscribe((res) => {
                if (isSessionStorage) {
                    sessionStorage.setItem('survey', JSON.stringify({ id: res.id, name: this.data.payload.name }));
                }
                this.waiting = false;
                this.dialogRef.close({ id: res.id, name: this.data.payload.name });
            });
        } else {
            this._api.Survey.surveyCreate(this.data.payload).subscribe((res) => {
                if (isSessionStorage) {
                    sessionStorage.setItem('survey', JSON.stringify({ id: res.id, name: this.data.payload.name }));
                }
                this.waiting = false;
                this.dialogRef.close({ id: res.id, name: this.data.payload.name });
            });
        }
    }

    saveArticle(isSessionStorage: boolean) {
        if (this.data.payload.id > 0) {
            this._api.KnowledgeBaseService.articleUpdate(this.data.payload, this.data.payload.id).subscribe((res) => {
                if (isSessionStorage) {
                    sessionStorage.setItem('article', JSON.stringify({
                        id: res.id,
                        categoriesObj: this.data.payload.categories?.length ? this.data.payload.categories.map(val => {
                            return {
                                id: val.id,
                                value: val.name
                            }
                        }) : []
                    }));
                }
                this.waiting = false;
                this.dialogRef.close({ id: res.id, name: this.data.payload.name });
            });
        } else {
            this._api.KnowledgeBaseService.articleCreate(this.data.payload).subscribe((res) => {
                if (isSessionStorage) {
                    sessionStorage.setItem('article', JSON.stringify({
                        id: res.id,
                        categoriesObj: this.data.payload.categories?.length ? this.data.payload.categories.map(val => {
                            return {
                                id: val.id,
                                value: val.name
                            }
                        }) : []
                    }));
                }
                this.waiting = false;
                this.dialogRef.close({ id: res.id, name: this.data.payload.name });
            });
        }
    }

    savePath(isSessionStorage: boolean) {
        this._api.Library.addPath(this.data.payload, null).subscribe(value => {
            if (isSessionStorage) {
                sessionStorage.setItem('path', JSON.stringify({ idPath: value.pathId, name: this.data.payload.name }));
            }
            this.waiting = false;
            this.dialogRef.close(true);
        });
    }

    saveOnboarding(isSessionStorage: boolean) {
        this._api.Onboarding.addOnboarding(this.data.payload).subscribe(value => {
            if (isSessionStorage) {
                sessionStorage.setItem('onboarding', JSON.stringify({
                    id: value.onboardingId,
                    name: this.data.payload.name
                }));
            }
            this.waiting = false;
            this.dialogRef.close(true);
        });
    }

    savePreboarding(isSessionStorage: boolean) {
        this._api.Onboarding.addOnboarding(this.data.payload).subscribe(value => {
            if (isSessionStorage) {
                sessionStorage.setItem('preboarding', JSON.stringify({
                    id: value.onboardingId,
                    name: this.data.payload.name
                }));
            }
            this.waiting = false;
            this.dialogRef.close(true);
        });
    }

    saveGamification(isSessionStorage: boolean) {
        this._api.Gamification.addGamification(this.data.payload).subscribe(value => {
            if (isSessionStorage) {
                sessionStorage.setItem('gamification', JSON.stringify({
                    id: value.gamificationId,
                    name: this.data.payload.name
                }));
            }
            this.waiting = false;
            this.dialogRef.close(true);
        });
    }

    saveFeedback(isSessionStorage: boolean) {
        if(this.isGlobalAdmin){
            this._api.Feedback.addGlobalQuestionnaire(this.data.payload).subscribe(value => {
                if (isSessionStorage) {
                    sessionStorage.setItem('questionnaire', JSON.stringify({ id: value.questionnaireId, name: this.data.payload.name }));
                }
                this.waiting = false;
                this.dialogRef.close({ id: value.questionnaireId, name: this.data.payload.name });
            });
        }else{
            this._api.Feedback.addQuestionnaire(this.data.id, this.data.payload).subscribe(value => {
                if (isSessionStorage) {
                    sessionStorage.setItem('questionnaire', JSON.stringify({ id: value.questionnaireId, name: this.data.payload.name }));
                }
                this.waiting = false;
                this.dialogRef.close({ id: value.questionnaireId, name: this.data.payload.name });
            });
        }

    }


    saveTraining(isSessionStorage: boolean) {
        if (this.data.payload.trainingId || (!this.data.payload.file && this.data.payload.type !== 'h5p' && this.data.payload.type !== 'videoAudio' && this.data.payload.type !== 'page' && this.data.payload.type !== 'url')) {
            this._api.Library.addTraining(this.data.payload, null).subscribe(value => {
                if (isSessionStorage) {
                    sessionStorage.setItem('training', JSON.stringify({
                        idTraining: value.trainingId,
                        name: this.data.payload.name
                    }));
                }
                this.waiting = false;
                this.dialogRef.close({ idTraining: value.trainingId, name: this.data.payload.name });
            });
        } else {
            if (this.data.payload.type === 'videoAudio') {
                this.uploadVideo(this.data.payload, isSessionStorage)
            }
            if (this.data.payload.type === 'file') {
                this.uploadDoc(this.data.payload, isSessionStorage);
            }
            if (this.data.payload.type === 'scorm') {
                this.uploadScorm(this.data.payload, isSessionStorage);
            }
            if (this.data.payload.type === 'h5p') {
                this.uploadH5P(this.data.payload, isSessionStorage);
            }
            if (this.data.payload.type === 'page') {
                this.uploadPage(this.data.payload, isSessionStorage);
            }
            if (this.data.payload.type === 'url') {
                this.uploadUrl(this.data.payload, isSessionStorage);
            }
        }
    }

    uploadVideo(payload: TrainingInterface, isSessionStorage: boolean) {
        if (payload.file) {
            this.uploadVideoFileToS3(payload.file, payload, isSessionStorage);
        }else{
            this.creatPayloadVideo(payload, isSessionStorage);
        }
    }

    creatPayloadVideo(payload: any, isSessionStorage: boolean = false) {
        const formData = new FormData();
        if (payload.temporaryStoragePath) {
            formData.append('originalFileName', payload.originalFileName);
            formData.append('fileSize', payload.fileSize);
            formData.append('temporaryStoragePath', payload.temporaryStoragePath);
        }
        formData.append('videoUrl', payload.videoUrl);
        formData.append('limitSeek', payload.limitSeek);
        formData.append('increaseSpeed', payload.increaseSpeed);
        formData.append('finishBefore', payload.finishBefore);

        this._api.Company.trainingUploadVideo(formData).subscribe((resp: any) => {
            if (resp.type === HttpEventType.Response) {
                payload = {
                    ...payload,
                    videoFileId: resp.body.fileId
                }

                if (payload.thumbFile || payload.thumbBase64) {
                    formData.append('thumbBase64', payload.thumbBase64);
                    formData.append('thumbFilename', payload.thumbFilename);
                    const body = {
                        videoFileId: resp.body.fileId,
                        thumbBase64: payload.thumbBase64,
                        thumbFilename: payload.thumbFilename,
                    };
                    this._api.Company.trainingUploadThumb(body).subscribe(() => {
                        payload = {
                            ...payload,
                            videoFileId: resp.body.fileId
                        };
                    });
                }

                this._api.Library.addTraining(payload, null).subscribe(value => {
                    if (isSessionStorage) {
                        sessionStorage.setItem('training', JSON.stringify({
                            idTraining: value.trainingId,
                            name: this.data.payload.name
                        }));
                    }
                    this.waiting = false;
                    this.dialogRef.close({ trainingId: value.trainingId });
                    this.canLeaveTraining.allowDeactivation(false);
                });
            }
        });
    }

    uploadVideoFileToS3(file: File, payload: any, isSessionStorage: boolean) {
        this.loader.show();
        const fileName = this.helper.createUniqueFilename(file.name);
        this.helper.uploadToS3(file, fileName).then(
            (data) => {
                payload.fileSize = file.size;
                payload.temporaryStoragePath = fileName;
                payload.originalFileName = file.name

                this.creatPayloadVideo(payload, isSessionStorage)
            },
            (error) => {
                if(error == 'ExpiredToken: The provided token has expired.'){
                    this._auth.refreshToken().subscribe( res => {
                        if(res){
                            this.uploadVideoFileToS3(file, payload, isSessionStorage);
                        }
                    })
                }
            }
        );
    }

    uploadDoc(payload: TrainingInterface, isSessionStorage: boolean) {
        const formData = new FormData();
        if (this.data.payload.file) {
            formData.append('file', this.data.payload.file, this.data.payload.fileName);
        }

        formData.append('action', this.data.payload.action);
        formData.append('allPagesSeenRequired', this.data.payload.allPagesSeenRequired);
        formData.append('pdfFileDownloadBlock', this.data.payload.pdfFileDownloadBlock);

        this._api.Company.trainingUploadMedia(formData).subscribe(resp => {
            if (resp.type === HttpEventType.Response) {
                payload = {
                    ...payload,
                    mediaFileId: resp.body.fileId
                }

                this._api.Library.addTraining(payload, null).subscribe(value => {
                    if (isSessionStorage) {
                        sessionStorage.setItem('training', JSON.stringify({
                            idTraining: value.trainingId,
                            name: this.data.payload.name
                        }));
                    }
                    this.waiting = false;
                    this.dialogRef.close({ trainingId: value.trainingId });
                });
            }
        }, () => {
            this.waiting = false;
        });
    }

    uploadScorm(payload: TrainingInterface, isSessionStorage: boolean) {
        const formData = new FormData();
        if (this.data.payload.file) {
            formData.append('file', this.data.payload.file, this.data.payload.fileName);
        }

        formData.append('attempts', this.data.payload.attempts);

        this._api.Company.trainingUploadScorm(formData).subscribe(resp => {
            if (resp.type === HttpEventType.Response) {
                payload = {
                    ...payload,
                    scormFileId: resp.body.fileId
                }

                this._api.Library.addTraining(payload, null).subscribe(value => {
                    if (isSessionStorage) {
                        sessionStorage.setItem('training', JSON.stringify({
                            idTraining: value.trainingId,
                            name: this.data.payload.name
                        }));
                    }
                    this.waiting = false;
                    this.dialogRef.close({ trainingId: value.trainingId });
                });
            }
        }, (err) => {
            this._api.Library.errorScorm.next(err.error.details);
            this.waiting = false;
            this.dialogRef.close(false);
        });
    }

    uploadH5P(payload: TrainingInterface, isSessionStorage: boolean) {
        const formData = new FormData();

        formData.append('library', this.data.payload.library);
        formData.append('parameters', this.data.payload.parameters);
        formData.append('confirmationRequired', this.data.payload.confirmationRequired);
        formData.append('pointsRequired', this.data.payload.pointsRequired);
        formData.append('points', this.data.payload.points);

        this._api.Company.h5pSave(formData).subscribe(resp => {
            payload = {
                ...payload,
                h5pContentId: resp.id
            }

            this._api.Library.addTraining(payload, null).subscribe(value => {
                if (isSessionStorage) {
                    sessionStorage.setItem('training', JSON.stringify({
                        idTraining: value.trainingId,
                        name: this.data.payload.name
                    }));
                }
                this.waiting = false;
                this.dialogRef.close({ trainingId: value.trainingId });
            });
        }, (err) => {
            this._api.Library.errorH5P.next(err.error.details);
            this.waiting = false;
            this.dialogRef.close(false);
        });
    }

    uploadPage(payload: TrainingInterface, isSessionStorage: boolean) {
        this._api.Library.addTraining(payload, null).subscribe(value => {
            if (isSessionStorage) {
                sessionStorage.setItem('training', JSON.stringify({
                    idTraining: value.trainingId,
                    name: this.data.payload.name
                }));
            }
            this.waiting = false;
            this.dialogRef.close({ trainingId: value.trainingId });
        });
    }

    uploadUrl(payload: TrainingInterface, isSessionStorage: boolean) {
        this._api.Library.addTraining(payload, null).subscribe(value => {
            if (isSessionStorage) {
                sessionStorage.setItem('training', JSON.stringify({
                    idTraining: value.trainingId,
                    name: this.data.payload.name
                }));
            }
            this.waiting = false;
            this.dialogRef.close({ trainingId: value.trainingId });
        });
    }
}
