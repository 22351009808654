<div class="{{data.dialogClass || ''}}">
    <h1 mat-dialog-title *ngIf="data.title" tabIndex="0">
        {{data.title | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content style="padding-bottom: 20px;"
         [ngStyle]="{'padding-top' : data.title != '' ? '0px' : '24px'}">
        <span class="{{data.icon || ''}}" *ngIf="data.icon" style="margin-bottom: 16px"></span>
        <span [ngClass]="{'bold' : data.bold !== false}"> {{data.content | translate}} </span>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button mat-button color="primary" class="dolineo-button blue-button pull-right"
                [mat-dialog-close]="true">{{(data.okText || 'Ok' )| translate}}</button>
    </div>
</div>
