<div class="add-remove-visibility">
    <h1 mat-dialog-title tabIndex="0">
        {{header | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="add-remove-visibility--content">
             <span class="add-remove-visibility--text">{{content | translate}}</span>

            <span class="number-selected">{{data.number}}</span>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close(false)"  mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button (click)="dialogRef.close(true)" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Accept' | translate}}</button>
    </div>
</div>