import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appUpperCaseEveryLetter]'
})
export class UpperCaseEveryLetterDirective {

    constructor(private el: ElementRef,
                private control: NgControl) {
    }

    @HostListener('blur') onBlur() {
        if (this.el.nativeElement.value) {
            this.makeFirstLetters(' ', true);
            this.makeFirstLetters('-', false);
        }
    }

    makeFirstLetters(delimiter, lower) {
        const arr: string[] = this.el.nativeElement.value.split(delimiter);
        arr.forEach((word, index) => {
            if (lower) {
                arr[index] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            } else {
                arr[index] = word.charAt(0).toUpperCase() + word.slice(1);
            }
        });

        const val = arr.join(delimiter);
        this.el.nativeElement.value = val;
        this.control.control.setValue(val);
    }
}
