import { Component, OnInit, ɵSafeHtml } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';

export interface CertPreviewInterface {
    backgroundBase64: string;
    dolineoLogoPosition: string;
    orgLogoPosition: string;
    text: ɵSafeHtml;
    secondPage: boolean;
    secondPageContent: ɵSafeHtml;
    objectName: string;
}

@Component({
    selector: 'app-certificate-preview',
    templateUrl: './certificate-preview.component.html',
    styleUrls: ['./certificate-preview.component.scss']
})
export class CertificatePreviewComponent implements OnInit {

    public user;
    public dataSend: boolean = false;
    public previewCreator: boolean = false;
    public certData: CertPreviewInterface = {
        backgroundBase64: '',
        dolineoLogoPosition: '',
        orgLogoPosition: '',
        text: '',
        secondPage: false,
        secondPageContent: '',
        objectName: ''
    };
    private filesPath = environment.filesPath;
    public logoUrl = '';
    public appLogoUrl = '';
    public appLogoAlt = '';

    constructor(protected _sanitizer: DomSanitizer,
                private _api: ApiService,
                private _translate: TranslateService,
                private _helper: HelperService) {
        if (environment.appName === 'dolineo') {
            this.appLogoUrl = '/assets/images/logo/logo_dolineo.png';
            this.appLogoAlt = 'Logo Dolineo';
        } else if (environment.appName === 'ontigo') {
            this.appLogoUrl = '/assets/images/logo/logo_ontigo.png';
            this.appLogoAlt = 'Logo Ontigo';
        }
    }

    ngOnInit(): void {
        if (this._helper.getUser().isVisibileLogo) {
            if (this._helper.getUser().companyLogoUrl !== '' && this._helper.getUser().companyLogoUrl != null) {
                this.logoUrl = this.filesPath + this._helper.getUser().companyLogoUrl;
            }
        }
    }

    patchValues(data) {
        this.previewCreator = data.previewCreator;

        this._api.User.user(this._helper.getUser().id).subscribe((res) => {
            this.user = res;

            this.dataSend = data.dataSend;
            this.certData = data.certData;
            this.certData.text = (this.certData.text) ? this._sanitizer.bypassSecurityTrustHtml(this.replaceText(this.certData.text)) : '';
            this.certData.secondPageContent = (this.certData.secondPageContent) ? this._sanitizer.bypassSecurityTrustHtml(this.replaceText(this.certData.secondPageContent)) : '';
        });
    }

    replaceText(text: any): string {
        return text
            .replace('[nameSurname]', this.user.firstName + ' ' + this.user.lastName)
            .replace('[position]', (this.user.position != null) ? this.user.position : 'Administrator')
            .replace('[trainingPath]', (this.certData.objectName) ? (this.certData.objectName) : this._translate.instant('CertificateCreator.NameOfTheTrainingOrPathYouCompleted'))
            .replace('[dateWithSpaces]', moment().format('D MMMM YYYY'))
            .replace('[dateWithDots]', moment().format('D.MM.YYYY'))
            .replace('[dateWithLines]', moment().format('YYYY-MM-D'))
    }
}
