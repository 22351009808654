import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from '../../../api/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppLocaleService } from '../../../core/services/locale.service';
import {AssignExpiryDateDialogComponent} from '../assign-expiry-date-dialog/assign-expiry-date-dialog.component';
import {calcWorkingDays} from '../../helpers';

@Component({
    selector: 'app-group-add-remove-visibility-dialog',
    templateUrl: './group-add-remove-visibility-dialog.component.html',
    styleUrls: ['./group-add-remove-visibility-dialog.component.scss']
})
export class GroupAddRemoveVisibilityDialogComponent implements OnInit {
    @ViewChildren(DaterangepickerDirective) datePickers: QueryList<DaterangepickerDirective>;

    form: FormGroup;
    minStartDate = moment();
    minEndDate = moment();
    minVisibleFromDate = moment().format('YYYY-MM-DD');
    expiryDateLtVisibleFromDate = false;
    hasDays = false;
    days = 0;

    defaultLocale = {
        daysOfWeek: [
            this.translate.instant('Common.ShortSunday'),
            this.translate.instant('Common.ShortMonday'),
            this.translate.instant('Common.ShortTuesday'),
            this.translate.instant('Common.ShortWednesday'),
            this.translate.instant('Common.ShortThursday'),
            this.translate.instant('Common.ShortFriday'),
            this.translate.instant('Common.ShortSaturday')
        ],
        monthNames: [
            this.translate.instant('Common.January'),
            this.translate.instant('Common.February'),
            this.translate.instant('Common.March'),
            this.translate.instant('Common.April'),
            this.translate.instant('Common.May'),
            this.translate.instant('Common.June'),
            this.translate.instant('Common.July'),
            this.translate.instant('Common.August'),
            this.translate.instant('Common.September'),
            this.translate.instant('Common.October'),
            this.translate.instant('Common.November'),
            this.translate.instant('Common.December')
        ],
        cancelLabel: this.translate.instant('Common.Cancel'),
        applyLabel: this.translate.instant('Common.Accept'),
        clearLabel: this.translate.instant('Common.Clear')
    };

    isError: boolean = false;
    dateToExit: any = null;

    constructor(public dialogRef: MatDialogRef<GroupAddRemoveVisibilityDialogComponent>,
                private _api: ApiService,
                private fb: FormBuilder,
                private deviceService: DeviceDetectorService,
                private translate: TranslateService,
                private _localeService: AppLocaleService,
                private _dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        _localeService.currentLocale().subscribe(q => {
            moment.locale(q);
        });
    }

    ngOnInit() {
        this.form = this.initForm();
        this.form.get('isMandatory').valueChanges.subscribe(value => {
            if (this.data.type !== 'pool' && this.data.type !== 'survey') {
                if (value) {
                    this.form.get('expiryDate').setValidators(Validators.required)
                } else {
                    this.form.get('expiryDate').setValue('')
                    this.form.get('expiryDate').clearValidators();
                    this.expiryDateLtVisibleFromDate = false;
                    this.hasDays = false;
                    this.days = 0;
                }
                this.form.get('expiryDate').updateValueAndValidity();
            }
        })

        this.form.get('startDate').valueChanges.subscribe((value) => {
            this.minEndDate = value?.startDate ? moment(value.startDate) : moment()
            if (this.form.get('endDate').value
                && this.form.get('endDate').value.startDate
                && moment(value.startDate).isAfter(moment(this.form.get('endDate').value.startDate), 'minute')) {
                this.form.get('endDate').setValue({
                    startDate: moment(value.startDate),
                    endDate: moment(value.startDate),
                })
            }
        })

        this.form.get('moreParameters').valueChanges.subscribe(value => {
            if (this.data.type === 'path' || this.data.type === 'training') {
                // this.form.get('visibleFromDate').setValue(this.minVisibleFromDate);

                if (value) {
                    this.form.get('visibleFromDate').setValidators(Validators.required)
                } else {
                    this.form.get('visibleFromDate').clearValidators();
                }
                this.form.get('visibleFromDate').updateValueAndValidity();
            }
        })

        this.form.get('expiryDate').valueChanges.subscribe((value) => {
            if (value && moment(value).isBefore(this.form.get('visibleFromDate').value)) {
                this.expiryDateLtVisibleFromDate = true;
            } else {
                this.expiryDateLtVisibleFromDate = false;
            }
        });

        this.form.get('visibleFromDate').valueChanges.subscribe((value) => {

            if (value && this.hasDays && this.days > 0) {
                this.form.get('expiryDate').setValue(moment(calcWorkingDays(new Date(value), this.days)).format('YYYY-MM-DD'));
            }

            if (this.form.get('expiryDate').value && value && moment(value).isAfter(this.form.get('expiryDate').value)) {
                this.expiryDateLtVisibleFromDate = true;
            } else {
                this.expiryDateLtVisibleFromDate = false;
            }
        });
    }

    initForm(): FormGroup {
        return this.fb.group({
            isMandatory: [false],
            realizationDate: [false],
            startDate: [{
                startDate: moment(),
                endDate: moment()
            }],
            endDate: [''],
            expiryDate: [''],
            visibleFromDate: [this.minVisibleFromDate],
            moreParameters: [false]
        });
    }

    clear(c, name: string) {
        if (c.startDate === null) {
            this.form.get(name).setValue('');
        }
    }

    getSelectedPathsName(items){
        const ids = [];
        items.forEach((e)=>{
            ids.push(e.id);
        });
        return ids;
    }

    assign() {
        this.form.markAllAsTouched();

        this.isError = ((this.data.type === 'pool' || this.data.type === 'survey') &&
            this.form.get('realizationDate').value &&
            !(this.form.get('startDate').value.startDate || this.form.get('endDate').value.startDate))

        if ((!this.form.valid || this.isError) && this.data.type !=='category' && this.data.type !=='gamification') {
            return;
        }

        const availability = {
            user: this.data.usersSelected.length > 0 ? this.data.usersSelected.map(val => val.userId) : [],
            group:  this.data.groupsSelected.length > 0 ? this.data.groupsSelected.map(val => val.id) : [],
            position: this.data.positionsSelected.length > 0 ? this.data.positionsSelected.map(val => val.id) : [],
        };

        const req = {
            availability,
            pathIds: this.getSelectedPathsName(this.data.pathsSelected),
            trainingIds: this.getSelectedPathsName(this.data.pathsSelected),
            assign: this.data.status
        };

        if (this.data.type === 'training') {
            this._api.Admin.saveGroupAvailabilityTrainingUsers(req).subscribe(() => {
                this.dialogRef.close(true);
            }, () => {
                this.dialogRef.close();
            });
        }

        if (this.data.type === 'path') {
            this._api.Admin.saveGroupAvailabilityPathUsers(req).subscribe(() => {
                this.dialogRef.close(true);
            }, () => {
                this.dialogRef.close();
            });
        }

        if (this.data.type === 'pool') {
            this._api.Library.assignPool(this.data.id, req).subscribe(() => {
                this.dialogRef.close(true);
            }, () => {
                this.dialogRef.close();
            });
        }

        if (this.data.type === 'survey') {
            this._api.Survey.assignSurvey(this.data.id, req).subscribe(() => {
                this.dialogRef.close(true);
            }, () => {
                this.dialogRef.close();
            });
        }

        if (this.data.type === 'category') {
            this._api.KnowledgeBaseService.assignCategory(this.data.id, req).subscribe(() => {
                if (this.deviceService.isDesktop()) {
                    this.dialogRef.close(true);
                } else {
                    this.dialogRef.close(true);
                }
            }, () => {
                this.dialogRef.close();
            });
        }

        if (this.data.type === 'gamification') {
            this._api.Gamification.assign(this.data.id, req).subscribe(() => {
                this.dialogRef.close(true);
            }, () => {
                this.dialogRef.close();
            });
        }
    }

    closeMobileDialog(openUserDialog: boolean) {
        this.dialogRef.close({ ...this.dateToExit, openUserDialog });
    }


    openExpiryDateDialog() {
        this._dialog.open(AssignExpiryDateDialogComponent, {
            width: '450px',
            data: {
                date: this.form.get('expiryDate').value,
                visibleFromDate: this.form.get('visibleFromDate').value,
                hasDays: this.hasDays,
                days: this.days,
                type: this.data.type
            }
        }).afterClosed().subscribe(res => {
            if (res) {
                this.form.get('expiryDate').setValue(res.expiryDate);
                this.hasDays = res.hasDays;
                this.days = res.days;
            }
        });
    }
}
