<div class="certificate-preview-modal">
    <h1 mat-dialog-title tabIndex="0">
        {{ 'CertificateCreator.CertificatePreviewCert' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <app-certificate-preview></app-certificate-preview>
    </div>
    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()"
                mat-button color="primary"
                class="dolineo-button blue-button">{{ 'Common.Close' | translate}}</button>
    </div>
</div>