<div class="report">

    <div *ngIf="showEmptyPage"
         class="report--no-certs">
        <img src="/assets/images/certs-no-items.svg" alt="certs-no-items">
        <div>{{'Report.EmptyReports' | translate}}</div>
    </div>

    <div class="data-grid-header" *ngIf="isLoaded && (isDialog || !showEmptyPage)">
        <div class="data-grid-header__left">
            <div class="table-length" *ngIf="!this.isDialog">
                <span matTooltip="{{(filtersString?'Common.FilteredElements': 'Common.LoadedElements') | translate}}"
                      matTooltipPosition="above"
                      matTooltipClass='above'>
                    {{items.length}}
                </span>
                {{'Common.Of' | translate}}
                <span matTooltip="{{'Common.AllElements' | translate}}"
                      matTooltipPosition="above"
                      matTooltipClass='above'>
                    {{request.pagination.totalElementsCount}}
                </span>
            </div>
        </div>

        <div class="data-grid-header__right">
            <button (click)="removeFilters()" *ngIf="filtersString" mat-flat-button color="primary" style="margin-right: 20px;"
                    class="btn-sm rounded-45 clear-table-filters">
                {{'Common.ClearFilters' | translate}}
                <i class="material-icons">close</i>
            </button>
            <button (click)="getData(filtersString)" *ngIf="this.isDialog" mat-flat-button color="primary"
                    class="btn-sm rounded-45 clear-table-filters">
                {{'Common.Reload' | translate}}
                <i class="material-icons">refresh</i>
            </button>
            <ng-container *ngIf="!this.isDialog">
                <button mat-button [matMenuTriggerFor]="configTable" (menuClosed)="menuClosed()" class="configure-table sm">
                    <img src="/assets/icons/configure-table.svg"
                         alt="configure-table">
                    {{'Common.Settings' | translate}}
                </button>
                <mat-menu #configTable="matMenu">
                    <app-config-table-dialog [sliceFrom]="2"
                                             [columns]="columns"
                                             [defaultColumns]="defaultColumns"
                                             (saveConfig)="saveConfig($event)">
                    </app-config-table-dialog>
                </mat-menu>

                <button mat-button
                        class="three-dots sm-menu"
                        [matMenuTriggerFor]="menu"
                        type="button">
                    <img src="/assets/icons/dot_v.svg" alt="dot_v">
                </button>

                <mat-menu #menu="matMenu" class="mat-menu-small">
                    <button (click)="downloadTable()" mat-menu-item>
                        {{'Common.Download' | translate}}
                    </button>

                    <button mat-menu-item [matMenuTriggerFor]="configTable">
                        {{'Common.Settings' | translate}}
                    </button>
                    <mat-menu #configTable="matMenu">
                        <app-config-table-dialog [sliceFrom]="2"
                                                 [columns]="columns"
                                                 [defaultColumns]="defaultColumns"
                                                 (saveConfig)="saveConfig($event)">
                        </app-config-table-dialog>
                    </mat-menu>
                </mat-menu>
            </ng-container>
        </div>
    </div>

    <app-data-grid *ngIf="isLoaded && (isDialog || !showEmptyPage)"
                   [columns]="columns"
                   [items]="items"
                   [request]="request"
                   (loadData)="getData($event)"
                   [noTabs]="false"
                   [selection]="false">
    </app-data-grid>


</div>
