import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-filter-status-dialog',
    templateUrl: './filter-status-dialog.component.html',
    styleUrls: ['./filter-status-dialog.component.scss']
})
export class FilterStatusDialogComponent {

    statues: any[] = [];
    isSingle: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<FilterStatusDialogComponent>) {
        this.statues = [...data.statusesSelected];
        this.isSingle = data.isSingle;
    }

    setStatus(status: any) {
        if (this.isSingle) {
            this.statues = [status]
        } else {
            if (status === this.data.statuses[0]) {
                this.statues = [this.data.statuses[0]]
                return
            }
            this.statues = this.statues.filter(value => value.name !== this.data.statuses[0].name)

            const isInside = this.statues.find(value => value.name === status.name)
            if (isInside) {
                this.statues = this.statues.filter(value => value.name !== status.name)
            } else {
                this.statues.push(status)
            }

            if (this.statues.length === 0) {
                this.statues = [this.data.statuses[0]]
            }
        }
    }

    accept() {
        this.data.statuses = this.data.statuses.map(val => {
            return {
                ...val,
                selected: !!this.statues.find(stat => stat.id === val.id)
            }
        })

        this.dialogRef.close(
            {
                status: this.statues,
                filters: this.data.statuses
            }
        )
    }
}
