import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {RequestInterface} from '../../intefaces/pagination.interface';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'employee-onboarding',
  templateUrl: './employee-onboarding.component.html',
  styleUrls: ['./employee-onboarding.component.scss']
})

export class EmployeeOnboardingComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  waiting = true;
  columns: any[] = [];
  tableItems: any[] = [];
  isMobile: boolean;

  public request: RequestInterface = {
    sort: {
      field: 'id',
      direction: 'DESC'
    },
    pagination: {
      pageNumber: 1,
      pageSize: 50,
      totalElementsCount: 0,
      totalPages: 0
    },
    waitingForData: false
  };

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _router: Router,
              private _translate: TranslateService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    this.createTable();
    if(!this.preview){
      this.getData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  getData(filter = ''): void {
    this.request.waitingForData = true;
    if(this.preview){
      this.request.pagination.totalElementsCount = 5;
      this.request.pagination.totalPages = 1;
      this.request.waitingForData = false;
    }else{
      this._api.Dashboard.getEmployeeOnboardingData().subscribe((res) => {
        this.tableItems = res;
        this.tableItems.sort(function(a,b){
          // @ts-ignore
          return new Date(a.startDate) - new Date(b.startDate);
        });
        this.waiting = false;
        this.prepareWidgetData()
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getExampleData(){

  const tableItems = [];
  tableItems.push(
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName1'),
        days: 8,
        totalElementsCount: 4,
        elementsFinishedCount: 3
      },
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName2'),
        days: 8,
        totalElementsCount: 4,
        elementsFinishedCount: 3
      },
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName3'),
        days: 24,
        totalElementsCount: 4,
        elementsFinishedCount: 3
      },
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName4'),
        days: 8,
        totalElementsCount: 4,
        elementsFinishedCount: 3
      },
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName5'),
        days: 24,
        totalElementsCount: 4,
        elementsFinishedCount: 3
      },
  );
  this.tableItems = tableItems;
  }

  createTable() {
    this.columns = [
      {
        index: 'avatarPath',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: this.isMobile ? false : true,
        type: 'AVATAR',
        css: {
          'padding-left': '10px',
          'min-width': '45px',
        },
        userName: (v) => {
          return v.user;
        },
        size: () => {
          return 28;
        },
        isActive: () => {
          return false
        },
      },
      {
        index: 'user',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '150px',
        },
        class: this.isMobile ? 'ft-auto' : '',
      },
      {
        index: 'days',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '90px',
        },
        render: (v)=>{
          return v.days + ' ' + this._translate.instant('DashboardWidgetEmployeeOnboarding.Day')
        }
      },
      {
        index: 'progress',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: this.isMobile ? false : true,
        type: 'PROGRESS',
        css: {
          'padding-left': '10px',
          'min-width': '200px',
        },
        elementsFinishedCount: (v) => {
          return v.elementsFinishedCount;
        },
        totalElementsCount: (v) => {
          return v.totalElementsCount
        },
        class: 'ft-auto',
      },
      {
        index: 'progress',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: this.isMobile ? true : false,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '60px',
        },
        render: (v) => {
          return '<span class="employee-onboarding">' +
                    '<span class="employee-onboarding-finished">' + v.elementsFinishedCount + '</span>' +
                    '<span class="employee-onboarding-total">/ ' + v.totalElementsCount + '</span>' +
                 '</span>';
        },
        class: this.isMobile ? 'employee-onboarding-mobile' : '',
      },
      {
        index: 'action',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: this.isMobile ? false : true,
        type: 'MENU',
        items: [
          {
            name: 'Onboarding.Results',
            icon: '<i class="dolineo-icon dolineo-icon-hover dolineo-icon-show_attempt_details"></i>',
            click: (row) => {
              this.goTo(row);
            }
          }
        ],
        css: {
          'padding-left': '10px',
          'min-width': '50px',
        },
      },
    ]
  }

  goTo(data){
    if(!this.preview) {
      this._router.navigateByUrl('/app/team/onboarding/' + data.id + '/users');
    }
  }

}