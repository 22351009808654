import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {IDialogData} from '../../../../intefaces/interfaces';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {
  hasPending: boolean = null;
  constructor(@Inject(MAT_DIALOG_DATA) public data: IDialogData,
              public dialogRef: MatDialogRef<ReportDialogComponent>,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  reports() {
    this.dialogRef.close();
    this.router.navigateByUrl('/app/reports');
  }

  onHasPending(event) {
    this.hasPending = event;
  }
}
