import { Component, Inject, Renderer2, Input, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-scroll-to-top',
    templateUrl: './scroll-to-top.component.html',
    styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent {
    @Input() set elementRef(element: ElementRef) {
        if (element) {
            this._elementRef = element
            this.renderer2.listen(element.nativeElement.firstChild, 'scroll', (e: Event) => {
                this.windowScrolled = (e.target as HTMLElement).scrollTop > 100;
            })
        }
    }

    windowScrolled: boolean;
    _elementRef: ElementRef;

    constructor(@Inject(DOCUMENT) private document: Document,
                private renderer2: Renderer2) {
    }

    scrollToTop() {
        (this._elementRef.nativeElement.firstChild as HTMLElement).scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
}