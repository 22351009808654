import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../../shared/components/loader/loader.service';
import { Router } from '@angular/router';
import { HelperService } from '../../shared/services/helper.service';

@Injectable()
export class PendingRequestsInterceptor implements HttpInterceptor {

    private _pendingRequests = 0;
    private _pendingRequestsStatus$ = new ReplaySubject<boolean>(1);

    constructor(private loaderService: LoaderService,
                private router: Router,
                private helper: HelperService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.loader(req, next)
    }

    loader(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const excludeUrl = req.url.indexOf('notification') > -1 ||
            req.url.indexOf('check-name') > -1 ||
            req.url.indexOf('name-validate') > -1 ||
            req.url.indexOf('survey-name') > -1 ||
            req.url.indexOf('video-progress') > -1 ||
            req.url.indexOf('scorm-progress') > -1 ||
            req.url.indexOf('media-progress') > -1 ||
            req.url.indexOf('attempt') > -1 ||
            req.url.indexOf('h5p-progress') > -1 ||
            req.url.indexOf('/user/feedback/update') > -1 ||
            req.url.indexOf('email-validation') > -1 ||
            req.url.indexOf('autoReload') > -1 ||
            req.url.indexOf('pool/open-ai') > -1 ||
            req.url.indexOf('self') > -1;

        if (!excludeUrl) {
            this._pendingRequests++;
        }

        if (1 === this._pendingRequests) {
            this._pendingRequestsStatus$.next(true);
            this.loaderService.show();
        }

        return next.handle(req).pipe(
            catchError(response => {
                if (response.status === 403) {
                    this.router.navigate(['app/no-permission']);
                }
                if (response.status === 404) {
                    if (this.helper.getUser().id) {
                        this.router.navigate(['app/404']);
                    }
                }
                if (response.status === 418) {
                    this.router.navigate(['app/maintenance-mode']);
                }
                return throwError(response);
            }),
            finalize(() => {
                if (!excludeUrl) {
                    this._pendingRequests--;
                }
                if (0 === this._pendingRequests && !excludeUrl) {
                    this.loaderService.hide();
                }
            })
        );
    }
}
