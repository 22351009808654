import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../services/helper.service';
import { RequestInterface } from '../../intefaces/pagination.interface';

@Component({
    selector: 'app-choice-base-gamification-dialog',
    templateUrl: './choice-base-gamification-dialog.component.html',
    styleUrls: ['./choice-base-gamification-dialog.component.scss']
})
export class ChoiceBaseGamificationDialogComponent implements OnInit, OnDestroy {
    columns: any[] = [];
    items: any[] = [];
    filtersString: string = '';
    public request: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };
    public searchRequest: any = null;
    selectedItem = null;

    constructor(public dialogRef: MatDialogRef<ChoiceBaseGamificationDialogComponent>,
                private _api: ApiService,
                private _translate: TranslateService,
                private _dialog: MatDialog,
                private _helper: HelperService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.createTable();
    }

    createTable() {
        this.columns = [
            {
                index: 'name',
                name: 'Common.GamificationName',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                css: {
                    'min-width': '195px',
                    'padding-right': '20px',
                    'word-wrap': 'anywhere'
                },
                class: 'ft-auto'
            },
            {
                index: 'author',
                name: 'Common.Author',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '182px',
                    'width': '182px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return `${v.authorName} ${v.authorSurname} `;
                }
            },
            {
                index: 'elements',
                name: 'Common.Elements',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'min-width': '140px',
                    'width': '140px',
                    'padding-right': '20px'
                }
            }
        ];
    }

    ngOnInit(): void {
        this.getData(this.filtersString);
    }

    ngOnDestroy() {
        // document.body.classList.add('nav-collapsed');
    }

    getData(filtersString?: string): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        this.filtersString = filtersString === '' ? `isDraft=0&status[]=active&status[]=inactive` :
            filtersString.indexOf(`isDraft=0&status[]=active&status[]=inactive`) !== -1 ?
                filtersString.replace(`isDraft=0&status[]=active&status[]=inactive`, `isDraft=0&status[]=active&status[]=inactive`) :
                filtersString + `&isDraft=0&status[]=active&status[]=inactive`
        req.waitingForData = true;

        this.searchRequest = this._api.Gamification.selfGamifications(
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.filtersString
        ).subscribe(res => {
            this.items = req.pagination.pageNumber === 1 ? res.elements : this.items.concat(res.elements);
            this.request.pagination.totalElementsCount = res.totalElementsCount;
            this.request.pagination.totalPages = res.totalPages;
            this.request.waitingForData = false;
        }, () => {
            req.waitingForData = false;
        });
    }

    choiceData() {
        if (!this.selectedItem) {
            return
        }

        this._api.Gamification.getGamification(this.selectedItem.id, '?clone=1').subscribe(value => {
            this._api.Library.baseSubject.next({
                ...value,
                gamificationId: null,
                id: null,
                status: 'draft',
                name: this._helper.stripFileName(`${this._translate.instant('Common.CopyName')}${value.name}`, 60),
                isUsed: 0
            });
            this.dialogRef.close();
        })
    }

    selectItem(item: any) {
        this.selectedItem = item;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
