import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

    @ViewChild('file') file;
    public selectedFile: File = null;
    private base64 = null;
    public base64Preview = null;
    public filenamePreview = null;
    private width = 0;
    private height = 0;
    public apiUrl = environment.filesPath;
    public fileErrorMessage = '';

    public validation = {
        fileSize: '',
        fileExt: this._translate.instant('Common.InvalidFileFormat')
    };
    public fileValidationError: boolean = false;
    public fileError: boolean = false;

    maxSize: number = 10485760;

    constructor(private _dialog: MatDialog,
                public dialogRef: MatDialogRef<AttachmentComponent>,
                public _translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.maxSize = this.data.maxSize;
        this.validation.fileSize = this.data.maxSizeText;
    }

    ngOnInit() {
        this.setImage();
    }

    setImage() {
        if (this.data.image && this.data.image.filename) {
            this.base64Preview = this.apiUrl + this.data.image.url;
            this.filenamePreview = this.data.image.filename;
            if (this.data.image.base) {
                this.base64 = this.data.image.base;
                this.base64Preview = this.data.image.base64Preview;
                this.selectedFile = new File([this.base64], this.data.image.filename, {
                    lastModified: new Date().getTime(),
                    type: this.data.image.type
                });
            } else {
                fetch(this.base64Preview)
                    .then(resp => resp.blob())
                    .then(blob => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            this.base64 = reader.result.toString().split(',')[1];
                        }
                        this.selectedFile = new File([blob], this.data.image.filename, {
                            lastModified: new Date().getTime(),
                            type: blob.type
                        });
                    });
            }
        }
    }

    public dropped(files: NgxFileDropEntry[]) {
        if (files.length > 0 && files[0].fileEntry.isFile && files[0].relativePath.indexOf('/') === -1) {
            const fileEntry = files[0].fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                this.onFileChange(file);
            });
        }
    }

    selectFile(): void {
        if (!this.filenamePreview) {
            this.file.nativeElement.click();
        }
        this.onFileChange();
    }

    onFileChange(file = null): void {
        if (file === null) {
            this.selectedFile = this.file.nativeElement.files[0];
        } else {
            this.selectedFile = file;
        }

        if (!this.selectedFile) {
            return;
        }

        this.fileValidationError = false;
        this.fileError = false;
        this.filenamePreview = this.selectedFile.name;

        if (this.selectedFile.name.toLowerCase().indexOf('.jpg') === -1 &&
            this.selectedFile.name.toLowerCase().indexOf('.jpeg') === -1 &&
            this.selectedFile.name.toLowerCase().indexOf('.png') === -1) {
            this.fileErrorMessage = this.validation.fileExt;
            this.fileError = true;
            this.selectedFile = null;
            this.data.image = null;
            this.base64Preview = null;
        } else if (this.selectedFile.size > this.maxSize) {
            this.fileErrorMessage = this.validation.fileSize;
            this.fileError = true;
            this.selectedFile = null;
            this.data.image = null;
            this.base64Preview = null;
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(this.selectedFile);
            reader.onload = () => {
                this.base64 = (reader.result as string).split(',')[1];
                this.base64Preview = reader.result as string;
                const image = new Image();
                image.src = reader.result as string;
                image.onload = () => {
                    this.width = image.width;
                    this.height = image.height;
                };
            };
        }
    }

    removeFile() {
        this.selectedFile = null;
        this.filenamePreview = null;
        this.fileError = false;
        if (this.file)
            this.file.nativeElement.value = null;
    }

    save() {
        let file = null;
        if (this.selectedFile) {
            file = {
                id: null,
                filename: this.selectedFile.name,
                base: this.base64,
                base64Preview: this.base64Preview,
                type: this.selectedFile.type
            };
        }

        this.dialogRef.close({
            updated: true,
            image: file,
            width: this.width,
            height: this.height,
            url: this.data.jsonParams && this.data.jsonParams.fileUrl && this.data.jsonParams.fileUrl !== '' ? this.data.jsonParams.fileUrl : ''
        });
    }

    downloadFile() {
        const source = `data:${this.selectedFile.type};base64,${this.base64}`;
        const link = document.createElement('a');
        link.href = source;
        link.download = `${this.selectedFile.name}`
        link.click();
    }
}