import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {ApiService} from "../../../api/api.service";

@Component({
    selector: 'app-generate-pool-questions-by-ai-dialog',
    templateUrl: './generate-pool-questions-by-ai-dialog.component.html',
    styleUrls: ['./generate-pool-questions-by-ai-dialog.component.scss']
})
export class GeneratePoolQuestionsByAiDialogComponent implements OnInit {

    @ViewChild('sourceFile') sourceFile;
    public file = null;

    public activeTab: string = 'text';

    public sourceText: string = '';
    public sourceTextError: boolean = false;

    public fileIsSelected: boolean;
    public errorMessage: string = '';
    public fileName: string = '';

    public questionCount: number;
    public questionCountError: boolean = false;
    public maxQuestionCount: number;
    public questionType: string = 'single-choice';

    isSubmitted: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<GeneratePoolQuestionsByAiDialogComponent>,
                private _api: ApiService) {
    }

    ngOnInit(): void {
    }

    selectTab(tab: string) {
        this.activeTab = tab;
    }

    selectFile(): void {
        if (!(this.fileIsSelected || this.fileName)) {
            this.sourceFile.nativeElement.click();
        }
    }

    validateFile(event: any): void {
        const file = event.target.files[0];
        this.errorMessage = '';

        if (file.name.toLowerCase().indexOf('.pdf') === -1
            && file.name.toLowerCase().indexOf('.doc') === -1
            && file.name.toLowerCase().indexOf('.docx') === -1
            && file.name.toLowerCase().indexOf('.ppt') === -1
            && file.name.toLowerCase().indexOf('.pptx') === -1) {
            //this.imageChangedEvent = null;
            this.fileName = '';
            this.errorMessage = 'PoolCreator.AcceptableFileFormatsAreDocuments';
        } else {
            this.fileIsSelected = true;
            this.fileName = file.name;
            this.file = file;
        }
    }

    public dropped(files: NgxFileDropEntry[]) {
        if (files.length > 0 && files[0].fileEntry.isFile && files[0].relativePath.indexOf('/') === -1) {
            const fileEntry = files[0].fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                this.errorMessage = '';

                if (file.name.toLowerCase().indexOf('.pdf') === -1
                    && file.name.toLowerCase().indexOf('.doc') === -1
                    && file.name.toLowerCase().indexOf('.docx') === -1
                    && file.name.toLowerCase().indexOf('.ppt') === -1
                    && file.name.toLowerCase().indexOf('.pptx') === -1) {
                    this.fileName = '';
                    this.errorMessage = 'PoolCreator.AcceptableFileFormatsAreDocuments';
                } else {
                    this.fileIsSelected = true;
                    this.fileName = file.name;
                    this.file = file;
                }
            });
        }
    }

    removeFile() {
        this.errorMessage = '';
        this.fileIsSelected = false;
        this.fileName = null;
        if (this.sourceFile)
            this.sourceFile.nativeElement.value = null;
    }

    generateQuestions() {
        const payload = {
            questionCount: this.questionCount,
            questionType: this.questionType,
            sourceText: this.sourceText
        };

        this.sourceTextError = this.sourceText.length == 0 && this.sourceFile == null;
        this.questionCountError = !this.questionCount;

        if (this.sourceTextError || this.questionCountError) {
            return;
        }

        let file = null
        if (this.activeTab == 'file') {
            file = this.file;
        }

        this.sourceTextError = false;
        this.questionCountError = false;
        this.isSubmitted = true;

        this._api.Admin.generateQuestionsByOpenAi(file, payload).subscribe(res => {
            if (res.body) {
                this.isSubmitted  = false;
                if (res.body.questions) {
                    this.dialogRef.close({
                        response: res.body,
                        questionType: this.questionType
                    });
                } else {
                    this.questionCountError = true;
                    this.maxQuestionCount = (res.body.suggestedQuestionCount > 25) ? 25 : res.body.suggestedQuestionCount;
                }
            }
        })
    }

}
