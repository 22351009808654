import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';

@Injectable()
export class AppLocaleService {
    private _subject = new BehaviorSubject<string>(null);
    public history: any[] = [];

    constructor() {
        //
    }

    setLocale(lang: string) {
        this._subject.next(lang);
    }

    currentLocale(): Observable<string> {
        return this._subject.asObservable();
    }

    public getLanguage(): string {
        if (JSON.parse(localStorage.getItem('dolineouser'))) {
            return JSON.parse(localStorage.getItem('dolineouser')).interfaceLanguageCode
        } else {
            return 'pl'
        }
    }
}