import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AppLocaleService } from '../../../core/services/locale.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { ApiService } from '../../../api/api.service';
import { humanizeMinutes } from '../../helpers';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {AssignGroupDialogComponent} from '../../dialogs/assign-group-dialog/assign-group-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-path-training-tile',
    templateUrl: './path-training-tile.component.html',
    styleUrls: ['./path-training-tile.component.scss']
})
export class PathTrainingTileComponent implements OnInit {
    @Output() updateLikeEmit: EventEmitter<any> = new EventEmitter();
    @Output() addRemoveSelf: EventEmitter<any> = new EventEmitter();
    @Output() assignUsers: EventEmitter<any> = new EventEmitter();
    @Input() data: any;
    @Input() showMore: boolean = false;
    @Input() searchText: string = '';
    public filesPath = environment.filesPath;

    public canEdit = false;
    public canAssign = false;
    public canSelfAssign = false;
    path = null;
    selectedItems = [];

    constructor(private _localeService: AppLocaleService,
                private _api: ApiService,
                private router: Router,
                private _translate: TranslateService,
                public helper: HelperService,
                private deviceService: DeviceDetectorService,
                private _dialog: MatDialog) {
        this._localeService.currentLocale().subscribe(q => {
            moment.locale(q);
        });
    }

    ngOnInit(): void {
        this.data.expiryDate = (this.data.expiryDateForNewUser && this.data.expiryDate) ? (moment(this.data.expiryDateForNewUser) < moment(this.data.expiryDate) ? this.data.expiryDateForNewUser : this.data.expiryDate) :
            (this.data.expiryDateForNewUser ? this.data.expiryDateForNewUser : this.data.expiryDate)
        const eventDate = moment(this.data.expiryDate);
        const todayDate = moment();
        this.data = {
            ...this.data,
            photoUrl: this.photoUrl(),
            expiryDate: this.setFinishedDate(),
            durationTime: humanizeMinutes(this.data.durationTime),
            bar: (this.data.completedTrainingsCount * 100) / this.data.totalElementsCount,
            redColor: (eventDate.diff(todayDate, 'days') <= 7) && !this.data.finishedDate
        };

        if (this.data.idPath) {
            this.canEdit = this.helper.hasPermission('content:path:edit') || this.helper.isCarer(this.data.carersId);
            this.canAssign = this.helper.hasPermissions(['team:path:assign', 'content:path:assign']) || this.helper.isCarer(this.data.carersId);
        } else {
            this.canEdit = this.helper.hasPermission('content:training:edit') || this.helper.isCarer(this.data.carersId);
            this.canAssign = this.helper.hasPermissions(['team:training:assign', 'content:training:assign']) || this.helper.isCarer(this.data.carersId);
        }
        this.canSelfAssign = this.data.assignedBy?.id === this.helper.getUser().id || this.data.assignedBy === null
        if (this.deviceService.isMobile()) {
            this.canEdit = false;
        }
    }

    photoUrl() {
        if (this.data.photoUrl) {
            return this.filesPath + this.data.photoUrl + '?token=' + this.helper.hashUserToken();
        } else {
            if (this.data.idPath) {
                return 'assets/images/default/path-background.jpg'
            } else {
                return 'assets/images/default/training-background.jpg'
            }
        }
    }

    setFinishedDate(): string {
        const eventDate = moment(this.data.expiryDate);
        const todayDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        if (this.data.expiryDate) {
            if (!this.data.finishedDate) {
                if (moment().isSame(eventDate, 'day')) {
                    return this._translate.instant('Calendar.Today');
                }

                if (eventDate.diff(todayDate, 'days') <= 7 && eventDate.diff(todayDate, 'days') > 0) {
                    return this._translate.instant('Common.RemainsDays').replace('{{x}}', eventDate.diff(todayDate, 'day'));
                }
            }
            return moment(this.data.expiryDate).format('DD.MM.YYYY');
        } else {
            return this._translate.instant('Common.NoDeadline');
        }
    }

    previewTraining(): void {
        this.router.navigateByUrl('/app/training/player/' + this.data.idTraining);
    }

    previewPath(): void {
        this.router.navigateByUrl('/app/path/path-view/' + this.data.idPath);
    }

    updateLike(data: any) {
        this.data.isFavourite = !this.data.isFavourite;
        this.data.isFavourite ? this.data.favouritesCount++ : this.data.favouritesCount--;
        this.updateLikeEmit.emit(data)
    }

    assign(data: any) {
        this.assignUsers.emit(data)
    }

    assignPathsToUsers(path): void {

        const paramsURI = `&${'paths[]'}=${encodeURIComponent(String(path.id))}`;
        this.selectedItems.push(path);
        this._api.Library.pathUsersGroup(this.helper.getUser().id, paramsURI).subscribe((data) => {
            const users = data.users;
            const groups = data.groups.map(value => {
                return {
                    ...value,
                    name: this._translate.instant(value.name)
                }
            });
            const positions = data.positions;
            this._dialog.closeAll();

            this._dialog.open(AssignGroupDialogComponent, {
                panelClass: 'assigned-users-dialog',
                autoFocus: false,
                // scrollStrategy: this.overlay.scrollStrategies.noop(),
                disableClose: true,
                data: {
                    type: 'path',
                    ids: this.selectedItems,
                    names: path.name,
                    users,
                    groups,
                    positions,
                    toastrAssign: 'Path.AssignedToPath',
                    toastrUnassign: 'Path.OutOfThePath'
                },
                width: '90vw',
                maxWidth: '90vw',
                maxHeight: '95vh'
            }).afterClosed().subscribe(() => {
                sessionStorage.removeItem('path');
            });
        });
    }

    assignTrainingsToUsers(training): void {

        const paramsURI = `&${'trainings[]'}=${encodeURIComponent(String(training.id))}`;
        this.selectedItems.push(training);
        this._api.Library.trainingUsersGroup(this.helper.getUser().id, paramsURI).subscribe((data) => {
            const users = data.users;
            const groups = data.groups.map(value => {
                return {
                    ...value,
                    name: this._translate.instant(value.name)
                }
            });
            const positions = data.positions;
            this._dialog.closeAll();

            this._dialog.open(AssignGroupDialogComponent, {
                panelClass: 'assigned-users-dialog',
                autoFocus: false,
                // scrollStrategy: this.overlay.scrollStrategies.noop(),
                disableClose: true,
                data: {
                    type: 'training',
                    ids: this.selectedItems,
                    names: training.name,
                    users,
                    groups,
                    positions,
                    toastrAssign: 'Training.AssignedToTrainings',
                },
                width: '90vw',
                maxWidth: '90vw',
                maxHeight: '95vh'
            }).afterClosed().subscribe(() => {
                sessionStorage.removeItem('training');
            });
        });
    }

    updateSelf(data: any) {
        this.data.assignedDate = this.data.assignedDate ? null : new Date();
        this.addRemoveSelf.emit(data);
    }

    goEdit() {
        if (this.data.idPath) {
            this.router.navigateByUrl('/app/paths-creator/update/path/' + this.data.idPath);
        } else {
            this.router.navigateByUrl('/app/trainings-creator/update/' + this.data.idTraining);
        }
    }

    runTraining() {
        this.router.navigateByUrl('/app/training/player/' + this.data.idTraining + '?autorun=1');
    }

    runPath() {
        this._api.User.getPath(this.helper.getUser().id, this.data.idPath).subscribe((path) => {
            this.path = {
                id: path.id,
                name: path.name,
                nextElement: path.nextElement,
                elements: path.elements,
            };
            this.setPathRouter();
        });
    }

    setPathRouter() {
        this.router.navigateByUrl('/app/path/path-player/' + this.path.id);
        /*
        if (!this.path.nextElement) {
            this.path.nextElement = this.path.elements[0];
        }
        if (this.path.nextElement) {
            if (this.path.nextElement.objectType === 'training') {
                this.router.navigateByUrl('/app/training/player/' + this.path.nextElement.objectId + '/start?b=path' + '&autorun=1&path=' + this.path.id);
            } else if (this.path.nextElement.objectType === 'pools') {
                this.router.navigateByUrl('/app/pool/player/' + this.path.nextElement.objectId + '?b=path' + '&autorun=1&path=' + this.path.id);
            } else if (this.path.nextElement.objectType === 'survey') {
                this.router.navigateByUrl('/app/survey/player/' + this.path.nextElement.objectId + '?b=path' + '&autorun=1&path=' + this.path.id);
            } else if (this.path.nextElement.objectType === 'knowledge-base-entry') {
                this.router.navigateByUrl('/app/library/knowledge-base/article/player/' + this.path.nextElement.objectId + '?b=path' + '&autorun=1&path=' + this.path.id);
            }
        }
        */
    }
}
