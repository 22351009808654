function makeAppConfig() {
  const date = new Date();
  const year = date.getFullYear();

  return {
    brand: 'Dolineo',
    user: '',
    year,
    layoutBoxed: false,               // true, false
    navCollapsed: false,              // true, false
    navBehind: true,                  // true, false
    fixedHeader: true,                // true, false
    sidebarWidth: 'middle',           // small, middle, large
    theme: 'light',                   // light, gray, dark
    colorOption: '35',                // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    AutoCloseMobileNav: true,         // true, false. Automatically close sidenav on route change (Mobile only)
    productLink: 'https://dev-dolineo2zero.dolineo.com/',
    defaultLocale: 'pl'
  };
}

export const APPCONFIG = makeAppConfig();
