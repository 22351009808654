import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    constructor() {
        //
    }

    set(key: string, value: string) {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        const expires = `expires=${date.toUTCString()}`;
        const secureFlag = `Secure; SameSite=Lax`;
        document.cookie = `${key}=${encodeURIComponent(value)}; ${expires}; path=/; ${secureFlag}`;
    }

    get(key: string): string | null {
        const name = `${key}=`;
        const decodedCookies = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookies.split('; ');

        for (const cookie of cookieArray) {
            if (cookie.startsWith(name)) {
                return cookie.substring(name.length);
            }
        }
        return '';
    }

    delete(key: string) {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure`;
    }

    deleteAll() {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const key = cookie.split('=')[0];
            this.delete(key);
        }
    }
}
