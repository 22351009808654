import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoryService } from '../../../api/services/category.service';
import { noWhitespaceValidator } from '../../validators/noWhitespaceValidator';
import { categoryValidator } from '../../validators/category.validator';

@Component({
    selector: 'app-category-dialog',
    templateUrl: './category-dialog.component.html',
    styleUrls: ['./category-dialog.component.scss']
})
export class CategoryDialogComponent implements OnInit {

    form: FormGroup;
    subscriptions: Subscription = new Subscription();

    constructor(private _toastrService: ToastrService,
                private fb: FormBuilder,
                protected categoryService: CategoryService,
                private _translate: TranslateService,
                public dialogRef: MatDialogRef<CategoryDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {

        this.form = this.initForm();

        if (this.data) {
            this.form.patchValue(this.data);
        }
    }

    initForm(): FormGroup {
        return this.fb.group({
            id: [''],
            name: ['', {
                validators: [Validators.required, noWhitespaceValidator],
                asyncValidators: [categoryValidator(this.categoryService, this.data?.id)]
            }]
        });
    }

    saveCategory() {
        if (!this.form.valid) {
            return;
        }
        const payload = {
            name: this.form.get('name').value.trim().charAt(0).toUpperCase() + this.form.get('name').value.slice(1)
        }

        if (this.data) {
            this.subscriptions.add(this.categoryService.updateCategory(this.data.id, { ...payload }).subscribe(() => {
                this._toastrService.success(this._translate.instant('SubjectAreas.AreaEdited'));
                this.dialogRef.close(true);
            }, () => {
                this._toastrService.error(this._translate.instant('Err.ErrRetry'))
            }));
        } else {
            this.subscriptions.add(this.categoryService.createCategory({ ...payload }).subscribe(() => {
                this._toastrService.success(this._translate.instant('SubjectAreas.AddedNewArea'));
                this.dialogRef.close(true);
            }, () => {
                this._toastrService.error(this._translate.instant('Err.ErrRetry'))
            }));
        }
    }
}
