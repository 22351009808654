<div class="guide-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{data[index].name}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content class="quill-content" [innerHTML]="data[index].content | safe: 'html'">
    </div>

    <div mat-dialog-actions class="dialog-footer space-beetwen"
         *ngIf="data.length > 0"
            [style.float]="data.length === 1 ? 'right' : 'unset'"
    >
        <div *ngIf="data.length > 1">
            <span *ngFor="let guide of data; let i = index"
                  [ngClass]="{'active': i === index}"
                  [style.marginBottom]="data.length >= 22 ? '8px': '2px'"
                  (click)="setIndex(i)">
            </span>
        </div>
        <div
            style="text-align: right;"
            [style.width]="data.length >= 22 ? '100%': 'auto'"
        >
            <button mat-button
                    (click)="previewGuide()"
                    color="primary"
                    *ngIf="index !== 0"
                    class="dolineo-button blue-button">
                <img src="assets/icons/arrow_left.svg" alt="arrow_left">
            </button>
            <button mat-button
                    (click)="nextGuide()"
                    color="primary"
                    *ngIf="index !== data.length-1"
                    class="dolineo-button blue-button">
                {{ 'Common.Next' | translate}}
                <img src="assets/icons/arrow_right.svg" alt="arrow_right">
            </button>
            <button mat-button
                    *ngIf="index === data.length-1"
                    (click)="dialogRef.close()"
                    color="primary"
                    class="dolineo-button blue-button">
                {{ 'Common.Close' | translate}}
            </button>
        </div>
    </div>
</div>