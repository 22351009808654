import { AbstractControl, ValidationErrors } from '@angular/forms';

export function noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    if (control && control.value) {
        if ((control.value as string).trim().length === 0) {
            return { cannotContainSpace: true }
        }
    }

    return null;
}
