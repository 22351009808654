<div class="choice-base-graphic">
    <h1 mat-dialog-title tabIndex="0">
        {{'Common.ChoiceFromGraphicBase' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content *ngIf="baseGraphics.length">
        <div class="choice-base-graphic__graphics">
            <div *ngFor="let graph of baseGraphics" class="graphic"
                 [ngStyle]="{'height': height+'px', 'width': width+'px'}"
                 [ngClass]="{'active': graph.fileId === chosenGraphic?.fileId}"
                 (click)="setChosenGraphic(graph)">
                <img [src]="apiUrl + graph.filePath"
                     loading="lazy"
                     alt="img">
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close(false)" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button (click)="accept()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Save' | translate}}</button>
    </div>
</div>
