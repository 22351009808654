import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {RequestInterface} from '../../intefaces/pagination.interface';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {getActivityName, relatedTypeName} from '../../helpers';
import * as moment from 'moment';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'employee-logs',
  templateUrl: './employee-logs.component.html',
  styleUrls: ['./employee-logs.component.scss']
})

export class EmployeeLogsComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  waiting = true;
  columns: any[] = [];
  tableItems: any[] = [];
  isMobile: boolean;

  public request: RequestInterface = {
    sort: {
      field: 'id',
      direction: 'DESC'
    },
    pagination: {
      pageNumber: 1,
      pageSize: 50,
      totalElementsCount: 0,
      totalPages: 0
    },
    waitingForData: false
  };

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _router: Router,
              private _translate: TranslateService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile()
  }

  ngOnInit() {
    this.createTable();
    if(!this.preview){
      this.getData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  getData(filter = ''): void {
    this.request.waitingForData = true;
    if(this.preview){
      this.request.pagination.totalElementsCount = 5;
      this.request.pagination.totalPages = 1;
      this.request.waitingForData = false;
    }else{
      this._api.Dashboard.getEmployeeLogsData().subscribe((res) => {
        this.tableItems = res;
        this.tableItems.sort(function(a,b){
          // @ts-ignore
          return new Date(a.startDate) - new Date(b.startDate);
        });
        this.waiting = false;
        this.prepareWidgetData()
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getExampleData(){

  const tableItems = [];
  tableItems.push(
      {
        avatarPath: '',
        userName: this._translate.instant('DashboardWidgetExample.UserName1'),
        createdDate: '30.08.2024, 12:05',
        activityName: 'updated',
        relatedObjectName: this._translate.instant('DashboardWidgetEmployeeLogs.Example1'),
        relatedObjectType: 'preboarding'
      },
      {
        avatarPath: '',
        userName: this._translate.instant('DashboardWidgetExample.UserName2'),
        createdDate: '29.08.2024, 12:05',
        activityName: 'created',
        relatedObjectName: this._translate.instant('DashboardWidgetEmployeeLogs.Example2'),
        relatedObjectType: 'onboarding'
      },
      {
        avatarPath: '',
        userName: this._translate.instant('DashboardWidgetExample.UserName3'),
        createdDate: '15.08.2024, 12:05',
        activityName: 'updated',
        relatedObjectName: this._translate.instant('DashboardWidgetEmployeeLogs.Example3'),
        relatedObjectType: 'training'
      },
      {
        avatarPath: '',
        userName: this._translate.instant('DashboardWidgetExample.UserName4'),
        createdDate: '10.08.2024, 12:05',
        activityName: 'login-as',
        relatedObjectName: this._translate.instant('DashboardWidgetEmployeeLogs.Example1'),
        relatedObjectType: 'gamification'
      },
      {
        avatarPath: '',
        userName: this._translate.instant('DashboardWidgetExample.UserName5'),
        createdDate: '04.08.2024, 12:05',
        activityName: 'created',
        relatedObjectName: this._translate.instant('DashboardWidgetEmployeeLogs.Example2'),
        relatedObjectType: 'onboarding'
      },
  );
  this.tableItems = tableItems;
  }

  createTable() {
    this.columns = [
      {
        index: 'avatarPath',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'AVATAR',
        css: {
          'padding-left': '10px',
          'min-width': '45px',
        },
        userName: (v) => {
          return v.userName;
        },
        size: () => {
          return 28;
        },
        isActive: () => {
          return false
        },
      },
      {
        index: 'userName',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '120px',
        },
        class: 'ft-auto',
      },
      {
        index: 'createdDate',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '130px',
        },
        render: (v) => {
          return this.preview ? v.createdDate : (v.createdDate ? moment(v.createdDate).format('DD.MM.YYYY HH:mm') : '');
        }
      },
      {
        index: 'activityName',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '120px',
        },
        render: (activity) => {
          return this._translate.instant(getActivityName(activity.activityName));
        }
      },
      {
        index: 'relatedObjectName',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '120px',
        },
      },
      {
        index: 'relatedObjectType',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '120px',
        },
        render: (v) => {
          return '<span class="'+ v.relatedObjectType +'">' + this._translate.instant(relatedTypeName(v.relatedObjectType)) + '</span>';
        }
      },
    ]
  }

  goTo(data){
    if(!this.preview) {
      this._router.navigateByUrl('/app/logs/system');
    }
  }

}