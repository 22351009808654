import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'gamification',
  templateUrl: './gamification.component.html',
  styleUrls: ['./gamification.component.scss']
})

export class GamificationComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  certificatesCount: number = 0;
  data: any;
  waiting = true;
  viewPie: any[] = [100, 100];
  colorScheme: any;
  gamifications: any[] = [];
  isMobile: boolean;

  pagination = {
    pageNumber: 1,
    pageSize: -159,
    totalElementsCount: 0,
    totalPages: 0
  };
  pieChartData = [];
  defaultElmentsColor: string = '00A6B3';

  customCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    dotsEach: 3,
    // autoplay: true,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        mergeFit: true,
        margin: 180,
        dotsEach: 3,
      },
    },
    nav: true
  }

  gamificationCarousel: OwlOptions = {
    ...this.customCarouselOptions,
    navText: [`<div><img src="assets/icons/left-arrow.svg" alt="arrow-left"></div>`,
      `<div><img src="assets/icons/right-arrow.svg" alt="arrow-right"></div>`],
  }

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _translate: TranslateService,
              private _router: Router) {}

  ngOnInit() {
    if(!this.preview){
      this.prepareGamificationsData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
      this.colorScheme = {
        domain: [
          this.defaultElmentsColor !== this.widget.elementsColor ? '#' + this.items.elementsColor : '#00A6B3',
          this.defaultElmentsColor !== this.widget.elementsColor ? this.hexToRGB('#' + this.items.backgroundColor,0.4) : '#ccedf0'
        ]
      };
    }
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  prepareGamificationsData(){
    this._api.Dashboard.getGamificationsData().subscribe((res) => {
      this.gamifications = [];
      this.gamifications = this.gamifications.concat(res);

      this.gamifications.forEach(gam => {
        const progress = gam.elementsFinishedCount / gam.totalElementsCount * 100;
        gam.pieChartData = [
          {
            name: progress.toString(),
            value: progress,
          },
          {
            name: (100 - progress - 0.001).toString(),
            value: 100 - progress - 0.001,
          }
        ]
      });

      this.waiting = false;
      this.prepareWidgetData()
    });
  }

  getExampleData(){
    const progress1 = 50;
    const pieChartDataExample1 = [
      {
        name: progress1.toString(),
        value: progress1,
      },
      {
        name: (100 - progress1 - 0.001).toString(),
        value: 100 - progress1 - 0.001,
      }
    ];
    const progress2 = 25;
    const pieChartDataExample2 = [
      {
        name: progress2.toString(),
        value: progress2,
      },
      {
        name: (100 - progress2 - 0.001).toString(),
        value: 100 - progress2 - 0.001,
      }
    ];
    this.gamifications = [
      {
        id: 0,
        name: this._translate.instant('DashboardWidgetGamification.Example1'),
        ranking: [0,1],
        rankingPlace: 1,
        totalElementsCount: 2,
        elementsFinishedCount: 1,
        totalPoints: 400,
        pieChartData: pieChartDataExample1
      },
      {
        id: 0,
        name: this._translate.instant('DashboardWidgetGamification.Example2'),
        ranking: [0,1,2],
        rankingPlace: 2,
        totalElementsCount: 4,
        elementsFinishedCount: 1,
        totalPoints: 10,
        pieChartData: pieChartDataExample2
      }
    ];
  }

  goTo(id){
    if(!this.preview){
      this._router.navigateByUrl('/app/gamification/my-gamifications/' + id);
    }
  }
}