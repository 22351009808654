import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public createCategory(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/category`, body, { headers });
    }

    public updateCategory(id: any, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/category/${id}`, body, { headers });
    }

    public getCategories(sortBy: string, orderBy: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        const sort = sortBy? `sortBy=${sortBy}` : '';
        const order = sortBy? `&order=${orderBy}&` : '';
        return this.http.get<any>(`${this.basePath}/category?${sort}${order}${params}`, {headers});
    }

    public removeCategory(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/admin/category/${id}`, {headers});
    }

    public checkCategoryName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/category-check-name`, body, {headers});
    }

    public categoryReorder(ids: any[]): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/category-reorder`, {ids}, {headers});
    }
}
