import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'roleTooltip' })
export class RoleTooltipPipe implements PipeTransform {

    transform(value: any[]) {
        const lastState = value[0].lastState? 1: 0;
        const newState = value[value.length-1].newState? 1: 0;
        const text = lastState === newState ?', przywrócono wartość domyślną': ', uprawnienie ma inną wartość niż domyślnie.';
        const date = moment(value[value.length-1].changedAt.date).format('YYYY-MM-DD');
        return `Edytowane ${date}${text}`;
    }
}
