import { Component } from '@angular/core';

@Component({
    selector: 'app-welcome-page-sys-admin',
    templateUrl: './welcome-page-sys-admin.component.html',
    styleUrls: ['./welcome-page-sys-admin.component.scss']
})
export class WelcomePageSysAdminComponent {

    constructor() {
        //
    }
}
