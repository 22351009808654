import {Component, OnInit, Input, OnChanges, SimpleChanges, HostListener} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'employee-certificates',
  templateUrl: './employee-certificates.component.html',
  styleUrls: ['./employee-certificates.component.scss']
})

export class EmployeeCertificatesComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.prepareWidgetData();
  }

  widget: any;
  certificates: any;
  waiting = true;
  defaultElmentsColor: string = 'FF9900';
  isMobile: boolean;
  innerWidth = window.innerWidth;

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    if(!this.preview){
      this.prepareCertificateData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: this.isMobile || this.innerWidth < 1220 ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getExampleData(){

    this.certificates = {
      preboarding: 18,
      preboardingVisible: false,
      onboarding: 37,
      onboardingVisible: true,
      training: 60,
      trainingVisible: true,
      gamification: 23,
      gamificationVisible: false,
      path: 22,
      pathVisible: true,
      all: 160
    };
  }

  prepareCertificateData(){
    this._api.Dashboard.getEmployeeCertificatesData().subscribe((res) => {
      this.certificates = {
        preboarding: res.find(x => x.type === 'preboarding') ? res.find(x => x.type === 'preboarding').typeCount : 0,
        preboardingVisible: res.find(x => x.type === 'preboarding') ? res.find(x => x.type === 'preboarding').visible : false,
        onboarding: res.find(x => x.type === 'onboarding') ? res.find(x => x.type === 'onboarding').typeCount : 0,
        onboardingVisible: res.find(x => x.type === 'onboarding') ? res.find(x => x.type === 'onboarding').visible : false,
        training: res.find(x => x.type === 'training') ? res.find(x => x.type === 'training').typeCount : 0,
        trainingVisible: res.find(x => x.type === 'training') ? res.find(x => x.type === 'training').visible : false,
        gamification: res.find(x => x.type === 'gamification') ? res.find(x => x.type === 'gamification').typeCount : 0,
        gamificationVisible: res.find(x => x.type === 'gamification') ? res.find(x => x.type === 'gamification').visible : false,
        path: res.find(x => x.type === 'path') ? res.find(x => x.type === 'path').typeCount : 0,
        pathVisible: res.find(x => x.type === 'path') ? res.find(x => x.type === 'path').visible : false,
        all: res.find(x => x.type === 'all') ? res.find(x => x.type === 'all').typeCount : 0,
      };
        this.waiting = false;
        this.prepareWidgetData()
    });
  }
}