<div class="config-certificate-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{'ConfigCertificateDialog.CertificateConfiguration' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>

        <div class="group-add-certificate-info" *ngIf="form.get('group').value">
            <div class="config-certificate-dialog--length">
                <span class="panel-header-info__title">{{ data.headerText | translate }}: </span>
                <div class="number-selected">
                    {{data.ids.length}}
                </div>
            </div>
            <div class="config-certificate-dialog--info">
                <img src="assets/icons/info.svg" alt="checked-green" style="margin-right: 2px;">
                {{(data.infoText | translate)}}
            </div>
        </div>

        <form [formGroup]="form">
            <div class="choice-certificate">
                <mat-form-field>
                    <mat-select placeholder="{{ 'ConfigCertificateDialog.CertificateTemplate' | translate}} *"
                                formControlName="selectedCertificate"
                                #selector
                                [compareWith]="compareCertificatesObjects">
                        <mat-option [value]="cert"
                                    *ngFor="let cert of certificates">{{cert.name}}</mat-option>
                    </mat-select>
                    <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                </mat-form-field>

                <button (click)="previewCertificate()"
                        mat-flat-button
                        [ngClass]="form.get('selectedCertificate').value ? 'blue-span' : 'gray-span'">
                    <img src="assets/icons/find_in_page.svg"
                         alt="find_in_page"
                         [ngClass]="form.get('selectedCertificate').value ? 'dolineo-icon-blue' : 'dolineo-icon-gray'">
                    {{'PathCreator.CertPreview' | translate}}
                </button>
            </div>

            <mat-slide-toggle color="primary" formControlName="twoSidesCertificate">
                {{'ConfigCertificateDialog.AddTheSecondSideOfTheCertificate' | translate}}
            </mat-slide-toggle>

            <div class="editor-wrapper" *ngIf="form.get('twoSidesCertificate').value">
                <label>{{'ConfigCertificateDialog.ContentOfTheReverseSideOfTheCertificate' | translate}} *</label>
                <div id="quill-editor"></div>

                <mat-error *ngIf="form.get('certificateContent').value === '<p><br></p>'">
                    {{ 'Common.FiledCanNotBeEmpty' | translate }}
                </mat-error>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" [mat-dialog-close]="false" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button (click)="addCertificate()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Add' | translate}}</button>
    </div>
</div>
