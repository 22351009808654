import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { CategoryService } from '../../../api/services/category.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../services/helper.service';
import { CategoryDialogComponent } from '../../dialogs/category-dialog/category-dialog.component';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { setLocalFilter } from '../../helpers';
import { RequestInterface } from '../../intefaces/pagination.interface';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

    public filesPath = environment.filesPath;
    searchRequest: any = null;
    public request: RequestInterface = {
        sort: {
            field: '',
            direction: ''
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    columns: any[] = [];
    items: any[] = [];
    filtersString = '';

    localStorageFilter: string = this._helper.isSuperAdmin() || this._helper.isSysAdmin() ? 'AG-categories-filters' : 'AL-categories-filters';

    constructor(protected categoryService: CategoryService,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                private _toastrService: ToastrService,
                public _helper: HelperService) {
        this._helper.createTitle([
            this._helper.isSuperAdmin() || this._helper.isSysAdmin() ? this._translate.instant('Menu.Config') : this._translate.instant('Menu.Admin'),
            'Common.SubjectAreas'
        ])
        this.createTable();
    }

    ngOnInit(): void {
        this.getData(localStorage.getItem(this.localStorageFilter) ? JSON.parse(localStorage.getItem(this.localStorageFilter)).filter : '');
    }

    createTable() {
        this.columns = [
            {
                type: 'DRAG_AND_DROP',
                visible: true,
                css: {
                    'min-width': '30px',
                    'width': '30px',
                }
            },
            {
                index: 'name',
                name: 'SubjectAreas.AreaName',
                searchable: true,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '420px',
                    'width': '420px',
                    'padding-left': '20px'
                }
            },
            {
                type: 'MENU',
                visible: true,
                items: [
                    {
                        name: 'Common.Edit',
                        icon: '<i class="dolineo-icon dolineo-icon-hover dolineo-icon-pencil2"></i>',
                        visible: (v) => {
                            return v.isGlobal === 'false' || v.isDefault || this._helper.isSuperAdmin() || this._helper.isSysAdmin();
                        },
                        click: (row) => {
                            this.addEditCategory(row);
                        }
                    },
                    {
                        name: 'Common.Delete',
                        icon: '<i class="dolineo-icon dolineo-icon-hover dolineo-icon-trash"></i>',
                        visible: (v) => {
                            return ((v.isGlobal === 'false' && !v.isDefault) || this._helper.isSuperAdmin() || this._helper.isSysAdmin()) && v.used === 0;
                        },
                        click: (row) => {
                            this.removeCategory(row);
                        }
                    }
                ],
                css: {
                    'width': '80px',
                    'min-width': '80px'
                }
            },
            {
                index: 'user',
                name: 'Common.Author',
                searchable: true,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '269px',
                    'width': '269px',
                    'padding-right': '20px'
                }
            },
            {
                index: '',
                name: '',
                searchable: false,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                class: 'ft-auto',
                css: {
                    'min-width': '50px',
                    'padding-right': '20px'
                }
            },
        ];
    }

    addEditCategory(category: any) {
        this._dialog.open(CategoryDialogComponent, {
            disableClose: true,
            width: '486px',
            data: category
        }).afterClosed().subscribe((data: boolean) => {
            if (data) {
                this.request.pagination.pageNumber = 1;
                this.getData(this.filtersString);
            }
        });
    }

    removeCategory(category: any) {
        this._dialog.open(ConfirmDialogComponent, {
            width: '486px',
            disableClose: true,
            data: {
                title: 'SubjectAreas.DeleteArea',
                content: 'SubjectAreas.DeleteConfirm',
                okText: 'Common.Delete'
            }
        }).afterClosed().subscribe(status => {
            if (status) {
                this.categoryService.removeCategory(category.id).subscribe(() => {
                    this._toastrService.success(this._translate.instant('SubjectAreas.Deleted'));
                    this.request.pagination.pageNumber = 1;
                    this.getData(this.filtersString);
                }, () => {
                    this._toastrService.error(this._translate.instant('Err.ErrRetry'));
                });
            }
        });
    }

    getData(filtersString = ''): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        this.filtersString = filtersString;
        setLocalFilter(this.filtersString, this.columns, this.request, this.localStorageFilter);
        this.searchRequest = this.categoryService.getCategories(
            req.sort.field,
            req.sort.direction,
            filtersString
        ).subscribe(res => {
                this.items = [...res];
                this.request.pagination.totalElementsCount = this.items.length;
                this.request.pagination.totalPages = 1;
            }
        )
    }

    removeFilters() {
        this.columns.forEach(c => {
            c.value = null;
            if (c.valueRange) {
                c.valueRange = null;
            }
        });
        this.request.pagination.pageNumber = 1;
        this.getData('');
    }

    reorderCategory(ids: any[]) {
        this.categoryService.categoryReorder(ids).subscribe();
    }
}
