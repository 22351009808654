import { ChangeDetectorRef, Directive, ElementRef, KeyValueDiffers } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { DaterangepickerDirective, LocaleService } from 'ngx-daterangepicker-material';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appDatepickerSingleDate]'
})
export class DatepickerSingleDateDirective extends DaterangepickerDirective {
    private _dateValue: any = null;

    constructor(_changeDetectorRef: ChangeDetectorRef,
                differs: KeyValueDiffers,
                _localeService: LocaleService,
                elementRef: ElementRef,
                overlay: Overlay,
                private control: NgControl) {
        super(_changeDetectorRef, differs, _localeService, elementRef, overlay);
    }

    calculateChosenLabel(startDate, endDate) {
        const format = this.locale.displayFormat ? this.locale.displayFormat : this.locale.format;
        if (this.singleDatePicker && startDate) {
            return startDate.format(format);
        }
        if (startDate && endDate) {
            return startDate.format(format) + this.locale.separator + endDate.format(format);
        }
        return null;
    }

    get value() {
        return this._dateValue || null;
    }

    set value(val) {
        this._dateValue = val;
        this.control.control.setValue(val);
        this._changeDetectorRef.markForCheck();
    }
}
