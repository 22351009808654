<h1 mat-dialog-title tabIndex="0">
    {{'Dialogs.SelectIcon' | translate}}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content class="text-center">
    <div class="include-icons">
        <div *ngFor="let icon of icons" (click)="onClickIcon(icon)" class="include-icon"
             [ngClass]="{'selected': selectedIcon.fileId === icon.fileId}">
            <img [src]="filesPath + icon.filePath" alt="file"/>
        </div>
    </div>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close(false)" mat-button class="cancel-button">{{'Common.Cancel' | translate}}</button>
    <button (click)="acceptIcon()" mat-button color="primary" class="dolineo-button blue-button">{{'Common.Accept' | translate}}</button>
</div>
