import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { HelperService } from '../services/helper.service';
import { UserSelfInterface } from '../intefaces/user-self.interface';
import { AccessLevelsType } from '../models/access-levels.type';
import { RolesType } from '../models/roles.type';
import { take } from 'rxjs/operators';
import { SubscriptionType } from '../models/subscription.type';

@Directive({
    selector: '[hasAccess]'
})
export class HasAccessDirective implements OnInit {
    @Input()
    set hasAccess(_roles: RolesType[]) {
        this.roles = _roles;
    }

    @Input()
    set hasAccessPerms(_permissions: AccessLevelsType[]) {
        this.permissions = _permissions;
    }

    @Input()
    set hasAccessSubscriptions(_subscriptions: SubscriptionType[]) {
        this.subscriptions = _subscriptions;
    }

    private user: UserSelfInterface;
    public roles: RolesType[] = [];
    public permissions: AccessLevelsType[] = [];
    public subscriptions: SubscriptionType[] = [];

    constructor(private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private helperService: HelperService) {
    }

    ngOnInit(): void {
        this.helperService.user().pipe(take(1)).subscribe((_user) => {
            this.user = _user;
            this.updateView();
        });
    }

    private updateView() {
        if (this.canAccess()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private hasRole(role: RolesType): boolean {
        return this.user.roles.indexOf(role) > -1;
    }

    private hasPermission(permission: AccessLevelsType): boolean {
        return this.user.accessLevels.indexOf(permission) > -1;
    }

    private hasAccessToSubscription(subscription: SubscriptionType): boolean {
        return this.user.subscriptions.indexOf(subscription) > -1;
    }

    private canAccess() {
        let canAccess = false;
        if (this.roles?.length > 0) {
            for (const role of this.roles) {
                canAccess = this.hasRole(role);
                if ((role === 'ROLE_SUPERADMIN' || role === 'ROLE_SYSTEMADMIN') && this.hasRole(role)) {
                    canAccess = true;
                    break;
                }
                if (!this.permissions?.length && this.hasRole(role)) {
                    canAccess = true;
                    break;
                }

                for (const permission of this.permissions) {
                    canAccess = this.hasPermission(permission);
                    if (canAccess) {
                        break;
                    }
                }
            }
        } else if (this.permissions?.length > 0) {
            for (const permission of this.permissions) {
                canAccess = this.hasPermission(permission);
                if (canAccess) {
                    break;
                }
            }
        }

        if (typeof this.subscriptions !== 'undefined') {
            for (const subscription of this.subscriptions) {
                if (!this.hasAccessToSubscription(subscription)) {
                    canAccess = false;
                }
            }
        }

        return canAccess;
    }
}
