import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import {
    SurveyOtherAnswersDialogComponent
} from '../../dialogs/survey-other-answers-dialog/syrvey-other-answers-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import { ImagePreviewComponent } from '../../dialogs/image-preview/image-preview.component';

@Component({
    selector: 'app-survey-question-stats',
    templateUrl: './survey-question-stats.component.html',
    styleUrls: ['./survey-question-stats.component.scss']
})
export class SurveyQuestionStatsComponent implements OnInit {
    @ViewChild('barVerticalComponent') barVerticalComponent: any;

    @Input() public question = null;
    @Input() public currentQuestionIndex = 0;
    @Input() public numberOfAllQuestions = 0;
    @Input() public totalAssigned = 0;

    public apiUrl = environment.filesPath;

    viewPie: any[] = [280, 197];
    pieChartDate: any[] = [];
    barVerticalDate: any[] = [];
    barHorizontalDate: any[] = [];
    colorScheme = {
        domain: [
            '#F6CE6B',
            '#70BEBF',
            '#F0A354',
            '#EA6D85',
            '#0AB4FF',
            '#1DB954',
            '#7B68EE',
            '#66A2FD',
            '#00B5B5',
            '#1D619F',
            '#572974',
            '#FB9678',
            '#8BC302',
            '#008ABE',
            '#DA4E58',
            '#F7D517',
            '#08C7E0',
            '#31802F',
            '#BC3837',
            '#F1AF04',
            '#82324D',
            '#003E7B',
            '#959D9F'
        ]
    };
    colorSchemeVerticalBar = {
        domain: [
            '#9BD6FD',
        ]
    };

    constructor(private _dialog: MatDialog,
                private overlay: Overlay,
                private _translate: TranslateService) {
    }

    ngOnInit(): void {
        this.question.answers = this.question.answers.filter(val => val.answer !== '');
        if (this.question.questionType === 'single' || this.question.questionType === 'list') {
            this.preparePieChartDate();
        } else if (this.question.questionType === 'multiple') {
            this.prepareHorizontalBarDate();
        } else if (this.question.questionType === 'range') {
            this.prepareVerticalBarDate()
        }
    }

    preparePieChartDate() {
        this.pieChartDate = this.question.answers.filter(val => typeof val.answer === 'string' || val.answer instanceof String).map((val, index) => {
            return {
                name: val.answer,
                value: val.totalAnswers,
                tooltipText: `${val.totalAnswersPercentage}% (${val.totalAnswers})`,
                color: this.colorScheme.domain[index % this.colorScheme.domain.length]
            }
        })

        if (this.question.hasOther === 1) {
            const indexOtherAnswer = this.pieChartDate.length
            this.pieChartDate.push({
                name: this._translate.instant('Survey.OtherAnswer'),
                value: this.question.totalOtherAnswers,
                tooltipText: `${this.question.totalOtherAnswersPercentage}% (${this.question.totalOtherAnswers})`,
                color: this.colorScheme.domain[indexOtherAnswer % this.colorScheme.domain.length]
            })
        }
    }

    prepareHorizontalBarDate() {
        this.barHorizontalDate = this.question.answers.filter(val => typeof val.answer === 'string' || val.answer instanceof String).map((val, index) => {
            return {
                name: val.answer,
                value: val.totalAnswersPercentage,
                tooltipText: `${val.totalAnswersPercentage}% (${val.totalAnswers})`,
                color: this.colorScheme.domain[index % this.colorScheme.domain.length]
            }
        })

        if (this.question.hasOther === 1) {
            const indexOtherAnswer = this.barHorizontalDate.length
            this.barHorizontalDate.push({
                name: this._translate.instant('Survey.OtherAnswer'),
                value: this.question.totalOtherAnswersPercentage,
                tooltipText: `${this.question.totalOtherAnswersPercentage}% (${this.question.totalOtherAnswers})`,
                color: this.colorScheme.domain[indexOtherAnswer % this.colorScheme.domain.length]
            })
        }
    }

    prepareVerticalBarDate() {
        this.barVerticalDate = this.question.answers.map((val) => {
            return {
                name: val.answer,
                value: val.totalAnswersPercentage,
                tooltipText: `${val.totalAnswersPercentage}% (${val.totalAnswers})`,
            }
        })
    }

    showOtherAnswer() {
        this._dialog.open(SurveyOtherAnswersDialogComponent, {
            minWidth: '350px',
            width: '85vh',
            maxWidth: '860px',
            panelClass: 'filter-popup',
            autoFocus: false,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            disableClose: true,
            data: {
                question: this.question,
                currentQuestionIndex: this.currentQuestionIndex
            }
        });
    }

    formatDataLabel(value: any) {
        return `${value}%`;
    }

    getTooltipPieChart(name: string) {
        return this.pieChartDate.find(val => val.name === name).tooltipText;
    }

    getTooltipHorizontalBar(name: string) {
        return this.barHorizontalDate.find(val => val.name === name).tooltipText;
    }

    getTooltipVerticalBar(name: string) {
        return this.barVerticalDate.find(val => val.name === name).tooltipText;
    }

    displayQuestionType(questionType: string): string {
        switch (questionType) {
            case 'single':
                return this._translate.instant('SurveyCreator.QuestionsTypeSingle');
            case 'multiple':
                return this._translate.instant('SurveyCreator.QuestionsTypeMultiple');
            case 'list':
                return this._translate.instant('SurveyCreator.QuestionsTypeList');
            case 'range':
                return this._translate.instant('SurveyCreator.RatingOnAScale');
            case 'matrix_single':
                return this._translate.instant('SurveyCreator.SingleChoiceGrid');
            case 'matrix_multiple':
                return this._translate.instant('SurveyCreator.MultipleChoiceGrid');
            case 'open':
                return this._translate.instant('SurveyCreator.OpenQuestion');
            case 'spacer_text':
                return this._translate.instant('SurveyCreator.TextBlock');
        }
    }

    showImage(question, url: string) {
        this._dialog.open(ImagePreviewComponent, {
            disableClose: true,
            minWidth: '350px',
            width: '85vh',
            maxWidth: '1122px',
            data: {
                question,
                title: 'Common.PreviewTheGraphicsInTheQuestion',
                imageUrl: url
            }
        });
    }
}
