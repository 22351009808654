import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-team-org-user-stats-dialog',
    templateUrl: './team-org-user-stats-dialog.component.html',
    styleUrls: ['./team-org-user-stats-dialog.component.scss']
})
export class TeamOrgUserStatsDialogComponent implements OnInit {
    surveyName: string;
    surveyId: number;
    userId: number;
    pageNumber: string;
    pageSize: string;
    field: string;
    direction: string;
    filtersString: string;
    attempt: any;
    numberOfAllQuestions: number = 0;
    indexQuestions = [];
    endpoint: any;

    constructor(public dialogRef: MatDialogRef<TeamOrgUserStatsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private api: ApiService,
                public _router: Router,
                private _helper: HelperService,
                private _translate: TranslateService) {
        this.surveyName = data.surveyName;
        this.surveyId = data.surveyId;
        this.userId = data.userId;
        this.pageNumber = data.pageNumber;
        this.pageSize = data.pageSize;
        this.field = data.field;
        this.direction = data.direction;
        this.filtersString = data.filtersString;
        this.choiceEndpoint();
    }

    ngOnInit(): void {
        this.getData();
    }

    choiceEndpoint() {
        if (this._router.url.includes('organization')) {
            this.endpoint = this.api.Survey.getSurveyStatOrgPerUser(
                this._helper.getUser().companyId,
                this.surveyId,
                this.userId,
                this.pageNumber,
                this.pageSize,
                this.field,
                this.direction,
                this.filtersString
            )
        } else {
            this.endpoint = this.api.Survey.getSurveyStatTeamPerUser(
                this._helper.getUser().id,
                this.surveyId,
                this.userId,
                this.pageNumber,
                this.pageSize,
                this.field,
                this.direction,
                this.filtersString
            )
        }
    }

    getData() {
        this.endpoint.subscribe((val: any) => {
            this.attempt = { ...val }

            this.numberOfAllQuestions = this.attempt.surveyQuestions.filter(question => question.questionType !== 'spacer_text').length;
            let index = 0;

            this.attempt.surveyQuestions.forEach(question => {
                this.indexQuestions.push(question.questionType !== 'spacer_text' ? index++ : '')
            })
        })
    }

    getDateByUser(userId: number) {
        this.userId = userId;
        this.choiceEndpoint();
        this.getData()
    }
}
