<div class="choice-category-dialog" *ngIf="editable" cdkDropListGroup>
    <div class="example-container">
        <div class="choice-category-dialog--header">
            {{'ChoiceCategory.SelectedAreas' | translate | uppercase}}
            <i class="dolineo-icon dolineo-icon-close cursor-pointer dolineo-icon-gray"
               matTooltip="{{'Common.Close' | translate}}"
               matTooltipPosition="below"
               matTooltipClass='below-top'></i>
        </div>

        <div class="categories">
            <ng-container *ngFor="let item of choiceCategories;let i=index">
                <div class="inner" cdkDropList
                     id="choiceCategories"
                     cdkDropListOrientation="horizontal"
                     [cdkDropListData]="{item:item,index:i}" (cdkDropListDropped)="drop($event, 'choiceCategories')">
                    <button class="btn-sm rounded-45"
                            mat-flat-button color="primary"
                            (click)="addToArray(item, 'choiceCategories'); $event.stopPropagation()" cdkDrag>
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        <img src="/assets/icons/drag-handler.svg"
                             matTooltip="{{'Common.DragAndDrop' | translate}}"
                             cdkDragHandle
                             (mousedown)="removeTooltipWhenDrag()"
                             class="cursor-pointer"
                             matTooltipPosition="below"
                             matTooltipClass='below-top'
                             alt="drag-handler">
                        {{item.name}}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="example-container">
        <div class="choice-category-dialog--header">{{'Common.SubjectAreas' | translate | uppercase}}</div>

        <div class="categories">
            <ng-container *ngFor="let item of categories;let i=index">
                <div class="inner" cdkDropList
                     id="categories"
                     cdkDropListOrientation="horizontal"
                     [cdkDropListData]="{item:item,index:i}" (cdkDropListDropped)="drop($event, 'categories')">
                    <button class="btn-sm rounded-45 category-to-choice"
                            mat-flat-button color="primary"
                            (click)="addToArray(item, 'categories'); $event.stopPropagation()" cdkDrag>
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        <img src="/assets/icons/drag-handler.svg"
                             matTooltip="{{'Common.DragAndDrop' | translate}}"
                             cdkDragHandle
                             (mousedown)="removeTooltipWhenDrag()"
                             class="cursor-pointer"
                             matTooltipPosition="below"
                             matTooltipClass='below-top'
                             alt="drag-handler">
                        {{item.name}}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="choice-category-dialog preview" *ngIf="editable === false">
    <div class="example-container">
        <i class="dolineo-icon dolineo-icon-close cursor-pointer dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'></i>
        <div cdkDropList
             cdkDropListOrientation="horizontal"
             class="example-list">
            <button mat-flat-button color="primary"
                    (click)="goToPageWithCategory(item)"
                    class="btn-sm rounded-45 cursor-pointer" *ngFor="let item of categories">
                {{item}}
            </button>
        </div>
    </div>
</div>