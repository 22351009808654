import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment.prod';
import { HelperService } from '../../services/helper.service';
import { setLocalFilter, setValueHeader } from '../../helpers';
import { RequestInterface } from '../../intefaces/pagination.interface';

@Component({
    selector: 'app-choice-base-certificate-dialog',
    templateUrl: './choice-base-certificate-dialog.component.html',
    styleUrls: ['./choice-base-certificate-dialog.component.scss']
})
export class ChoiceBaseCertificateDialogComponent implements OnInit, OnDestroy {
    columns: any[] = [];
    items: any[] = [];
    filtersString = '';
    public request: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };
    public searchRequest: any = null;
    selectedItem = null;
    protected basePath = environment.apiPath;
    public isSuperAdmin;
    localStorageFilter: string = this._helper.getUser().id + 'choice-base-certificate'

    constructor(public dialogRef: MatDialogRef<ChoiceBaseCertificateDialogComponent>,
                private _api: ApiService,
                private _translate: TranslateService,
                private _helper: HelperService,
                private _dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.isSuperAdmin = this._helper.isSuperAdmin() || this._helper.isSysAdmin();
        this.createTable();
        if (localStorage.getItem(this.localStorageFilter)) {
            this.columns = [...setValueHeader(this.localStorageFilter, this.columns)];
            this.request.sort = { ...JSON.parse(localStorage.getItem(this.localStorageFilter)).sort }
        }
    }

    createTable() {
        this.columns = [
            {
                index: 'name',
                name: 'CertificateCreator.TemplateName',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '241px',
                    'word-wrap': 'anywhere'
                },
                class: 'ft-auto'
            },
            {
                index: 'author',
                name: 'Common.Author',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '228px',
                    'width': '228px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return (!this.isSuperAdmin && v.companyId === null) ? 'Administrator Dolineo' : `${v.author}`;
                }
            },
            {},
            {
                index: 'notes',
                name: 'Common.Comments',
                searchable: true,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '180px',
                    'width': '180px'
                }
            }
        ];
    }

    ngOnInit(): void {
        this.getData(localStorage.getItem(this.localStorageFilter) ? JSON.parse(localStorage.getItem(this.localStorageFilter)).filter : this.filtersString);
    }

    ngOnDestroy() {
        // document.body.classList.add('nav-collapsed');
    }

    getData(filtersString = ''): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        this.filtersString = filtersString;
        setLocalFilter(this.filtersString, this.columns, this.request, this.localStorageFilter);
        this.request.waitingForData = true;
        this.searchRequest = this._api.Certificate.getCertificateTemplates(
            req.sort.field,
            req.sort.direction,
            filtersString
        ).subscribe(res => {
                this.items = req.pagination.pageNumber === 1 ? res.elements : this.items.concat(res.elements);
                this.request.pagination.totalElementsCount = res.totalElementsCount;
                this.request.pagination.totalPages = res.totalPages;
                this.request.waitingForData = false;
            }
        )
    }

    choiceData() {
        if (!this.selectedItem) {
            return
        }

        this._api.Certificate.getTemplate(this.selectedItem.id).subscribe(value => {
            this._api.Certificate.baseSubject.next({
                ...value,
                name: this._helper.stripFileName(`${this._translate.instant('Common.CopyName')}${value.name}`, 60),
            });
            this.dialogRef.close();
        })
    }

    selectItem(item: any) {
        this.selectedItem = item;
    }
}
