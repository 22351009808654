<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>
<div class="assign-unassign">
    <h1 mat-dialog-title tabIndex="0" *ngIf="dateToExit === null">
        {{ data.headerText | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content [ngStyle]="{'margin-top' : dateToExit !== null ? '75px' : '0'}">
        <ng-container *ngIf="dateToExit === null">
            <form [formGroup]="form">
                <div class="assign-unassign--length">
                    <span>
                        {{ data.pathsContentText | translate}}
                    </span>
                    <div class="number-selected">
                        {{data.pathsSelected.length}}
                    </div>
                    <div class="mat-error" *ngIf="(data.pathsSelected.length === 0)">
                        {{ data.type === 'path' ? ('PathCreator.PathRequired'| translate) : ('TrainingCreator.TrainingRequired'| translate)}}
                    </div>
                </div>
                <div class="assign-unassign--length">
                    <span>
                        {{ data.usersContentText | translate}}
                    </span>
                    <div class="number-selected">
                        {{data.usersSelected.length}}
                    </div>
                    <div class="mat-error" *ngIf="(data.usersSelected.length === 0 && data.positionsSelected.length === 0 && data.groupsSelected.length === 0)">
                        {{ data.type === 'path' ? ('PathCreator.SelectedUserRequired'| translate) : ('TrainingCreator.SelectedUserRequired'| translate)}}
                    </div>
                </div>
                <div class="assign-unassign--length" *ngIf="data.positionsSelected.length > 0">
                    <span>
                        {{ data.positionsContentText | translate}}
                    </span>
                    <div class="number-selected">
                        {{data.positionsSelected.length}}
                    </div>
                </div>
                <div class="assign-unassign--length" *ngIf="data.groupsSelected.length > 0">
                    <span>
                        {{ data.groupsContentText | translate}}
                    </span>
                    <div class="number-selected">
                        {{data.groupsSelected.length}}
                    </div>
                </div>

                <div *ngIf="data.status && (data.type === 'pool' || data.type === 'survey')">
                    <div class="realization-date">
                        <mat-slide-toggle color="primary" formControlName="realizationDate">
                            {{'Common.RealisationDate' | translate}}
                        </mat-slide-toggle>
                        <div class="realization-date__inputs" *ngIf="form.get('realizationDate').value">
                            <mat-form-field>
                                <input [id]="'startDate'"
                                       matInput
                                       ngxDaterangepickerMd
                                       showCancel="true"
                                       showClearButton="true"
                                       placeholder="{{'AdminStats.SelectDateFrom' | translate}}"
                                       readonly="readonly"
                                       formControlName="startDate"
                                       [timePicker]="true"
                                       [minDate]="minStartDate"
                                       [timePicker24Hour]="true"
                                       [locale]="defaultLocale"
                                       [singleDatePicker]="true"
                                       [showDropdowns]="true"
                                       [lockStartDate]="false"
                                       [customRangeDirection]="false"
                                       (datesUpdated)="clear($event, 'startDate')"/>
                            </mat-form-field>
                            <span style="white-space: pre" *ngIf="form.get('realizationDate').value"> - </span>
                            <mat-form-field>
                                <input [id]="'endDate'"
                                       matInput
                                       ngxDaterangepickerMd
                                       showCancel="true"
                                       showClearButton="true"
                                       placeholder="{{'AdminStats.SelectDateTo' | translate}}"
                                       readonly="readonly"
                                       formControlName="endDate"
                                       [timePicker]="true"
                                       [minDate]="minEndDate"
                                       [timePicker24Hour]="true"
                                       [locale]="defaultLocale"
                                       [singleDatePicker]="true"
                                       [showDropdowns]="true"
                                       [lockStartDate]="false"
                                       [customRangeDirection]="false"
                                       (datesUpdated)="clear($event, 'endDate')"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-error *ngIf="isError">{{'AssignDialog.OneOfTheTwoDatesIsMissing'| translate}}</mat-error>
                    <div>
                        <mat-slide-toggle color="primary" formControlName="isMandatory">
                            {{(data.type === 'pool' ? 'AssignDialog.MandatoryTest' : 'AssignDialog.MandatorySurvey') | translate}}
                        </mat-slide-toggle>
                    </div>
                </div>
            </form>
        </ng-container>

        <ng-container *ngIf="dateToExit !== null">
            <div style="padding-top: 30px; display: flex; justify-content: center; align-items: center">
                <img src="assets/icons/checked-green.svg" alt="checked-green" style="margin-right: 8px;">
                <span *ngIf="data.status">{{(data.type === 'pool' ? 'Pools.AssignedToTheTest' : data.type === 'survey' ? 'Surveys.AssignedToTheSurvey' : data.type === 'training' ? 'Training.AssignedToTrainings' : 'Path.AssignedToPaths') | translate}}</span>
                <span *ngIf="data.status === false">{{'Common.UnsubscribedFrom' | translate}} {{(data.type === 'pool' ? 'Pools.UnsubscribedFromTheTest' : data.type === 'survey' ? 'Surveys.UnsubscribedFromTheSurvey' : data.type === 'training' ? 'Training.OutOfTheTraining' : 'Path.OutOfThePath') | translate}}</span>
            </div>
        </ng-container>

        <div class="assign-unassign--info">
            <img src="assets/icons/info.svg" alt="checked-green" style="margin-right: 2px;">
            <span *ngIf="data.status">{{data.type === 'training' ? ('Training.UsersWithAccessToSelectedTrainingCoursesWillBeOmitted'| translate) : ('Paths.UsersWithAccessToSelectedPathCoursesWillBeOmitted'| translate)}}</span>
            <span *ngIf="!data.status">{{data.type === 'training' ? ('Training.UsersWithNotAccessToSelectedTrainingCoursesWillBeOmitted'| translate) : ('Paths.UsersWithNotAccessToSelectedPathCoursesWillBeOmitted'| translate)}}</span>

        </div>

    </div>

    <div mat-dialog-actions class="dialog-footer">
        <ng-container *ngIf="dateToExit === null">
            <button mat-flat-button (click)="dialogRef.close()" color="primary"
                    class="cancel-button">{{'Common.Cancel' | translate}}</button>
            <button (click)="assign()"
                    [disabled] = "expiryDateLtVisibleFromDate || (data.usersSelected.length === 0 && data.positionsSelected.length === 0 && data.groupsSelected.length === 0) || data.pathsSelected.length === 0"
                    mat-button color="primary"
                    class="dolineo-button blue-button">{{(data.status ? 'Common.Shared' : 'Paths.RemoveAccess') | translate}}</button>
        </ng-container>
        <div *ngIf="dateToExit !== null" style="display: flex; justify-content: space-between; width: 100%">
            <button mat-flat-button class="dolineo-button white-button"
                    (click)="closeMobileDialog(false)">
                {{'AssignDialog.ContinueToSignUp' | translate}}
            </button>

            <button mat-flat-button class="dolineo-button"
                    (click)="closeMobileDialog(true)"
                    color="primary">
                {{'AssignDialog.ViewAssigned' | translate}}
            </button>
        </div>
    </div>
</div>
