<h1 mat-dialog-title tabIndex="0">
    {{data.title | translate}}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content>

    <p [innerHTML]="data.content | translate"></p>
    <p *ngIf="!data.isTraining" [innerHTML]="data.extraContent | translate"></p>
    <div *ngIf="data.isTraining">
        <span style="color: #8E94A0; padding-right: 7px;" [innerHTML]="data.extraContent | translate"></span>
        <span style="font-weight: 500;">{{ data.trainingName }}</span>
    </div>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close(false)" mat-button
            class="cancel-button">{{(data.cancelText || 'Common.Cancel') | translate}}</button>
    <button mat-button color="primary" (click)="dialogRef.close(true)"
            class="dolineo-button blue-button">{{(data.okText || 'Ok') | translate}}</button>
</div>