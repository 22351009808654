import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], phrase: string, propName: string): any {
        return items.filter(item => (!phrase || phrase.length === 0) ||
            (item[propName] && item[propName].startsWith(phrase))
        );
    }
}