<div class="report-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{ 'Report.DialogTitle' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="report-dialog--content">
            <app-report [isDialog]="true" (hasPending)="onHasPending($event)"></app-report>
        </div>

        <div class="report-dialog--info orange" *ngIf="hasPending === false">
            <div><span class="icon"><img src="assets/icons/info.svg" alt="info"></span></div>
            <div>{{ 'Report.FullReportInfo' | translate }}</div>
        </div>

        <div class="report-dialog--info orange" *ngIf="hasPending === true">
            <div><span class="icon"><img src="assets/icons/info.svg" alt="info"></span></div>
            <div>{{ 'Report.InProgressInfo' | translate }}</div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="reports()" mat-button class="cancel-button">{{'Report.GoToReports' | translate}}</button>
        <button mat-button color="primary" class="dolineo-button blue-button pull-right"
                [mat-dialog-close]="true">{{ 'Common.Close' | translate}}</button>
    </div>
</div>
