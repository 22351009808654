import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class BackButtonService {

    private previousUrl: string = '';
    private currentUrl: string = '';
    private routeHistory: string[];

    public reload: boolean = false;

    constructor(protected router: Router) {
        this.routeHistory = [];
        router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.setURLs(event);
            });
    }

    private setURLs(event: NavigationEnd): void {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
        if (this.getPreviousUrl() === this.currentUrl) {
            this.routeHistory.pop();
        } else {
            this.routeHistory.push(this.previousUrl);
        }
    }

    getPreviousUrl(): string {
        return this.routeHistory.length > 0 ? this.routeHistory[this.routeHistory.length - 1] : '';
    }

    getCurrentUrl(): string {
        return this.currentUrl;
    }

    getRouteHistory(): string[] {
        return this.routeHistory;
    }

    goBack() {
        if (this.reload === true) {
            document.location.reload();
        } else if (this.getPreviousUrl() !== '' && this.getCurrentUrl() !== this.getPreviousUrl()) {
            this.router.navigateByUrl(this.getPreviousUrl());
        }
    }

    hasHistory() {
        return this.reload === true || (this.getPreviousUrl() !== '' && this.getCurrentUrl() !== this.getPreviousUrl());
    }

}
