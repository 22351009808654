import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDialogData } from '../../intefaces/interfaces';

@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: IDialogData,
                public dialogRef: MatDialogRef<InfoDialogComponent>) {
    }
}