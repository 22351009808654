<div class="assign-dialog">
    <h1 mat-dialog-title tabIndex="0">
        <span>
            {{ translations.title | translate }}
        </span>

        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div class="panel-header-info">
        <span class="panel-header-info__title">{{data.type == 'path' ? ('PathCreator.SelectedPaths' | translate) : ('TrainingCreator.SelectedTrainings' | translate ) }}: </span>
        <span>
                {{(data.names.length > 120) ? (data.names | slice:0:120) + '...' : (data.names)}}
            </span>
        <div class="number-selected" *ngIf="data.names.length > 120">
            {{data.ids.length}}
        </div>
        <button *ngIf="data.type == 'path'" (click)="changeSelectedPaths()"
                mat-button color="primary"
                class="btn-sm btn-dolineo-icon">
            <i class="dolineo-icon dolineo-icon-pencil2 dolineo-icon-blue"></i>
            <span class="sm">
                        {{'PathCreator.ChangePathSelection' |translate}}
                    </span>
        </button>
        <button *ngIf="data.type != 'path'" (click)="changeSelectedTrainings()"
                mat-button color="primary"
                class="btn-sm btn-dolineo-icon">
            <i class="dolineo-icon dolineo-icon-pencil2 dolineo-icon-blue"></i>
            <span class="sm">
                        {{'TrainingCreator.ChangeTrainingSelection' |translate}}
                    </span>
        </button>
    </div>

    <div mat-dialog-content [ngClass]="{'gray-background': !hasPermissions && _helper.getUser().isSupervisor}">
        <mat-accordion *ngIf="hasPermissions">
            <mat-expansion-panel [expanded]="step === 0" hideToggle *ngIf="hasUsers">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(0, 'users'); $event.stopPropagation()">
                        <div class="panel-header--left">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 0? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 0}">
                            {{'AssignDialog.Users' | translate | uppercase}}
                        </div>

                        <div class="panel-header--right" *ngIf="step !== 0">
                            <span style="font-weight: 500">{{'PathCreator.SelectedUsers' | translate }}</span>
                            <div class="number-selected">
                                {{assignedUsers.length}}
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids gray-background">
                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'PathCreator.UnselectedUsers' | translate}}</span>
                        </div>
                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="usersColumns"
                                [items]="unassignedUsers"
                                [refreshSelectedItemsArray]="usersSelectedToAdd.length === 0"
                                (itemCheckboxSelected)="selectedAddItems($event,'users')">
                        </app-table-with-data>
                    </div>

                    <div class="data-grids--buttons">
                        <button mat-button color="primary"
                                [disabled]="usersSelectedToAdd.length === 0"
                                (click)="selectItems('users')"
                                class="dolineo-button blue-button">
                            {{'Common.Add' | translate}}
                            <img src="/assets/icons/arrow_right.svg" alt="arrow_right"/>
                        </button>

                        <button mat-button color="primary"
                                [disabled]="usersSelectedToRemove.length === 0"
                                (click)="deselectItems('users')"
                                class="dolineo-button blue-button">
                            <img src="/assets/icons/arrow_left.svg" alt="arrow_left"/>
                            {{'Common.Delete' | translate}}
                        </button>
                    </div>

                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <div style="max-width: 250px;align-items: baseline;">
                                <span style="min-width: 75px;">{{'PathCreator.SelectedUsers' | translate }}</span>
                                <div class="number-selected">
                                    {{assignedUsers.length}}
                                </div>
                            </div>
                        </div>

                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="usersColumnsAvailability"
                                [items]="assignedUsers"
                                [refreshSelectedItemsArray]="usersSelectedToRemove.length === 0"
                                (itemCheckboxSelected)="selectedRemoveItems($event, 'users')">
                        </app-table-with-data>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 1" hideToggle
                                 *ngIf="hasPositions">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(1, 'positions'); $event.stopPropagation()">
                        <div class="panel-header--left">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 1? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 1}">
                            {{'AssignDialog.Positions' | translate | uppercase}}
                        </div>

                        <div class="panel-header--right" *ngIf="step !== 1">
                            <span style="font-weight: 500">{{'PathCreator.SelectedPositions' | translate }}</span>
                            <div class="number-selected">
                                {{assignedPositions.length}}
                            </div>
                            <span class="cut-text">
                                {{assignedPositions | showAtMost: 'name'}}
                            </span>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids gray-background">
                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'PathCreator.UnselectedPositions' | translate}}</span>
                        </div>
                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="positionsColumns"
                                [items]="unassignedPositions"
                                [refreshSelectedItemsArray]="positionsSelectedToAdd.length === 0"
                                (itemCheckboxSelected)="selectedAddItems($event, 'positions')">
                        </app-table-with-data>
                    </div>

                    <div class="data-grids--buttons">
                        <button mat-button color="primary"
                                [disabled]="positionsSelectedToAdd.length === 0"
                                (click)="selectItems('positions')"
                                class="dolineo-button blue-button">
                            {{'Common.Add' | translate}}
                            <img src="/assets/icons/arrow_right.svg" alt="arrow_right"/>
                        </button>

                        <button mat-button color="primary"
                                [disabled]="positionsSelectedToRemove.length === 0"
                                (click)="deselectItems('positions')"
                                class="dolineo-button blue-button">
                            <img src="/assets/icons/arrow_left.svg" alt="arrow_left"/>
                            {{'Common.Delete' | translate}}
                        </button>
                    </div>

                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <div style="max-width: 250px;align-items: baseline; ">
                                <span style="min-width: 130px;">{{'PathCreator.SelectedPositions' | translate }}</span>
                                <div class="number-selected">
                                    {{assignedPositions.length}}
                                </div>
                                <span class="cut-text">{{assignedPositions | showAtMost: 'name'}}</span>
                            </div>
                        </div>
                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="positionsColumnsAvailability"
                                [items]="assignedPositions"
                                [refreshSelectedItemsArray]="positionsSelectedToRemove.length === 0"
                                (itemCheckboxSelected)="selectedRemoveItems($event, 'positions')">
                        </app-table-with-data>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 2" hideToggle
                                 *ngIf="hasGroups">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(2, 'groups'); $event.stopPropagation()">
                        <div class="panel-header--left">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 2? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 2}">
                            {{'AssignDialog.Groups' | translate | uppercase}}
                        </div>

                        <div class="panel-header--right" *ngIf="step !== 2">
                            <span style="font-weight: 500">{{'PathCreator.SelectedGroups' | translate }}</span>
                            <div class="number-selected">
                                {{assignedGroups.length}}
                            </div>
                            <span class="cut-text">{{assignedGroups | showAtMost: 'name'}}</span>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids gray-background">
                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'PathCreator.UnselectedGroups' | translate}}</span>
                        </div>
                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="groupsColumns"
                                [items]="unassignedGroups"
                                [refreshSelectedItemsArray]="groupsSelectedToAdd.length === 0"
                                (itemCheckboxSelected)="selectedAddItems($event, 'groups')">
                        </app-table-with-data>
                    </div>

                    <div class="data-grids--buttons">
                        <button mat-button color="primary"
                                [disabled]="groupsSelectedToAdd.length === 0"
                                (click)="selectItems('groups')"
                                class="dolineo-button blue-button">
                            {{'Common.Add' | translate}}
                            <img src="/assets/icons/arrow_right.svg" alt="arrow_right"/>
                        </button>

                        <button mat-button color="primary"
                                [disabled]="groupsSelectedToRemove.length === 0"
                                (click)="deselectItems('groups')"
                                class="dolineo-button blue-button">
                            <img src="/assets/icons/arrow_left.svg" alt="arrow_left"/>
                            {{'Common.Delete' | translate}}
                        </button>
                    </div>

                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <div style="max-width: 250px;align-items: baseline; ">
                                <span style="min-width: 100px;">{{'PathCreator.SelectedGroups' | translate }}</span>
                                <div class="number-selected">
                                    {{assignedGroups.length}}
                                </div>
                                <span class="cut-text">
                                    {{assignedGroups | showAtMost: 'name'}}
                                </span>
                            </div>
                        </div>

                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="groupsColumnsAvailability"
                                [items]="assignedGroups"
                                [refreshSelectedItemsArray]="groupsSelectedToRemove.length === 0"
                                (itemCheckboxSelected)="selectedRemoveItems($event, 'groups')">
                        </app-table-with-data>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="data-grids" *ngIf="!hasPermissions && _helper.getUser().isSupervisor">
            <div class="data-grids--column">
                <div class="data-grids--column--header">
                    <span>{{'PathCreator.UnselectedUsers' | translate}}</span>
                </div>
                <app-table-with-data
                        highlightActiveBox
                        [show]="true"
                        [columns]="usersColumns"
                        [items]="unassignedUsers"
                        [refreshSelectedItemsArray]="usersSelectedToAdd.length === 0"
                        (itemCheckboxSelected)="selectedAddItems($event, 'users')">
                </app-table-with-data>
            </div>

            <div class="data-grids--buttons">
                <button mat-button color="primary"
                        [disabled]="usersSelectedToAdd.length === 0"
                        (click)="selectItems('users')"
                        class="dolineo-button blue-button">
                    {{'Common.Add' | translate}}
                    <img src="/assets/icons/arrow_right.svg" alt="arrow_right"/>
                </button>

                <button mat-button color="primary"
                        [disabled]="usersSelectedToRemove.length === 0"
                        (click)="deselectItems('users')"
                        class="dolineo-button blue-button">
                    <img src="/assets/icons/arrow_left.svg" alt="arrow_left"/>
                    {{'Common.Delete' | translate}}
                </button>
            </div>

            <div class="data-grids--column">
                <div class="data-grids--column--header">
                    <div>
                        <span style="min-width: 75px;">{{'PathCreator.SelectedUsers' | translate }}</span>
                        <div class="number-selected">
                            {{assignedUsers.length}}
                        </div>
                    </div>
                </div>

                <app-table-with-data
                        highlightActiveBox
                        [show]="true"
                        [columns]="usersColumnsAvailability"
                        [items]="assignedUsers"
                        [refreshSelectedItemsArray]="usersSelectedToRemove.length === 0"
                        (itemCheckboxSelected)="selectedRemoveItems($event, 'users')">
                </app-table-with-data>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()"
                mat-button color="primary"
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button (click)="assign()"
                mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Assign' | translate}}</button>
    </div>
</div>

<div class="assign-dialog-mobile">
    <div class="assign-dialog-mobile__header">
        <button (click)="$event.stopPropagation(); dialogRef.close()" mat-flat-button
                class="back-button">
            <i class="material-icons">navigate_before</i>
            {{'Common.ComeBack' | translate}}
        </button>
        <h2>{{ translations.title | translate }}</h2>
    </div>

    <div class="assign-dialog-mobile__row" style="justify-content: space-between">
        <div class="tab-links">
            <a [ngClass]="{'active': activeTab === 'unassign'}"
               (click)="activeTab = 'unassign'">{{'PathCreator.UnselectedUsers' | translate}}</a>
            <a [ngClass]="{'active': activeTab === 'assign'}"
               (click)="activeTab = 'assign'">{{'PathCreator.SelectedUsers' | translate}}</a>
        </div>
        <div *ngIf="activeTab === 'assign'" style="display: flex">
            <span class="number-selected">
                {{userToPreview.length}}
            </span>

            <span (click)="showUsers()" class="dialog-footer--preview"
                  [ngClass]="{'dialog-footer--preview__gray': !userToPreview.length}">
                <img src="/assets/icons/show_user.svg" alt="show_user">
            </span>
        </div>
    </div>

    <div class="assign-dialog-mobile__row">
        <div (click)="setStep(0, 'users')" [ngClass]="{'is-selected': selectedSectionName === 'users'}"
             class="category">
            <div class="name">{{ 'AssignDialog.Users'| translate }}</div>
        </div>
        <div (click)="setStep(1, 'positions')" [ngClass]="{'is-selected': selectedSectionName === 'positions'}"
             class="category" *ngIf="hasPermissions">
            <div class="name">{{ 'AssignDialog.Positions'| translate }}</div>
        </div>
        <div (click)="setStep(2, 'groups')" [ngClass]="{'is-selected': selectedSectionName === 'groups'}"
             class="category" *ngIf="hasPermissions">
            <div class="name">{{ 'AssignDialog.Groups'| translate }}</div>
        </div>
    </div>

    <div mat-dialog-content>
        <ng-container *ngIf="activeTab === 'unassign'">
            <app-table-with-data
                    *ngIf="selectedSectionName === 'users'"
                    [columns]="usersColumns"
                    [items]="unassignedUsers"
                    [refreshSelectedItemsArray]="usersSelectedToAdd.length === 0"
                    (itemCheckboxSelected)="selectedAddItems($event, 'users')">
            </app-table-with-data>

            <app-table-with-data
                    *ngIf="selectedSectionName === 'positions'"
                    [columns]="positionsColumns"
                    [items]="unassignedPositions"
                    [refreshSelectedItemsArray]="positionsSelectedToAdd.length === 0"
                    (itemCheckboxSelected)="selectedAddItems($event, 'positions')">
            </app-table-with-data>

            <app-table-with-data
                    *ngIf="selectedSectionName === 'groups'"
                    [columns]="groupsColumns"
                    [items]="unassignedGroups"
                    [refreshSelectedItemsArray]="groupsSelectedToAdd.length === 0"
                    (itemCheckboxSelected)="selectedAddItems($event, 'groups')">
            </app-table-with-data>
        </ng-container>

        <ng-container *ngIf="activeTab === 'assign'">
            <app-table-with-data
                    *ngIf="selectedSectionName === 'users'"
                    [columns]="usersColumnsAvailability"
                    [items]="assignedUsers"
                    [refreshSelectedItemsArray]="usersSelectedToRemove.length === 0"
                    (itemCheckboxSelected)="selectedRemoveItems($event, 'users')">
            </app-table-with-data>

            <app-table-with-data
                    *ngIf="selectedSectionName === 'positions'"
                    [columns]="positionsColumnsAvailability"
                    [items]="assignedPositions"
                    [refreshSelectedItemsArray]="positionsSelectedToRemove.length === 0"
                    (itemCheckboxSelected)="selectedRemoveItems($event, 'positions')">
            </app-table-with-data>

            <app-table-with-data
                    *ngIf="selectedSectionName === 'groups'"
                    [columns]="groupsColumnsAvailability"
                    [items]="assignedGroups"
                    [refreshSelectedItemsArray]="groupsSelectedToRemove.length === 0"
                    (itemCheckboxSelected)="selectedRemoveItems($event, 'groups')">
            </app-table-with-data>
        </ng-container>
    </div>

    <div class="dialog-footer" mat-dialog-actions>
        <button (click)="dialogRef.close()" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <div>
            <button mat-flat-button class="dolineo-button"
                    *ngIf="activeTab === 'unassign'"
                    (click)="selectItems(selectedSectionName)"
                    [disabled]="this[selectedSectionName+'SelectedToAdd'].length === 0"

                    color="primary">
                {{'Common.Add' | translate}}
            </button>

            <ng-container *ngIf="activeTab === 'assign'">
                <button mat-flat-button class="dolineo-button white-button"
                        [disabled]="this[selectedSectionName+'SelectedToRemove'].length === 0"
                        (click)="selectItems(selectedSectionName, true)">
                    {{'PathCreator.ChangeDeadline' | translate}}
                </button>

                <button mat-flat-button class="dolineo-button"
                        (click)="deselectItems(selectedSectionName)"
                        [disabled]="this[selectedSectionName+'SelectedToRemove'].length === 0"
                        color="primary">
                    {{'Common.Delete' | translate}}
                </button>
            </ng-container>
        </div>
    </div>
</div>
