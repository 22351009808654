import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {DashboardWidgetShortcutAddEditDialogComponent} from '../../dialogs/dashboard-widget-shortcut-add-edit-dialog/dashboard-widget-shortcut-add-edit-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'user-shortcut',
  templateUrl: './user-shortcut.component.html',
  styleUrls: ['./user-shortcut.component.scss']
})

export class UserShortcutComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  url: string = '';
  urlName: string = '';
  waiting = true;
  defaultElmentsColor: string = '40ced9';
  isMobile: boolean;

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _dialog: MatDialog,
              private _toastrService: ToastrService,
              private _translate: TranslateService,
              private _router: Router,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    if(!this.preview){
      this.getWidgetData();
    }else{
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: this.isMobile ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
        url: null !== this.items.url && this.items.url !== undefined ? this.items.url : ''
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getWidgetData(){
    this._api.Dashboard.getUserLink(this.items.widgetId).subscribe((res) => {
        this.url = res.url;
        this.urlName = res.urlName;
        this.waiting = false;
        this.prepareWidgetData()
    });
  }

  goTo(url){
    if(!this.preview && this.url) {
      window.location.href = url;
    }
  }

  addUrl(){
    if(!this.preview){
      this._dialog.open(DashboardWidgetShortcutAddEditDialogComponent, {
        disableClose: true,
        width: '578px',
        data:{
          widgetId: this.items.widgetId,
          url: this.url,
          name: this.urlName,
          addshortcut: true,
        }
      }).afterClosed().subscribe((data) => {
        if (data) {
          this.url = data.url;
          this.urlName = data.name;
          this._toastrService.success(this._translate.instant('DashboardWidgetUserShortcut.UrlAdded'));
        }
      });
    }
  }
}