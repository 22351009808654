import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../services/helper.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FeedbackUserReportInterface } from '../../intefaces/feedback-user-report.interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import Quill from 'quill';
import {AttachmentComponent} from '../../dialogs/attachment/attachment.component';
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog/confirm-dialog.component';
import {ToastrService} from 'ngx-toastr';

const QuillBlock = Quill.import('blots/block');
class QuillBlockDivBlock extends QuillBlock {
    static blockName = 'div';
    static tagName = 'div';
}
Quill.register(QuillBlockDivBlock);

import ImageResize from 'quill-image-resize-module';
import {NavLinkInterface} from '../../intefaces/nav-link.interface';
import {ApproveRejectTaskDialogComponent} from '../../dialogs/approve-reject-task-dialog/approve-reject-task-dialog.component';
import {ChoiceBaseSelectPathTrainingDialogComponent} from '../../dialogs/choice-base-select-path-training-dialog/choice-base-select-path-training-dialog.component';
import {GroupAssignUnassignDialogComponent} from '../../dialogs/group-assign-unassign-dialog/group-assign-unassign-dialog.component';
import {AssignGroupDialogComponent} from '../../dialogs/assign-group-dialog/assign-group-dialog.component';
Quill.register('modules/imageResize', ImageResize);

@Component({
    selector: 'app-feedback-development-tips',
    templateUrl: './feedback-development-tips.component.html',
    styleUrls: ['./feedback-development-tips.component.scss']
})
export class FeedbackDevelopmentTipsComponent implements OnInit {
    public filesPath = environment.filesPath;
    userFullName: string;
    userAvatarUrl: string;
    baseRouter: string;
    userId: number;
    data: FeedbackUserReportInterface;
    feedbackId: number;
    isEdit: boolean;
    errorEditor: boolean;
    description: string;
    private editor: Quill = null;
    devTipsIsVisible: boolean;
    userCanEdit: boolean;
    item: object;
    positions: any[] = [];
    @ViewChild('quillEditor') private quillEditor: ElementRef;
    activeTab = 'user-report';
    public navLinks: NavLinkInterface[] = [];
    showNav: boolean = false;
    assignUsersBlock = false;
    assignUsersToPaths = false;
    assignUsersToTrainings = false;
    userVisiblePathTrainingBlock = false;
    elements: any[] = [];
    userIsSupervisorOrAdmin: boolean = false;

    toolbarOptions = [
        ['bold', 'italic'],
        ['underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],

        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }, 'image']
    ];

    createQuillEditor() {
        const options = {
            modules: {
                toolbar: this.toolbarOptions,
                imageResize: {
                    modules: [ 'Resize', 'DisplaySize' ]
                }
            },
            theme: 'snow',
            scrollingContainer: '.mat-horizontal-content-container',
            clipboard: {
                matchVisual: false
            }
        };

        document.getElementById('quill-editor').innerHTML = this.data.devTips.userIndividualDevTip;

        this.editor = new Quill('#quill-editor', options);
        this.editor.getModule('toolbar').addHandler('image', () => {
            this.setAttachment(null);
        });
    }

    setAttachment(guide: any) {
        let range = null;
        if (this.editor?.getSelection()) {
            range = this.editor.getSelection();
        }
        this._dialog.open(AttachmentComponent, {
            data: {
                image: guide ? guide.image : null,
                maxSize: 5242880,
                maxSizeText: this._translate.instant('Common.TheMaximumFileSizeExceeds').replace('{{size}}', '5 MB')
            },
            disableClose: true,
            width: '690px',
        }).afterClosed().subscribe((data) => {
            if (data && data.updated === true && guide) {
                guide.image = data.image;
                guide.imagePath = data.url;
            }
            if (guide === null && data && data.image && range) {
                this.insertToEditor(data.image, range)
            }
        });
    }

    insertToEditor(file: any, range: any) {
        const base64String = `data:${file.type};base64,${file.base}`

        this.editor.insertEmbed(range.index, 'image', base64String);
        this.editor.setSelection(range.index + 1);
    }

    isMobile: boolean = false;
    constructor(private _api: ApiService,
                private _router: Router,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                private activatedRoute: ActivatedRoute,
                public _helper: HelperService,
                private deviceService: DeviceDetectorService,
                private _toastrService: ToastrService,
                public dialogRef: MatDialogRef<AssignGroupDialogComponent>,) {
        this._helper.createTitle(['Common.Feedback', 'Feedback.CollectFeedback'])
        this.userAvatarUrl = this._helper.getUser().avatarUrl;
        this.isMobile = !this.deviceService.isDesktop();
        this.isEdit = false;
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.pipe(
            tap((params) => {
                this.feedbackId = Number(params.get('feedbackId'));
                this.userId = Number(params.get('userId')) ? Number(params.get('userId')) : this._helper.getUser().id;
            }),
            switchMap(() => this.getEndpoint())
        ).subscribe((response: FeedbackUserReportInterface) => {
            this.data = response;
            this.baseRouter = this.setBaseRouter();
            this.prepareData(response);
        });
        this.assignUsersBlock = this._helper.hasSubscription('elearning') && ((this._helper.hasPermissions(['team:path:assign']) || this._helper.hasPermissions(['team:training:assign'])) || this._helper.isSysAdmin() || this._helper.isAdmin());
        this.assignUsersToPaths = this._helper.hasPermissions(['team:path:assign']) ||  (this._helper.isSysAdmin() || this._helper.isAdmin());
        this.assignUsersToTrainings = this._helper.hasPermissions(['team:training:assign']) ||  (this._helper.isSysAdmin() || this._helper.isAdmin());
        this.userIsSupervisorOrAdmin = (this._helper.getUser().isSupervisor && this.userId !== this._helper.getUser().id) || this._helper.isSysAdmin() || this._helper.isAdmin();

    }

    setBaseRouter(): string {
        if (this._router.url.includes('organization')) {
            return `/app/organization/feedbacks/report/${this.data.questionnaire.id}/results`
        } else if (this._router.url.includes('team')) {
            return `/app/team/feedbacks/report/${this.data.questionnaire.id}/results`
        } else {
            return '/app/feedback'
        }
    }

    prepareData(response: FeedbackUserReportInterface): FeedbackUserReportInterface {
        this.devTipsIsVisible = this._helper.hasPermissions(['organization:manage','team:manage']) ||
        this._helper.hasPermissions(['user:feedback:dashboard']) && response.devTips.userIndividualDevTipIsVisible ? true : false;
        this.userCanEdit = this._helper.hasPermissions(['organization:manage','team:manage']);
        if(response.devTips.userIndividualDevTipIsVisible && response.devTips.userIndividualDevTip !== '' && response.userShareResultsAfterCampaign){
            this.showNav = true;
        }
        this.userVisiblePathTrainingBlock = this._helper.hasSubscription('elearning');
        this.getDataTile();
        this.userFullName = response.user.name;
        return response
    }

    getEndpoint(): Observable<any> {
        if (this._router.url.includes('organization')) {
            return this._api.Feedback.getUserReportOrganization(this._helper.getUser().companyId, this.feedbackId, this.userId)
        } else if (this._router.url.includes('team')) {
            return this._api.Feedback.getUserReportTeam(this._helper.getUser().id, this.feedbackId, this.userId)
        } else {
            return this._api.Feedback.getUserReport(this.feedbackId)
        }
    }

    editIndividualTip(){
        this.isEdit = true;
        this.createQuillEditor();
    }

    deleteIndividualTip(){
        this._dialog.open(ConfirmDialogComponent, {
            width: '486px',
            disableClose: true,
            data: {
                title: 'QuestionnaireCreator.RemoveIndividualDevTipsTitle',
                content: 'QuestionnaireCreator.RemoveIndividualDevTipsContent',
                extraContent: 'Common.TheActionIsPermanentAndIrreversible',
                okText: 'Common.IConfirm'
            }
        }).afterClosed().subscribe((value) => {
            if (value) {
                this._api.Feedback.removeUserIndividualDevTips(
                    this._helper.getUser().companyId,
                    this.feedbackId,
                    this.userId,
                ).subscribe(() => {
                    this._toastrService.success(this._translate.instant('QuestionnaireCreator.RemovedIndividualDevTips'));
                    this.data.devTips.userIndividualDevTip = '';
                })
            }
        })
    }

    checkContent(text: string): boolean {
        return text.replace(/<\/?[^>]+(>|$)/g, '').replace(/\s/g, '') === ''
    }

    checkValue(event: any){
        if(event === 1){
            this.data.devTips.userIndividualDevTipIsVisible = true;
        }else{
            this.data.devTips.userIndividualDevTipIsVisible = false;
            this.data.devTips.requireConfirmationOfDevTips = false;
        }
    }

    saveIndividualDevTip(){
        const contentEditor = this.quillEditor.nativeElement.firstChild.innerHTML.toString().replaceAll('ql-cursor', '');
        this.errorEditor = this.checkContent(contentEditor);
        if (this.errorEditor) {
            return;
        }

        this.data.devTips.userIndividualDevTip = contentEditor;
        this._api.Feedback.updateUserIndividualDevTips(
            this._helper.getUser().companyId,
            this.feedbackId,
            this.userId,
            this.data.questionnaire.id,
            {
                content: contentEditor,
                isVisible: this.data.devTips.userIndividualDevTipIsVisible,
                requireConfirmation: this.data.devTips.requireConfirmationOfDevTips,
                status: this.data.devTips.status === 'confirmed' ? this.data.devTips.status : null
            }
        ).subscribe((res) => {
            this.data.devTips.status = null;
            this.data.devTips.logs = res.logs;
        });
        this.closeEditor();

    }

    cancelEdit(){
        this.closeEditor();
    }

    closeEditor(){
        document.getElementById('quill-editor').innerHTML = '';
        document.getElementById('quill-editor').removeAttribute('class');
        document.querySelector('.ql-toolbar').remove();
        this.errorEditor = null;
        this.isEdit = false;
    }

    confirmDevTips(){
        this._dialog.open(ConfirmDialogComponent, {
            width: '608px',
            disableClose: true,
            data: {
                title: 'QuestionnaireCreator.RequireConfirmationOfDevTipsAccept',
                content: 'QuestionnaireCreator.RequireConfirmationOfDevTipsAcceptInfo',
                okText: 'Common.IConfirm',
                cancelText: 'Common.Cancel',
            }
        }).afterClosed().subscribe(
            (data) => {
                if (data) {
                    this._api.Feedback.confirmDevTips(
                        this._helper.getUser().companyId,
                        this.feedbackId,
                        this.userId,
                        this.data.questionnaire.id,
                        {
                            confirm: 1,
                            status: 'confirmed',
                        }
                    ).subscribe((res) => {
                        this._toastrService.success(this._translate.instant('QuestionnaireCreator.AcceptedIndividualDevTips'));
                        this.data.devTips.isConfirmed = true;
                        this.data.devTips.status = 'confirmed';
                        this.data.devTips.confirmedDate = res.confirmedDate;
                        this.data.devTips.logs = res.logs;
                    });
                }
            }
        );
    }

    rejectDevTips(){
        this._dialog.open(ApproveRejectTaskDialogComponent, {
            width: '559px',
            disableClose: true,
            data: {
                title: 'QuestionnaireCreator.RequireConfirmationOfDevTipsReject',
                content: 'QuestionnaireCreator.RequireConfirmationOfDevTipsRejectInfo',
                showTextArea: true,
                devTips: true
            }
        }).afterClosed().subscribe(data => {
            if (data) {
                this._api.Feedback.confirmDevTips(
                    this._helper.getUser().companyId,
                    this.feedbackId,
                    this.userId,
                    this.data.questionnaire.id,
                    {
                        confirm: 0,
                        comment: data.comment,
                        status: 'rejected'
                    }
                ).subscribe((res) => {
                    this._toastrService.success(this._translate.instant('QuestionnaireCreator.RejectedIndividualDevTips'));
                    this.data.devTips.status = 'rejected';
                    this.data.devTips.rejectedDate = res.rejectedDate;
                    this.data.devTips.logs = res.logs;
                });
            }
        });
    }

    assignToPaths(){
        this._dialog.open(ChoiceBaseSelectPathTrainingDialogComponent, {
            disableClose: true,
            autoFocus: false,
            data: {
                type: 'path',
                ids: [],
                userName: this.userFullName
            },
            width: '820px',
        }).afterClosed().subscribe((data) => {
            if(data.selectedItems.length > 0){
                this.assign('path', data.selectedItems);
            }
        });
    }

    assignToTrainings(){
        this._api.User.getCreatorTrainings(
            'name',
            'asc',
            'pathCreator=1'
        ).subscribe(res => {
            this._dialog.open(ChoiceBaseSelectPathTrainingDialogComponent, {
                disableClose: true,
                autoFocus: false,
                data: {
                    type: 'training',
                    hasBaseObjects: !!res.elements.length,
                    ids: [],
                    userName: this.userFullName
                },
                width: '820px',
            }).afterClosed().subscribe((data) => {
                if(data.selectedItems.length > 0){
                    this.assign('training',data.selectedItems);
                }
            });
        });
    }

    assign(type, selectedItems) {
        this._dialog.open(GroupAssignUnassignDialogComponent, {
            disableClose: true,
            width: '540px',
            panelClass: 'assign-unassign-popup',
            data: {
                type: type,
                pathsSelected: selectedItems,
                usersSelected: [this.userId],
                headerText: type === 'training' ? 'FeedbackUserReport.AssignToTrainingsDialog' : 'FeedbackUserReport.AssignToPathsDialog',
                contentText: '',
                pathsContentText: type === 'training' ? 'TrainingCreator.SelectedTrainings' : 'PathCreator.SelectedPaths',
                usersContentText: 'AddRemoveVisibility.SelectedUsers',
                positionsContentText: 'AddRemoveVisibility.SelectedPositions',
                groupsContentText: 'AddRemoveVisibility.SelectedGroups',
                status: true,
                id: selectedItems,
                positionsSelected: [],
                groupsSelected: [],
                selectedTable: [],
                devTipsAssign: true,
                questionnaireId: this.data.questionnaire.id
            }
        }).afterClosed().subscribe(val => {
            if (val) {
                if (this.deviceService.isDesktop()) {
                    this._toastrService.success(this._translate.instant(type === 'training' ? 'Training.AssignedToTrainings' : 'Path.AssignedToPaths'));
                }
                this.getDataTile();
                this.dialogRef.close();
            }
        })
    }

    getDataTile() {
        this._api.User.myPathsTrainings(this.userId, 'isDevTips=' + this.data.questionnaire.id,'expiryDate').subscribe(res => {

            const itemsWithCompleteDate = [];
            const otherItems = [];
            res.forEach(elem => {
               if(elem.maxCompleteDate && !elem.finishedDate){
                   itemsWithCompleteDate.push(elem);
               } else{
                   otherItems.push(elem);
               }
            });

            itemsWithCompleteDate.sort(function(a,b){
                // @ts-ignore
                return new Date(a.maxCompleteDate) - new Date(b.maxCompleteDate);
            });
            otherItems.sort(function(a,b){
                // @ts-ignore
                return new Date(b.publicationDate) - new Date(a.publicationDate);
            });

            this.elements = itemsWithCompleteDate.concat(otherItems);

        })
    }

    onFavouritePath(path): void {
        path.waitForLike = true;
        if (path.isFavourite) {
            this._api.User.unFavouritePath(this._helper.getUser().id, path.idPath).subscribe(() => {
                path.isFavourite = false;
                path.waitForLike = false;
                this._toastrService.success(this._translate.instant('Favourite.PathRemovedFromFavourites'));
            }, () => {
                path.waitForLike = false;
            });
        } else {
            this._api.User.favouritePath(this._helper.getUser().id, path.idPath).subscribe(() => {
                path.isFavourite = true;
                path.waitForLike = false;
                this._toastrService.success(this._translate.instant('Favourite.PathAddedToFavourites'));
            }, () => {
                path.waitForLike = false;
            });
        }
    }
}
