import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TeamService } from './services/team.service';
import { ApiService } from './api.service';
import { UserService } from './services/user.service';
import { CompanyService } from './services/company.service';
import { OrganizationService } from './services/organization.service';
import { ManagerService } from './services/manager.service';
import { LibraryService } from './services/library.service';
import { AdminService } from './services/admin.service';
import { SuperAdminService } from './services/super-admin.service';
import { CategoryService } from './services/category.service';
import {CertificateService} from './services/certificate.service';
import { FeedbackService } from './services/feedback.service';
import {SettingsService} from './services/settings.service';
import {CustomFieldsService} from './services/custom-fields.service';
import {DashboardService} from './services/dashboard.service';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        TeamService,
        UserService,
        CompanyService,
        OrganizationService,
        ManagerService,
        LibraryService,
        AdminService,
        SuperAdminService,
        ApiService,
        CategoryService,
        CertificateService,
        FeedbackService,
        SettingsService,
        CustomFieldsService,
        DashboardService
    ]
})
export class ApiModule {
    //
}
