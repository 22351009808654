import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'employee-feedback',
  templateUrl: './employee-feedback.component.html',
  styleUrls: ['./employee-feedback.component.scss']
})

export class EmployeeFeedbackComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  data: any;
  waiting = true;
  defaultElmentsColor: string = 'FF9635';

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _translate: TranslateService) {}

  ngOnInit() {
    if(!this.preview){
      this.prepareFeedbackData();
    }else{
      this.prepareWidgetData();
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
        data: this.preview ? this.getExampleData() : this.data
      };
    }
  }

  getExampleData(){

    return [
    {
      questionnaireName: 'Feedback kwartalny firmowy IV',
      publicationStartDate: '01.09.2024',
      daysLeft: 14,
      invitations: 8,
      assessments: 6,
      collectFeedback: 15,
      selfAssessments: 3,
      usersCount: 5,
      usersWaitingForFeedback: this.items.size === 'b' ? [
            {
              'userFullName': this._translate.instant('DashboardWidgetExample.UserName3'),
              'avatarUrl': null
            },
            {
              'userFullName': this._translate.instant('DashboardWidgetExample.UserName2'),
              'avatarUrl': null
            },
            {
              'userFullName': this._translate.instant('DashboardWidgetExample.UserName1'),
              'avatarUrl': null
            },
            {
              'userFullName': this._translate.instant('DashboardWidgetExample.UserName4'),
              'avatarUrl': null
            },
            {
              'userFullName': this._translate.instant('DashboardWidgetExample.UserName5'),
              'avatarUrl': null
            },
          ] :
          [
            {
              'userFullName': this._translate.instant('DashboardWidgetExample.UserName3'),
              'avatarUrl': null
            },
            {
              'userFullName': this._translate.instant('DashboardWidgetExample.UserName2'),
              'avatarUrl': null
            }
          ]
    },
      {
        questionnaireName: 'Feedback kwartalny firmowy IV',
        publicationStartDate: '01.09.2024',
        daysLeft: 14,
        invitations: 8,
        assessments: 6,
        collectFeedback: 15,
        selfAssessments: 3,
        usersCount: 5,
        usersWaitingForFeedback: this.items.size === 'b' ? [
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName3'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName2'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName1'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName4'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName5'),
                'avatarUrl': null
              },
            ] :
            [
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName3'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName2'),
                'avatarUrl': null
              }
            ]
      },
      {
        questionnaireName: 'Feedback kwartalny firmowy IV',
        publicationStartDate: '01.09.2024',
        daysLeft: 14,
        invitations: 8,
        assessments: 6,
        collectFeedback: 15,
        selfAssessments: 3,
        usersCount: 5,
        usersWaitingForFeedback: this.items.size === 'b' ? [
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName3'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName2'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName1'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName4'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName5'),
                'avatarUrl': null
              },
            ] :
            [
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName3'),
                'avatarUrl': null
              },
              {
                'userFullName': this._translate.instant('DashboardWidgetExample.UserName2'),
                'avatarUrl': null
              }
            ]
      },
    ];
  }

  prepareData(users){
    if(users !== null && this.items.size === 'a' && users.length > 3) {
      if(null !== users[1]){
        return [
          users[0],
          users[1]
        ];
      }else{
        return [users[0]]
      }
    }
    return users;
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  prepareFeedbackData(){
    this._api.Dashboard.getEmployeeFeedbackData().subscribe((res) => {
      if(res){
        this.data = res;
        this.data.usersWaitingForFeedback = this.prepareData(this.data.usersWaitingForFeedback);
      }
        this.prepareWidgetData();
        this.waiting = false;
    });
  }
}