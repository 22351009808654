import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { ImagePreviewComponent } from '../../dialogs/image-preview/image-preview.component';

@Component({
    selector: 'app-survey-question-summary',
    templateUrl: './survey-question-summary.component.html',
    styleUrls: ['./survey-question-summary.component.scss']
})
export class SurveyQuestionSummaryComponent implements OnInit {
    @Input() public answersIds = null;
    @Input() public question = null;
    @Input() public currentQuestionIndex = 0;
    @Input() public numberOfAllQuestions = 0;

    public apiUrl = environment.filesPath;

    constructor(private _dialog: MatDialog) {
    }

    ngOnInit(): void {
        //
    }

    showImage(question, url: string) {
        this._dialog.open(ImagePreviewComponent, {
            disableClose: true,
            minWidth: '350px',
            width: '85vh',
            maxWidth: '1122px',
            data: {
                question,
                title: 'Common.PreviewTheGraphicsInTheQuestion',
                imageUrl: url
            }
        });
    }
}
