import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeSpaces'
})
export class RemoveSpacesPipe implements PipeTransform {

    transform(value: string, args?: any): string {
        if(null === value || typeof value === 'undefined')
            return '';
        return String(value).replace(/ /g, '');
    }

}
