import {Directive, ElementRef, HostListener, AfterViewInit, Input} from '@angular/core';
import * as Mark from 'mark.js';

@Directive({
    selector: '[yellowSearchText]'
})
export class YellowSearchTextDirective {
    private element: HTMLElement;
    private options: any = {
        className: 'yellow-search-text'
    };
    @Input() keyword: string = '';
    constructor(private el: ElementRef) {
        this.element = this.el.nativeElement as HTMLElement;
    }


    ngAfterViewInit() {
        var instance = new Mark(this.element);
        instance.mark(this.keyword, this.options)
        // this.element.innerHTML = 'asdasd';
    }
}
