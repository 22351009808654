<div class="choice-method-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{ title | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="choice-method-dialog__content">
            <div class="choice-method-dialog__box" (click)="save(false)">
                <img [src]="data.type === 'test' ? 'assets/icons/test-publication.svg' : 'assets/icons/plus-circle.svg'"
                     alt="plus-circle">
                <span class="choice-method-dialog__box__text" [innerHTML]="publicationText | translate"></span>
            </div>

            <div class="choice-method-dialog__box"
                 (click)="!isGlobalAdmin && !data.fromPath && hasPermission? save(true): null"
                 [ngClass]="{'gray-box': isGlobalAdmin || data.fromPath || !hasPermission}">
                <img [src]="data.type === 'test' ? 'assets/icons/test-publication-assign.svg' : 'assets/icons/document.svg'"
                     alt="document">
                <span class="choice-method-dialog__box__text" [innerHTML]="publicationAndAssignText | translate"></span>
            </div>
        </div>

        <div class="box-info box-info--orange"
             *ngIf="data?.type === 'training' && data?.payload?.type === 'videoAudio'">
            <img src="/assets/icons/info.svg" alt="info">
            <span [innerHTML]="'PublishPathTraining.AfterTheTrainingIsPublishedTheBackgroundVideoConversionWillStart' | translate"></span>
        </div>

        <div class="box-info box-info--orange" *ngIf="data.type === 'path' && data.statusPending">
            <img src="/assets/icons/info.svg" alt="info">
            <span [innerHTML]="'PublishPathTraining.AfterThePathIsPublishedTheBackgroundVideoConversionWillStart' | translate"></span>
        </div>

        <div class="box-info box-info--gray">
            <img src="/assets/icons/info.svg" alt="info">
            <span [innerHTML]="info | translate"></span>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" [mat-dialog-close]="false" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
    </div>
</div>
