<div class="next-training">
    <div class="training-name">{{ 'Training.NextTraining' | translate }} {{ name }}</div>
    <div class="button-container">
        <div>
            <button mat-flat-button class="cancel rounded-2" color="primary"
                    (click)="cancel()">{{'Common.Cancel' | translate}}</button>
            <button mat-flat-button class="confirm blue rounded-2" color="primary" (click)="confirm()">
                <span>{{'Training.GetStartedNow' | translate}}</span>
                <div class="loader" [@changeDivSize]=currentState></div>
            </button>
        </div>
    </div>
</div>
