<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>
<div class="filter-status-type-dialog">
    <div mat-dialog-content>
        <span class="filter-status-type-dialog__header">{{'FilterStatus.Filters' |translate | uppercase}}</span>
        <div class="filter-status-type-dialog__container">
            <div class="filter-status-type-dialog__status" *ngIf="data.statuses">
                <span class="filter-status-type-dialog__status__header">{{'Common.Status' |translate}}</span>
                <div *ngFor="let status of data.statuses"
                     (click)="setStatus(status)"
                     class="filter-status-type-dialog__status__content cursor-pointer">
                    <div style="display: flex">
                        <img src="assets/icons/checked.svg" alt="checked" *ngIf="statues.includes(status)">
                    </div>
                    <span>{{(status === 'Common.All' ? status : 'Calendar.' + status) |translate}}</span>
                </div>
            </div>
            <div class="filter-status-type-dialog__type" *ngIf="data.types">
                <span class="filter-status-type-dialog__type__header">{{'Common.Type' |translate}}</span>
                <div *ngFor="let type of data.types"
                     (click)="setType(type)"
                     class="filter-status-type-dialog__type__content cursor-pointer">
                    <div>
                        <img src="assets/icons/checked.svg" alt="checked" *ngIf="types.includes(type)">
                    </div>
                    <span>{{(type === 'Common.All' ? type : 'Calendar.' + type) |translate}}</span>
                </div>
            </div>
        </div>
        <div class="filter-status-type-dialog__footer">
            <button (click)="removeFilters()"
                    mat-button
                    class="cancel-button">
                {{'Common.ClearFilters' | translate}}
            </button>
            <button mat-button
                    color="primary"
                    (click)="accept()"
                    class="dolineo-button blue-button">
                {{'Common.Apply' | translate}}
            </button>
        </div>
    </div>
</div>
