import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { AppLocaleService } from '../../../core/services/locale.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import {
    FilterStatusTypeDialogComponent
} from '../../dialogs/filter-status-type-dialog/filter-status-type-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import {InfoDialogComponent} from '../../dialogs/info-dialog/info-dialog.component';

@Component({
    host: {
        '(document:click)': 'onClick($event)',
    },
    selector: 'app-calendar-view',
    templateUrl: './calendar-view.component.html',
    styleUrls: ['./calendar-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CalendarViewComponent implements OnInit, OnDestroy {

    @ViewChild('datepickerFooter', { static: false }) datepickerFooter: ElementRef;
    @ViewChild('datePickerValue', { static: false }) datePickerValue: ElementRef;
    @ViewChild('basicDatepicker', { static: false }) datepicker: MatDatepicker<any>;

    @Input() set idUser(id: number) {
        this.userId = id ? id : this._helper.getUser().id;
        this.localStorageFilter = this.userId + 'calendar-view';
        if (localStorage.getItem(this.localStorageFilter)) {
            const filters = JSON.parse(localStorage.getItem(this.localStorageFilter));
            this.statusFilter = filters.statusFilter
            this.typeFilter = filters.typeFilter
        }
        this.applyFilters();
    }

    myDate = new Date();
    userId: number;
    typeFilter: string[] = [];
    statusFilter: string[] = [];
    datePickerOriginalValue: string;
    allowDatepickerClose: boolean = true;
    subscriptions: Subscription = new Subscription();

    activities: any[] = [];
    records: any[] = [];
    statuses: string[] = [
        'assign',
        'deadline',
        'finished',
        'received',
        'deadline_passed',
        'start'
    ];

    types: string[] = [];

    disableButtonBack: boolean = false;
    hasKnowledgeBase: boolean = false;
    hasOnboarding: boolean = false;
    hasPreboarding: boolean = false;
    hasGamification: boolean = false;
    hasFeedback: boolean = false;
    hasElearning: boolean = false;
    localStorageFilter: string = '';

    constructor(private _api: ApiService,
                private _dialog: MatDialog,
                private _helper: HelperService,
                private router: Router,
                private _translate: TranslateService,
                private _activatedRoute: ActivatedRoute,
                private _toastrService: ToastrService,
                private _eref: ElementRef,
                private overlay: Overlay,
                private _localeService: AppLocaleService) {
        this.hasKnowledgeBase = this._helper.getUser().subscriptions.indexOf('knowledge_base') > -1;
        this.hasOnboarding = this._helper.getUser().subscriptions.indexOf('onboarding') > -1;
        this.hasPreboarding = this._helper.getUser().subscriptions.indexOf('preboarding') > -1;
        this.hasFeedback = this._helper.getUser().subscriptions.indexOf('feedback') > -1;
        this.hasElearning = this._helper.getUser().subscriptions.indexOf('elearning') > -1;
        this.hasGamification = this._helper.getUser().subscriptions.indexOf('gamification') > -1;

        if (this.hasElearning) {
            this.types.push('path');
            this.types.push('training');
            this.types.push('test');
            this.types.push('survey');
            this.types.push('certificate');
        }

        if (this.hasKnowledgeBase) {
            this.types.push('knowledge-base-entry')
        }

        if (this.hasOnboarding) {
            this.types.push('onboarding')
            this.types.push('onboarding-section')
            this.types.push('task')
        }

        if (this.hasPreboarding) {
            this.types.push('preboarding')
            this.types.push('preboarding-section')
            this.types.push('task')
        }

        if (this.hasGamification) {
            this.types.push('gamification')
            this.types.push('gamification-section')
            this.types.push('task')
        }

        if (this.hasFeedback) {
            this.types.push('feedback');
        }

        this._helper.createTitle(['Common.Calendar'])
        this.subscriptions.add(_localeService.currentLocale().subscribe(q => {
            moment.locale(q);
        }));
        if (this.router.url.includes('profile/team') || this.router.url.includes('profile/organization-users')) {
            this.disableButtonBack = true
        }
    }

    ngOnInit() {
        //
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onClick(event: any) {
        if ($(event.target).hasClass('cdk-overlay-backdrop')) {
            this.allowDatepickerClose = true;
            this.datepicker.close();
        }
    }

    removeFilters() {
        this.statusFilter = [];
        this.typeFilter = [];
        this.applyFilters();
    }

    goBack() {
        window.history.back();
    }

    onDatepickerClose() {
        this.allowDatepickerClose = true;
        this.datepicker.close();
        this.datePickerValue.nativeElement.value = this.datePickerOriginalValue;
    }

    onDatepickerToday() {
        this.datepicker.select(new Date());
        this.onDatepickerAccept();
    }

    onDatepickerAccept() {
        this.allowDatepickerClose = true;
        this.datepicker.close();
        let val = this.datePickerValue.nativeElement.value.length === 9 ? '0' + this.datePickerValue.nativeElement.value : this.datePickerValue.nativeElement.value;
        if (!val) return;
        val = 'cal-date-' + val.replaceAll('.', '-');
        const elements = document.querySelectorAll('.' + val);
        if (elements.length > 0) {
            this.scrollTo(val);
        } else {
            this._dialog.open(InfoDialogComponent, {
                disableClose: true,
                width: '417px',
                data: {
                    title: 'Calendar.emptyDateTitle',
                    content: 'Calendar.emptyDateMessage',
                    bold: false,
                    icon: 'icon',
                    okText: 'Common.Close'
                }
            })
        }
    }

    scrollTo(val: string) {
        if (this.activities.length === 0) return;
        const element = document.getElementsByClassName(val)[0] as HTMLElement;

        if (element) {
            element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }
    }

    onDatepickerOpen() {
        this.allowDatepickerClose = false;
        if (typeof this.datepicker['_originalClose'] === 'undefined') {
            this.datepicker['_originalClose'] = this.datepicker.close;
            this.datepicker.close = () => {
                if (this.allowDatepickerClose) {
                    return this.datepicker['_originalClose'].apply(this.datepicker, arguments);
                }
            }
        }

        this.datePickerOriginalValue = this.datePickerValue.nativeElement.value;
        const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
        matCalendar.appendChild(this.datepickerFooter.nativeElement);
    }

    isPast(i: number): boolean {
        return !(this.isFuture(i) || this.isToday(i));
    }

    isToday(i: number): boolean {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        const d2 = new Date();
        d2.setTime(this.activities[i].date.getTime());
        d2.setHours(0, 0, 0, 0);
        return d.getTime() === d2.getTime();
    };

    isFuture(i: number): boolean {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 1);
        return this.activities[i].date.getTime() >= d.getTime();
    }

    getTimeClass(i: number): string {
        if (this.isPast(i)) {
            return 'past'
        }

        if (this.isToday(i)) {
            return 'today'
        }

        if (this.isFuture(i)) {
            return 'future'
        }
    }

    isDifferentYearOrMonth(i: number): boolean {
        if (i === 0) return true;
        const d1 = this.activities[i - 1].date;
        const d2 = this.activities[i].date;
        return !(d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth());
    }

    isSameDay(i: number): boolean {
        if (i === 0) return false;
        const d = new Date();
        d.setTime(this.activities[i].date.getTime());
        d.setHours(0, 0, 0, 0);
        const d2 = new Date();
        d2.setTime(this.activities[i - 1].date.getTime());
        d2.setHours(0, 0, 0, 0);
        return d.getTime() === d2.getTime();
    }

    getCalendarEntries(params: string) {
        this.subscriptions.add(this._api.User.calendar(this.userId, params).subscribe((activities) => {
            this.activities = [...activities];
            this.records = [];
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            let hasToday = false;

            for (const activity of activities) {
                const act = { ...activity };
                act.date = new Date(act.date);
                if (act.date.getFullYear() === today.getFullYear() &&
                    act.date.getMonth() === today.getMonth() &&
                    act.date.getDate() === today.getDate()) {
                    hasToday = true;
                }
                this.records.push(act);
            }

            if (!hasToday && this.activities.length) {
                this.records.push({
                    date: today,
                    message: '',
                    relatedObjectId: 0,
                    relatedObjectName: '',
                    relatedObjectType: '',
                    type: ''
                });
            }

            this.records.sort((a, b) => {
                return b.date.getTime() - a.date.getTime();
            });

            this.activities = [...this.records];
        }));
    }

    applyFilters() {
        const statusParams = this.statusFilter.length ? 'status[]=' + this.statusFilter.join('&status[]=').replace('Calendar.', '') : '';
        let typeParams = this.typeFilter.length ? 'type[]=' + this.typeFilter.join('&type[]=').replace('Calendar.', '') : '';
        if (statusParams) {
            typeParams = '&' + typeParams;
        }
        const params = '?' + statusParams + typeParams;
        this.getCalendarEntries(params);
        localStorage.setItem(this.localStorageFilter, JSON.stringify({
            statusFilter: this.statusFilter,
            typeFilter: this.typeFilter
        }));
    }

    showActivity(activity) {
        if (activity.relatedObjectType === 'training') {
            this.router.navigateByUrl('/app/training/player/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'test') {
            this.router.navigateByUrl('/app/pool/player/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'path') {
            this.router.navigateByUrl('/app/path/path-view/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'survey') {
            this.router.navigateByUrl('/app/survey/player/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'knowledge-base-entry') {
            this.router.navigateByUrl('/app/library/knowledge-base/article/player/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'onboarding') {
            this._api.Onboarding.getOnboardingPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((onboarding) => {
                this.router.navigateByUrl(`/app/onboarding/onboarding-player/${activity.relatedObjectId}/${onboarding.sections[0].elements[0].id}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'preboarding') {
            this._api.Onboarding.getOnboardingPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((preboarding) => {
                this.router.navigateByUrl(`/app/preboarding/preboarding-player/${activity.relatedObjectId}/${preboarding.sections[0].elements[0].id}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'task') {
            this.router.navigateByUrl(`/app/onboarding/onboarding-player/${activity.onboardingId}/${activity.relatedObjectId}`);
            return;
        }

        if (activity.relatedObjectType === 'onboarding-section') {
            this._api.Onboarding.getOnboardingPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((onboarding) => {
                const elementId = onboarding.sections.find(val => val.id === activity.relatedObjectId).elements[0].id
                this.router.navigateByUrl(`/app/onboarding/onboarding-player/${activity.onboardingId}/${elementId}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'preboarding-section') {
            this._api.Onboarding.getOnboardingPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((preboarding) => {
                const elementId = preboarding.sections.find(val => val.id === activity.relatedObjectId).elements[0].id
                this.router.navigateByUrl(`/app/preboarding/preboarding-player/${activity.onboardingId}/${elementId}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'preboarding-task') {
            this.router.navigateByUrl(`/app/preboarding/preboarding-player/${activity.onboardingId}/${activity.relatedObjectId}`);
            return;
        }

        if (activity.relatedObjectType === 'gamification') {
            this._api.Gamification.getGamificationPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((gamification) => {
                this.router.navigateByUrl(`/app/gamification/gamification-player/${activity.relatedObjectId}/${gamification.sections[0].elements[0].id}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'gamification-task') {
            this.router.navigateByUrl(`/app/gamification/gamification-player/${activity.gamificationId}/${activity.relatedObjectId}`);
            return;
        }

        if (activity.relatedObjectType === 'gamification-section') {
            this._api.Gamification.getGamificationPlayer(this._helper.getUser().id, activity.gamificationId, false).subscribe((gamification) => {
                const elementId = gamification.sections.find(val => val.id === activity.relatedObjectId).elements[0].id
                this.router.navigateByUrl(`/app/gamification/gamification-player/${activity.gamificationId}/${elementId}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'gamification-section-element') {
            this.router.navigateByUrl(`/app/gamification/gamification-player/${activity.gamificationId}/${activity.relatedObjectId}`);
            return;
        }

        if (activity.relatedObjectType === 'certificate') {
            this.router.navigateByUrl('/app/profile/certificates');
            return;
        }

        if (activity.relatedObjectType === 'feedback' || activity.relatedObjectType === 'feedback-questionnaire' || activity.relatedObjectType === 'feedback-questionnaire-self-assessment') {
            this.router.navigateByUrl('/app/feedback');
            return;
        }
    }

    openFilterPopup() {
        this._dialog.open(FilterStatusTypeDialogComponent, {
            panelClass: 'filter-popup',
            autoFocus: false,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            disableClose: true,
            data: {
                types: ['All', ...this.types],
                statuses: ['All', ...this.statuses],
                typesSelected: this.typeFilter.length ? this.typeFilter : ['All'],
                statusesSelected: this.statusFilter.length ? this.statusFilter : ['All']
            }
        }).afterClosed().subscribe((val) => {
            if (val) {
                this.typeFilter = val.type;
                this.statusFilter = val.status
                this.applyFilters();
            }
        });
    }
}
