import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-syrvey-other-answers-dialog',
    templateUrl: './syrvey-other-answers-dialog.component.html',
    styleUrls: ['./syrvey-other-answers-dialog.component.scss']
})
export class SurveyOtherAnswersDialogComponent {
    question: any = null;
    currentQuestionIndex: number = 0;

    constructor(public dialogRef: MatDialogRef<SurveyOtherAnswersDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.question = data.question;
        this.currentQuestionIndex = data.currentQuestionIndex;
    }

    displayQuestionType(questionType: string): string {
        switch (questionType) {
            case 'single':
                return 'SurveyCreator.QuestionsTypeSingle';
            case 'multiple':
                return 'SurveyCreator.QuestionsTypeMultiple';
            case 'open':
                return 'SurveyCreator.OpenQuestion';
        }
    }
}