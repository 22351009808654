import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    ChoiceBasePathTrainingDialogComponent
} from '../choice-base-path-training-dialog/choice-base-path-training-dialog.component';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import {
    ChoiceBaseCertificateDialogComponent
} from '../choice-base-certificate-dialog/choice-base-certificate-dialog.component';
import { ChoiceBasePoolDialogComponent } from '../choice-base-pool-dialog/choice-base-pool-dialog.component';
import { ChoiceBaseSurveyDialogComponent } from '../choice-base-survey-dialog/choice-base-survey-dialog.component';
import { ApiService } from '../../../api/api.service';
import { ChoiceBaseArticleDialogComponent } from '../choice-base-article-dialog/choice-base-article-dialog.component';
import {
    ChoiceBaseOnboardingDialogComponent
} from '../choice-base-onboarding-dialog/choice-base-onboarding-dialog.component';
import {
    ChoiceBaseGamificationDialogComponent
} from '../choice-base-gamification-dialog/choice-base-gamification-dialog.component';
import {
    ChoiceBaseQuestionnaireDialogComponent
} from '../choice-base-questionnaire-dialog/choice-base-questionnaire-dialog.component';
import {ChoiceBaseMultiPathTrainingDialogComponent} from '../choice-base-multi-path-training-dialog/choice-base-path-training-dialog.component';

@Component({
    selector: 'app-choice-method-dialog',
    templateUrl: './choice-method-dialog.component.html',
    styleUrls: ['./choice-method-dialog.component.scss']
})
export class ChoiceMethodDialogComponent implements OnInit {

    public header = '';
    public stepOneText;
    public stepTwoText;
    public hasBaseObjects = false;
    public isGlobalAdmin = false;

    constructor(public dialogRef: MatDialogRef<ChoiceMethodDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _router: Router,
                private api: ApiService,
                private helper: HelperService,
                private _dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.headerText();
        this.stepOneTextMethod();
        this.stepTwoTextMethod();
        this.hasBaseObjects = this.data.hasBaseObjects;
        this.isGlobalAdmin = this.helper.isSuperAdmin() || this.helper.isSysAdmin();
    }

    openChosePopup() {
        if (this.hasBaseObjects === false) {
            return '';
        }
        let dialogType = null;

        switch (this.data.type) {
            case 'training':
                dialogType = ChoiceBasePathTrainingDialogComponent
                break;
            case 'path':
                dialogType = ChoiceBasePathTrainingDialogComponent
                break;
            case 'multi-path':
                dialogType = ChoiceBaseMultiPathTrainingDialogComponent
                break;
            case 'pool':
                dialogType = ChoiceBasePoolDialogComponent
                break;
            case 'survey':
                dialogType = ChoiceBaseSurveyDialogComponent
                break;
            case 'article':
                dialogType = ChoiceBaseArticleDialogComponent
                break;
            case 'certificate-template':
                dialogType = ChoiceBaseCertificateDialogComponent
                break;
            case 'onboarding':
                dialogType = ChoiceBaseOnboardingDialogComponent
                break;
            case 'preboarding':
                dialogType = ChoiceBaseOnboardingDialogComponent
                break;
            case 'questionnaire':
                dialogType = ChoiceBaseQuestionnaireDialogComponent
                break;
            case 'gamification':
                dialogType = ChoiceBaseGamificationDialogComponent
                break;
        }

        this._dialog.open(dialogType,
            {
                disableClose: true,
                autoFocus: false,
                data: {
                    type: this.data.type,
                    articleType: this.data.articleType,
                    ids: this.data.ids,
                    time: null !== this.data.time ? this.data.time : 0
                },
                width: '860px'
            });

        this.dialogRef.close();
    }

    headerText() {
        switch (this.data.type) {
            case 'training':
                this.header = 'ChoiceMethodDialog.ChooseATrainingCreationMethod';
                break;
            case 'path':
                this.header = 'ChoiceMethodDialog.ChooseAPathCreationMethod';
                break;
            default:
                this.header = this.data.header;
                break;
        }
    }

    stepOneTextMethod() {
        switch (this.data.type) {
            case 'training':
                this.stepOneText = 'ChoiceMethodDialog.CreateANewTrainingStepByStep';
                break;
            case 'path':
                this.stepOneText = 'ChoiceMethodDialog.CreateANewPathStepByStep';
                break;
            default:
                this.stepOneText = this.data.stepOneText;
                break;
        }
    }

    stepTwoTextMethod() {
        switch (this.data.type) {
            case 'training':
                this.stepTwoText = 'ChoiceMethodDialog.CreateATrainingBasedOnAnExistingOne';
                break;
            case 'path':
                this.stepTwoText = 'ChoiceMethodDialog.CreateAPathBasedOnAnExistingOne';
                break;
            default:
                this.stepTwoText = this.data.stepTwoText;
                break;
        }
    }

    closeWithEmpty() {
        this.api.Library.baseSubject.next(null)
        this.dialogRef.close({ empty: true });
    }

    close() {
        switch (this.data.type) {
            case 'training':
                if (this.helper.isSuperAdmin() || this.helper.isSysAdmin()) {
                    this._router.navigateByUrl('app/trainings-creator/list');
                } else {
                    this._router.navigateByUrl('app/trainings-creator/lists/self');
                }
                break;
            case 'path':
                if (this.helper.isSuperAdmin() || this.helper.isSysAdmin()) {
                    this._router.navigateByUrl('app/paths-creator/list');
                } else {
                    this._router.navigateByUrl('app/paths-creator/lists/self');
                }
                break;
            case 'pool':
                if (this.helper.isSuperAdmin() || this.helper.isSysAdmin()) {
                    this._router.navigateByUrl('app/pools-creator/list');
                } else {
                    this._router.navigateByUrl('app/pools-creator/lists/self');
                }
                break;
            case 'survey':
                if (this.helper.isSuperAdmin() || this.helper.isSysAdmin()) {
                    this._router.navigateByUrl('app/surveys-creator/list');
                } else {
                    this._router.navigateByUrl('app/surveys-creator/lists/self');
                }
                break;
            case 'article':
                if (this.data.articleType === 'help') {
                    this._router.navigateByUrl('app/help-creator/list');
                } else {
                    this._router.navigateByUrl('app/knowledge-base-creator/list');
                }
                break;
            case 'certificate-template':
                this._router.navigateByUrl('app/certificates-creator/list');
                break;
            case 'onboarding':
                this._router.navigateByUrl('app/onboarding-creator/list');
                break;
            case 'preboarding':
                this._router.navigateByUrl('app/preboarding-creator/list');
                break;
            case 'gamification':
                this._router.navigateByUrl('app/gamification-creator/list');
                break;
            case 'questionnaire':
                this._router.navigateByUrl(this.isGlobalAdmin ? `app/feedback-creator/list` : `app/feedback-creator/lists/campaign/${this.data.campaignId}`);
                break;
        }
        this.dialogRef.close();
    }
}
