<div class="team-org-user-stats">
    <h1 mat-dialog-title tabIndex="0">
        <span>
            {{'TeamOrgUserStat.ViewTheAnswer' | translate}}
            <span style="color: #7D8485">{{ surveyName }}</span>
        </span>
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>
    <div mat-dialog-content *ngIf="attempt">
        <div class="team-org-user-stats__header">
            <div class="team-org-user-stats__header--name">
                <span class="name">{{attempt.username}}</span>

                <div *ngIf="attempt.prevUserId !== attempt.nextUserId">
                    <button mat-flat-button (click)="getDateByUser(attempt.prevUserId)" color="primary"
                            class="dolineo-button blue-button">
                        <img src="assets/icons/arrow_left.svg" alt="arrow_left">
                    </button>
                    <button mat-flat-button (click)="getDateByUser(attempt.nextUserId)" color="primary"
                            class="dolineo-button blue-button">
                        {{ 'Common.Next' | translate}}
                        <img src="assets/icons/arrow_right.svg" alt="arrow_right">
                    </button>
                </div>
            </div>

            <div class="team-org-user-stats__header--dates">
                {{ 'Common.started' | translate }}: {{attempt.startedAt| date: 'dd.MM.YYYY, HH:mm'}}&nbsp;&nbsp;|&nbsp;&nbsp;{{ 'Common.FinishedDate' | translate }}: {{attempt.completedAt| date: 'dd.MM.YYYY, HH:mm'}}
            </div>
        </div>
        <div class="box" *ngFor="let q of attempt.surveyQuestions; let i = index"
             [ngClass]="{'odd': i%2 == 0}">
            <app-survey-question-summary
                    [currentQuestionIndex]="indexQuestions[i]"
                    [numberOfAllQuestions]="numberOfAllQuestions"
                    [answersIds]="attempt.answers[i]"
                    [question]="q">
            </app-survey-question-summary>
            <div class="is-required" *ngIf="q.isRequired">
                <span style="color: #ef5350;"> * </span>
                {{'Common.AnswerRequired' | translate}}
            </div>
        </div>
    </div>

    <div class="dialog-footer">
        <button (click)="dialogRef.close()" mat-flat-button color="primary"
                class="dolineo-button pull-right">{{'Common.Close' | translate}}</button>
    </div>
</div>
