import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../api/api.service';
import {RequestInterface} from '../../intefaces/pagination.interface';
import {HelperService} from '../../services/helper.service';
import {carersText, downloadFile, setLocalFilter, setTableFromLocalStorage, setValueHeader} from '../../helpers';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ReportService} from '../../services/report.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy{

    @Input() isDialog: boolean = false;
    @Output() hasPending = new EventEmitter();

    isLoaded = false;
    showEmptyPage = false;
    columns: any[] = [];
    items: any[] = [];
    filtersString = '';
    public request: RequestInterface = {
        sort: {
            field: 'createdAt',
            direction: 'desc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    defaultColumns: any[] = [];
    searchRequest: any = null;
    localStorageTableName: string = this.helper.getUser().id + '-reports';
    localStorageFilter: string = this.localStorageTableName + '-filter';
    isMobile: boolean;
    refreshInterval = null;

    constructor(
        private api: ApiService,
        private helper: HelperService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private reportService: ReportService
    ) {
    }

    ngOnDestroy() {
        this.clearInterval();
        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }
    }

    ngOnInit(): void {
        if (this.isDialog) {
            this.localStorageTableName = this.helper.getUser().id + '-reports-dialog';
            this.localStorageFilter = this.localStorageTableName + '-filter';
            this.request.pagination.pageSize = 5;
        }

        this.createTable();

        if (localStorage.getItem(this.localStorageTableName)) {
            this.columns = [...setTableFromLocalStorage(this.columns, this.localStorageTableName)];
        }
        if (localStorage.getItem(this.localStorageFilter)) {
            this.columns = [...setValueHeader(this.localStorageFilter, this.columns)];
            this.request.sort = {...JSON.parse(localStorage.getItem(this.localStorageFilter)).sort}
        }

        this.getData(localStorage.getItem(this.localStorageFilter) ? JSON.parse(localStorage.getItem(this.localStorageFilter)).filter : '');
    }

    clearInterval() {
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
    }

    createTable() {
        this.columns = [
            {
                index: 'fileName',
                name: 'Report.Name',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '250px',
                },
                class: 'ft-auto'
            },
            {
                type: 'MENU',
                visible: true,
                items: [
                    {
                        name: 'Common.Download',
                        icon: '<i class="dolineo-icon dolineo-icon-hover dolineo-icon-download"></i>',
                        visible: (v) => {
                            return v.status === 'done';
                        },
                        click: (row) => {
                            this.api.Report.report(row.id).subscribe(report => {
                                this.download(report);
                            })
                        }
                    },
                    {
                        name: 'Common.Delete',
                        icon: '<i class="dolineo-icon dolineo-icon-hover dolineo-icon-trash"></i>',
                        visible: (v) => {
                            return v.status === 'done';
                        },
                        click: (v) => {
                            this.delete(v);
                        }
                    },
                ],
                css: {
                    'width': '80px',
                    'min-width': '80px'
                }
            },
            {
                index: 'status',
                name: 'Common.Status',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                options: [
                    {id: 'pending', value: 'Report.StatusPending'},
                    {id: 'in_progress', value: 'Report.StatusInProgress'},
                    {id: 'done', value: 'Report.StatusDone'},
                    {id: 'error', value: 'Report.StatusError'}
                ],
                class: 'ft-center',
                css: {
                    'min-width': '170px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    if (v.status === 'pending') {
                        return `<span class="badge badge-light"> ${this.translate.instant('Report.StatusPending')}</span>`;
                    } else if (v.status === 'done') {
                        return `<span class="badge badge-success">${this.translate.instant('Report.StatusDone')}</span>`;
                    } else if (v.status === 'error') {
                        return `<span class="badge badge-error">${this.translate.instant('Report.StatusError')}</span>`;
                    } else if (v.status === 'in_progress') {
                        return `<span class="badge badge-light">${this.translate.instant('Report.StatusInProgress')}</span>`;
                    }
                }
            },
            {
                index: 'createdAt',
                name: 'Report.CreatedAt',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE',
                valueRange: null,
                timePicker: false,
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '150px',
                    'width': '150px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.createdAt ? moment(v.createdAt).format('DD.MM.YYYY HH:mm') : '';
                }
            },
            {
                index: 'finishedAt',
                name: 'Report.FinishedAt',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE',
                valueRange: null,
                timePicker: false,
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '150px',
                    'width': '150px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.finishedAt ? moment(v.finishedAt).format('DD.MM.YYYY HH:mm') : '';
                }
            },
            {
                index: 'fileSize',
                name: 'Report.Size',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'min-width': '140px',
                    'width': '140px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.fileSize ? v.fileSize + ' kB' : '-';
                }
            },
            {
                index: 'downloaded',
                name: 'Report.Downloaded',
                searchable: true,
                sortable: true,
                visible: true,
                multiple: false,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                options: [
                    {id: '1', value: 'Common.Yes'},
                    {id: '0', value: 'Common.No'}
                ],
                css: {
                    'min-width': '100px',
                    'width': '100px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.downloaded === 1 ? this.translate.instant('Common.Yes') : this.translate.instant('Common.No');
                }
            },
            {
                index: 'type',
                name: 'Report.Type',
                searchable: true,
                sortable: true,
                visible: true,
                multiple: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                options: [
                    {id: 'xlsx', value: 'Report.TypeXlsx'},
                    {id: 'pdf', value: 'Report.TypePdf'},
                    {id: 'zip', value: 'Report.TypeZip'},
                ],
                css: {
                    'min-width': '100px',
                    'width': '100px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    switch (v.type) {
                        case 'xlsx':
                            return this.translate.instant('Report.TypeXlsx').toUpperCase();
                        case 'pdf':
                            return this.translate.instant('Report.TypePdf').toUpperCase();
                        case 'zip':
                            return this.translate.instant('Report.TypeZip').toUpperCase();
                    }
                }
            },
            {
                index: 'area',
                name: 'Report.Category',
                searchable: true,
                sortable: true,
                visible: true,
                multiple: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                options: [
                    {id: 'user', value: 'Common.Users'},
                    {id: 'path', value: 'Common.Paths'},
                    {id: 'training', value: 'Common.Trainings'},
                    {id: 'pool', value: 'Common.Pools'},
                    {id: 'survey', value: 'Common.Surveys'},
                    {id: 'onboarding', value: 'Common.Onboardings'},
                    {id: 'preboarding', value: 'Common.Preboardings'},
                    {id: 'gamification', value: 'Common.Gamifications'},
                    {id: 'feedback', value: 'Common.Feedback'},
                    {id: 'certificate', value: 'Common.Certificates'},
                    {id: 'activity', value: 'Client.Activities'},
                    {id: 'article', value: 'Common.Articles'},
                    {id: 'administration', value: 'Admin.Header'},

                ],
                css: {
                    'min-width': '200px',
                    'width': '200px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    switch (v.area) {
                        case 'user':
                            return this.translate.instant('Common.Users');
                        case 'path':
                            return this.translate.instant('Common.Paths');
                        case 'training':
                            return this.translate.instant('Common.Trainings');
                        case 'pool':
                            return this.translate.instant('Common.Pools');
                        case 'survey':
                            return this.translate.instant('Common.Surveys');
                        case 'onboarding':
                            return this.translate.instant('Common.Onboardings');
                        case 'preboarding':
                            return this.translate.instant('Common.Preboardings');
                        case 'gamification':
                            return this.translate.instant('Common.Gamifications');
                        case 'feedback':
                            return this.translate.instant('Common.Feedback');
                        case 'certificate':
                            return this.translate.instant('Common.Certificates');
                        case 'activity':
                            return this.translate.instant('Client.Activities');
                        case 'article':
                            return this.translate.instant('Common.Articles');
                        case 'administration':
                            return this.translate.instant('Admin.Header');
                    }
                }
            },
        ];

        this.defaultColumns = [...this.columns];
    }

    getData(filtersString: string = ''): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        this.filtersString = filtersString;
        setLocalFilter(this.filtersString, this.columns, this.request, this.localStorageFilter);
        this.request.waitingForData = true;
        this.searchRequest = this.api.Report.reports(
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            filtersString,
            this.isDialog ? true : false
        ).subscribe(res => {
                this.items = req.pagination.pageNumber === 1 ? res.elements : this.items.concat(res.elements);
                this.reportService.hasPendingReports = res.hasPendingReports;
                if (res.hasPendingReports === true) {
                    if (this.isDialog && !this.refreshInterval) {
                        this.refreshInterval = true;
                        this.hasPending.emit(true);
                        this.refreshInterval = setInterval(()=>{
                            this.getData(this.filtersString);
                        }, 3000);
                    }
                } else {
                    if (this.refreshInterval) {
                        this.hasPending.emit(false);
                        this.clearInterval();
                    }
                }

                this.request.pagination.totalElementsCount = res.totalElementsCount;
                this.request.pagination.totalPages = res.totalPages;

                this.request.waitingForData = false;
                this.isLoaded = true;
                if (!this.isDialog && this.isLoaded && this.filtersString === '' && res.totalElementsCount === 0) {
                    this.showEmptyPage = true;
                }
            }
        )
    }

    removeFilters() {
        this.columns.forEach(c => {
            c.value = null;
            if (c.valueRange) {
                c.valueRange = null;
            }
        });
        this.request.pagination.pageNumber = 1;
        this.getData('');
    }

    saveConfig(columns: any[]) {
        this.columns = [...columns];
        localStorage.removeItem(this.localStorageTableName);
        localStorage.setItem(this.localStorageTableName, JSON.stringify(this.columns));
    }

    menuClosed() {
        this.columns = [...this.columns]
    }

    download(report) {
        this.api.download(environment.apiPath + report.path).subscribe(blob => {
            this.getData();
            downloadFile(blob, report.fileName);
        })
    }

    delete(report) {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            autoFocus: false,
            data: {
                title: 'Report.DeleteTitle',
                content: 'Report.DeleteText',
                okText: 'Common.IConfirm'
            },
            width: '486px'
        }).afterClosed().subscribe(value => {
            if (value) {
                this.api.Report.remove(report.id).subscribe((res) => {
                    this.getData();
                });
            }
        });
    }

}
