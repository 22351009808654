import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { GuidInterface } from '../../intefaces/guide.interface';

@Component({
    selector: 'app-guide-dialog',
    templateUrl: './guide-dialog.component.html',
    styleUrls: ['./guide-dialog.component.scss']
})
export class GuideDialogComponent implements OnInit {

    index: number = 0;
    dataLength: number;

    constructor(public dialogRef: MatDialogRef<GuideDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: GuidInterface[],
                private dialog: MatDialog,
                private _api: ApiService) {
    }

    ngOnInit(): void {
        this.dataLength = this.data.length;
        this.setGuideSeen();
    }

    public nextGuide() {
        this.index++;
        this.setGuideSeen();
    }

    public previewGuide() {
        this.index--;
        this.setGuideSeen();
    }

    setIndex(i: number) {
        this.index = i;
        this.setGuideSeen();
    }

    setGuideSeen() {
        const id = this.data[this.index].seen === 0 ? this.data[this.index].id : null;
        if (id) {
            this._api.Admin.setGuideSeen(id).subscribe(() => {
                this.data[this.index].seen = 1;
            });
        }
    }

    closeDialog() {
        const unreadGuides = this.data.filter(value => value.seen === 0);
        if (unreadGuides.length > 0) {
            const readGuides = this.data.filter(value => value.seen === 1);
            this.dialogRef.close([...unreadGuides, ...readGuides]);
        } else {
            this.dialogRef.close(null);
        }
    }
}
