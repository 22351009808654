<div class="selection-of-numbers">
    <span class="selection-of-numbers__header" (click)="$event.stopPropagation()">{{title | translate}}</span>
    <div class="selection-of-numbers__container" (click)="$event.stopPropagation()">
        <mat-form-field>
            <input [(ngModel)]="numberFrom"
                   matInput
                   digitOnly
                   (click)="$event.stopPropagation();"
                   placeholder="{{'AdminStats.SelectDateFrom' | translate}}">
        </mat-form-field>
        <span class="selection-of-numbers--text">-</span>
        <mat-form-field>
            <input [(ngModel)]="numberTo"
                   matInput
                   digitOnly
                   (click)="$event.stopPropagation();"
                   placeholder="{{'AdminStats.SelectDateTo' | translate}}">
        </mat-form-field>
        <span class="selection-of-numbers--text" *ngIf="hasMinutes">{{'Common.Minutes' | translate}}</span>
    </div>

    <div class="selection-of-numbers__footer">
        <button mat-flat-button class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button mat-flat-button (click)="save()" color="primary"
                class="dolineo-button blue-button">{{'Common.Apply' | translate}}</button>
    </div>
</div>
