import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { KnowledgeCategory } from '../../knowledge-base-creator/interfaces/knowledge-category';
import { ArticleInterface } from '../../knowledge-base/interfaces/article.interface';
import { ArticlesListItemInterface } from '../../shared/intefaces/articles-list-item.interface';

@Injectable({
    providedIn: 'root'
})
export class KnowledgeBaseService {
    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public knowledgeCategoryCheckName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-category/validate-name`, body, { headers });
    }

    public knowledgeCategoryCreate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/admin/knowledge-category`, body, { headers });
    }

    public knowledgeCategoryEdit(body: any, categoryId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-category/${categoryId}`, body, { headers });
    }

    public getCategories(type): Observable<KnowledgeCategory[]> {
        const headers = this.defaultHeaders;
        return this.http.get<KnowledgeCategory[]>(`${this.basePath}/admin/knowledge-category/tree?type=${type}`, { headers });
    }

    public getCategoryById(categoryId: number): Observable<KnowledgeCategory> {
        const headers = this.defaultHeaders;
        return this.http.get<KnowledgeCategory>(`${this.basePath}/admin/knowledge-category/${categoryId}`, { headers });
    }

    public getPatentsCategories(type: string, id: number): Observable<KnowledgeCategory[]> {
        const headers = this.defaultHeaders;
        const param = id ? `&categoryId=${id}` : ''
        return this.http.get<KnowledgeCategory[]>(`${this.basePath}/admin/knowledge-category/parents?type=${type}${param}`, { headers });
    }

    public getFlatParents(type): Observable<KnowledgeCategory[]> {
        const headers = this.defaultHeaders;
        return this.http.get<KnowledgeCategory[]>(`${this.basePath}/admin/knowledge-category/flat?type=${type}`, { headers });
    }

    public reorderCategory(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-category/reorder`, body, { headers });
    }

    public deleteCategory(categoryId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/admin/knowledge-category/${categoryId}`, { headers });
    }

    public getArticles(type: string, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string, excludedIds?: any[]): Observable<any> {
        const headers = this.defaultHeaders;
        let url = `${this.basePath}/admin/knowledge-entry?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;

        if (excludedIds?.length > 0) {
            excludedIds.forEach(excludedId => {
                url += `&eid[]=${excludedId}`;
            });
        }

        return this.http.get<any>(url, { headers });
    }

    public downloadArticlesXLS(params: string): string {
        return `/admin/knowledge-entry/xls?${params}`;
    }

    public getCreatorArticle(params?: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/knowledge-entry?isDraft=0&status[]=active&status[]=inactive${params ? params : ''}`, { headers });
    }

    public articleCheckName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-entry-name-validate`, body, { headers });
    }

    public articleCreate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/admin/knowledge-entry`, body, { headers });
    }

    public articleUpdate(body: any, id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-entry/${id}`, body, { headers });
    }

    public getArticle(id: number | string, isPreview: boolean = false, isClone: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        const preview = isPreview ? '?preview=1' : ''
        const clone = isClone ? '?clone=1' : '';
        return this.http.get<any>(`${this.basePath}/admin/knowledge-entry/${id}${preview}${clone}`, { headers });
    }

    public changeCategories(articleId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-entry-category/${articleId}`, body, { headers });
    }

    public categoryUsers(idCategory: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/knowledge-category/${idCategory}/user-checklist`, { headers });
    }

    public assignCategory(idCategory: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-category/${idCategory}/assign`, body, { headers });
    }

    public categoryUsersAssigned(idCategory: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/knowledge-category/${idCategory}/assigned`, { headers });
    }

    public categoryAccessLevels(idCategory: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/knowledge-category/${idCategory}/access-levels-checklist`, { headers });
    }

    public assignAccessLevels(idCategory: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-category/${idCategory}/assign-access-levels`, body, { headers });
    }

    public userCategories(type: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/knowledge-category/tree?type=${type}`, { headers });
    }

    public userArticles(idCategory: number, sortBy: string, type: string): Observable<any> {
        const headers = this.defaultHeaders;
        let params = sortBy ? `&sortBy=${sortBy}` : ''
        params = params + (idCategory ? `&category=${idCategory}` : '')
        return this.http.get<any>(`${this.basePath}/knowledge-entry?type=${type}${params}`, { headers });
    }

    public userArticleDetails(idArticle: number): Observable<ArticleInterface> {
        const headers = this.defaultHeaders;
        return this.http.get<ArticleInterface>(`${this.basePath}/knowledge-entry/${idArticle}`, { headers });
    }

    downloadAllFile(id: number): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.get<HttpResponse<Blob>>(`${this.basePath}/knowledge-entry/${id}/attachements`, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    addCategoryGroup(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-entry/multi/category`, body, { headers });
    }

    removeCategoryGroup(body: any): Observable<any> {
        const options = {
            headers: this.defaultHeaders,
            body
        };
        return this.http.delete<any>(`${this.basePath}/admin/knowledge-entry/multi/category`, options);
    }

    addCarerGroup(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/knowledge-entry/multi/carer`, body, { headers });
    }

    removeCarerGroup(body: any): Observable<any> {
        const options = {
            headers: this.defaultHeaders,
            body
        };
        return this.http.delete<any>(`${this.basePath}/admin/knowledge-entry/multi/carer`, options);
    }

    addToFavourite(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/knowledge-entry/${id}/favourite`, {}, { headers });
    }

    removeFromFavourite(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/knowledge-entry/${id}/favourite`, { headers });
    }

    getFavouriteArticles(params: string = ''): Observable<ArticlesListItemInterface[]> {
        const headers = this.defaultHeaders;
        return this.http.get<ArticlesListItemInterface[]>(`${this.basePath}/knowledge-entry?favourite=1${params}`, { headers });
    }

    getNewArticles(params: string = ''): Observable<ArticlesListItemInterface[]> {
        const headers = this.defaultHeaders;
        return this.http.get<ArticlesListItemInterface[]>(`${this.basePath}/knowledge-entry?isNew=1${params}`, { headers });
    }

    getArticlesToReorder(id: number, type: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<ArticleInterface>(`${this.basePath}/admin/knowledge-entry-category/${id}?type=${type}`, { headers });
    }

    reorderArticles(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<ArticleInterface>(`${this.basePath}/admin/knowledge-entry/reorder/${id}`, { ...body }, { headers });
    }
}
