import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {
    public imageUrl = '';

    constructor(private _dialog: MatDialog,
                public dialogRef: MatDialogRef<ImagePreviewComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.imageUrl = this.data.imageUrl;
    }
}

