import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'rangeTo'})
export class RangeToPipe implements PipeTransform {
  transform(value, start) : any {
    const res = [];
    for (let i = (start || 0); i <= value; i++) {
        res.push(i);
      }
      return res;
  }
}