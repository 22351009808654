import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-choice-category-dialog',
    templateUrl: './choice-category-dialog.component.html',
    styleUrls: ['./choice-category-dialog.component.scss']
})
export class ChoiceCategoryDialogComponent implements OnInit {

    @Input() choiceCategories: any[];
    @Input() categories: any[];
    @Input() editable: boolean = true;
    @Output() outputDate = new EventEmitter();
    @Output() goToPage = new EventEmitter();

    constructor(public dialogRef: MatDialogRef<ChoiceCategoryDialogComponent>) {
    }

    ngOnInit(): void {
        //
    }

    drop(event: CdkDragDrop<any>, name: string) {
        if (event.previousContainer.id === event.container.id) {
            if (name === 'choiceCategories') {
                this.choiceCategories[event.previousContainer.data.index] = event.container.data.item
                this.choiceCategories[event.container.data.index] = event.previousContainer.data.item
            } else {
                this.categories[event.previousContainer.data.index] = event.container.data.item
                this.categories[event.container.data.index] = event.previousContainer.data.item
            }
        } else {
            if (name === 'choiceCategories') {
                this.choiceCategories.push(event.previousContainer.data.item)
                this.categories = this.categories.filter(value => value !== event.previousContainer.data.item)
            } else {
                this.categories.push(event.previousContainer.data.item)
                this.choiceCategories = this.choiceCategories.filter(value => value !== event.previousContainer.data.item)
            }
        }

        this.outputDate.emit({
            choiceCategories: this.choiceCategories,
            categories: this.categories
        })
    }

    removeTooltipWhenDrag() {
        $(document).find('.below-top').css('display', 'none');
    }

    addToArray(item: any, name: string) {
        if (name === 'categories') {
            this.categories = this.categories.filter((value) => {
                return value !== item;
            });
            this.choiceCategories.push(item);
        } else {
            this.choiceCategories = this.choiceCategories.filter((value) => {
                return value !== item;
            });
            this.categories.push(item);
        }

        this.outputDate.emit({
            choiceCategories: this.choiceCategories,
            categories: this.categories
        })
    }

    goToPageWithCategory(item: any) {
        this.goToPage.emit(item)
    }
}
