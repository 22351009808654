import { Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
    selector: 'app-assign-carer-action-dialog',
    templateUrl: './assign-carer-action-dialog.component.html',
    styleUrls: ['./assign-carer-action-dialog.component.scss']
})
export class AssignCarerActionDialogComponent {
    @ViewChildren(DaterangepickerDirective) datePickers: QueryList<DaterangepickerDirective>;

    constructor(public dialogRef: MatDialogRef<AssignCarerActionDialogComponent>,
                private _api: ApiService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    assign() {
        const req = {
            type: this.data.type,
            id: this.data.id,
            usersEnable: [],
            usersDisable: []
        };

        if (this.data.assign) {
            req.usersEnable = this.data.selected.map(val => val.userId);
        } else {
            req.usersDisable = this.data.selected.map(val => val.userId);
        }

        this._api.Library.assignCarerAction(req).subscribe(() => {
            this.dialogRef.close(true);
        }, () => {
            this.dialogRef.close();
        });
    }
}
