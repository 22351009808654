<div class="reorder-elements-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{data.title | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close(false)"></i>
    </h1>

    <div mat-dialog-content *ngIf="elements.length" cdkScrollable>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
            <div *ngFor="let ele of elements let i = index;"
                 [cdkDragPreviewClass]="'flex-start-drag'"
                 cdkDrag>
                <div class="element-row cursor-pointer" cdkDragHandle (mousedown)="removeTooltipWhenDrag()">
                    <img src="/assets/icons/drag-handler.svg"
                         matTooltip="{{'Common.DragAndDrop' | translate}}"
                         matTooltipPosition="below"
                         matTooltipClass='below-top'
                         alt="drag-handler">
                    <span>{{ele.name | translate}}</span>
                    <i class="dolineo-icon dolineo-icon-arrow-up cursor-pointer"
                       *ngIf="i > 0"
                       (click)="moveToTop(ele, i)"></i>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close(false)" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button (click)="save()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.IConfirm' | translate}}</button>
    </div>
</div>