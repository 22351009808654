import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoaderService {

    private loaderBehaviorSubject: BehaviorSubject<boolean>;

    constructor() {
        this.loaderBehaviorSubject = new BehaviorSubject<boolean>(false);
    }

    show(): void {
        this.loaderBehaviorSubject.next(true);
    }

    hide(): void {
        this.loaderBehaviorSubject.next(false);
    }

    status(): Observable<boolean> {
        return this.loaderBehaviorSubject.asObservable();
    }
}