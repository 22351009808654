import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-config-table-dialog',
    templateUrl: './config-table-dialog.component.html',
    styleUrls: ['./config-table-dialog.component.scss']
})

export class ConfigTableDialogComponent {
    @Input() defaultColumns: any[] = [];
    @Input() sliceFrom: number;
    @Input() withoutLastColumn: boolean = false;

    @Input() set columns(columns: any) {
        this._columns = columns.filter(c => {
            return c.name || c.type === 'MENU' ? true : false;
        }).map(val => {
            return { ...val }
        })
        this.tempColumns = this._columns.slice(this.sliceFrom, this.withoutLastColumn ? columns.length - 1 : columns.length)
    }

    @Output() saveConfig = new EventEmitter();

    public tempColumns: any[];
    public _columns: any[];

    constructor(private _toastrService: ToastrService,
                private _translate: TranslateService) {
    }

    drop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.tempColumns, event.previousIndex, event.currentIndex);
    }

    saveDefault() {
        this.saveConfig.emit(this.defaultColumns);
        this._toastrService.success(this._translate.instant('ConfigTableDialog.TableLayoutUpdated'));
    }

    checkAll() {
        this.tempColumns.forEach(val => val.visible = true);
    }

    save() {
        const cols = [...this._columns.slice(0, this.sliceFrom), ...this.tempColumns];
        if (this.withoutLastColumn) {
            cols.push(this._columns[this._columns.length - 1])
        }
        this.saveConfig.emit(cols);
        this._toastrService.success(this._translate.instant('ConfigTableDialog.TableLayoutUpdated'));
    }
}