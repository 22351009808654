<h1 mat-dialog-title tabIndex="0">
    {{(data?.trainingId ? 'Common.EditFile' : 'PathCreator.AddFromDisk') | translate}}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content>
    <form [formGroup]="form" class="training-from-disk">
        <div class="training-from-disk--row">
            <div class="training-from-disk--row--header">
                <img src="/assets/icons/format-selection.svg" alt="format-selection">
                {{'PathCreator.ContentFormat' | translate}}
            </div>

            <div class="training-from-disk--row--content">
                <mat-form-field class="format">
                    <mat-select [formControl]="format" placeholder="{{ 'PathCreator.Format' | translate}}">
                        <mat-option [value]="format.index"
                                    *ngFor="let format of trainingFormat">{{format.name | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="box--info">
                    <div>
                        <img src="/assets/icons/info.svg" alt="format-selection">
                    </div>
                    <span> {{selectedFormat.info | translate}} </span>
                </div>
            </div>

            <div class="training-from-disk--row--content" style="min-height: 156px">
                <div *ngIf="selectedFormat.index === 'videoAudio'" class="full-width">
                    <div class="flex-items" style="justify-content: space-between">
                        <div class="flex-items">
                            <button class="download-button" mat-flat-button
                                    (click)="selectFile()"
                                    [ngClass]="{'opacity-30 cursor-default': form.get('origFileName').value || filenamePreview || form.get('videoUrl').value}"
                                    type="button">
                                <i class="dolineo-icon dolineo-icon-download2"
                                   style="margin-bottom: 8px; margin-right: 5px;"></i>
                                {{ (filenamePreview || form.get('origFileName').value ? 'TrainingCreator.UpdateVideoFile' : 'TrainingCreator.LoadVideoFile') | translate }}
                            </button>
                            <input type="file" #file style="display: none;"
                                   (change)="onFileChange(null, 'videoAudio')"/>
                        </div>
                    </div>
                    <div class="drop-down-container">
                        <ngx-file-drop
                                dropZoneLabel="{{'PathCreator.DragAndDropTheFileMP4WEBMAVIMOVWMVOGVMP3' | translate}}"
                                (onFileDrop)="dropped($event, 'videoAudio')"
                                (click)="selectFile()"
                                multiple="false"
                                *ngIf="!filenamePreview"
                                directory="false"
                                class="cursor-pointer">
                        </ngx-file-drop>

                        <mat-error *ngIf="!form.get('fileName').valid && form.get('fileName').touched"
                                   class="error-message">
                            {{ 'Common.FiledCanNotBeEmpty' | translate }}
                        </mat-error>
                    </div>

                    <div class="file-container" *ngIf="filenamePreview || form.get('origFileName').value">
                        <div class="flex-items">
                            <span> {{ filenamePreview || form.get('origFileName').value }} </span>
                            <div>
                                <i (click)="downloadFile()"
                                   matTooltip="{{'Common.Download' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-download2"></i>
                                <i (click)="removeFile()"
                                   matTooltip="{{'Common.Delete' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-trash"></i>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="fileError" style="margin-top:22px; font-size: 12px;">
                        <div>
                            <mat-error>{{ fileErrorMessage }}</mat-error>
                        </div>
                    </div>

                    <div class="flex-items mt-3"
                         [ngClass]="{'opacity-30 cursor-default': form.get('origFileName').value || filenamePreview}">
                        <i class="dolineo-icon dolineo-icon-link" style="margin-right: 5px;"></i>
                        <label style="width: 300px;">{{'TrainingCreator.PasteYouTubeOrVimeo' | translate}} </label>
                        <mat-form-field style="width: 100%;">
                            <input matInput formControlName="videoUrl" placeholder="Adres url"
                                   [attr.disabled]="(form.get('origFileName').value || filenamePreview) ? true : null"
                                   (keydown.enter)="$event.preventDefault()">
                            <mat-error *ngIf="urlError !== ''">{{ urlError }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="selectedFormat.index === 'scorm'" class="full-width">
                    <div class="flex-items">
                        <button class="download-button" mat-flat-button
                                type="button"
                                (click)="selectFile()">
                            <i class="dolineo-icon dolineo-icon-download2"></i>
                            {{ (filenamePreview || form.get('origFileName').value ? 'TrainingCreator.UpdateScorm' : 'TrainingCreator.AddScorm') | translate }}
                        </button>
                        <input type="file" #file style="display: none;" (change)="onFileChange(null, 'scorm')"/>
                    </div>

                    <div class="drop-down-container">
                        <ngx-file-drop dropZoneLabel="{{'PathCreator.DragAndDropTheFileSCORM' | translate}}"
                                       (onFileDrop)="dropped($event, 'scorm')"
                                       (click)="selectFile()"
                                       multiple="false"
                                       *ngIf="!filenamePreview"
                                       directory="false"
                                       class="cursor-pointer">
                        </ngx-file-drop>

                        <mat-error *ngIf="!form.get('fileName').valid && form.get('fileName').touched"
                                   class="error-message">
                            {{ 'Common.FiledCanNotBeEmpty' | translate }}
                        </mat-error>
                    </div>

                    <div *ngIf="fileError" style="margin-top:22px; font-size: 12px;">
                        <div>
                            <mat-error>{{ fileErrorMessage }}</mat-error>
                        </div>
                    </div>

                    <div class="file-container" *ngIf="filenamePreview || form.get('origFileName').value">
                        <div class="flex-items">
                            <span> {{ filenamePreview || form.get('origFileName').value }} </span>
                            <div>
                                <i (click)="downloadFile()"
                                   matTooltip="{{'Common.Download' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-download2"></i>
                                <i (click)="removeFile()"
                                   matTooltip="{{'Common.Delete' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-trash"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedFormat.index === 'h5p'" class="full-width">
                    <div class="flex-items">
                        <button class="download-button" mat-flat-button
                                type="button"
                                (click)="selectFile()">
                            <i class="dolineo-icon dolineo-icon-download2"></i>
                            {{ (filenamePreview || form.get('origFileName').value ? 'TrainingCreator.UpdateH5p' : 'TrainingCreator.AddH5p') | translate }}
                        </button>
                        <input type="file" #file style="display: none;" (change)="onFileChange(null, 'h5p')"/>
                    </div>

                    <div class="drop-down-container">
                        <ngx-file-drop dropZoneLabel="{{'PathCreator.DragAndDropTheFileH5P' | translate}}"
                                       (onFileDrop)="dropped($event, 'h5p')"
                                       (click)="selectFile()"
                                       multiple="false"
                                       *ngIf="!filenamePreview"
                                       directory="false"
                                       class="cursor-pointer">
                        </ngx-file-drop>

                        <mat-error *ngIf="!form.get('fileName').valid && form.get('fileName').touched"
                                   class="error-message">
                            {{ 'Common.FiledCanNotBeEmpty' | translate }}
                        </mat-error>
                    </div>

                    <div *ngIf="fileError" style="margin-top:22px; font-size: 12px;">
                        <div>
                            <mat-error>{{ fileErrorMessage }}</mat-error>
                        </div>
                    </div>

                    <div class="file-container" *ngIf="filenamePreview || form.get('origFileName').value">
                        <div class="flex-items">
                            <span> {{ filenamePreview || form.get('origFileName').value }} </span>
                            <div>
                                <i (click)="downloadFile()"
                                   matTooltip="{{'Common.Download' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-download2"></i>
                                <i (click)="removeFile()"
                                   matTooltip="{{'Common.Delete' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-trash"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedFormat.index === 'file'" class="full-width">
                    <div class="flex-items">
                        <button class="download-button" mat-flat-button
                                type="button"
                                (click)="selectFile()">
                            <i class="dolineo-icon dolineo-icon-download2"></i>
                            {{ (filenamePreview || form.get('origFileName').value ? 'TrainingCreator.UpdateFile' : 'Common.AddFile') | translate }}
                        </button>
                        <input type="file" #file style="display: none;" (change)="onFileChange(null, 'file')"/>
                    </div>

                    <div class="drop-down-container">
                        <ngx-file-drop
                                dropZoneLabel="{{'PathCreator.DragAndDropTheFilePDFDOCDOCXPPTPPTXXLSXLSXOrOther' | translate}}"
                                (onFileDrop)="dropped($event, 'file')"
                                (click)="selectFile()"
                                multiple="false"
                                *ngIf="!filenamePreview"
                                directory="false"
                                class="cursor-pointer">
                        </ngx-file-drop>

                        <mat-error *ngIf="!form.get('fileName').valid && form.get('fileName').touched"
                                   class="error-message">
                            {{ 'Common.FiledCanNotBeEmpty' | translate }}
                        </mat-error>
                    </div>

                    <div *ngIf="fileError" style="margin-top:22px; font-size: 12px;">
                        <div>
                            <mat-error>{{ fileErrorMessage }}</mat-error>
                        </div>
                    </div>

                    <div class="file-container" *ngIf="filenamePreview || form.get('origFileName').value">
                        <div class="flex-items">
                            <span> {{ filenamePreview || form.get('origFileName').value }} </span>
                            <div>
                                <i (click)="downloadFile()"
                                   matTooltip="{{'Common.Download' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-download2"></i>
                                <i (click)="removeFile()"
                                   matTooltip="{{'Common.Delete' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-trash"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedFormat.index === 'page'" class="full-width">
                    <div class="d-flex editor-container">
                        <div class="editor-container__wrapper">
                            <div id="quill-editor-page"></div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedFormat.index === 'url'" style="width: 100%;">
                    <div class="d-flex url-container">
                        <div class="url-container__wrapper">
                            <div class="flex-items mt-3">
                                <label style="width: 165px;">{{'TrainingCreator.PasteLinkToPage' | translate}} </label>
                                <mat-form-field style="width: 100%;">
                                    <input matInput formControlName="url"
                                           type="text"
                                           placeholder="{{'TrainingCreator.UrlAdress' | translate}}"
                                           (keydown.enter)="$event.preventDefault()">
                                    <mat-error *ngIf="urlError !== ''">{{ urlError }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="flex-items xfont-weight-light">
                                <div style="margin-right: 17px;">{{'TrainingCreator.DisplayingLink' | translate}}</div>
                                <mat-form-field>
                                    <mat-select formControlName="display" style="width: 170px;">
                                        <mat-option value="0">{{'TrainingCreator.InNewTab' | translate}}</mat-option>
                                        <mat-option value="1">{{'TrainingCreator.InWindowOnPlatform' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="training-from-disk--row training-from-disk--row--center">
            <div>
                <div class="training-from-disk--row--header">
                    <img src="/assets/icons/letter-a.svg" alt="letter-a">
                    {{'TeamPathsTrainings.ElementName' | translate}}
                </div>

                <div class="training-from-disk--row--content" style="width: 407px">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="{{'PoolCreator.SummaryName' | translate}} *"
                               formControlName="name"
                               #name
                               [maxLength]="60">
                        <mat-hint align="end">{{name.value.length}} / 60</mat-hint>
                        <mat-error
                                *ngIf="(form.get('name').hasError('required') || form.get('name').hasError('cannotContainSpace'))&&
                                        form.get('name').touched">
                            {{ 'Common.FiledCanNotBeEmpty' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div style="padding-right: 30px; position: relative">
                <div class="training-from-disk--row--header">
                    <img src="/assets/icons/timer.svg" alt="timer">
                    {{'PathCreator.Duration' | translate}}
                </div>

                <div class="training-from-disk--row--content flex-items">
                    <mat-form-field style="width: 60px; margin-right: 5px">
                        <input matInput
                               digitOnly
                               formControlName="duration"
                               placeholder="{{'Common.Number' | translate}} *" maxlength="4">
                    </mat-form-field>
                    {{'Common.Minutes' | translate}}
                </div>
                <mat-error style="position: absolute; bottom: -5px"
                           *ngIf="form.get('duration').hasError('required') && form.get('duration').touched">
                    {{ 'Common.FiledCanNotBeEmpty' | translate }}
                </mat-error>
            </div>
        </div>

        <div class="training-from-disk--row"
             *ngIf="selectedFormat.index !== 'scorm' && selectedFormat.index !== 'page' && selectedFormat.index !== 'url'">
            <div class="training-from-disk--row--header" style="margin-bottom: 10px">
                <img src="/assets/icons/configure_create.svg" alt="format-selection">
                {{'TrainingCreator.OptionalConfiguration' | translate}}
            </div>

            <div class="training-from-disk--row--content">
                <div *ngIf="selectedFormat.index === 'videoAudio'" class="training-from-disk--configure">
                    <div class="flex-items xfont-weight-light">
                        <mat-slide-toggle color="primary" formControlName="limitSeek">
                            {{'TrainingCreator.VideoConfigurationRewindLock' | translate}}
                        </mat-slide-toggle>
                    </div>

                    <div class="flex-items xfont-weight-light">
                        <mat-slide-toggle color="primary" formControlName="increaseSpeed">
                            {{'TrainingCreator.VideoConfigurationIncreaseSpeed' | translate}}
                        </mat-slide-toggle>
                    </div>

                    <div class="flex-items xfont-weight-light">
                        <div style="margin-right: 5px;">{{'TrainingCreator.VideoConfigurationStoppingVideoTextBeforeSelect' | translate}}</div>

                        <mat-form-field>
                            <mat-select formControlName="finishBefore" style="width: 50px;">
                                <mat-option *ngFor="let finish of finishBefore"
                                            [value]="finish.value">{{finish.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div style="margin-left: 5px;">{{'TrainingCreator.VideoConfigurationStoppingVideoTextAfterSelect' | translate }}</div>
                    </div>
                </div>

                <div *ngIf="selectedFormat.index === 'h5p'" class="training-from-disk--configure">
                    <div class="flex-items">
                        <div style="font-weight: 400;">{{'TrainingCreator.UserHaveToSeenAllPagesToFinishTraining' | translate}}</div>
                    </div>

                    <div class="flex-items">
                        <mat-slide-toggle formControlName="confirmationRequired"
                                          color="primary">{{'TrainingCreator.ConfirmationRequired' | translate}}</mat-slide-toggle>
                    </div>

                    <div class="flex-items xfont-weight-light">
                        <mat-slide-toggle
                                color="primary"
                                formControlName="pointsRequired">{{'TrainingCreator.PointsRequired' | translate}}</mat-slide-toggle>
                        <ng-container *ngIf="form.get('pointsRequired').value">
                            <mat-form-field style="margin-left: 10px; position: relative">
                                <input matInput
                                       formControlName="points"
                                       max="100"
                                       style="width:60px;"
                                       placeholder="{{'Common.Number' | translate}} *"
                                       min="1" digitOnly type="text"/>
                                <div style="position: absolute; top: 30px; width: 200px">
                                    <mat-error
                                            *ngIf="form.get('points').hasError('required') && form.get('points').touched"
                                            class="error-message">
                                        {{ 'Common.FiledCanNotBeEmpty' | translate }}
                                    </mat-error>
                                </div>
                            </mat-form-field>
                            %
                        </ng-container>
                        <div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedFormat.index === 'file'" class="training-from-disk--configure mb-3">
                    <div class="flex-items xfont-weight-light">
                        <div style="margin-right: 5px;">{{'TrainingCreator.FileConfigurationUserCan' | translate}}</div>
                        <mat-form-field>
                            <mat-select style="width: 100px;" formControlName="action">
                                <mat-option value="display">{{'TrainingCreator.Display' | translate}}</mat-option>
                                <mat-option value="download">{{'TrainingCreator.Download' | translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div style="margin-left: 5px;">{{'TrainingCreator.FileConfigurationThisFile' | translate }}</div>
                    </div>

                    <mat-slide-toggle formControlName="allPagesSeenRequired"
                                      color="primary">{{'TrainingCreator.FileConfigurationAllPagesSeenRequired' | translate}}</mat-slide-toggle>

                    <mat-slide-toggle style="margin-top: 15px;"
                            formControlName="pdfFileDownloadBlock"
                                      color="primary">{{'TrainingCreator.PdfFileDownloadBlock' | translate}}</mat-slide-toggle>
                </div>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close()" mat-button
            class="cancel-button">{{'Common.Cancel' | translate}}</button>
    <button (click)="save()" mat-button color="primary"
            class="dolineo-button blue-button">{{ (data && data.trainingId ? 'Common.Update' : 'Common.Add') | translate}}</button>
</div>
