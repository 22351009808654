import { Component } from '@angular/core';

@Component({
    selector: 'app-no-connection',
    templateUrl: './no-connection.component.html',
    styleUrls: ['./no-connection.component.scss']
})
export class NoConnectionComponent {
    hasConnect = false;
    noConnect = false

    constructor() {
        setInterval(() => {
            if (navigator.onLine) {
                this.hasConnect = navigator.onLine && this.noConnect;
                this.noConnect = false;
                setTimeout(() => {
                    this.hasConnect = false;
                }, 3000)
            } else {
                this.noConnect = true;
                this.hasConnect = false;
            }
        }, 3000)
    }
}
