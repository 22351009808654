import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import * as moment from 'moment';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'dolineo-subscription',
  templateUrl: './dolineo-subscription.component.html',
  styleUrls: ['./dolineo-subscription.component.scss']
})

export class DolineoSubscriptionComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  subscription: any;
  waiting = true;
  defaultElmentsColor: string = 'FF9900';
  isMobile: boolean;

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    if(!this.preview){
      this.prepareSubscriptionData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: this.isMobile ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getExampleData(){

    this.subscription = {
      created: 106,
      purchased: 150,
      available: 44,
      active: 100,
      suspended: 3,
      anonymized: 3,
      date: '31.12.2025'
    };
  }

  prepareSubscriptionData(){
    this._api.Dashboard.getEmployeeSubscriptionData().subscribe((res) => {
      const activeSubscription = res.subscriptions.elements.find(x => x.isActive === true);
      const createdAccounts = (res.users[0].active + res.users[0].suspended + res.users[0].anonymized);
      this.subscription = {
        created: activeSubscription ? createdAccounts : '',
        purchased: activeSubscription ? activeSubscription.accountsCount : '',
        available: activeSubscription ? (activeSubscription.accountsCount - createdAccounts) : '',
        active: res.users[0].active,
        suspended: res.users[0].suspended,
        anonymized: res.users[0].anonymized,
        date: activeSubscription ? moment(activeSubscription.stopDate).format('DD.MM.YYYY') : ''
      };
        this.waiting = false;
        this.prepareWidgetData()
    });
  }
}