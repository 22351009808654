<div class="calendar-component-wrapper container-fluid">
    <div class="page-title" [ngClass]="{'ml-15': disableButtonBack}">
        <back-button text="Calendar.Calendar" color="white"></back-button>
    </div>

    <div class="calendar-filters">
        <div class="date-filter">
            <span (click)="basicDatepicker.open()"
                  [ngClass]="{'sm': datePickerValue.value}">{{ 'Calendar.GoToDate'| translate }}</span>
            <input [matDatepicker]="basicDatepicker"
                   (click)="basicDatepicker.open()"
                   [ngStyle]="{'width': datePickerValue.value?  '80px' : '0' }"
                   readonly="readonly" #datePickerValue>
            <mat-datepicker-toggle [for]="basicDatepicker">
                <mat-icon matDatepickerToggleIcon>
                </mat-icon>
            </mat-datepicker-toggle>

            <mat-datepicker #basicDatepicker (opened)="onDatepickerOpen()">
            </mat-datepicker>

            <div #datepickerFooter class="date-picker-footer">
                <div class="btn-close" (click)="onDatepickerClose()"></div>
                <button class="btn today" (click)="onDatepickerToday()">{{'Calendar.Today'|translate}}</button>
                <button class="btn cancel" (click)="onDatepickerClose()">{{'Common.Cancel'|translate}}</button>
                <button class="btn accept" (click)="onDatepickerAccept()">{{'Common.Accept'|translate}}</button>
            </div>
        </div>

        <div style="display: flex" class="calendar-filters--right">
            <button (click)="removeFilters()" class="clear-filters" *ngIf="typeFilter.length || statusFilter.length">
                {{'Common.ClearFilters' | translate}}
                <i class="material-icons clear-button">close</i>
            </button>

            <div class="filter-sm cursor-pointer" (click)="openFilterPopup()">
                <img src="/assets/icons/filter.svg" alt="filter">
            </div>

            <div class="status-filter">
                <mat-select placeholder="{{ 'Common.Status'|translate }}" [(ngModel)]="statusFilter" multiple
                            (selectionChange)="applyFilters()">
                    <mat-option *ngFor="let status of statuses"
                                [value]="status">
                        {{ 'Calendar.' + status | translate }}
                    </mat-option>
                </mat-select>
            </div>

            <div class="type-filter">
                <mat-select placeholder="{{ 'Calendar.Type'|translate }}" [(ngModel)]="typeFilter" multiple
                            (selectionChange)="applyFilters()">
                    <mat-option *ngFor="let type of types" [value]="type">
                        {{ 'Calendar.' + type | translate }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>

    <div class="calendar-activities" *ngIf="activities.length; else emptyCalendar">
        <div class="calendar-item" *ngFor="let activity of activities; let i = index">
            <div class="month-year-label" *ngIf="isDifferentYearOrMonth(i)">
                {{ activity.date | date: 'MMMM y' }}
            </div>

            <div class="record {{activity.status}} cal-date-{{activity.date | date: 'dd-MM-y'}} {{getTimeClass(i)}}"
                 [ngClass]="{'same-day': isSameDay(i), 'empty': !activity.relatedObjectName}">
                <div class="date">
                    <span class="day">{{ activity.date | date: 'dd'}}</span>
                    <span class="dayName">{{ activity.date | date: 'EEE' }}</span>
                    <span class="color-gray" style="max-width: 65px;">{{'Calendar.Today' | translate |uppercase}}</span>
                    <span class="icon">
                        <span class="dot"></span>
                    </span>
                </div>

                <div class="details" *ngIf="activity.relatedObjectName; else emptyDetails">
                    <div class="main-details">
                        <h2 (click)="showActivity(activity)">{{ activity.relatedObjectName }}</h2>
                        <span class="details-date">
                            <span class="data-text">{{'Common.Date' | translate}}:</span>
                            <span class="date-string"
                                  [ngClass]="{'outdated': activity.status === 'deadline_passed'}">
                                {{ activity.date | date: 'dd.MM.y'}}
                            </span>
                        </span>
                    </div>

                    <div class="extra-details">
                        {{ 'Calendar.' + activity.relatedObjectType | translate | uppercase  }}

                        <span class="details-status">
                            <span class="icon"></span>
                            {{ 'Calendar.' + activity.status | translate }}<span
                                *ngIf="activity.status ==='assign'">: {{activity.assignedBy}}</span>
                        </span>
                    </div>
                </div>

                <ng-template #emptyDetails>
                    <div class="emptyDetails"></div>
                </ng-template>
            </div>
        </div>
    </div>

    <ng-template #emptyCalendar>
        <div class="calendar-activities empty-calendar"
             *ngIf="typeFilter.length || statusFilter.length; else detectiveSearch">
            <img src="assets/images/default/empty_page_filter.png" alt="empty_page_filter">
            <span class="empty-calendar__text">{{'Common.NoDataToDisplayWithFilters' | translate}}</span>
        </div>

        <ng-template #detectiveSearch>
            <div class="calendar-activities empty-calendar">
                <div class="calendar-item">
                    <div class="month-year-label">
                        {{ myDate | date: 'MMMM y' }}
                    </div>

                    <div class="record today">
                        <div class="date">
                            <span class="day">{{ myDate | date: 'dd'}}</span>
                            <span class="dayName">{{ myDate | date: 'EEE' }}</span>
                            <span class="color-gray"
                                  style="max-width: 65px;">{{'Calendar.Today' | translate |uppercase}}</span>
                            <span class="icon">
                                <span class="dot"></span>
                            </span>
                        </div>

                        <div class="details">
                            <div class="details--border-box">
                                <p>{{'Calendar.YourActivitiesAndUpcomingEventsWillAppearHere' | translate}}</p>
                                <p>{{'Calendar.EGDeadlinesForTheImplementationOfTheAssignedTraining' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>
