import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AssignCarerActionDialogComponent } from '../assign-carer-action-dialog/assign-carer-action-dialog.component';
import * as he from 'he';

@Component({
    selector: 'assign-carer-dialog',
    templateUrl: './assign-carer-dialog.component.html',
    styleUrls: ['./assign-carer-dialog.component.scss']
})
export class AssignCarerDialogComponent implements OnInit, OnDestroy {
    public assignedUsers = [];
    public unassignUsers: any[] = [];

    usersColumns: any[] = [];
    usersColumnsAvailability: any[] = [];

    hasUsers: boolean;

    selectedToAdd = [];
    selectedToRemove = [];
    selectedSectionName: string = 'users';

    users = [];

    translations = {
        title: '',
        accessAdded: 'AccessAdded',
        accessRemoved: 'AccessRemoved'
    };

    public hasPermissions = false;
    positions: any[] = [];

    constructor(public dialogRef: MatDialogRef<AssignCarerDialogComponent>,
                private _toastrService: ToastrService,
                private api: ApiService,
                public _helper: HelperService,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        let transDict = '';
        switch (this.data.type) {
            case 'path':
                transDict = 'PathCreator.';
                break;
            case 'training':
                transDict = 'TrainingCreator.';
                break;
            case 'pools':
                transDict = 'PoolCreator.';
                break;
            case 'survey':
                transDict = 'SurveyCreator.';
                break;
            case 'knowledge_base_category':
                transDict = 'KnowledgeBase.';
                break;
            case 'knowledge_base_entry':
                transDict = 'Article.';
                break;
            case 'onboarding':
                transDict = 'Onboarding.';
                break;
            case 'preboarding':
                transDict = 'Preboarding.';
                break;
            case 'gamification':
                transDict = 'GamificationCreator.';
                break;
            case 'feedback':
                transDict = 'FeedbackCampaign.';
                break;
            case 'feedback-questionnaire':
                transDict = 'FeedbackQuestionnaire.';
                break;
        }

        this.translations.title = transDict + 'SaveCarers';
        this.translations.accessAdded = this._translate.instant(transDict + this.translations.accessAdded);
        this.translations.accessRemoved = this._translate.instant(transDict + this.translations.accessRemoved);

        this.setAssignedUnassigned();

        this.getPositions();
    }

    setAssignedUnassigned() {
        this.assignedUsers = this.data.users
            .filter(value => value.isAssigned)
            .map(user => {
                return {
                    ...user,
                    disabled: Number(user.userId) === this._helper.getUser().id
                }
            });
        this.unassignUsers = this.data.users.filter(value => !value.isAssigned);
        this.hasUsers = this.data.users.length > 0;
    }

    ngOnDestroy() {
        // document.body.classList.add('nav-collapsed');
    }

    setStep(index: number, name: string) {
        this.selectedSectionName = name;
        // document.body.classList.add('nav-collapsed');
    }

    getPositions() {
        if (this._helper.getUser().companyId) {
            this.api.Company.positions(
                this._helper.getUser().companyId,
                1,
                -159,
                'name',
                'asc',
                '').subscribe((res) => {
                this.positions = res.map(obj => {
                    return {
                        id: obj.name,
                        value: obj.name
                    };
                });
                this.createUsersColumn();
            });
        } else {
            this.createUsersColumn();
        }
    }

    createUsersColumn() {
        this.usersColumns = [
            {
                index: 'userName',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '150px',
                    'padding-right': '20px',
                },
                class: 'ft-auto',
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: this._helper.getUser().companyId ? true : false,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '139px',
                    'width': '139px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                sortable: true,
                visible: this._helper.getUser().companyId ? true : false,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                options: this.positions,
                css: {
                    'min-width': '137px',
                    'width': '137px',
                    'padding-right': '20px'
                },
                novalue: true
            }
        ];

        this.usersColumnsAvailability = [
            {
                index: 'userName',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '141px',
                    'width': '141px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + he.encode(v.userName) + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewUser')} </span>`;
                    } else {
                        return '<div class="cut-text">' + he.encode(v.userName) + '</div>';
                    }
                }
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: this._helper.getUser().companyId ? true : false,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '139px',
                    'width': '139px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                sortable: true,
                visible: this._helper.getUser().companyId ? true : false,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                options: this.positions,
                css: {
                    'min-width': '137px',
                    'width': '137px',
                    'padding-right': '20px'
                },
                novalue: true
            }
        ];
    }

    assign(headerText: string, contentText: string) {
        this._dialog.open(AssignCarerActionDialogComponent, {
            disableClose: true,
            width: '540px',
            data: {
                type: this.data.type,
                selected: this.selectedToAdd,
                headerText,
                contentText,
                id: this.data.id,
                assign: true
            }
        }).afterClosed().subscribe(val => {
            if (val) {
                this.selectedToAdd = this.selectedToAdd.map(value => {
                    return {
                        ...value,
                        selected: false,
                        isNew: true
                    };
                });

                this.assignedUsers = [...this.selectedToAdd, ...this.assignedUsers];
                this.unassignUsers = this.unassignUsers.filter(item => !this.assignedUsers.find(ele => ele.userName === item.userName));
                this._toastrService.success(this._translate.instant('Common.ATutorHasBeenAssigned'));
                this.selectedToAdd = [];
            }
        })
    }

    unAssign(headerText: string, contentText: string,) {
        this._dialog.open(AssignCarerActionDialogComponent, {
            disableClose: true,
            width: '540px',
            data: {
                type: this.data.type,
                selected: this.selectedToRemove,
                headerText,
                contentText,
                id: this.data.id,
                assign: false
            }
        }).afterClosed().subscribe(val => {
            if (val) {
                this.selectedToRemove = this.selectedToRemove
                    .filter(value => {
                        if (Number(value.userId) !== this._helper.getUser().id) {
                            return value;
                        }
                    }).map(value => {
                        return {
                            ...value,
                            selected: false,
                            isNew: false,
                        }
                    });
                this.unassignUsers = [...this.selectedToRemove, ...this.unassignUsers];
                this.assignedUsers = this.assignedUsers.filter(item => !this.selectedToRemove.find(ele => ele.userName === item.userName))
                this._toastrService.success(this._translate.instant('Common.ATutorHasBeenRemoved'));
                this.selectedToRemove = [];
            }
        })
    }

    selectedAddItems(items: []) {
        this.selectedToAdd = [...items];
    }

    selectedRemoveItems(items: []) {
        this.selectedToRemove = [...items];
    }
}
