<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>

<div class="approval-tasks-dialog" *ngIf="approvalOnboarding || preview">
    <h1 mat-dialog-title tabIndex="0">
        <span>{{(data.preview ? 'Common.ImplementationPreview' : (this.onboarding ? (this.data.isPreboarding ? 'Preboarding.AcceptanceOfTasksInPreboarding' : 'Onboarding.AcceptanceOfTasksInOnboarding') : 'Gamification.AcceptanceOfTasksInGamification')) | translate}}
            <span class="color-gray">{{ this.onboarding ? onboarding.name : gamification.name }}</span>
        </span>
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="approval-tasks-dialog__top">
            <div class="approval-tasks-dialog__top--user">
                <span (click)="backToTasks()" *ngIf="task">
                    <i class="material-icons">navigate_before</i>
                    {{'Onboarding.Back' | translate}}
                </span>
                <h1>{{userName}}</h1>
            </div>
            <div class="approval-tasks-dialog__top--buttons">
                <ng-container *ngIf="!task">
                    <mat-slide-toggle color="primary"
                                      [checked]="onlyForApprove"
                                      (change)="changeToogle($event)">
                        {{'Onboarding.ShowOnlyTasksForApproval' | translate}}
                    </mat-slide-toggle>

                    <div class="navigate-buttons" *ngIf="showButtons">
                        <button mat-button
                                (click)="previousUser()"
                                class="dolineo-button blue-button">
                            <img src="assets/icons/arrow_left.svg" alt="arrow_left"/>
                        </button>
                        <span> {{approvalOnboarding.userIndex}} {{'Common.Of' | translate}} {{approvalOnboarding.total}} </span>
                        <button mat-button
                                (click)="nextUser()"
                                class="dolineo-button blue-button">
                            <img src="assets/icons/arrow_right.svg" alt="arrow_right"/>
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="task && task.approvalStatus === 'pending'">
                    <button mat-flat-button
                            (click)="acceptTask(task)"
                            [disabled]="!task.canUserApprove"
                            class="dolineo-button white-button">{{'Common.AcceptButton' | translate}}</button>
                    <button mat-flat-button
                            (click)="rejectTask(task)"
                            [disabled]="!task.canUserApprove"
                            class="dolineo-button red-button">{{'Common.RejectButton' | translate}}</button>
                </ng-container>

                <ng-container *ngIf="task && task.approvalStatus !== 'pending'">
                    {{task.approvalStatusLog[task.approvalStatusLog.length - 1]?.date | date : 'dd.MM.YYYY'}}
                    <span class="dot"></span>
                    {{task.approvalStatusLog[task.approvalStatusLog.length - 1]?.approver}}

                    <span class="badge badge-success"
                          *ngIf="task.approvalStatusLog[task.approvalStatusLog.length - 1]?.status === 'approved'">
                        {{'Onboarding.AcceptedTasks'| translate | uppercase}}
                    </span>
                    <span class="badge badge-danger"
                          *ngIf="task.approvalStatusLog[task.approvalStatusLog.length - 1]?.status === 'rejected'">
                        {{'Onboarding.RejectedTasks'| translate | uppercase}}
                    </span>
                </ng-container>
            </div>
        </div>

        <div class="approval-tasks-dialog__content">
            <div class="approval-tasks-dialog__box" *ngIf="!task && !preview && tasks?.length">
                <div class="approval-tasks-dialog__box--top">
                    <button mat-flat-button *ngIf="!gamification"
                            [disabled]="!checkList.length"
                            (click)="acceptTaskList()"
                            class="dolineo-button white-button">{{'Onboarding.AcceptChecked' | translate}}</button>
                    <button mat-flat-button
                            [disabled]="!checkList.length"
                            (click)="rejectTaskList()"
                            class="dolineo-button red-button">{{'Onboarding.RejectChecked' | translate}}</button>
                </div>

                <div class="approval-tasks-dialog__rows" tableWrapper (callback)="loadMore()">
                    <div class="approval-tasks-dialog__row"  *ngFor="let task of tasks">
                        <div class="approval-tasks-dialog__row--left">
                            <mat-checkbox color="primary" (change)="changeCheckbox($event, task.approvalPendingId)"
                                          *ngIf="task.approvalStatus === 'pending' && (onboarding || gamification) && task.canUserApprove">
                            </mat-checkbox>
                            <span class="approval-tasks-dialog-task-name" [ngStyle]="{'margin-left' : task.approvalStatus === 'pending' && onboarding && task.canUserApprove ? '0' : '26px'}">{{task.name}}</span>

                            <div class="approval-tasks-dialog__row--left__right data-grid-header__right">
                                <i class="dolineo-icon dolineo-icon-hover dolineo-icon-show_attempt_details"
                                   matTooltip="{{'Onboarding.TaskPreview' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   (click)="previewTask(task.objectId)"
                                ></i>
                                <button mat-button (click)="downloadAttachment(task.userAttachment)"
                                        *ngIf="task.userAttachment"
                                        class="configure-table sm">
                                    <img src="/assets/icons/download.svg"
                                         alt="download">
                                    {{'Onboarding.DownloadAttachment' | translate}}
                                </button>
                            </div>
                        </div>

                        <div class="approval-tasks-dialog__row--buttons">
                            <ng-container *ngIf="task.approvalStatus && task.approvalStatus === 'pending'">
                                <button mat-flat-button
                                        [disabled]="!task.canUserApprove"
                                        (click)="acceptTask(task)"
                                        class="dolineo-button white-button">{{'Common.AcceptButton' | translate}}</button>
                                <button mat-flat-button
                                        (click)="rejectTask(task)"
                                        [disabled]="!task.canUserApprove"
                                        class="dolineo-button red-button">{{'Common.RejectButton' | translate}}</button>
                            </ng-container>
                            <div class="approval-tasks-dialog__row--buttons__info flex-table"
                                 *ngIf="task.approvalStatus && task.approvalStatus !== 'pending'">
                                <button mat-flat-button
                                        (click)="openRejectedTasks(task)"
                                        class="task-rejected-button"
                                        type="button" *ngIf="task.approvalStatusLog.length && !task.finishedDate && task.approvalStatus === 'rejected'">
                                    <img src="assets/icons/info2.svg"
                                         alt="info2">
                                    {{'Task.TaskRejectedInfo' | translate}}
                                </button>
                                <span>
                                    {{task.approvalStatusLog[task.approvalStatusLog.length - 1]?.date | date : 'dd.MM.YYYY'}}
                                    <span class="dot"></span>
                                    {{task.approvalStatusLog[task.approvalStatusLog.length - 1]?.approver}}
                                </span>
                                <div *ngIf="gamification">
                                    <ng-container *ngIf="task.pointsRange === true && task.approvalStatus !== 'rejected'">
                                        <div class="points-range" [ngClass]="{'points-range-change': task.canUserApprove}" (click)="acceptTask(task)">
                                            ({{ task.pointsRangeFrom }}-{{ task.pointsRangeTo }}%) <span>{{ task.userPoints }} pkt</span>
                                        </div>
                                    </ng-container>
                                </div>
                                <span class="badge badge-success"
                                      *ngIf="task.approvalStatusLog[task.approvalStatusLog.length - 1]?.status === 'approved'">
                                    {{'Onboarding.AcceptedTasks'| translate | uppercase}}
                                </span>
                                <span class="badge badge-danger"
                                      *ngIf="task.approvalStatusLog[task.approvalStatusLog.length - 1]?.status === 'rejected'">
                                    {{'Onboarding.RejectedTasks'| translate | uppercase}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="approval-tasks-dialog__empty-box" *ngIf="!task && !preview && !tasks?.length">
                <img src="assets/images/default/empty_page_filter.png" alt="empty_page_filter">
                <span class="empty-tasks__text">{{'Onboarding.ThereAreNoTasksPendingApproval' | translate}}</span>
            </div>

            <div class="approval-tasks-dialog__task" *ngIf="task && !preview">
                <div class="task-player__title">
                    <h1>{{task.name}}</h1>
                </div>

                <div class="task-player__info">
                    <div class="task-player__info--accept">
                        <img src="assets/icons/info.svg"
                             loading="lazy"
                             alt="info">
                        {{(!task.approvalRequired ? 'Onboarding.AcceptTheCompletionOfTheTaskYourself' : 'Onboarding.CompletionOfTheTaskWillBeApprovedByTheOnboardingSupervisor') | translate}}
                    </div>

                    <div class="task-player__info--date" *ngIf="task.startDate">
                        <img src="/assets/icons/calendar.svg" alt="calendar" class="dl-icon">
                        {{'AdminStats.SelectDateFrom' | translate | lowercase}} {{task.startDate  | date : 'dd.MM.YYYY'}}
                    </div>
                </div>

                <div class="task-player__files-to-download" *ngIf="task?.attachements?.length">
                    <mat-expansion-panel hideToggle [expanded]="true">
                        <mat-expansion-panel-header (click)="setExpanded()">
                            <div class="panel-header">
                                <div class="panel-header--arrow-text">
                                    <img src="/assets/icons/blue_arrow_right.svg"
                                         matTooltip="{{'Common.Collapse' | translate}}"
                                         matTooltipPosition="below"
                                         [ngClass]="{'rotate-arrow': expanded}"
                                         matTooltipClass='below-top'
                                         alt="blue_arrow_right">
                                    <span>{{'KnowledgeBase.FileToDownload' | translate}}</span>
                                    <div class="circle-blue">{{task.attachements.length}}</div>
                                </div>

                                <button class="download-button" mat-flat-button
                                        type="button"
                                        (click)="downloadAll();$event.stopPropagation()"
                                        *ngIf="task.attachements.length>1">
                                    <i class="dolineo-icon dolineo-icon-blue dolineo-icon-download"></i>
                                    {{'Common.DownloadAttachments'| translate}}
                                </button>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="files-to-download__row"
                             *ngFor="let att of task.attachements">
                            <i [class]="getIconName(att.mimeType)" style="margin-right: 12px"></i>
                            <span (click)="downloadFile(att)" class="cursor-pointer"
                                  [innerHTML]="att.origFileName"></span>
                        </div>
                    </mat-expansion-panel>
                </div>

                <div class="task-player__content">
                    <p [innerHTML]="task.description | nl2br"></p>
                </div>

                <div *ngIf="task.attachmentRequired" class="task-player__upload">
                    <div class="file-container" *ngIf="filenamePreview">
                        <div class="flex-items">
                            <span> {{ filenamePreview }} </span>
                            <div>
                                <i (click)="downloadInputFile()"
                                   matTooltip="{{'Common.Download' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                   class="dolineo-icon dolineo-icon-download2"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="approval-tasks-dialog__preview" *ngIf="!task && preview && previewOnboarding">
                <div class="preview-onboarding__stages">
                    <div class="stages__line"
                         [ngStyle]="{'margin-bottom' : previewOnboarding.sections[previewOnboarding.sections.length-1].expanded ? '-10px' : '30px'}">
                    </div>
                    <div class="stages__rows">
                        <div class="stage"
                             *ngFor="let stage of previewOnboarding.sections; let i = index">
                            <div class="stage-number"
                                 [ngClass]="{'stage-number__in-progress': stage.elementsDone> 0 && !stage.finished,
                                'stage-number__finished': stage.finished}">
                                {{i + 1}}
                                <img src="/assets/icons/onboarding_stage_disabled.svg"
                                     *ngIf="!stage.isAvailable"
                                     alt="onboarding_stage_disabled">
                            </div>
                            <mat-expansion-panel hideToggle [expanded]="stage.expanded">
                                <mat-expansion-panel-header #panelH
                                                            (click)="panelH._toggle(); stage.expanded !=stage.expanded">
                                    <mat-panel-title>
                                        <div class="stage__image">
                                            <img [src]="'assets/images/onboarding/onboarding_tile_' + i%12 + '.jpg'"
                                                 *ngIf="!stage.cover && !stage.coverPath"
                                                 alt="onboarding_tile">
                                            <img [src]="stage.cover" alt="cover"
                                                 *ngIf="stage.cover">
                                            <img [src]="apiUrl + stage.coverPath" alt="coverPath"
                                                 *ngIf="stage.coverPath && !stage.cover">
                                            <span *ngIf="!stage.cover && !stage.coverPath"
                                                  class="stage__image--name">
                                                {{'OnboardingCreator.Stage'| translate : {number: i + 1} }}
                                            </span>
                                        </div>

                                        <div class="stage-center">
                                            <div class="stage-center__top">
                                                <div class="stage-name">
                                                    {{stage.name}}
                                                </div>
                                            </div>

                                            <div class="stage-center__bottom">
                                                <div class="stage-center__bottom__progress">
                                                    <div class="stage-center__bottom__progress__bar">
                                                        <div class="progress-done"
                                                             [style.width]="((stage.elementsDone/stage.elements.length) * 100) + '%'"></div>
                                                    </div>
                                                    <div class="stage-center__bottom__progress__number">
                                                        <span>{{stage.elementsDone}}</span>
                                                        <span> / {{stage.elements.length}}</span>
                                                    </div>
                                                </div>

                                                <div class="stage-center__bottom__days"
                                                     *ngIf="previewOnboarding.enableDuration && !stage.finished">
                                                    <div class="days__left">
                                                        <img src="assets/icons/calendar-start.svg"
                                                             alt="calendar-start">
                                                    </div>
                                                    <div class="days__right">
                                                        <span>{{'OnboardingCreator.CompleteBy' | translate}}</span>
                                                        <span [ngClass]="{'days__right--red': isPast(stage.dueToDate)}">
                                                            {{stage.dueToDate | date: 'dd.MM.YYYY' }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <ng-container *ngIf="stage.finished">
                                                    <div class="element-finished">
                                                        <img src="assets/icons/checked-without-bg.svg"
                                                             loading="lazy"
                                                             alt="checked-without-bg">
                                                        {{'Onboarding.FinishedAt' | translate | uppercase}}
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="stage__content">
                                    <div class="stage__description" *ngIf="stage.description">
                                        {{stage.description}}
                                    </div>

                                    <div class="stage__element"
                                         *ngFor="let ele of stage.elements; let eleIndex = index;">
                                        <div class="stage__element--left">
                                            <span class="element-name">
                                                {{ele.name}}
                                            </span>
                                            <div class="flex-items">
                                                <ng-container *ngIf="ele.duration !== 0 &&
                                                        ele.duration !== null &&
                                                        ele.objectType !== 'survey' &&
                                                        ele.objectType !== 'task' &&
                                                        ele.duration !== ''">
                                                    {{ele.duration | humanizeTime}}
                                                    <div class="circle"></div>
                                                </ng-container>
                                                {{ displayTrainingType(ele.objectType, ele.trainingType, ele.trainingAction, ele.isSoundMaterial) | translate}}
                                            </div>
                                        </div>

                                        <div class="stage__element--right">
                                            <ng-container>
                                                <button mat-button (click)="downloadAttachment(ele.userAttachment)"
                                                        *ngIf="ele.userAttachment"
                                                        class="configure-table sm mat-button mat-button-base">
                                                    <img src="/assets/icons/download.svg"
                                                         alt="download">
                                                    {{'Onboarding.DownloadAttachment' | translate}}
                                                </button>

                                                <ng-container
                                                        *ngIf="ele.approvalStatus && ele.approvalStatus === 'pending'">
                                                    <button mat-flat-button
                                                            [disabled]="!ele.canUserApprove"
                                                            (click)="acceptTask(ele)"
                                                            class="dolineo-button white-button">{{'Common.AcceptButton' | translate}}</button>
                                                    <button mat-flat-button
                                                            (click)="rejectTask(ele)"
                                                            [disabled]="!ele.canUserApprove"
                                                            class="dolineo-button red-button">{{'Common.RejectButton' | translate}}</button>
                                                </ng-container>

                                                <div class="approval-tasks-dialog__row--buttons__info flex-table"
                                                     *ngIf="ele.approvalStatus && ele.approvalStatus !== 'pending'">
                                                    <span>
                                                        {{ele.approvalStatusLog[ele.approvalStatusLog.length - 1]?.date | date : 'dd.MM.YYYY'}}
                                                        <span class="dot"></span>
                                                        {{ele.approvalStatusLog[ele.approvalStatusLog.length - 1]?.approver}}
                                                    </span>
                                                    <span class="badge badge-success"
                                                          *ngIf="ele.approvalStatusLog[ele.approvalStatusLog.length - 1]?.status === 'approved'">
                                                        {{'Onboarding.AcceptedTasks'| translate | uppercase}}
                                                    </span>
                                                    <span class="badge badge-danger"
                                                          *ngIf="ele.approvalStatusLog[ele.approvalStatusLog.length - 1]?.status === 'rejected'">
                                                        {{'Onboarding.RejectedTasks'| translate | uppercase}}
                                                    </span>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="!ele.finishedDate && !ele.approvalStatus">
                                                <img src="assets/icons/calendar-start.svg" alt="calendar-start"
                                                     class="calendar-start"
                                                     *ngIf="ele.accessType ==='choosen_date' && !ele.isAvailable">
                                                <span *ngIf="ele.accessType ==='choosen_date' && !ele.isAvailable">
                                                    {{ele.startDate | date: 'dd.MM.YYYY'}}
                                                </span>
                                            </ng-container>

                                            <ng-container *ngIf="ele.finishedDate && !ele.approvalStatus">
                                                <div class="element-finished">
                                                    <img src="assets/icons/checked-without-bg.svg"
                                                         loading="lazy"
                                                         alt="checked-without-bg">
                                                    {{'Onboarding.FinishedAt' | translate | uppercase}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <button mat-button (click)="panelH._toggle(); stage.expanded = !stage.expanded"
                                    class="stage__toggle"
                                    matTooltip="{{(panelH._isExpanded()? 'Common.Collapse': 'Common.Expand') | translate}}"
                                    matTooltipPosition="below"
                                    matTooltipClass='below-top'
                                    type="button">
                                <img src="assets/icons/arrow_down_gray.svg"
                                     [ngClass]="{'rotate-arrow': panelH._isExpanded()}"
                                     [ngStyle]="{'top': panelH._isExpanded() ? '1px' : '0px'}"
                                     alt="arrow-full-right">
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="approval-tasks-dialog__preview approval-tasks-dialog__preview-gamification" *ngIf="!task && preview && previewGamification">
                <div class="preview-onboarding__stages">
                    <div class="stages__line"
                         [ngStyle]="{'margin-bottom' : previewGamification.sections[previewGamification.sections.length-1].expanded ? '-10px' : '30px'}">
                    </div>
                    <div class="stages__rows">
                        <div class="stage"
                             *ngFor="let stage of previewGamification.sections; let i = index">
                            <div class="stage-number"
                                 [ngClass]="{'stage-number__in-progress': stage.elementsDone> 0 && !stage.finished,
                                'stage-number__finished': stage.finished}">
                                {{i + 1}}
                                <img src="/assets/icons/onboarding_stage_disabled.svg"
                                     *ngIf="!stage.isAvailable"
                                     alt="onboarding_stage_disabled">
                            </div>
                            <mat-expansion-panel hideToggle [expanded]="stage.expanded">
                                <mat-expansion-panel-header #panelH
                                                            (click)="panelH._toggle(); stage.expanded !=stage.expanded">
                                    <mat-panel-title>
                                        <div class="stage__image">
                                            <img [src]="'assets/images/gamification/gamification_tile-' + i%19 + '.jpg'"
                                                 *ngIf="!stage.cover && !stage.coverPath"
                                                 alt="onboarding_tile">
                                            <img [src]="stage.cover" alt="cover"
                                                 *ngIf="stage.cover">
                                            <img [src]="apiUrl + stage.coverPath" alt="coverPath"
                                                 *ngIf="stage.coverPath && !stage.cover">
                                            <span *ngIf="!stage.cover && !stage.coverPath"
                                                  class="stage__image--name">
                                    </span>
                                        </div>

                                        <div class="stage-center">
                                            <div class="stage-center__top">
                                                <div class="stage-name">
                                                    {{stage.name}}
                                                </div>
                                            </div>

                                            <div class="stage-center__bottom">
                                                <div class="stage-center__bottom__progress">
                                                    <div class="stage-center__bottom__progress__bar">
                                                        <div class="progress-done"
                                                             [style.width]="((stage.elementsDone/stage.elements.length) * 100) + '%'"></div>
                                                    </div>
                                                    <div class="stage-center__bottom__progress__number">
                                                        <span>{{stage.elementsDone}}</span>
                                                        <span> / {{stage.elements.length}}</span>
                                                    </div>
                                                    <div class="stage-center__bottom__progress__points">
                                                        <span>{{ stage.userTotalPoints }}</span>
                                                        <span> / {{stage.totalPoints}}</span>
                                                    </div>
                                                </div>

                                                <div class="stage-center__bottom__days"
                                                     *ngIf="previewGamification.enableDuration && !stage.finished">
                                                    <div class="days__left">
                                                        <img src="assets/icons/calendar-start.svg"
                                                             alt="calendar-start">
                                                    </div>
                                                    <div class="days__right">
                                                        <span>{{'OnboardingCreator.CompleteBy' | translate}}</span>
                                                        <span [ngClass]="{'days__right--red': isPast(stage.dueToDate)}">
                                                            {{stage.dueToDate | date: 'dd.MM.YYYY' }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <ng-container *ngIf="stage.finished">
                                                    <div class="element-finished">
                                                        <img src="assets/icons/checked-without-bg.svg"
                                                             loading="lazy"
                                                             alt="checked-without-bg">
                                                        {{'Onboarding.FinishedAt' | translate | uppercase}}
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="stage__content">
                                    <div class="stage__description" *ngIf="stage.description">
                                        {{stage.description}}
                                    </div>

                                    <div class="stage__element"
                                         *ngFor="let ele of stage.elements; let eleIndex = index;">
                                        <div class="stage__element--left">
                                            <span class="element-name">
                                                {{ele.name}}
                                            </span>
                                            <div class="flex-items">
                                                <ng-container *ngIf="ele.duration !== 0 &&
                                                        ele.duration !== null &&
                                                        ele.objectType !== 'survey' &&
                                                        ele.objectType !== 'task' &&
                                                        ele.duration !== ''">
                                                    {{ele.duration | humanizeTime}}
                                                    <div class="circle"></div>
                                                </ng-container>
                                                {{ displayTrainingType(ele.objectType, ele.trainingType, ele.trainingAction, ele.isSoundMaterial) | translate}}
                                            </div>
                                        </div>

                                        <div class="stage__element--right">
                                            <ng-container>
                                                <button mat-button (click)="downloadAttachment(ele.userAttachment)"
                                                        *ngIf="ele.userAttachment"
                                                        class="configure-table sm">
                                                    <img src="/assets/icons/download.svg"
                                                         alt="download">
                                                    {{'Onboarding.DownloadAttachment' | translate}}
                                                </button>

                                                <ng-container
                                                        *ngIf="ele.approvalStatus && ele.approvalStatus === 'pending'">
                                                    <button mat-flat-button
                                                            [disabled]="!ele.canUserApprove"
                                                            (click)="acceptTask(ele)"
                                                            class="dolineo-button white-button">{{'Common.AcceptButton' | translate}}</button>
                                                    <button mat-flat-button
                                                            (click)="rejectTask(ele)"
                                                            [disabled]="!ele.canUserApprove"
                                                            class="dolineo-button red-button">{{'Common.RejectButton' | translate}}</button>
                                                </ng-container>

                                                <div class="approval-tasks-dialog__row--buttons__info flex-table"
                                                     *ngIf="ele.approvalStatus && ele.approvalStatus !== 'pending'">
                                                    <span>
                                                        {{ele.approvalStatusLog[ele.approvalStatusLog.length - 1]?.date | date : 'dd.MM.YYYY'}}
                                                        <span class="dot"></span>
                                                        {{ele.approvalStatusLog[ele.approvalStatusLog.length - 1]?.approver}}
                                                    </span>
                                                    <span class="badge badge-success"
                                                          *ngIf="ele.approvalStatusLog[ele.approvalStatusLog.length - 1]?.status === 'approved'">
                                                        {{'Onboarding.AcceptedTasks'| translate | uppercase}}
                                                    </span>
                                                    <span class="badge badge-danger"
                                                          *ngIf="ele.approvalStatusLog[ele.approvalStatusLog.length - 1]?.status === 'rejected'">
                                                        {{'Onboarding.RejectedTasks'| translate | uppercase}}
                                                    </span>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="!ele.finishedDate && !ele.approvalStatus">
                                                <img src="assets/icons/calendar-start.svg" alt="calendar-start"
                                                     class="calendar-start"
                                                     *ngIf="ele.accessType ==='choosen_date' && !ele.isAvailable">
                                                <span *ngIf="ele.accessType ==='choosen_date' && !ele.isAvailable">
                                                    {{ele.startDate | date: 'dd.MM.YYYY'}}
                                                </span>
                                            </ng-container>

                                            <ng-container *ngIf="ele.finishedDate && !ele.approvalStatus">
                                                <div class="element-finished">
                                                    <img src="assets/icons/checked-without-bg.svg"
                                                         loading="lazy"
                                                         alt="checked-without-bg">
                                                    {{'Onboarding.FinishedAt' | translate | uppercase}}
                                                </div>
                                            </ng-container>
                                            <div class="element-points element-points-finished" [ngClass]="{'element-points-finished' : ele.finishedDate}">
                                                +{{ ele.finishedDate ? ele.userPoints : ele.maxPoints }} pkt
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <button mat-button (click)="panelH._toggle(); stage.expanded = !stage.expanded"
                                    class="stage__toggle"
                                    matTooltip="{{(panelH._isExpanded()? 'Common.Collapse': 'Common.Expand') | translate}}"
                                    matTooltipPosition="below"
                                    matTooltipClass='below-top'
                                    type="button">
                                <img src="assets/icons/arrow_down_gray.svg"
                                     [ngClass]="{'rotate-arrow': panelH._isExpanded()}"
                                     [ngStyle]="{'top': panelH._isExpanded() ? '1px' : '0px'}"
                                     alt="arrow-full-right">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Close' | translate}}</button>

        <!--
        <div class="navigate-buttons" *ngIf="showButtons">
            <button mat-button
                    (click)="previousUser()"
                    class="dolineo-button blue-button">
                <img src="assets/icons/arrow_left.svg" alt="arrow_left"/>
            </button>
            <span> {{approvalOnboarding.userIndex}} {{'Common.Of' | translate}} {{approvalOnboarding.total}} </span>
            <button mat-button
                    (click)="nextUser()"
                    class="dolineo-button blue-button">
                <img src="assets/icons/arrow_right.svg" alt="arrow_right"/>
            </button>
        </div>
        -->
    </div>
</div>
