import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Directive({
    selector: '[appDataGridScroll]'
})
export class DataGridScrollDirective implements AfterViewChecked {
    el: ElementRef;

    constructor(el: ElementRef, private deviceService: DeviceDetectorService) {
        this.el = el;
    }

    @HostListener('scroll', ['$event.target'])
    onWindowScroll() {
        const $body = $(this.el.nativeElement).get(0);
        const $header = $($body.parentElement).find('.ft-head').get(0);
        const swidth = $body.scrollWidth - $body.offsetWidth;
        $header.scrollLeft = $body.scrollLeft;
        if (this.deviceService.isDesktop()) {
            $($header).css('padding-right', `${swidth}px`);
        }
        $($header).css('overflow-y', `hidden`);
    }

    ngAfterViewChecked(): void {
        const $body = $(this.el.nativeElement).get(0);
        const $header = $($body.parentElement).find('.ft-head').get(0);
        const swidth = $body.offsetWidth - $body.scrollWidth;
        $($header).css('overflow-y', `hidden`);
        if (this.deviceService.isDesktop()) {
            $($header).css('padding-right', `${swidth}px`);
        }

        if (!$($body.parentElement).find('.ft-row').get(0)) {
            $($header).css('overflow-x', `auto`);
            $($header).css('overflow-y', `hidden`);
            $($header).css('scrollbar-width', `auto`);
        } else {
            $($header).css('overflow-x', `hidden`);
            $($header).css('overflow-y', `scroll;`);
            $($header).css('scrollbar-width', `none`);
        }
    }
}
