<div class="approve-points-task-dialog">
    <div>
        <div class="approve-points-task-dialog__title">{{ 'Task.ApproveTaskPercentage' | translate}}</div>
        <div class="approve-points-task-dialog__ranges">
            <div class="approve-points-task-dialog__ranges__range" *ngFor="let range of points" (click)="select(range)">
                <span class="approve-points-task-dialog__ranges__range__selected">
                    <img src="assets/icons/checked.svg" *ngIf="range.selected"/>
                </span>
                <span class="approve-points-task-dialog__ranges__range__from-to">
                    ({{ range.valueFrom }}-{{ range.valueTo }}%)
                </span>
                <span class="approve-points-task-dialog__ranges__range__spacer">
                    <img src="assets/icons/icon-arrow-to.svg" />
                </span>
                <span class="approve-points-task-dialog__ranges__range__points">
                    {{ range.points }} pkt
                </span>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close(false)" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button mat-button color="primary" (click)="close()" [disabled]="!selected"
                class="dolineo-button blue-button">{{'Common.IConfirm' | translate}}</button>
    </div>
</div>
