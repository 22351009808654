import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LayoutService {
    private refreshTableHeader = new Subject<boolean>();
    private preloaderStateSource = new Subject<string>();

    updatePreloaderState(state: string) {
        this.preloaderStateSource.next(state);
    }

    private echartsSource = new Subject<boolean>();
    echartsState$ = this.echartsSource.asObservable();

    updateEChartsState(state: boolean) {
        this.echartsSource.next(state);
    }

    setRefreshTableHeader(status: boolean) {
        this.refreshTableHeader.next(status);
    }

    getRefreshTableHeader(): Observable<boolean> {
        return this.refreshTableHeader.asObservable();
    }
}