import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'training-needs',
  templateUrl: './training-needs.component.html',
  styleUrls: ['./training-needs.component.scss']
})

export class TrainingNeedsComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  defaultElmentsColor: string = '14ad4b';
  isMobile: boolean;

  constructor(private _helper: HelperService,
              private deviceService: DeviceDetectorService) {
      this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    this.prepareWidgetData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
          sizeClass: this.isMobile ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

}