import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'humanizeTime'
})
export class HumanizeTimePipe implements PipeTransform {

    transform(value: number, withoutMinutes: boolean = false, hasSecond: boolean = false): string {
        if (!withoutMinutes) {
            if (value == 0) {
                return '0m';
            } else {
                const hours = Math.floor(value / 60);
                const min = value % 60;
                return `${hours ? hours + 'h' : ''} ${min ? min + 'm' : ''}`;
            }
        } else {
            if (value == 0) {
                return '00:00';
            } else if (hasSecond) {
                const min = Math.floor(value / 60);
                const seconds = Math.floor(value % 60);
                return `${min ? (min < 10 ? '0' + min : min) : '00'}${seconds ? ':' + (seconds < 10 ? '0' + seconds : seconds) : ':00'} `;
            } else {
                const hours = Math.floor(value % 60);
                const min = value % 60;
                return `${hours ? (hours < 10 ? '0' + hours : hours) + ':' : ''}${min ? (min < 10 ? '0' + min : min) : '00'}:00 `;
            }
        }
    }
}
