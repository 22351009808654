import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-gauge-chart',
    templateUrl: './gauge-chart.component.html',
    styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent {

    @Input() set data(obj: any) {
        this.percent = `background-image: conic-gradient(#EBEBEB ${(100 - obj.percent) * 2.87}deg, transparent 0); `;
        this.color = `background: ${obj.color}`;
        this.leftPosition = `left: ${obj.left}%;`;
    }

    percent: string;
    color: string;
    leftPosition: string;
}
