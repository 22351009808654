<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>
<div class="survey-other-answers">
    <h1 mat-dialog-title tabIndex="0">
        {{'PoolStats.Answers' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="row">
            <div class="col question-number">
            <span class="question-number--blue"
                  *ngIf="question.questionType !== 'spacer_text'">{{ currentQuestionIndex + 1 }}.</span>
                <span class="badge">{{ displayQuestionType(question.questionType) | translate}}</span>
            </div>
        </div>

        <div class="survey-other-answers__title">
            {{question.question}}
        </div>

        <div class="answers-container">
            <div *ngFor="let other of question.otherAnswers; let aIdx = index"
                 class="answers-container__row"
                 [ngClass]="{'odd': aIdx%2 == 0}">
                <span>{{aIdx + 1 }} </span>
                <span>{{other.answer}}</span>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button mat-button color="primary" (click)="dialogRef.close()"
                class="dolineo-button blue-button">{{'Common.Close' | translate}}</button>
    </div>
</div>