import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-download-dialog',
    templateUrl: './download-dialog.component.html',
    styleUrls: ['./download-dialog.component.scss']
})
export class DownloadDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<DownloadDialogComponent>) {
    }

    download() {
        const cols = this.data.filter(val => val.selected);

        this.dialogRef.close(cols);
    }

    selectAll(event:MatCheckboxChange) {
        this.data.forEach(val => val.selected = event.checked);
    }
}
