<div class="attachment">
    <h1 mat-dialog-title tabIndex="0">
        {{'Common.AddArtwork' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="form-group">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <div class="upload-file">
                            <button mat-flat-button (click)="selectFile()"
                                    [ngClass]="{'opacity-30 cursor-default': filenamePreview}">
                                <i class="dolineo-icon dolineo-icon-download2"
                                   style="margin-bottom: 5px; margin-right: 5px;"></i>{{ (data.loadTextButton ? data.loadTextButton : 'Common.LoadFile') | translate }}
                            </button>
                            <input type="file" #file style="display: none;" (change)="onFileChange()"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!filenamePreview">
                <div class="col-12">
                    <ngx-file-drop dropZoneLabel="{{ 'Common.UploadText' | translate }}"
                                   (click)="selectFile()"
                                   class="cursor-pointer"
                                   (onFileDrop)="dropped($event)"
                                   multiple="false"
                                   directory="false">
                    </ngx-file-drop>
                </div>
            </div>

            <div class="file-container" *ngIf="filenamePreview">
                <div class="flex-items">
                    <span> {{ filenamePreview }} </span>
                    <div>
                        <i (click)="downloadFile()"
                           matTooltip="{{'Common.Download' | translate}}"
                           matTooltipPosition="above"
                           matTooltipClass='above'
                           class="dolineo-icon dolineo-icon-download2"></i>
                        <i (click)="removeFile()"
                           matTooltip="{{'Common.Delete' | translate}}"
                           matTooltipPosition="above"
                           matTooltipClass='above'
                           class="dolineo-icon dolineo-icon-trash"></i>
                    </div>
                </div>
                <div *ngIf="fileError" class="error-file">
                    <mat-error>{{ fileErrorMessage | translate }}</mat-error>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" [mat-dialog-close]="false" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button mat-button color="primary" (click)="save()" [disabled]="fileError"
                class="dolineo-button blue-button">{{'Common.Add' | translate}}</button>
    </div>
</div>
