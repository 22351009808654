<div class="training-path-visibility">
    <h1 mat-dialog-title tabIndex="0">
        <span>
            {{ translations.title | translate}}
            <span style="color: #7D8485">{{data.name | capitalize}}</span>
        </span>
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="close()"></i>
    </h1>

    <div mat-dialog-content>
        <mat-accordion>
            <mat-expansion-panel [expanded]="step === 0" hideToggle *ngIf="hasUsers">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(0, 'users'); $event.stopPropagation()">
                        <div class="panel-header--left">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 0? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 0}">
                            {{'Common.Users' | translate | uppercase}}
                        </div>

                        <div class="panel-header--right" *ngIf="step !== 0">
                            <span style="font-weight: 500">{{'TrainingPathVisibility.WithAccess' | translate }}</span>
                            <div class="number-selected">
                                {{availabilityUsers.length}}
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids">
                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'TrainingPathVisibility.WithoutAccess' | translate}}</span>
                        </div>
                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="usersColumns"
                                [items]="usersItems"
                                (itemCheckboxSelected)="selectedAddItems($event,'users')"
                                [refreshSelectedItemsArray]="usersSelectedToAdd.length === 0">
                        </app-table-with-data>
                    </div>

                    <div class="data-grids--buttons">
                        <button mat-button color="primary"
                                [disabled]="usersSelectedToAdd.length === 0"
                                (click)="addAvailable('users')"
                                class="dolineo-button blue-button">
                            {{'Common.Add' | translate}}
                            <img src="/assets/icons/arrow_right.svg" alt="arrow_right">
                        </button>

                        <button mat-button color="primary"
                                [disabled]="usersSelectedToRemove.length === 0"
                                (click)="removeAvailable('users')"
                                class="dolineo-button blue-button">
                            <img src="/assets/icons/arrow_left.svg" alt="arrow_left">
                            {{'Common.Delete' | translate}}
                        </button>
                    </div>

                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'TrainingPathVisibility.WithAccess' | translate }}</span>
                            <div class="number-selected">
                                {{availabilityUsers.length}}
                            </div>
                        </div>

                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="usersColumnsAvailability"
                                [items]="availabilityUsers"
                                (itemCheckboxSelected)="selectedRemoveItems($event, 'users')"
                                [refreshSelectedItemsArray]="usersSelectedToRemove.length === 0">
                        </app-table-with-data>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 1" hideToggle
                                 *ngIf="hasPositions">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(1, 'positions'); $event.stopPropagation()">
                        <div class="panel-header--left">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 1? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 1}">
                            {{'AssignDialog.Positions' | translate | uppercase}}
                        </div>

                        <div class="panel-header--right" *ngIf="step !== 1">
                            <span style="font-weight: 500">{{'TrainingPathVisibility.WithAccess' | translate }}</span>
                            <div class="number-selected">
                                {{availabilityPositions.length}}
                            </div>
                            <span class="cut-text">{{availabilityPositions | showAtMost: 'name'}}</span>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids">
                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'TrainingPathVisibility.WithoutAccess' | translate}}</span>
                            <span class="cut-text">{{availabilityPositions | showAtMost: 'name'}}</span>
                        </div>
                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="positionsColumns"
                                [items]="positionsItems"
                                (itemCheckboxSelected)="selectedAddItems($event,'positions')"
                                [refreshSelectedItemsArray]="positionsSelectedToAdd.length === 0">
                        </app-table-with-data>
                    </div>

                    <div class="data-grids--buttons">
                        <button mat-button color="primary"
                                [disabled]="positionsSelectedToAdd.length === 0"
                                (click)="addAvailable('positions')"
                                class="dolineo-button blue-button">
                            {{'Common.Add' | translate}}
                            <img src="/assets/icons/arrow_right.svg" alt="arrow_right">
                        </button>

                        <button mat-button color="primary"
                                [disabled]="positionsSelectedToRemove.length === 0"
                                (click)="removeAvailable('positions')"
                                class="dolineo-button blue-button">
                            <img src="/assets/icons/arrow_left.svg" alt="arrow_left">
                            {{'Common.Delete' | translate}}
                        </button>
                    </div>

                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'TrainingPathVisibility.WithAccess' | translate }}</span>
                            <div class="number-selected">
                                {{availabilityPositions.length}}
                            </div>
                            <span class="cut-text">{{availabilityPositions | showAtMost: 'name'}}</span>
                        </div>
                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="positionsColumnsAvailability"
                                [items]="availabilityPositions"
                                (itemCheckboxSelected)="selectedRemoveItems($event,'positions')"
                                [refreshSelectedItemsArray]="positionsSelectedToRemove.length === 0">
                        </app-table-with-data>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 2" hideToggle
                                 *ngIf="hasGroups">
                <mat-expansion-panel-header>
                    <div class="panel-header" (click)="setStep(2, 'groups'); $event.stopPropagation()">
                        <div class="panel-header--left">
                            <img src="/assets/icons/blue_arrow_right.svg"
                                 matTooltip="{{(step === 2? 'Common.Collapse': 'Common.Expand') | translate}}"
                                 matTooltipPosition="below"
                                 matTooltipClass='below-top'
                                 alt="blue_arrow_right"
                                 [ngClass]="{'rotate-arrow': step === 2}">
                            {{'AssignDialog.Groups' | translate | uppercase}}
                        </div>

                        <div class="panel-header--right" *ngIf="step !== 2">
                            <span style="font-weight: 500">{{'TrainingPathVisibility.WithAccess' | translate }}</span>
                            <div class="number-selected">
                                {{availabilityGroups.length}}
                            </div>
                            <span class="cut-text">{{availabilityGroups | showAtMost: 'groupName'}}</span>
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <div class="data-grids">
                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'TrainingPathVisibility.WithoutAccess' | translate}}</span>
                        </div>
                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="groupsColumns"
                                [items]="groupsItems"
                                (itemCheckboxSelected)="selectedAddItems($event,'groups')"
                                [refreshSelectedItemsArray]="groupsSelectedToAdd.length === 0">
                        </app-table-with-data>
                    </div>

                    <div class="data-grids--buttons">
                        <button mat-button color="primary"
                                [disabled]="groupsSelectedToAdd.length === 0"
                                (click)="addAvailable('groups')"
                                class="dolineo-button blue-button">
                            {{'Common.Add' | translate}}
                            <img src="/assets/icons/arrow_right.svg" alt="arrow_right">
                        </button>

                        <button mat-button color="primary"
                                [disabled]="groupsSelectedToRemove.length === 0"
                                (click)="removeAvailable('groups')"
                                class="dolineo-button blue-button">
                            <img src="/assets/icons/arrow_left.svg" alt="arrow_left">
                            {{'Common.Delete' | translate}}
                        </button>
                    </div>

                    <div class="data-grids--column">
                        <div class="data-grids--column--header">
                            <span>{{'TrainingPathVisibility.WithAccess' | translate }}</span>
                            <div class="number-selected">
                                {{availabilityGroups.length}}
                            </div>
                            <span class="cut-text">{{availabilityGroups | showAtMost: 'groupName'}}</span>
                        </div>

                        <app-table-with-data
                                highlightActiveBox
                                [show]="true"
                                [columns]="groupsColumnsAvailability"
                                [items]="availabilityGroups"
                                (itemCheckboxSelected)="selectedRemoveItems($event, 'groups')"
                                [refreshSelectedItemsArray]="groupsSelectedToRemove.length === 0">
                        </app-table-with-data>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <div class="dialog-footer--left">
            <span (click)="showUsers()" class="dialog-footer--preview"
                  [ngClass]="{'dialog-footer--preview__gray': !userToPreview.length}">
                <img src="/assets/icons/show_user.svg" alt="show_user">
                {{'AssignDialog.ViewUsersWithAccess' | translate}}
            </span>

            <span class="number-selected">
                {{userToPreview.length}}
            </span>
        </div>

        <button (click)="close()"
                mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Close' | translate}}</button>
    </div>
</div>
