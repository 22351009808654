import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {CookieService} from '../../shared/services/cookie.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(public auth: AuthService,
                public router: Router,
                private cookie: CookieService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        if (!this.auth.isAuthenticated) {
            sessionStorage.setItem('url', state.url)
            this.router.navigate(['login']);
            localStorage.clear();
            this.cookie.deleteAll();
            return false;
        }
        return true;
    }
}
