<div class="assign-dialog">
    <h1 mat-dialog-title tabIndex="0">
        <span>
            {{ translations.title | translate }}
            <span style="color: #7D8485">{{data.name | capitalize}}</span>
        </span>

        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content *ngIf="hasUsers">
        <div class="data-grids">
            <div class="data-grids--column">
                <div class="data-grids--column--header">
                    <span>{{'AssignDialog.Unassigned' | translate}}</span>
                </div>
                <app-table-with-data
                        highlightActiveBox
                        [show]="true"
                        [columns]="usersColumns"
                        [items]="unassignUsers"
                        [refreshSelectedItemsArray]="selectedToAdd.length === 0"
                        (itemCheckboxSelected)="selectedAddItems($event)">
                </app-table-with-data>
            </div>

            <div class="data-grids--buttons">
                <button mat-button color="primary"
                        [disabled]="selectedToAdd.length === 0"
                        (click)="assign('AssignDialog.AssignUsers', 'AddRemoveVisibility.SelectedUsers')"
                        class="dolineo-button blue-button">
                    {{'Common.Assign' | translate}}
                    <img src="/assets/icons/arrow_right.svg" alt="arrow_right"/>
                </button>

                <button mat-button color="primary"
                        [disabled]="selectedToRemove.length === 0"
                        (click)="unAssign('AssignDialog.UnassignUsers', 'AddRemoveVisibility.SelectedUsers')"
                        class="dolineo-button blue-button">
                    <img src="/assets/icons/arrow_left.svg" alt="arrow_left"/>
                    {{'Common.Unassign' | translate}}
                </button>
            </div>

            <div class="data-grids--column">
                <div class="data-grids--column--header">
                    <div style="display: flex; align-items: center">
                        <span style="min-width: 75px;">{{'AssignDialog.Assigned' | translate }}</span>
                        <div class="number-selected">
                            {{assignedUsers.length}}
                        </div>
                    </div>
                </div>

                <app-table-with-data
                        highlightActiveBox
                        [show]="true"
                        [columns]="usersColumnsAvailability"
                        [items]="assignedUsers"
                        [refreshSelectedItemsArray]="selectedToRemove.length === 0"
                        (itemCheckboxSelected)="selectedRemoveItems($event)">
                </app-table-with-data>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <div class="dialog-footer--left">
        </div>

        <button (click)="dialogRef.close()"
                mat-button color="primary"
                class="dolineo-button blue-button">
            {{'Common.Close' | translate}}
        </button>
    </div>
</div>
