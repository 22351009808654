import {Component, OnInit, Input, OnChanges, SimpleChanges, HostListener} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {RequestInterface} from '../../intefaces/pagination.interface';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'new-employees',
  templateUrl: './new-employees.component.html',
  styleUrls: ['./new-employees.component.scss']
})

export class NewEmployeesComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.prepareWidgetData();
  }

  widget: any;
  waiting = true;
  columns: any[] = [];
  tableItems: any[] = [];
  isMobile: boolean;
  innerWidth = window.innerWidth;

  public request: RequestInterface = {
    sort: {
      field: 'id',
      direction: 'DESC'
    },
    pagination: {
      pageNumber: 1,
      pageSize: 50,
      totalElementsCount: 0,
      totalPages: 0
    },
    waitingForData: false
  };

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _router: Router,
              private _translate: TranslateService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    this.createTable();
    if(!this.preview){
      this.getData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  getData(filter = ''): void {
    this.request.waitingForData = true;
    if(this.preview){
      this.request.pagination.totalElementsCount = 5;
      this.request.pagination.totalPages = 1;
      this.request.waitingForData = false;
    }else{
      this._api.Dashboard.getNewEmployeesData().subscribe((res) => {
        this.tableItems = res;
        this.waiting = false;
        this.prepareWidgetData()
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getExampleData(){

  const tableItems = [];
  tableItems.push(
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName5'),
        position: this._translate.instant('DashboardWidgetExample.Position1'),
        date: '02.09.2024'
      },
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName6'),
        position: this._translate.instant('DashboardWidgetExample.Position2'),
        date: '03.09.2024'
      },
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName7'),
        position: this._translate.instant('DashboardWidgetExample.Position3'),
        date: '04.09.2024'
      },
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName9'),
        position: this._translate.instant('DashboardWidgetExample.Position4'),
        date: '10.09.2024'
      },
      {
        avatarPath: '',
        user: this._translate.instant('DashboardWidgetExample.UserName10'),
        position: this._translate.instant('DashboardWidgetExample.Position5'),
        date: '20.09.2024'
      },
  );
  this.tableItems = tableItems;
  }

  createTable() {
    this.columns = [
      {
        index: 'avatarPath',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: this.isMobile ? false : true,
        type: 'AVATAR',
        css: {
          'padding-left': '10px',
          'min-width': '45px',
          'background': '#fff',
        },
        userName: (v) => {
          return v.user;
        },
        size: () => {
          return this.isMobile || this.innerWidth < 1100 ? 28 : 50;
        },
        isActive: () => {
          return false
        },
      },
      {
        index: 'user',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '200px',
          'background': '#fff',
        },
        render: (v) => {
          return this.isMobile ? '<span class="new-employees-name">' + v.user + '</span>' +
              '<span class="new-employees-second-item">' +
              '<span class="new-employees-date">' + this._translate.instant('DashboardWidgetNewEmployees.From') + ' ' + v.date + '</span>' +
              (v.position ? '<span class="new-employees-dot"></span>' : '') +
              '<span class="new-employees-date">' + v.position + '</span>' +
              '</span>'
              : '<span class="user-item">' + v.user + '</span>' + '<span class="position-item">' + v.position + '</span>';
        },
        class: this.isMobile ? 'ft-auto new-employees-mobile' : 'ft-auto',
      },
      {
        index: 'date',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: this.isMobile ? false : true,
        type: 'STRING',
        css: {
          'padding-left': '10px',
          'min-width': '120px',
          'background': '#fff',
        },
        render: (v)=>{
          return this._translate.instant('DashboardWidgetNewEmployees.From') + ' ' + v.date
        }
      },
    ]
  }
}