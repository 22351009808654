import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'yellowSearchText'
})
export class YellowSearchTextPipe implements PipeTransform {
    constructor() {
        //
    }

    transform(text: string = '', searchText: string = '', withHtmlTag: boolean = false): string | SafeHtml {
        return withHtmlTag? this.textWithHtmlTag(text, searchText) : this.textWithoutHtmlTag(text, searchText)
    }

    textWithoutHtmlTag(text: string = '', searchText: string = '') {
        const textUpper = text.replaceAll('&nbsp;', ' ').toUpperCase();
        const searchTextUpper = searchText.toUpperCase();
        text = text.replaceAll('&nbsp;', ' ')
        if (textUpper.includes(searchTextUpper) && !!searchText) {
            const indexes = [...textUpper.matchAll(new RegExp(searchTextUpper, 'gi'))].map(a => a.index).reverse();
            indexes.forEach(value => {
                const originalWord = text.slice(value, value + searchText.length);
                text = text.substring(0, value) + `<span style="background-color: yellow">${originalWord}</span>` + text.substring(value + searchText.length);
            })
        }

        return text;
    }


    textWithHtmlTag(text: string = '', searchText: string = '') {
        const searchTextUpper = searchText.replaceAll('&nbsp;', ' ').toUpperCase();
        const splitSearchTextUpper = [...searchTextUpper]
        text = text.replaceAll('&nbsp;', ' ').trim()
        let textUpper = text.toUpperCase();
        let word = '';

        const cleanText = textUpper.replace(/<[^>]+>/g, '');

        if (cleanText.includes(searchTextUpper)) {
            splitSearchTextUpper.forEach((val, index) => {
                word = word + val
                if (textUpper.includes(word)) {
                    if (!textUpper.includes(word + splitSearchTextUpper[index + 1])) {
                        const indexes = [...textUpper.matchAll(new RegExp(word, 'gi'))].map(a => a.index).reverse();
                        indexes.forEach(value => {
                            const originalWord = text.slice(value, value + word.length);
                            text = text.substring(0, value) + `<span style="background-color: yellow">${originalWord}</span>` + text.substring(value + word.length);
                        })
                        if (word === searchTextUpper) {
                            word = '';
                        }
                    }
                }

                textUpper = text.toUpperCase();
            })
        }

        return text;
    }
}
