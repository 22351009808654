import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-reorder-elements',
    templateUrl: './reorder-elements.component.html',
    styleUrls: ['./reorder-elements.component.scss']
})
export class ReorderElementsComponent implements OnInit {

    title: string = ''
    elements: { id: number, name: string }[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ReorderElementsComponent>) {
        this.title = data.title;
        this.elements = data.elements;
    }

    ngOnInit(): void {
        //
    }

    drop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.elements, event.previousIndex, event.currentIndex);
    }

    save() {
        const idArray = this.elements.map(val => val.id)
        this.dialogRef.close(idArray);
    }

    removeTooltipWhenDrag() {
        $(document).find('.below-top').css('display', 'none');
    }

    moveToTop(element: { id: number, name: string }, index: number) {
        this.elements.splice(index, 1)
        this.elements.unshift(element)
    }
}
