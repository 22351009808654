import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
    selector: '[appResizableWidth]'
})

export class ResizableWidthDirective implements OnInit {

    @Input() resizableGrabWidth: number = 24;
    @Input() resizableMinWidth: number = 10;
    @Input() resizableMaxWidth: number = 10;
    @Input() classResize: string = '';

    dragging = false;

    constructor(private el: ElementRef) {

        function preventGlobalMouseEvents() {
            document.body.style.cursor = 'col-resize';
        }

        function restoreGlobalMouseEvents() {
            document.body.style.cursor = 'default';
        }

        const newWidth = (wid) => {
            const newWidth = this.resizableMaxWidth < Math.max(this.resizableMinWidth, wid) ? this.resizableMaxWidth : Math.max(this.resizableMinWidth, wid);
            el.nativeElement.style.maxWidth = `${newWidth}px`;
            if (this.classResize) {
                $(this.classResize).css('max-width', `calc(100% - ${newWidth}px)`)
            }
        }

        const mouseMoveG = (evt) => {
            if (!this.dragging) {
                return;
            }
            newWidth(evt.clientX - el.nativeElement.offsetLeft)
            evt.stopPropagation();
        };

        const mouseUpG = (evt) => {
            if (!this.dragging) {
                return;
            }
            restoreGlobalMouseEvents();
            this.dragging = false;
            evt.stopPropagation();
        };

        const mouseDown = (evt) => {
            if (evt?.target.classList.contains('resize-width')) {
                preventGlobalMouseEvents();
                this.dragging = true;
                evt.stopPropagation();
            }
        };

        document.addEventListener('mousemove', mouseMoveG, true);
        document.addEventListener('mouseup', mouseUpG, true);
        el.nativeElement.addEventListener('mousedown', mouseDown, true);
    }

    ngOnInit(): void {
        this.el.nativeElement.style['border-right'] = this.resizableGrabWidth + 'px solid transparent';
    }
}
