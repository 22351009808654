<div class="feedback-compare feedback-compare__container" [ngClass]="{'no-data': data.report.areas.length === 0}" *ngIf="data">
    <div class="top-section">
        <div class="white-box questionnaires" *ngIf="data.report.areas.length !== 0">
            <div class="white-box__title">
                <h1>{{ 'CompareFeedbacks.ComparedQuestionnaires' | translate }}</h1>

                <button mat-button
                        type="button"
                        matTooltip="{{'Common.More' | translate}}"
                        matTooltipPosition="below"
                        matTooltipClass='below-top'
                        (click)="changeOrder()"
                >
                    <img src="/assets/icons/dot_v.svg"
                         loading="lazy"
                         alt="dot_v">
                </button>
            </div>
            <div class="white-box__content">
                <div class="questionnaire" *ngFor="let q of data.questionnaires; let aidx = index;">
                    <span [style.background-color]="colors[aidx % colors.length]"></span> #{{ q.index }} {{ q.name }}
                </div>
            </div>
        </div>
        <div class="white-box filters" *ngIf="data.report.areas.length > 0">
            <div class="filters__left">
                <div class="white-box__title">
                    <h1>{{ 'CompareFeedbacks.FiltersResults' | translate }}</h1>
                </div>
                <div class="white-box__content">
                    <div class="filters__selects-box">
                        <mat-form-field>
                            <mat-select class="full-width"
                                        placeholder="{{ 'Common.Choose' | translate}}"
                                        [value]="selectedGroupBy"
                                        (selectionChange)="selectGroupBy($event)">
                                <ng-container *ngFor="let group of groupBy">
                                    <mat-option *ngIf="group.isVisible"
                                                [value]="group.value">
                                        {{group.label | translate}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>


                        <mat-form-field *ngIf="selectedGroupBy !== 'all'" [style.width]="200 + 'px'">
                            <mat-select (openedChange)="openedChange($event)" placeholder="{{ 'Common.Choose' | translate}}" [formControl]="selectFormControl" multiple>
                                <mat-select-trigger>
                                    {{selectFormControl.value && selectFormControl.value[0] ? selectFormControl.value[0].name : ''}}
                                    <span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">
                                        <span>{{selectFormControl.value.length - 1}}</span>
                                    </span>
                                </mat-select-trigger>
                                <div class="select-container">
                                    <mat-optgroup >
                                        <mat-form-field style="width:100%;">
                                            <input #search autocomplete="off" [placeholder]="searchPlaceholder" matInput [formControl]="searchTextboxControl">
                                            <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="{{ 'Common.Clear' | translate}}" (click)="clearSearch($event)">
                                                <mat-icon >close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </mat-optgroup>
                                    <mat-optgroup *ngIf="(filteredOptions | async).length == 0">
                                        <div>{{ 'Common.NoResults' | translate}}</div>
                                    </mat-optgroup>
                                    <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{ option.name }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>


                    </div>
                </div>
            </div>
            <div class="filters__right">
                <img src="/assets/icons/feedback-filters.png" />
            </div>
        </div>
    </div>

    <div class="only-open-questions-error" *ngIf="data.report.areas.length === 0">
        <img src="/assets/images/feedback/feedback_compare_error.svg"
             alt="feedback_compare_error">
        <span>{{'CompareFeedbacks.OnlyOpenQuestions' | translate}}</span>
    </div>

    <div class="general white-box" *ngIf="data.report.areas.length > 0">
        <h3>{{'CompareFeedbacks.GeneralResults' | translate}}</h3>

        <div class="feedback-compare__general-buttons">
            <div *ngFor="let category of categories;"
                 (click)="onSelectCategory(category)"
                 class="category"
                 [ngClass]="{'is-selected': category.id === selectedCategory.id }">
                <div class="name">{{category.name | translate}}</div>
            </div>
        </div>

        <div class="general__row">
            <ng-container *ngIf="selectedCategory.id === 'areas'">
                <ng-container *ngIf="selectedGroupBy === 'all'">
                    <ng-container *ngFor="let area of data.report.competencesInAreas; let aidx = index;">
                        <div class="general__row__column"
                             *ngIf="area.chartData.length">
                            <div class="general__row__column__name">
                                {{'QuestionnaireCreator.Area' | translate | uppercase}}:<span>{{area.name}}</span>
                            </div>

                            <div class="bar-vertical-container" #containerRef>
                                <ngx-charts-bar-vertical
                                        [view]="[171, 250]"
                                        [results]="area.chartDataGeneral"
                                        [scheme]="{ domain: area.chartColors }"
                                        [barPadding]="2"
                                        [xAxis]="false"
                                        [yAxis]="true"
                                        [showXAxisLabel]="false"
                                        [showYAxisLabel]="false"
                                        [yScaleMax]="100"
                                        [yScaleMin]="0"
                                        [yAxisTickFormatting]="yAxisTickFormatting"
                                >
                                </ngx-charts-bar-vertical>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedGroupBy !== 'all'">
                    <ng-container *ngFor="let area of data.report.competencesInAreas; let aidx = index; let last = last;">
                        <div class="general__row__column"
                             *ngIf="area.chartDataGeneral && selectedValues.length && area.loaded">
                            <div class="general__row__column__name">
                                <span>{{'QuestionnaireCreator.Area' | translate | uppercase}}</span>
                                    :
                                {{area.name}}
                            </div>

                            <div class="bar-vertical-container">
                                <div *ngFor="let selection of selectedValues"
                                     #containerRef>
                                    <ngx-charts-bar-vertical
                                            [view]="[171, 250]"
                                            [results]="area.chartDataGeneral[selection.id]"
                                            [scheme]="{ domain: area.chartColors[selection.id] }"
                                            [barPadding]="2"
                                            [xAxis]="false"
                                            [yAxis]="true"
                                            [showXAxisLabel]="false"
                                            [showYAxisLabel]="false"
                                            [yScaleMax]="100"
                                            [yScaleMin]="0"
                                            [yAxisTickFormatting]="yAxisTickFormatting">
                                    </ngx-charts-bar-vertical>
                                    <div class="bar-vertical-container__name">
                                        <span *ngIf="selectedGroupBy === 'positions'">{{'Common.Position' | translate }}</span>
                                        <span *ngIf="selectedGroupBy === 'users'">{{'Common.user' | translate }}</span>
                                        <span *ngIf="selectedGroupBy === 'supervisors'">{{'Common.Team' | translate }}</span>
                                        <span *ngIf="selectedGroupBy === 'groups'">{{'Common.Group' | translate }}</span>
                                        :
                                        {{selection.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="general__row__spacer" *ngIf="!last"></div>
                    </ng-container>
                </ng-container>

            </ng-container>
            <ng-container *ngIf="selectedCategory.id === 'competences'">
                <ng-container *ngIf="selectedGroupBy === 'all'">
                    <ng-container *ngFor="let area of data.report.areas">
                        <ng-container *ngFor="let competence of area.competences">
                            <div class="general__row__column"
                                 *ngIf="competence.chartData.length">
                                <div class="general__row__column__name">
                                    {{'QuestionnaireCreator.Competence' | translate | uppercase}}:<span>{{competence.description}}</span>
                                </div>

                                <div class="bar-vertical-container" #containerRef>
                                    <ngx-charts-bar-vertical
                                            [view]="[171, 250]"
                                            [results]="competence.chartDataGeneral"
                                            [scheme]="{ domain: competence.chartColors }"
                                            [barPadding]="2"
                                            [xAxis]="false"
                                            [yAxis]="true"
                                            [showXAxisLabel]="false"
                                            [showYAxisLabel]="false"
                                            [yScaleMax]="100"
                                            [yScaleMin]="0"
                                            [yAxisTickFormatting]="yAxisTickFormatting"
                                    >
                                    </ngx-charts-bar-vertical>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedGroupBy !== 'all'">
                    <ng-container *ngFor="let area of data.report.areas">
                        <ng-container *ngFor="let competence of area.competences">
                            <div class="general__row__column"
                                 *ngIf="competence.chartData && selectedValues.length && competence.loaded">
                                <div class="general__row__column__name">
                                    {{'QuestionnaireCreator.Competence' | translate | uppercase}}:<span>{{competence.description}}</span>
                                </div>

                                <div class="bar-vertical-container" #containerRef>
                                    <div *ngFor="let selection of selectedValues">
                                        <ngx-charts-bar-vertical
                                                [view]="[171, 250]"
                                                [results]="competence.chartDataGeneral[selection.id]"
                                                [scheme]="{ domain: competence.chartColors[selection.id] }"
                                                [barPadding]="2"
                                                [xAxis]="false"
                                                [yAxis]="true"
                                                [showXAxisLabel]="false"
                                                [showYAxisLabel]="false"
                                                [yScaleMax]="100"
                                                [yScaleMin]="0"
                                                [yAxisTickFormatting]="yAxisTickFormatting"
                                        >
                                        </ngx-charts-bar-vertical>
                                        <div class="bar-vertical-container__name">
                                            <span *ngIf="selectedGroupBy === 'positions'">{{'Common.Position' | translate }}</span>
                                            <span *ngIf="selectedGroupBy === 'users'">{{'Common.user' | translate }}</span>
                                            <span *ngIf="selectedGroupBy === 'supervisors'">{{'Common.Team' | translate }}</span>
                                            <span *ngIf="selectedGroupBy === 'groups'">{{'Common.Group' | translate }}</span>
                                            :
                                            {{selection.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedCategory.id === 'behaviours'">
                <ng-container *ngIf="selectedGroupBy === 'all'">
                    <ng-container *ngFor="let area of data.report.areas">
                        <ng-container *ngFor="let competence of area.competences">
                            <ng-container *ngFor="let behaviour of competence.behaviours">
                                <div class="general__row__column"
                                     *ngIf="behaviour.chartData.length">
                                    <div class="general__row__column__name">
                                        {{'QuestionnaireCreator.Behavior' | translate | uppercase}}:<span>{{behaviour.description}}</span>
                                    </div>

                                    <div class="bar-vertical-container" #containerRef>
                                        <ngx-charts-bar-vertical
                                                [view]="[171, 250]"
                                                [results]="behaviour.chartDataGeneral"
                                                [scheme]="{ domain: behaviour.chartColors }"
                                                [barPadding]="4"
                                                [xAxis]="false"
                                                [yAxis]="true"
                                                [showXAxisLabel]="false"
                                                [showYAxisLabel]="false"
                                                [yScaleMax]="100"
                                                [yScaleMin]="0"
                                                [yAxisTickFormatting]="yAxisTickFormatting"
                                        >
                                        </ngx-charts-bar-vertical>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedGroupBy !== 'all'">
                    <ng-container *ngFor="let area of data.report.areas">
                        <ng-container *ngFor="let competence of area.competences">
                            <ng-container *ngFor="let behaviour of competence.behaviours">
                                <div class="general__row__column"
                                     *ngIf="behaviour.chartData && selectedValues.length && competence.loaded">
                                    <div class="general__row__column__name">
                                        {{'QuestionnaireCreator.Behavior' | translate | uppercase}}:<span>{{behaviour.description}}</span>
                                    </div>

                                    <div class="bar-vertical-container" #containerRef>
                                        <div *ngFor="let selection of selectedValues">
                                            <ngx-charts-bar-vertical
                                                    [view]="[171, 250]"
                                                    [results]="behaviour.chartDataGeneral[selection.id]"
                                                    [scheme]="{ domain: behaviour.chartColors[selection.id] }"
                                                    [barPadding]="4"
                                                    [xAxis]="false"
                                                    [yAxis]="true"
                                                    [showXAxisLabel]="false"
                                                    [showYAxisLabel]="false"
                                                    [yScaleMax]="100"
                                                    [yScaleMin]="0"
                                                    [yAxisTickFormatting]="yAxisTickFormatting"
                                            >
                                            </ngx-charts-bar-vertical>
                                            <div class="bar-vertical-container__name">
                                                <span *ngIf="selectedGroupBy === 'positions'">{{'Common.Position' | translate }}</span>
                                                <span *ngIf="selectedGroupBy === 'users'">{{'Common.user' | translate }}</span>
                                                <span *ngIf="selectedGroupBy === 'supervisors'">{{'Common.Team' | translate }}</span>
                                                <span *ngIf="selectedGroupBy === 'groups'">{{'Common.Group' | translate }}</span>
                                                :
                                                {{selection.name}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>


    </div>

    <div class="area-details white-box" *ngIf="data.report.competencesInAreas.length > 0">
        <h3>{{'FeedbackUserReport.AssessmentOfCompetenciesInTheAreas' | translate}}</h3>

        <ng-container>
            <ng-container *ngFor="let area of data.report.competencesInAreas">
                <div class="area-details__row"
                     *ngIf="area.chartData.length || area.loaded">
                    <div class="area-details__row--name">
                            <span class="f-w-300">{{'QuestionnaireCreator.Area' | translate | uppercase}}
                                :</span> {{area.name}}
                    </div>

                    <ng-container *ngIf="selectedGroupBy === 'all'">
                        <div class="bar-horizontal-container" #containerRef>
                            <div class="bar-horizontal-container__left">
                                <div *ngFor="let chartData of area.chartData" class="bar-horizontal-container__row">

                                    <div class="bar-horizontal-container__dot average-rating__dot--{{ chartData.shortname }}"></div>

                                    <div class="bar-horizontal-container__row__label">{{ chartData.shortname }}</div>
                                    <ng-container>
                                        <span class="bar-horizontal-container__row__value">{{chartData.value| number : '1.2-2'}}</span>
                                    </ng-container>
                                </div>
                            </div>
                            <ngx-charts-bar-horizontal
                                    [results]="area.chartData"
                                    [view]="containerRef.offsetWidth > 694 ? [750,(area.chartData.length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(area.chartData.length*24)+barHorizontalHeight]"
                                    [scheme]="{ domain: area.chartColors }"
                                    [barPadding]="2"
                                    [xAxis]="true"
                                    [animations]="false"
                                    [yAxis]="false"
                                    [roundEdges]="false"
                                    [showXAxisLabel]="false"
                                    [xScaleMax]="data.report.competences.maxValue"
                                    [xAxisTickFormatting]="xAxisTickFormattingHiddeNumber"
                                    [xAxisTicks]="xAxisTicks"
                                    [showYAxisLabel]="false">
                            </ngx-charts-bar-horizontal>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="selectedGroupBy !== 'all'">
                        <ng-container *ngFor="let questionnaire of data.questionnaires">
                            <div *ngIf="area.chartDataGrouped && area.chartDataGrouped[questionnaire.shortName] && area.chartDataGrouped[questionnaire.shortName].length">
                                <div class="details-questionnaire-name">{{  questionnaire.name }}</div>
                                <div class="bar-horizontal-container" #containerRef>
                                    <div class="bar-horizontal-container__left">
                                        <div *ngFor="let chartData of area.chartDataGrouped[questionnaire.shortName]" class="bar-horizontal-container__row">

                                            <div class="bar-horizontal-container__dot average-rating__dot--{{ chartData.name }}"></div>

                                            <div class="bar-horizontal-container__row__label">{{ chartData.name }}</div>
                                            <ng-container>
                                                <span class="bar-horizontal-container__row__value">{{chartData.value| number : '1.2-2'}}</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <ngx-charts-bar-horizontal
                                            [results]="area.chartDataGrouped[questionnaire.shortName]"
                                            [view]="containerRef.offsetWidth > 694 ? [750,(area.chartDataGrouped[questionnaire.shortName].length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(area.chartDataGrouped[questionnaire.shortName].length*24)+barHorizontalHeight]"
                                            [scheme]="{ domain: [questionnaire.color] }"
                                            [barPadding]="2"
                                            [xAxis]="true"
                                            [animations]="false"
                                            [yAxis]="false"
                                            [roundEdges]="false"
                                            [showXAxisLabel]="false"
                                            [xScaleMax]="data.report.competences.maxValue"
                                            [xAxisTickFormatting]="xAxisTickFormattingHiddeNumber"
                                            [xAxisTicks]="xAxisTicks"
                                            [showYAxisLabel]="false">
                                    </ngx-charts-bar-horizontal>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

    </div>


    <div class="competence-details white-box" *ngIf="data.report.areas.length > 0">
        <h3>{{'FeedbackUserReport.DetailedAssessmentOfCompetencies' | translate}}</h3>

        <ng-container *ngFor="let area of data.report.areas">
            <ng-container *ngFor="let competence of area.competences">
                <div class="competence-details__container">
                    <div class="competence-details__title">
                        {{'QuestionnaireCreator.Competence' | translate | uppercase}} {{competence.index}}
                    </div>
                    <div class="competence-details__description">
                        {{competence.description}}
                    </div>

                    <ng-container *ngIf="selectedGroupBy === 'all'">
                        <div *ngIf="competence.chartData?.length" class="bar-horizontal-container" #containerRef>
                            <div class="bar-horizontal-container__left">
                                <div *ngFor="let chartData of competence.chartData"
                                     class="bar-horizontal-container__row">

                                    <div class="bar-horizontal-container__dot average-rating__dot--{{ chartData.shortname }}"></div>

                                    <div class="bar-horizontal-container__row__label">{{ chartData.shortname | translate}}</div>
                                    <ng-container *ngIf="!data.report.reportHideNumbers">
                                        <span class="bar-horizontal-container__row__value">{{chartData.value| number : '1.2-2'}}</span>
                                    </ng-container>
                                </div>
                            </div>
                            <ngx-charts-bar-horizontal
                                    [results]="competence.chartData"
                                    [view]="containerRef.offsetWidth > 694 ? [750,(competence.chartData.length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(competence.chartData.length*24)+barHorizontalHeight]"
                                    [scheme]="{ domain: competence.chartColors }"
                                    [barPadding]="2"
                                    [xAxis]="true"
                                    [animations]="false"
                                    [yAxis]="false"
                                    [roundEdges]="false"
                                    [showXAxisLabel]="false"
                                    [xScaleMax]="data.report.competences.maxValue"
                                    [xAxisTickFormatting]="xAxisTickFormattingHiddeNumber"
                                    [xAxisTicks]="xAxisTicks"
                                    [showYAxisLabel]="false">
                            </ngx-charts-bar-horizontal>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="selectedGroupBy !== 'all'">
                        <ng-container *ngFor="let questionnaire of data.questionnaires">
                            <div *ngIf="competence.chartDataGrouped && competence.chartDataGrouped[questionnaire.shortName] && competence.chartDataGrouped[questionnaire.shortName].length">
                                <div class="details-questionnaire-name">{{  questionnaire.name }}</div>
                                <div class="bar-horizontal-container" #containerRef>
                                    <div class="bar-horizontal-container__left">
                                        <div *ngFor="let chartData of competence.chartDataGrouped[questionnaire.shortName]" class="bar-horizontal-container__row">

                                            <div class="bar-horizontal-container__dot average-rating__dot--{{ chartData.name }}"></div>

                                            <div class="bar-horizontal-container__row__label">{{ chartData.name }}</div>
                                            <ng-container>
                                                <span class="bar-horizontal-container__row__value">{{chartData.value| number : '1.2-2'}}</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <ngx-charts-bar-horizontal
                                            [results]="competence.chartDataGrouped[questionnaire.shortName]"
                                            [view]="containerRef.offsetWidth > 694 ? [750,(competence.chartDataGrouped[questionnaire.shortName].length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(competence.chartDataGrouped[questionnaire.shortName].length*24)+barHorizontalHeight]"
                                            [scheme]="{ domain: [questionnaire.color] }"
                                            [barPadding]="2"
                                            [xAxis]="true"
                                            [animations]="false"
                                            [yAxis]="false"
                                            [roundEdges]="false"
                                            [showXAxisLabel]="false"
                                            [xScaleMax]="data.report.competences.maxValue"
                                            [xAxisTickFormatting]="xAxisTickFormattingHiddeNumber"
                                            [xAxisTicks]="xAxisTicks"
                                            [showYAxisLabel]="false">
                                    </ngx-charts-bar-horizontal>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

    </div>

    <div class="report-section-description white-box" *ngIf="data.report.areas.length > 0">
        <h3>{{'FeedbackUserReport.BehaviorDetails' | translate}}</h3>
    </div>


    <ng-container *ngFor="let area of data.report.areas; let aidx = index;">
        <div class="behaviour-details white-box" *ngFor="let competence of area.competences; let cidx = index;">
            <div class="behaviour-details__row" *ngFor="let behaviour of competence.behaviours; let bidx = index;">
                <div class="behaviour-details__area" *ngIf="cidx === 0 && bidx === 0">
                        <span class="behaviour-details__area--left">
                            <span class="f-w-300">{{'QuestionnaireCreator.Area' | translate | uppercase}}: </span>
                            {{area.name}}
                        </span>

                    <div class="behaviour-details__area--right">
                        <span>{{'QuestionnaireCreator.Competences'| translate}}</span>
                        <div>{{area.competences.length}}</div>
                    </div>
                </div>

                <ng-container *ngIf="bidx === 0">
                    <div class="behaviour-details__competence--number">
                        {{'QuestionnaireCreator.Competence' | translate | uppercase}} {{competence.index}}
                    </div>
                    <div class="behaviour-details__competence--name">
                        {{competence.description}}
                    </div>
                </ng-container>

                <div class="behaviour-details__behavior">
                    <div class="behaviour-details__behavior--name">
                        {{behaviour.description}}
                        <ng-container
                                *ngIf="behaviour.questionType === 'open'">{{ 'FeedbackStats.OpenQuestion' | translate }}</ng-container>
                        <span class="red-star" *ngIf="behaviour.isRequired"> * </span>
                    </div>

                    <ng-container *ngIf="selectedGroupBy === 'all'">
                        <ng-container *ngIf="behaviour.questionType !== 'open'">
                            <div class="bar-horizontal-container" #containerRef
                                 *ngIf="behaviour.chartData?.length && behaviour.questionType !=='open'">
                                <div class="bar-horizontal-container__left">
                                    <div *ngFor="let chartData of behaviour.chartData"
                                         class="bar-horizontal-container__row">
                                        <div class="bar-horizontal-container__dot average-rating__dot--{{ chartData.shortname }}"></div>

                                        <div class="bar-horizontal-container__row__label">{{ chartData.shortname }}</div>
                                        <ng-container *ngIf="!data.reportHideNumbers">
                                            <span class="bar-horizontal-container__row__value">{{(behaviour.fromZero ? (chartData.value - 1 >= 0 ? ((chartData.value - 1)| number : '1.2-2') : '-') : chartData.value | number : '1.2-2')}}</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <ngx-charts-bar-horizontal
                                        [results]="behaviour.chartData"
                                        [view]="containerRef.offsetWidth > 694 ? [750,(behaviour.chartData.length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(behaviour.chartData.length*24)+barHorizontalHeight]"
                                        [scheme]="{ domain: behaviour.chartColors }"
                                        [barPadding]="2"
                                        [xAxis]="true"
                                        [animations]="false"
                                        [yAxis]="false"
                                        [roundEdges]="false"
                                        [showXAxisLabel]="false"
                                        [xAxisTickFormatting]="behaviour.fromZero? xAxisTickFormattingFromZero : xAxisTickFormattingHiddeNumber"
                                        [xScaleMax]="behaviour.maxValue"
                                        [xAxisTicks]="behaviour.xAxisTicks"
                                        [showYAxisLabel]="false">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </ng-container>

                        <!--
                        <ng-container *ngIf="behaviour.questionType !=='open' && data.reportShowDistributionOfResponses && behaviour.distribution">
                            <div class="response-distribution-outer" [ngClass]="{'response-distribution-outer-vertical' : behaviour.answers.length > distributionVerticalFrom && behaviour.displayAs !=='range'}">

                                <ng-container *ngIf="behaviour.answers.length <= distributionVerticalFrom || behaviour.displayAs ==='range'">
                                    <div class="response-distribution__headers">
                                        <div class="response-distribution__headers--left">
                                            {{'FeedbackUserReport.ResponseDistribution' | translate}}
                                        </div>
                                        <div class="response-distribution__headers--table">
                                            <ng-container *ngIf="behaviour.displayAs !=='range'">
                                                <div class="answer-text"
                                                     *ngFor="let answer of behaviour.answers">
                                                    {{answer.answer|truncate:80}}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="behaviour.displayAs ==='range'">
                                                <div class="answer-text answer-text__left">
                                                    {{behaviour.scaleMinDescription}}&nbsp;
                                                </div>
                                                <div class="answer-text answer-text__right">
                                                    {{behaviour.scaleMaxDescription}}&nbsp;
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="response-distribution__headers--right" *ngIf="behaviour.noValueAnswers">
                                            {{'Common.NoValueAnswers' | translate}}
                                        </div>
                                        <div class="response-distribution__headers--right" *ngIf="behaviour.emptyResponses">
                                            {{'Common.NoAnswer' | translate}}
                                        </div>
                                    </div>

                                    <div class="response-distribution"
                                         *ngIf="behaviour.questionType !=='open' && data.reportShowDistributionOfResponses && behaviour.distribution">

                                        <div class="response-distribution__left">
                                            <div *ngFor="let distribution of behaviour.distributionArray"
                                                 class="response-distribution__left--row">
                                                {{ distribution.name | truncate: 10 }}
                                                <ng-container *ngIf="!data.reportHideNumbers">
                                                    <span>{{distribution.avg}}</span>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="response-distribution__table">
                                            <table>
                                                <tr *ngFor="let distribution of behaviour.distributionArray">
                                                    <td *ngFor="let distributionValue of distribution.valueArray">
                                                        {{distributionValue ? distributionValue : '-'}}
                                                    </td>
                                                </tr>
                                            </table>
                                            <div class="response-distribution__table--under">
                                                <ng-container *ngIf="behaviour.displayAs ==='range'">
                                                    <div *ngFor="let scale of [].constructor(behaviour.maxValue); let scaleMaxIndex = index"
                                                         class="answer-value">
                                                        {{behaviour.fromZero ? scaleMaxIndex : scaleMaxIndex + 1}}
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="behaviour.displayAs !=='range'">
                                                    <div *ngFor="let answer of behaviour.answers"
                                                         class="answer-value">
                                                        {{answer.value}}
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="response-distribution__right" *ngIf="behaviour.noValueAnswers">
                                            <table>
                                                <tr *ngFor="let noValueAnswer of behaviour.noValueAnswersArray">
                                                    <td>
                                                        {{noValueAnswer > 0 ? noValueAnswer : '-'}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="response-distribution__right" *ngIf="behaviour.emptyResponses">
                                            <table>
                                                <tr *ngFor="let emptyResponse of behaviour.emptyResponsesArray">
                                                    <td>
                                                        {{emptyResponse > 0 ? emptyResponse : '-'}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="behaviour.answers.length > distributionVerticalFrom && behaviour.displayAs !=='range'">
                                    <div class="response-distribution__headers">
                                        <div class="response-distribution__headers--left">
                                            {{'FeedbackUserReport.ResponseDistribution' | translate}}
                                        </div>

                                        <div class="response-distribution__table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th class="answer-text">&nbsp;</th>
                                                        <th *ngFor="let distribution of behaviour.distributionArray"
                                                             class="answer-value">
                                                            {{ distribution.name|truncate:80 }}
                                                        </th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr  *ngFor="let answer of behaviour.answers; let aidx = index;">
                                                        <td class="answer-text">{{answer.answer|truncate:80}}</td>
                                                        <td class="answer-value" *ngFor="let distribution of behaviour.distributionArray">
                                                            {{ behaviour.distribution[distribution.name] && behaviour.distribution[distribution.name][aidx] ? behaviour.distribution[distribution.name][aidx] : '-' }}
                                                        </td>
                                                        <td class="answer-answer-value">{{ answer.value }}</td>
                                                    </tr>
                                                    <tr *ngIf="behaviour.noValueAnswers">
                                                        <td class="answer-text">{{'Common.NoValueAnswers' | translate}}</td>
                                                        <td class="answer-value" *ngFor="let distribution of behaviour.noValueAnswers">
                                                            {{ behaviour.noValueAnswers[distribution.name] && behaviour.noValueAnswers[distribution.name][aidx] ? behaviour.noValueAnswers[distribution.name][aidx] : '-' }}
                                                        </td>
                                                    </tr>
                                                    <tr  *ngIf="behaviour.emptyResponses">
                                                        <td class="answer-text">{{'Common.NoAnswer' | translate}}</td>
                                                        <td class="answer-value" *ngFor="let distribution of behaviour.emptyResponses">
                                                            {{ behaviour.emptyResponses[distribution.name] && behaviour.emptyResponses[distribution.name][aidx] ? behaviour.emptyResponses[distribution.name][aidx] : '-' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        -->
                    </ng-container>

                    <ng-container *ngIf="selectedGroupBy !== 'all'">
                        <ng-container *ngIf="behaviour.questionType !== 'open'">
                            <ng-container *ngFor="let questionnaire of data.questionnaires">
                                <div *ngIf="behaviour.chartDataGrouped && behaviour.chartDataGrouped[questionnaire.shortName] && behaviour.chartDataGrouped[questionnaire.shortName].length">
                                    <div class="details-questionnaire-name">{{  questionnaire.name }}</div>
                                    <div class="bar-horizontal-container" #containerRef
                                         *ngIf="behaviour.chartDataGrouped[questionnaire.shortName]?.length && behaviour.questionType !=='open'">
                                        <div class="bar-horizontal-container__left">
                                            <div *ngFor="let chartData of behaviour.chartDataGrouped[questionnaire.shortName]"
                                                 class="bar-horizontal-container__row">
                                                <div class="bar-horizontal-container__dot average-rating__dot--{{ chartData.name }}"></div>

                                                <div class="bar-horizontal-container__row__label">{{ chartData.name }}</div>
                                                <ng-container *ngIf="!data.reportHideNumbers">
                                                    <span class="bar-horizontal-container__row__value">{{(behaviour.fromZero ? (chartData.value - 1 >= 0 ? ((chartData.value - 1)| number : '1.2-2') : '-') : chartData.value | number : '1.2-2')}}</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <ngx-charts-bar-horizontal
                                                [results]="behaviour.chartDataGrouped[questionnaire.shortName]"
                                                [view]="containerRef.offsetWidth > 694 ? [750,(behaviour.chartDataGrouped[questionnaire.shortName].length*24)+barHorizontalHeight] : [containerRef.offsetWidth,(behaviour.chartDataGrouped[questionnaire.shortName].length*24)+barHorizontalHeight]"
                                                [scheme]="{ domain: [questionnaire.color] }"
                                                [barPadding]="2"
                                                [xAxis]="true"
                                                [animations]="false"
                                                [yAxis]="false"
                                                [roundEdges]="false"
                                                [showXAxisLabel]="false"
                                                [xAxisTickFormatting]="behaviour.fromZero? xAxisTickFormattingFromZero : xAxisTickFormattingHiddeNumber"
                                                [xScaleMax]="behaviour.maxValue"
                                                [xAxisTicks]="behaviour.xAxisTicks"
                                                [showYAxisLabel]="false">
                                        </ngx-charts-bar-horizontal>
                                    </div>

                                    <!--
                                    <ng-container *ngIf="behaviour.questionType !=='open' && data.reportShowDistributionOfResponses && behaviour.distributionArray[questionnaire.shortName]">
                                        <div class="response-distribution-outer" [ngClass]="{'response-distribution-outer-vertical' : behaviour.answers.length > distributionVerticalFrom && behaviour.displayAs !=='range'}">

                                            <ng-container *ngIf="behaviour.answers.length <= distributionVerticalFrom || behaviour.displayAs ==='range'">
                                                <div class="response-distribution__headers">
                                                    <div class="response-distribution__headers--left">
                                                        {{'FeedbackUserReport.ResponseDistribution' | translate}}
                                                    </div>
                                                    <div class="response-distribution__headers--table">
                                                        <ng-container *ngIf="behaviour.displayAs !=='range'">
                                                            <div class="answer-text"
                                                                 *ngFor="let answer of behaviour.answers">
                                                                {{answer.answer |truncate:80}}
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="behaviour.displayAs ==='range'">
                                                            <div class="answer-text answer-text__left">
                                                                {{behaviour.scaleMinDescription}}&nbsp;
                                                            </div>
                                                            <div class="answer-text answer-text__right">
                                                                {{behaviour.scaleMaxDescription}}&nbsp;
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="response-distribution__headers--right" *ngIf="behaviour.noValueAnswers">
                                                        {{'Common.NoValueAnswers' | translate}}
                                                    </div>
                                                    <div class="response-distribution__headers--right" *ngIf="behaviour.emptyResponses">
                                                        {{'Common.NoAnswer' | translate}}
                                                    </div>
                                                </div>

                                                <div class="response-distribution"
                                                     *ngIf="behaviour.questionType !=='open' && data.reportShowDistributionOfResponses && behaviour.distributionArray[questionnaire.shortName]">

                                                    <div class="response-distribution__left">
                                                        <div *ngFor="let distribution of behaviour.distributionArray[questionnaire.shortName]"
                                                             class="response-distribution__left--row">
                                                            {{ distribution.name |truncate:10 }}
                                                            <ng-container *ngIf="!data.reportHideNumbers">
                                                                <span>{{distribution.avg}}</span>
                                                            </ng-container>
                                                        </div>
                                                    </div>

                                                    <div class="response-distribution__table">
                                                        <table>
                                                            <tr *ngFor="let distribution of behaviour.distributionArray[questionnaire.shortName]">
                                                                <td *ngFor="let distributionValue of distribution.valueArray">
                                                                    {{distributionValue ? distributionValue : '-'}}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <div class="response-distribution__table--under">
                                                            <ng-container *ngIf="behaviour.displayAs ==='range'">
                                                                <div *ngFor="let scale of [].constructor(behaviour.maxValue); let scaleMaxIndex = index"
                                                                     class="answer-value">
                                                                    {{behaviour.fromZero ? scaleMaxIndex : scaleMaxIndex + 1}}
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="behaviour.displayAs !=='range'">
                                                                <div *ngFor="let answer of behaviour.answers"
                                                                     class="answer-value">
                                                                    {{answer.value}}
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>

                                                    <div class="response-distribution__right" *ngIf="behaviour.noValueAnswers">
                                                        <table>
                                                            <tr *ngFor="let noValueAnswer of behaviour.noValueAnswersArray[questionnaire.shortName]">
                                                                <td>
                                                                    {{noValueAnswer > 0 ? noValueAnswer : '-'}}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="response-distribution__right" *ngIf="behaviour.emptyResponses">
                                                        <table>
                                                            <tr *ngFor="let emptyResponse of behaviour.emptyResponsesArray[questionnaire.shortName]">
                                                                <td>
                                                                    {{emptyResponse > 0 ? emptyResponse : '-'}}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="behaviour.answers.length > distributionVerticalFrom && behaviour.displayAs !=='range'">
                                                <div class="response-distribution__headers">
                                                    <div class="response-distribution__headers--left">
                                                        {{'FeedbackUserReport.ResponseDistribution' | translate}}
                                                    </div>

                                                    <div class="response-distribution__table">
                                                        <table>
                                                            <thead>
                                                            <tr>
                                                                <th class="answer-text">&nbsp;</th>
                                                                <th *ngFor="let distribution of behaviour.distributionArray[questionnaire.shortName]"
                                                                    class="answer-value">
                                                                    {{ distribution.name|truncate:80 }}
                                                                    {{ behaviour.distribution|json }}
                                                                </th>
                                                                <th>&nbsp;</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr  *ngFor="let answer of behaviour.answers; let aidx = index;">
                                                                <td class="answer-text">{{answer.answer|truncate:80}}</td>
                                                                <td class="answer-value" *ngFor="let distribution of behaviour.distributionArray">
                                                                    {{ behaviour.distribution[distribution.name] && behaviour.distribution[distribution.name][aidx] ? behaviour.distribution[distribution.name][aidx] : '-' }}
                                                                </td>
                                                                <td class="answer-answer-value">{{ answer.value }}</td>
                                                            </tr>
                                                            <tr *ngIf="behaviour.noValueAnswers">
                                                                <td class="answer-text">{{'Common.NoValueAnswers' | translate}}</td>
                                                                <td class="answer-value" *ngFor="let distribution of behaviour.noValueAnswers">
                                                                    {{ behaviour.noValueAnswers[distribution.name] && behaviour.noValueAnswers[distribution.name][aidx] ? behaviour.noValueAnswers[distribution.name][aidx] : '-' }}
                                                                </td>
                                                            </tr>
                                                            <tr  *ngIf="behaviour.emptyResponses">
                                                                <td class="answer-text">{{'Common.NoAnswer' | translate}}</td>
                                                                <td class="answer-value" *ngFor="let distribution of behaviour.emptyResponses">
                                                                    {{ behaviour.emptyResponses[distribution.name] && behaviour.emptyResponses[distribution.name][aidx] ? behaviour.emptyResponses[distribution.name][aidx] : '-' }}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    -->
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>


                </div>
            </div>
        </div>
    </ng-container>


</div>