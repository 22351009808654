import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CustomFieldsService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    productGroupName = '';

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public getCustomFields(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/custom-field?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public remove(id: any): Observable<any> {
        return this.http.delete<any>(`${this.basePath}/custom-field/${id}`);
    }

    public find(id: any): Observable<any> {
        return this.http.get<any>(`${this.basePath}/custom-field/${id}`);
    }

    public checkFieldName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/custom-field/check-name`, body, { headers });
    }

    public checkFieldUniqueValue(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/custom-field/check-unique-value`, body, { headers });
    }

    public create(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/custom-field`, body, { headers });
    }

    public update(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/custom-field/${id}`, body, { headers });
    }
}
