import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { calcWorkingDays } from '../../helpers';
import { IDialogData } from '../../intefaces/interfaces';

@Component({
    selector: 'app-assign-expiry-date-dialog',
    templateUrl: './assign-expiry-date-dialog.component.html',
    styleUrls: ['./assign-expiry-date-dialog.component.scss']
})
export class AssignExpiryDateDialogComponent implements OnInit{
    selected = moment();
    days: number
    disabled = false
    visibleFromDate = null;

    form: FormGroup;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                public dialogRef: MatDialogRef<AssignExpiryDateDialogComponent>) {
        this.visibleFromDate = this.data.visibleFromDate;
    }

    ngOnInit(): void {
        this.form = this.initForm();
        this.form.get('hasDays').valueChanges.subscribe(value => {
            if (value) {
                this.disabled = true;
                this.form.get('days').setValidators(Validators.required)
            } else {
                this.disabled = false;
                this.form.get('days').setValue('')
                this.form.get('days').clearValidators();
            }

            this.form.get('days').updateValueAndValidity();
        });
        this.form.patchValue(this.data > 0 ? this.data : null);
    }

    initForm(): FormGroup {
        return this.fb.group({
            hasDays: [false],
            days: [null]
        });
    }

    onSelectedChange(event: any) {
        this.selected = event;
        this.save();
    }

    save() {
        let expiryDate = moment(this.selected);

        if (this.form.get('hasDays').value && this.form.get('days').value > 0) {
            expiryDate = moment(calcWorkingDays(new Date(this.visibleFromDate), this.form.get('days').value));
        }

        this.dialogRef.close({
            expiryDate: expiryDate.format('YYYY-MM-DD'),
            hasDays: this.form.get('hasDays').value,
            days: this.form.get('days').value
        });
    }
}