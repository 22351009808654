import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LibraryService } from '../../api/services/library.service';

@Directive({
    selector: '[appShowHiddenNumberCategoryArticle]'
})
export class ShowHiddenNumberCategoryArticleDirective implements OnInit, AfterViewInit {
    @Input() showNumber: boolean = true;

    elNative: any;
    items: number;

    constructor(private el: ElementRef,
                private deviceService: DeviceDetectorService,
                private libraryService: LibraryService) {

        this.elNative = el.nativeElement;
    }

    ngOnInit() {
        if (this.deviceService.isDesktop()) {
            this.libraryService.isNavBar.pipe(debounceTime(1000)).subscribe(() => {
                this.recalcWidths();
            })
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.recalcWidths();
        })
    }

    recalcWidths() {
        const containerHeight = this.elNative.offsetHeight;
        const childNodes = [...this.elNative.childNodes].filter(value => value.classList?.contains('cat'))
        const more = this.elNative.querySelector('#more')
        more.style.visibility = 'hidden';

        const elementCount = childNodes.filter((val) => {
            return val.offsetTop >= containerHeight;
        }).length;

        childNodes.forEach((val, index) => {
            if (index > 0) {
                if (childNodes[index - 1].offsetTop < containerHeight && val.offsetTop >= containerHeight) {
                    if (this.showNumber) {
                        more.getElementsByClassName('cat-name')[0].innerHTML = `+${elementCount}`
                    }
                    more.style.left = ((childNodes[index - 1].offsetLeft + childNodes[index - 1].offsetWidth) + 10) + 'px';
                    more.style.visibility = 'visible';
                }
            }
        })
    }
}
