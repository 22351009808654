<div class="custome-select cursor-pointer" [matMenuTriggerFor]="select">
    <div class="custome-select__box">
        {{_selectedElement}}
    </div>
    <img src="/assets/icons/angle-down.svg" alt="">
</div>

<mat-menu #select="matMenu">
    <button mat-menu-item *ngFor="let item of itemsToSelect"
            class="text-align-center"
            (click)="setObject(item)" [ngStyle]="{'background-color': item.value === _selectedElement?  '#e5eef9' : '' }">
        <span [ngStyle]="{'color': item.isDone?  '#959D9F' : '#2F2F2F' }" style="font-weight: 500">{{ item.value }}</span>
        <img [src]="item.img" *ngIf="item.img" alt="img">
    </button>
</mat-menu>