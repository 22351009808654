import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[app-sort-by]'
})
export class SortByDirective {
    @HostListener('click', ['$event']) onClick(event: any) {
        event.preventDefault();
        this.sorted.next(this.sortProperty); // Raise clicked event
    }

    @Output()
    sorted: EventEmitter<string> = new EventEmitter<string>();

    @Input('app-sort-by')
    set sortBy(value: string) {
        this.sortProperty = value;
    }

    sortProperty: string = '';

    constructor() {
        //
    }
}
