<div class="widget-container"
     *ngIf="items && defaultItems && !waiting"
     [ngClass]="('widget-container-dolineo-subscription' + ' widget-size-' + widget.sizeClass + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(37deg, rgba(255, 255, 255, 44) 0%, rgba(245, 155, 28, 0.15) 100%)')"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name">{{ 'DashboardCreator.DolineoSubscription' | translate}}</div>
    <div class="widget-container__date">
        {{ 'DashboardWidgetDolineoSubscription.To' | translate}}
        {{ subscription.date }}
    </div>

    <svg class="background-icon" width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle [style.fill]="'#' + widget.backgroundColor" cx="30.5" cy="30" r="30" fill="#F59B1C" fill-opacity="0.2"/>
        <path [style.fill]="'#' + widget.backgroundColor" d="M25.4821 44.1708C21.3065 44.1708 17.9233 40.7869 17.9233 36.6139C17.9233 32.4364 21.3065 29.0532 25.4821 29.0532C29.6564 29.0532 33.0416 32.4371 33.0416 36.6139C33.0416 40.7869 29.6564 44.1708 25.4821 44.1708ZM30.5 11C20.0082 11 11.5 19.5076 11.5 30C11.5 31.7911 11.7514 33.5194 12.215 35.1617C12.9383 28.4705 18.5997 23.2645 25.4821 23.2645C28.289 23.2645 30.8927 24.1303 33.0416 25.6116V17.6329C33.0416 16.3004 34.1239 15.2205 35.4552 15.2205H38.8315V36.6139C38.8315 42.2271 35.357 47.0272 30.4436 48.9987C30.462 48.9987 30.481 49 30.5 49C40.9924 49 49.5 40.4905 49.5 30C49.5 19.5076 40.9924 11 30.5 11Z" fill="#F59B1C"/>
    </svg>

    <div class="widget-container__content">
        <div class="widget-container__content--subscription--categories">

            <div class="widget-container__content--subscription--categories--main-data">
                <div class="subscription-progress__progress">
                    <div class="subscription-progress__progress__bar">
                        <div class="progress-done"
                             [style.width]="(100 - ((subscription.available/subscription.created) * 100)) + '%'"></div>
                    </div>
                </div>
                <div class="widget-container__content--subscription--categories--main-data--category">
                    <div class="widget-container__content--subscription--categories--main-data--category--label">{{ 'DashboardWidgetDolineoSubscription.Created' | translate}}</div>
                    <div class="widget-container__content--subscription--categories--main-data--category--count">
                        <span class="created">{{ subscription.created }}</span>
                    </div>
                </div>

                <div class="widget-container__content--subscription--categories--main-data--category">
                    <div class="widget-container__content--subscription--categories--main-data--category--label">{{ 'DashboardWidgetDolineoSubscription.Available' | translate}}</div>
                    <div class="widget-container__content--subscription--categories--main-data--category--count"
                    ><span class="available">{{ subscription.available }}</span>
                    </div>
                </div>

                <div class="widget-container__content--subscription--categories--main-data--category" *ngIf="widget.sizeClass !== 'a'">
                    <div class="widget-container__content--subscription--categories--main-data--category--label">{{ 'DashboardWidgetDolineoSubscription.Purchased' | translate}}</div>
                    <div class="widget-container__content--subscription--categories--main-data--category--count"
                    ><span class="purchased">{{ subscription.purchased }}</span>
                    </div>
                </div>
            </div>

            <div class="widget-container__content--subscription--categories--second-data" *ngIf="widget.sizeClass !== 'a'">
                <div class="widget-container__content--subscription--categories--second-data--category">
                    <div class="widget-container__content--subscription--categories--second-data--category--label">{{ 'DashboardWidgetDolineoSubscription.Active' | translate}}</div>
                    <div class="widget-container__content--subscription--categories--second-data--category--count">
                        <span class="active">{{ subscription.active }}</span>
                    </div>
                </div>

                <div class="widget-container__content--subscription--categories--second-data--category">
                    <div class="widget-container__content--subscription--categories--second-data--category--label">{{ 'DashboardWidgetDolineoSubscription.Suspended' | translate}}</div>
                    <div class="widget-container__content--subscription--categories--second-data--category--count">
                        <span class="suspended">{{ subscription.suspended }}</span>
                    </div>
                </div>

                <div class="widget-container__content--subscription--categories--second-data--category">
                    <div class="widget-container__content--subscription--categories--second-data--category--label">{{ 'DashboardWidgetDolineoSubscription.Anonymized' | translate}}</div>
                    <div class="widget-container__content--subscription--categories--second-data--category--count">
                        <span class="anonymized">{{ subscription.anonymized }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

