import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';

if (environment.production) {
    enableProdMode();

    console.warn = (...data) => {};
    console.error = (...data) => {};
    console.log = (...data) => {};

    window.onerror =  (message, source, lineno, colno, error) => {
        // console.log('Nieobsłużony wyjątek:', message);
        return true;  // Zwrócenie true zapobiega wyświetlaniu błędu w konsoli
    };

    window.addEventListener('unhandledrejection', (event) => {
        // console.log('Nieobsłużone odrzucenie obietnicy:', event.reason);
    });
}


const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    } else {
        //
    }
} else {
    bootstrap();
}
