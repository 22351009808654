import { FormControl } from '@angular/forms';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {CustomFieldsService} from '../../api/services/custom-fields.service';

export const customFieldUniqueValueValidator = (api: CustomFieldsService, fieldId: number, userId: number) => {
    let hasError = false;
    return (input: FormControl) => {
        const value = input.value.trim();
        if (value.length === 0) {
            return of(true);
        } else {
            return of(value).pipe(
                filter(name => name.length > 0),
                distinctUntilChanged(),
                switchMap((name: string) => api.checkFieldUniqueValue({fieldId, value, userId})),
                map(() => null),
                catchError(() => {
                    hasError = true;
                    return of({valueExists: true})
                })
            );
        }
    };
};
