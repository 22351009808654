import {EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import {ApiService} from '../../api/api.service';
import { LoaderService } from '../components/loader/loader.service';
import {downloadFile, setLocalFilter} from '../helpers';
import {environment} from '../../../environments/environment';
import {ReportDialogComponent} from '../components/report/dialogs/report-dialog/report-dialog.component';
import {ReportArea} from '../models/report-area';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ReportService {
    public openedDialog = false;
    public hasPendingReports = false;
    searchRequest: any = null;

    constructor(
        private api: ApiService,
        private loader: LoaderService,
        private dialog: MatDialog,
        private toasterService: ToastrService,
        private translate: TranslateService
    ) {
        // console.log('report service constr');
        setInterval(() => {
            if (this.hasPendingReports && !this.openedDialog) {
                if (this.searchRequest) {
                    this.searchRequest.unsubscribe()
                }
                this.searchRequest = this.api.Report.reports(
                    1,
                    1, 'createdAt',
                    'desc',
                    'autoReload=1',
                    true
                ).subscribe(res => {
                        this.hasPendingReports = res.hasPendingReports;
                        if (res.hasPendingReports === false) {
                            this.toasterService.success(this.translate.instant('Report.ReportDownloaded'));
                            this.openModal();
                        }
                    }
                )
            }
        }, 3000);
    }

    create(area: ReportArea, url: string, method: string, fileName: string, type: string, postData: any = null) {
        this.api.Report.create(area, url, method, fileName, type, postData).subscribe( response => {
            this.api.Report.report(response.id).subscribe(report => {
                if (report.status === 'done') {
                    this.download(report);
                } else {
                    this.openModal();
                }
            })
        });
    }

    download(report) {
        this.api.download(environment.apiPath + report.path).subscribe(blob => {
            downloadFile(blob, report.fileName);
        })
    }

    openModal() {
        this.openedDialog = true;
        this.dialog.open(ReportDialogComponent, {
            data: {},
            width: '820px'
        }).afterClosed().subscribe(value => {
            this.openedDialog = false;
        });
    }
}