import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[blockWhiteSpace]',
})
export class BlockWhiteSpaceDirective {
    inputElement: HTMLInputElement;

    constructor(public el: ElementRef) {
        this.inputElement = el.nativeElement;
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent): any {
        if (e.key === ' ') {
            e.preventDefault();
            return;
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: any): void {
        let pastedInput: string;
        if (window['clipboardData']) {
            pastedInput = window['clipboardData'].getData('text');
        } else if (event.clipboardData && event.clipboardData.getData) {
            pastedInput = event.clipboardData.getData('text/plain');
        }

        this.pasteData(pastedInput);
        event.preventDefault();
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        const textData = event.dataTransfer.getData('text');
        this.inputElement.focus();
        this.pasteData(textData);
        event.preventDefault();
    }

    private pasteData(pastedContent: string) {
        const sanitizedContent = this.sanitizeInput(pastedContent);
        const pasted = document.execCommand('insertText', false, sanitizedContent);
        if (!pasted) {
            if (this.inputElement.setRangeText) {
                const { selectionStart: start, selectionEnd: end } = this.inputElement;
                this.inputElement.setRangeText(sanitizedContent, start, end, 'end');
            } else {
                this.insertAtCursor(this.inputElement, sanitizedContent);
            }
        }
    }

    private insertAtCursor(myField: HTMLInputElement, myValue: string) {
        const startPos = myField.selectionStart;
        const endPos = myField.selectionEnd;

        myField.value =
            myField.value.substring(0, startPos) +
            myValue +
            myField.value.substring(endPos, myField.value.length);

        const pos = startPos + myValue.length;
        myField.focus();
        myField.setSelectionRange(pos, pos);

        this.triggerEvent(myField, 'input');
    }

    private triggerEvent(el: HTMLInputElement, type: string): void {
        if ('createEvent' in document) {
            // modern browsers, IE9+
            const e = document.createEvent('HTMLEvents');
            e.initEvent(type, false, true);
            el.dispatchEvent(e);
        }
    }

    private sanitizeInput(input: string): string {
        let result = input.replace(/\s/g, '');

        const maxLength = this.inputElement.maxLength;
        if (maxLength > 0) {
            const allowedLength = maxLength - this.inputElement.value.length;
            result = allowedLength > 0 ? result.substring(0, allowedLength) : '';
        }
        return result;
    }
}
