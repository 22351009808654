import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener, Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';

@Directive({
    selector: '[tableWrapper]'
})
export class TableHeaderDirective implements OnInit, OnDestroy {

    @Output()
    callback: EventEmitter<any> = new EventEmitter<any>();

    @Input() marginBottom: number = 1;

    private tableWrapper: HTMLElement;
    private refreshSub;

    constructor(private el: ElementRef) {
        this.tableWrapper = this.el.nativeElement as HTMLElement;
    }

    ngOnInit(): void {
        //
    }

    ngOnDestroy(): void {
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    }

    @HostListener('scroll', ['$event'])
    onWindowScroll() {
        if (this.tableWrapper.scrollTop >= this.tableWrapper.scrollHeight - this.tableWrapper.offsetHeight - this.marginBottom) {
            this.callback.emit();
        }
    }
}
