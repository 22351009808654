import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-custome-select',
    templateUrl: './custome-select.component.html',
    styleUrls: ['./custome-select.component.scss']
})
export class CustomeSelectComponent {

    @Output() onSelect = new EventEmitter();
    @Input() itemsToSelect: any[];

    @Input() set selectedElement(selectedElement: number) {
        this._selectedElement = selectedElement;
    }

    _selectedElement = 1;

    constructor() {
        //
    }

    setObject(item: any) {
        this._selectedElement = item.value;
        this.onSelect.emit(item.id);
    }
}