import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { AppLocaleService } from '../../../core/services/locale.service';
import { humanizeMinutes } from '../../helpers';
import { HelperService } from '../../services/helper.service';
import { ApiService } from '../../../api/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-test-tile',
    templateUrl: './test-tile.component.html',
    styleUrls: ['./test-tile.component.scss']
})
export class TestTileComponent implements OnInit {
    @Output() selfAssign: EventEmitter<any> = new EventEmitter();
    @Output() selfRemove: EventEmitter<any> = new EventEmitter();
    @Output() assignUsers: EventEmitter<any> = new EventEmitter();
    @Input() data: any;
    @Input() searchText: string = '';
    @Input() isMyProgress: boolean;

    public canEdit = false;
    public canSelfAssign = false;
    public canAssignUsers = false;

    public filesPath = environment.filesPath;
    outOfDate = false;

    constructor(private _localeService: AppLocaleService,
                private _api: ApiService,
                private router: Router,
                public _translate: TranslateService,
                public helper: HelperService,
                private deviceService: DeviceDetectorService) {
        this._localeService.currentLocale().subscribe(q => {
            moment.locale(q);
        });
    }

    ngOnInit(): void {
        const eventDate = moment(this.data.endDate);
        const todayDate = moment();
        this.data = {
            ...this.data,
            photoUrl: this.photoUrl(),
            startDate: this.data.startDate ? moment(this.data.startDate).format('DD.MM.YYYY, HH:mm') : this._translate.instant('Common.NoDeadline'),
            endDate: this.setFinishedDate(),
            durationTime: humanizeMinutes(this.data.durationTime),
            redColor: eventDate.diff(todayDate, 'days') <= 7 && this.data.finishedDate === null
        }

        this.canEdit = this.data.type === 'test' ? this.helper.hasPermission('content:pools:edit') && this.helper.isCarer(this.data.carersId) :
            this.helper.hasPermission('content:survey:edit') && this.helper.isCarer(this.data.carersId);
        this.canAssignUsers = this.data.type === 'test' ? this.helper.hasPermissions(['team:pools:assign', 'content:pools:assign']) || this.helper.isCarer(this.data.carersId) :
            this.helper.hasPermissions(['team:survey:assign', 'content:survey:assign']) || this.helper.isCarer(this.data.carersId);
        this.canSelfAssign = this.data.isSelfAssigned || this.data.isSelfAssigned === 0;
        if (this.deviceService.isMobile()) {
            this.canEdit = false;
        }
    }

    photoUrl() {
        if (this.data.photoUrl) {
            return this.filesPath + this.data.photoUrl + '?token=' + this.helper.hashUserToken();
        } else {
            if (this.data.type === 'test') {
                return 'assets/images/default/test-background.jpg';
            } else {
                return 'assets/images/default/survey-background.jpg';
            }
        }
    }

    assign(data: any) {
        this.assignUsers.emit(data)
    }

    goEdit() {
        if (this.data.type === 'test') {
            this.router.navigateByUrl('/app/pools-creator/update/' + this.data.id);
        } else {
            this.router.navigateByUrl('/app/surveys-creator/update/' + this.data.id);
        }
    }

    updateSelf(): void {
        if (this.data.assignedDate) {
            this.selfRemove.emit(this.data);
        } else {
            this.selfAssign.emit(this.data);
        }
        this.data.assignedDate = this.data.assignedDate ? null : new Date();
    }

    setFinishedDate(): string {
        if (this.data.endDate) {
            const eventDate = moment(this.data.endDate);
            const todayDate = moment();
            if (eventDate.subtract(1, 'minute').isBefore(todayDate, 'minute')) {
                this.outOfDate = true;
                return moment(this.data.endDate).format('DD.MM.YYYY, HH:mm');
            }

            if (moment().isSame(eventDate, 'day')) {
                return `${this._translate.instant('Calendar.Today')}, ${moment(this.data.endDate).format('HH:mm')}`;
            }
            if (eventDate.diff(todayDate, 'days') <= 7 && eventDate.diff(todayDate, 'days') > 0) {
                return this._translate.instant('Common.RemainsDays').replace('{{x}}', eventDate.diff(todayDate, 'day'));
            }

            return moment(this.data.endDate).format('DD.MM.YYYY, HH:mm');
        } else {
            return this._translate.instant('Common.NoDeadline');
        }
    }
}
