import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppLocaleService } from '../../../core/services/locale.service';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
    @Input() text: string;
    @Input() url: string;
    @Input() color: string;
    @Input() disableButtonBack: boolean = false;
    @Input() showButtonBack: boolean = false;
    @Input() event: boolean = false;
    @Input() tooltipText: string = 'Common.ComeBack';
    @Input() matTooltipClass: string = 'below-top';

    @Output() onBackEvent: EventEmitter<any> = new EventEmitter();

    constructor(private _location: Location,
                private router: Router,
                public _helper: HelperService,
                private localeService: AppLocaleService) {
    }

    goBack() {
        if (this.event) {
            this.onBackEvent.emit();
        } else if (this.localeService.history.length >= 1) {
            if (this.url) {
                this.router.navigateByUrl(this.url);
            } else {
                this._location.back();
            }
        } else {
            if (this._helper.isSuperAdmin() || this._helper.isSysAdmin()) {
                this.router.navigateByUrl('/app/superadmin/clients');
            } else {
                this.router.navigateByUrl(this._helper.getHomePageUrl());
            }
        }
    }
}
