import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment.prod';
import {
    capitalizeFirstLetter,
    categoryText,
    humanizeMinutes,
    setTableFromLocalStorage,
    setValueHeader
} from '../../helpers';
import { HelperService } from '../../services/helper.service';
import { RequestInterface } from '../../intefaces/pagination.interface';

@Component({
    selector: 'app-choice-base-select-path-training-dialog',
    templateUrl: './choice-base-select-path-training-dialog.component.html',
    styleUrls: ['./choice-base-select-path-training-dialog.component.scss']
})
export class ChoiceBaseSelectPathTrainingDialogComponent implements OnInit {
    columns: any[] = [];
    items: any[] = [];
    filtersString: string = '';
    public request: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };
    gridExtraParams = {
        status: 'published'
    };
    public searchRequest: any = null;
    selectedItem = null;
    selectedIds: any[] = [];
    categories: any[] = [];
    protected basePath = environment.apiPath;

    constructor(public dialogRef: MatDialogRef<ChoiceBaseSelectPathTrainingDialogComponent>,
                private _api: ApiService,
                private _translate: TranslateService,
                private _dialog: MatDialog,
                private _helper: HelperService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    createTable() {
        if(this.data.type === 'training'){
            this.columns = [
                {
                    index: 'name',
                    name: 'Common.TrainingName',
                    searchable: true,
                    sortable: true,
                    value: '',
                    defaultValue: '',
                    visible: true,
                    type: 'STRING',
                    css: {
                        'padding-left': '20px',
                        'min-width': '241px',
                        'word-wrap': 'anywhere'
                    },
                    class: 'ft-auto'
                },
                {
                    index: 'categories',
                    name: 'Common.SubjectArea',
                    searchable: true,
                    sortable: false,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    options: [...this.categories],
                    css: {
                        'min-width': '251px',
                        'width': '251px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return categoryText(v);
                    }
                },
                {
                    index: 'duration',
                    name: 'Common.DurationM',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    valueRange: null,
                    type: 'NUMBER',
                    hasMinutes: true,
                    css: {
                        'min-width': '140px',
                        'width': '140px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return humanizeMinutes(v.duration);
                    }
                },
                {}
            ];
        }else{
            this.columns = [
                {
                    index: 'name',
                    name: 'Common.PathName',
                    searchable: true,
                    sortable: true,
                    value: '',
                    defaultValue: '',
                    visible: true,
                    type: 'STRING',
                    css: {
                        'padding-left': '20px',
                        'min-width': '241px',
                        'word-wrap': 'anywhere'
                    },
                    class: 'ft-auto'
                },
                {
                    index: 'categories',
                    name: 'Common.SubjectArea',
                    searchable: true,
                    sortable: false,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    options: [...this.categories],
                    css: {
                        'min-width': '200px',
                        'width': '200px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return categoryText(v);
                    }
                },
                {
                    index: 'totalElementsCount',
                    name: 'Common.Elements',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    valueRange: null,
                    type: 'NUMBER',
                    hasMinutes: false,
                    css: {
                        'min-width': '100px',
                        'width': '100px',
                        'padding-right': '20px'
                    }
                },
                {
                    index: 'duration',
                    name: 'Common.DurationM',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    valueRange: null,
                    type: 'NUMBER',
                    hasMinutes: true,
                    css: {
                        'min-width': '140px',
                        'width': '140px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return humanizeMinutes(v.duration);
                    }
                },
                {}
            ];
        }

        this.changeColumn();
    }

    getCategories() {
        this._api.Company.categories().subscribe((categories) => {
            this.categories = categories.map(val => {
                return {
                    id: val.id,
                    value: val.name
                }
            });
            this.createTable();
        });
    }

    ngOnInit(): void {
        this.data.type === 'training' ? this.getTrainings(this.filtersString) : this.getPaths(this.filtersString);
        this.selectedItem = this.data.ids;
        this.setSelectedIds();
        this.getCategories();
    }

    setSelectedIds(){
        this.data.ids.forEach((e)=>{
            this.selectedIds.push(e.id);
        });
    }

    changeColumn() {
        if (this.data.type === 'multi-path') {
            this.columns[3] = {
                index: 'totalElementsCount',
                name: 'Common.Elements',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'min-width': '138px',
                    'width': '138px',
                    'padding-right': '20px'
                }
            }
        }
    }

    getTrainings(filtersString = ''): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        this.filtersString = filtersString === '' ? `status[]=active&status[]=inactive&isTemplate=0&pathCreator=1` :
        filtersString + `&status[]=active&status[]=inactive&isTemplate=0&pathCreator=1`;
        this.searchRequest = this._api.User.getCreatorTrainings(
            req.sort.field,
            req.sort.direction,
            this.filtersString
        ).subscribe(res => {
                this.items = req.pagination.pageNumber === 1 ? res.elements : this.items.concat(res.elements);
                this.request.pagination.totalElementsCount = res.totalElementsCount;
                this.request.pagination.totalPages = res.totalPages;
                this.request.waitingForData = false;
                this.items.forEach((e) => {
                    if (this.selectedIds.includes(e.id)) {
                        e.selected = true;
                    }
                })
            }
        )
    }

    getPaths(filtersString = ''): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        this.filtersString = filtersString === '' ? `status[]=active&status[]=inactive&pathCreator=1` :
        filtersString + `&status[]=active&status[]=inactive&pathCreator=1`;

        this.request.waitingForData = true;
        this.searchRequest = this._api.User.getCreatorPaths(
            req.sort.field,
            req.sort.direction,
            this.filtersString
        ).subscribe(res => {
                this.items = req.pagination.pageNumber === 1 ? res.elements : this.items.concat(res.elements);
                this.request.pagination.totalElementsCount = res.totalElementsCount;
                this.request.pagination.totalPages = res.totalPages;
                this.request.waitingForData = false;
                this.items.forEach((e) =>{
                    if(this.selectedIds.includes(e.id)){
                        e.selected = true;
                    }
                });
            }
        )
    }

    choiceData() {
        if (!this.selectedItem) {
           return '';
        }

        this.dialogRef.close({
            selectedItems: this.selectedItem
        });
    }

    selectItem(item: any) {
        this.selectedItem = item;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
