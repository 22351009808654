<h1 mat-dialog-title tabIndex="0">
    {{'Common.AddFile' | translate}}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content class="text-center">
    <div *ngIf="uploadInProgress" class="d-flex justify-content-center" style="height: 65px;align-items: center;">
        <div style="margin-right: 10px;width: 25px;">{{uploadedPercentage}}%</div>
        <mat-progress-bar mode="determinate" [value]="uploadedPercentage"></mat-progress-bar>
    </div>

    <div class="d-flex justify-content-center align-items-center" *ngIf="selectedFile !== null && !uploadInProgress">
        {{getFileName()}} ({{getFileSize()}})
        <button (click)="removeFile()" class="remove-btn" mat-icon-button>
            <mat-icon class="material-icons">close</mat-icon>
        </button>
    </div>
    <p *ngIf="errorMessage !== null" style="color:red">{{errorMessage}}</p>
    <button *ngIf="selectedFile === null" (click)="selectFile()" color="primary" mat-raised-button
            class="btn-w-md">{{'Common.SelectFile' | translate}}</button>
    <input type="file" #file style="display: none;" (change)="onFileInputChange()"/>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button mat-button [disabled]="uploadInProgress" (click)="dialogRef.close()"
            class="cancel-button">{{'Common.Cancel' | translate}}</button>
    <button mat-button (click)="acceptFile()" color="primary" [disabled]="selectedFile === null || uploadInProgress"
            class="dolineo-button blue-button">{{'Common.Accept' | translate}}</button>
</div>