<h1 mat-dialog-title tabIndex="0">
    {{'ProductGroupsDialog.ProductGroupShow' | translate}}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content class="product-groups">
    <span>{{'ProductGroupsDialog.OccurrenceInProductGroups' |translate}}</span>
    <span *ngFor="let productGroup of data.productGroups">
        - {{productGroup}}
    </span>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button mat-button color="primary" (click)="dialogRef.close()"
            class="dolineo-button blue-button">{{'Common.Close' | translate}}</button>
</div>