<div id="notification">
    <div class="table-wrapper" *ngIf="formMode">
        <div class="box box-default">
            <div class="notification-form">
                <form [formGroup]="form">
                    <h3 style="font-size: 18px;margin: 15px 0;">{{formTitle}}</h3>
                    <div class="form-group" style="margin-top: 20px;">
                        <mat-form-field class="full-width">
                            <input matInput formControlName="title" placeholder="{{'Notifications.Title' | translate}}">
                            <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                        </mat-form-field>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('title', '[nazwa szkolenia]')">
                            [{{'Common.TrainingName' | translate | lowercase}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('title', '[nazwa ścieżki]')">
                            [{{'Common.PathName' | translate | lowercase}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('title', '[nazwa testu]')">
                            [{{'Common.PoolName' | translate | lowercase}}]
                        </button>
                        <button mat-flat-button color="accent"
                                (click)="addPlaceholder('title', '[imię i nazwisko podwładnego]')">
                            [{{'Notifications.SubordinateNameParam' | translate}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('title', '[imię i nazwisko]')">
                            [{{'Notifications.FullNameParam' | translate}}]
                        </button>
                    </div>

                    <br/><br/><br/>
                    <div class="form-group">
                        <div id="editor-wrapper">
                            <label>{{'Notifications.Content' | translate}}</label>
                            <div id="quill-editor"></div>
                            <mat-error *ngIf="contentError === true">
                                <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                            </mat-error>
                        </div>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[hasło]')">
                            [{{'Notifications.PassParam' | translate}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[imię]')">
                            [{{'Notifications.FirstNameParam' | translate}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[login]')">
                            [{{'Notifications.Login' | translate}}]
                        </button>
                        <button mat-flat-button color="accent"
                                (click)="addPlaceholder('content', '[link aktywacyjny]')">
                            [{{'Notifications.ActivationLink' | translate}}]
                        </button>
                        <button mat-flat-button color="accent"
                                (click)="addPlaceholder('content', '[link reset hasla]')">
                            [{{'Notifications.PasswordResetLink' | translate}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[link do Dolineo]')">
                            [{{'Notifications.LinkParam' | translate}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[nazwa szkolenia]')">
                            [{{'Common.TrainingName' | translate | lowercase}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[nazwa ścieżki]')">
                            [{{'Common.PathName' | translate | lowercase}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[nazwa testu]')">
                            [{{'Common.PoolName' | translate | lowercase}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[imię i nazwisko]')">
                            [{{'Notifications.FullNameParam' | translate}}]
                        </button>
                        <button mat-flat-button color="accent" (click)="addPlaceholder('content', '[liczba]')">
                            [{{'Notifications.NumParam' | translate}}]
                        </button>
                    </div>

                    <div class="form-group">
                        <mat-radio-group [(ngModel)]="notification.type" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button value="email"
                                              color="primary">{{'Powiadomienie e - mail' | translate}}</mat-radio-button>
                            <mat-radio-button value="system" color="primary"
                                              [disabled]="formTitle == 'Zmiana hasła' || formTitle == 'Aktywacja konta użytkownika' || formTitle == 'Zaproszenie do platformy' || formTitle == 'Odzyskanie hasła'">
                                {{'Notifications.SystemNotification' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="form-group text-center">
                        <button mat-flat-button color="primary" (click)="save()"
                                style="margin-right: 10px;">{{'Common.Accept' | translate}}</button>
                        <button mat-flat-button color="primary"
                                (click)="cancel()">{{'Common.Cancel' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!formMode">
        <div class="data-grid-header">
            <div class="data-grid-header__left">
                <span matTooltip="{{'Common.LoadedElements' | translate}}"
                      matTooltipPosition="above"
                      matTooltipClass='above'>
                    {{notifications.length}}
                </span>
                <span style="white-space: pre"> {{'Common.Of' | translate}} </span>
                <span matTooltip="{{'Common.AllElements' | translate}}"
                      matTooltipPosition="above"
                      matTooltipClass='above'>
                    {{notifications.length}}
                </span>
            </div>
        </div>

        <app-data-grid [columns]="columns"
                       [items]="notifications"
                       [request]="request"
                       (loadData)="getData()"
                       [noTabs]="false"
                       [selection]="false">
        </app-data-grid>
    </ng-container>
</div>
