import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appSideBarScroll]'
})
export class SideBarScrollDirective implements AfterViewInit {
    el: ElementRef;
    marginTop: number = 72;
    viewMax: number = 0;
    detailsHeight: number = 0;
    containerHeight: number = 0;

    @HostListener('scroll', ['$event.target'])
    scroll() {
        const $details = $('#details');
        const $contentTopScroll = $(this.el.nativeElement).scrollTop();
        if (this.detailsHeight > this.viewMax) {
            if ($contentTopScroll < this.detailsHeight - this.viewMax + 10) {
                $($details).parent().css('marginTop', `${this.marginTop - $contentTopScroll}px`);
                $($details).parent().css('position', `absolute`);
                $($details).css('maxHeight', `none`);
            } else {
                $($details).parent().css('position', `fixed`);
                $($details).css('maxHeight', `${this.viewMax}px`);
                $($details).scrollTop($contentTopScroll);
            }
        } else {
            $($details).parent().css('position', `fixed`);
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        const $details = $('#details');
        const $contentTopScroll = $(window).scrollTop();
        if (this.detailsHeight > this.viewMax) {
            if ($contentTopScroll < this.detailsHeight - this.viewMax + 10) {
                $($details).parent().css('marginTop', `${this.marginTop - $contentTopScroll}px`);
                $($details).parent().css('position', `absolute`);
                $($details).css('maxHeight', `none`);
            } else {
                $($details).parent().css('position', `fixed`);
                $($details).css('maxHeight', `${this.viewMax}px`);
                $($details).scrollTop($contentTopScroll);
            }
        } else {
            $($details).parent().css('position', `fixed`);
        }
    }

    constructor(el: ElementRef) {
        this.el = el;
    }

    ngAfterViewInit() {
        const windowHeight = $(window).height();
        const $container = $(this.el.nativeElement).find('#container');
        const $details = $(this.el.nativeElement).find('#details');

        const headerHeight = 60;
        const footerHeight = 80;
        this.viewMax = windowHeight - headerHeight - footerHeight;
        this.detailsHeight = $details.height() + this.marginTop;
        this.containerHeight = $container.height();
        $container.css('paddingBottom', `${this.detailsHeight - this.containerHeight + 32}px`);
    }
}
