import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserSelfInterface } from '../../shared/intefaces/user-self.interface';
import { GuidInterface } from '../../shared/intefaces/guide.interface';
import { LanguageInterface } from '../../shared/intefaces/language.interface';
import {IUserInfo} from '../../shared/intefaces/interfaces';

@Injectable()
export class UserService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    public guides: BehaviorSubject<GuidInterface[]> = new BehaviorSubject<GuidInterface[]>([]);

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public logout(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/logout`, null, { headers });
    }

    public logoutFromAllDevices(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/logout-all-devices`, null, { headers });
    }

    public user(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}`, { headers });
    }

    public userInfo(id: number): Observable<IUserInfo> {
        const headers = this.defaultHeaders;
        return this.http.get<IUserInfo>(`${this.basePath}/user-info/${id}`, { headers });
    }

    public avatars(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/avatar`, { headers });
    }

    public create(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user`, body, { headers });
    }

    public update(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/user/${id}`, body, { headers });
    }

    public activateUser(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${id}/activation`, body, { headers });
    }

    public systemUserCreate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/create-system-user`, body, { headers });
    }

    public systemUserUpdate(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/update-system-user/${id}`, body, { headers });
    }

    public changePassword(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${id}/change-password`, body, { headers });
    }

    public staySignedIn(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${id}/stay-signed-in`, body, { headers });
    }

    public paths(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public trainings(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public certificates(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/certificate?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public downloadCertificate(idUser: number, userCertId: number): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.get<HttpResponse<Blob>>(`${this.basePath}/user/${idUser}/certificate/${userCertId}/pdf`, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public myPaths(id: number, params: string, pageNumber?: any, pageSize?: any, sortBy?: any, order?: any): Observable<any> {
        const headers = this.defaultHeaders;
        const extraParams = pageNumber ? `&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}` : '';
        return this.http.get<any>(`${this.basePath}/user/${id}/development/path?${params}${extraParams}`, { headers });
    }

    public myPathsTrainings(id: number, params: string, order?: any): Observable<any> {
        const headers = this.defaultHeaders;
        const extraParams = `&order=${order}`;
        return this.http.get<any>(`${this.basePath}/user/${id}/development/path-training?${params}${extraParams}`, { headers });
    }

    public myPools(id: number, params: string, pageNumber?: any, pageSize?: any, sortBy?: any, order?: any): Observable<any> {
        const headers = this.defaultHeaders;
        const extraParams = pageNumber ? `&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}` : '';
        return this.http.get<any>(`${this.basePath}/user/${id}/development/pools?${params}${extraParams}`, { headers });
    }

    myCategoriesPath(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/development/path/categories`, { headers });
    }

    myCategoriesTraining(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/development/training/categories`, { headers });
    }

    public myTrainings(id: number, params: string, pageNumber?: any, pageSize?: any, sortBy?: any, order?: any): Observable<any> {
        const headers = this.defaultHeaders;
        const extraParams = pageNumber ? `&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}` : '';
        return this.http.get<any>(`${this.basePath}/user/${id}/development/training?${params}${extraParams}`, { headers });
    }

    public avatar(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${id}/avatar`, body, { headers });
    }

    public toDoMyProgress(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/development/todo`, { headers });
    }

    public downloadPathTable(id: number, params: string): string {
        return `/user/${id}/development/path/xls?${params}`;
    }

    public downloadTrainingTable(id: number, params: string): string {
        return `/user/${id}/development/training/xls?${params}`;
    }

    public downloadPoolTable(id: number, params: string): string {
        return `/user/${id}/development/pools/xls?${params}`;
    }

    public self(isAutoReload = false): Observable<UserSelfInterface> {
        const headers = this.defaultHeaders;
        return this.http.get<UserSelfInterface>(`${this.basePath}/self${isAutoReload ? '?autoReload=1' : ''}`, { headers });
    }

    public favouriteTraining(idUser: number, idTraining: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/favourite/training`, { trainingId: idTraining }, { headers });
    }

    public unFavouriteTraining(idUser: number, idTraining: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/user/${idUser}/favourite/training/${idTraining}`, { headers });
    }

    public favouritePath(idUser: number, idPath: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/favourite/path`, { pathId: idPath }, { headers });
    }

    public unFavouritePath(idUser: number, idPath: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/user/${idUser}/favourite/path/${idPath}`, { headers });
    }

    public calendar(idUser: number, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/calendar${params}`, { headers });
    }

    public getPath(idUser: number, pathId: number, preview: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/path/${pathId}` + (preview ? '?preview=1' : ''), { headers });
    }

    public getTraining(idUser: number, trainingId: number, fromPathId: number = null, preview: boolean = false, fromOnboardingId: number = null, fromGamificationId: number = null,): Observable<any> {
        const headers = this.defaultHeaders;
        const fromPath = fromPathId ? `&fromPathId=${fromPathId}` : '';
        const fromOnboarding = fromOnboardingId ? `&fromOnboardingId=${fromOnboardingId}` : '';
        const fromGamification = fromGamificationId ? `&fromGamificationId=${fromGamificationId}` : '';
        return this.http.get<any>(`${this.basePath}/user/${idUser}/training/${trainingId}?a=1${fromPath}${fromOnboarding}${fromGamification}` + (preview ? '&preview=1' : ''), { headers });
    }

    public updateTrainingVideoProgress(idUser: number, trainingId: number, video: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/training/${trainingId}/video-progress`, video, { headers });
    }

    public updateTrainingScormProgress(idUser: number, trainingId: number, scormFile: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/training/${trainingId}/scorm-progress`, scormFile, { headers });
    }

    public updateTrainingMediaProgress(idUser: number, trainingId: number, media: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/training/${trainingId}/media-progress`, media, { headers });
    }

    public updateTrainingH5pProgress(idUser: number, trainingId: number, media: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/training/${trainingId}/h5p-progress`, media, { headers });
    }

    public notifications(idUser: number, sortBy: any, order: any, pageSize: any, pageNumber: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/notification?sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&pageNumber=${pageNumber}&${params}`, { headers });
    }

    public notificationsUnreadCount(idUser: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/notification/unread-count`, { headers });
    }

    public changeNotificationSeenStatus(idUser: number, notificationId: number, seenval: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/notification/${notificationId}/seen/${seenval}`, { headers });
    }

    public setAllNotificationStatusAsSeen(idUser: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/notification/set-seen-all`, { headers });
    }

    public groups(idUser: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/group`, { headers });
    }

    public removeFromGroup(idUser: number, idGroup: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/user/${idUser}/group/${idGroup}`, { headers });
    }

    public search(idUser: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/search`, { ...body }, { headers });
    }

    public resetPassword(idUser: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/reset-password`, { headers });
    }

    /* creator */
    public myCreatedTrainings(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/training/editor-list?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCreatorTrainings(sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/training/creator-list?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public myCreatedPaths(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/path/editor-list?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCreatorPaths(sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/path/creator-list?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getDolineoCreatorPaths(sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/path/dolineo-creator-list?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getDolineoCreatorTrainings(sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/training/dolineo-creator-list?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    // Training
    public finishTraining(idUser: number, trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/training/${trainingId}/finish`, {}, { headers });
    }

    public startTraining(idUser: number, trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/training/${trainingId}/start`, {}, { headers });
    }

    // Pools
    public myCreatedPools(type: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getPool(id, userId, withAnswers = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/pools/${id}` + (withAnswers ? '?withAnswers=1' : ''), { headers });
    }

    public getPoolAttempt(userId, poolId, attemptId): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/pools/${poolId}/attempt/${attemptId}`, { headers });
    }

    public startPool(idUser: number, poolId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/pools/${poolId}/attempt/start`, { headers });
    }

    public updatePoolAttempt(idUser: number, poolId: number, poolAttemptId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/pools/${poolId}/attempt/${poolAttemptId}/update`, body, { headers });
    }

    public checkUserAnswer(idUser: number, questionId, poolAttemptsQuestionId, body): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${idUser}/question/${questionId}/${poolAttemptsQuestionId}`, body,{ headers });
    }

    public removePoolAttempt(idUser: number, poolId: number, poolAttemptId: number, questionId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/user/${idUser}/pools/${poolId}/attempt/${poolAttemptId}/question/${questionId}`, { headers });
    }

    // Categories
    public categories(type: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/category/user?type=${type}`, { headers });
    }

    public getInterfaceLanguages(): Observable<LanguageInterface[]> {
        const headers = this.defaultHeaders;
        return this.http.get<LanguageInterface[]>(`${this.basePath}/language/interface`, { headers });
    }

    public getMaintenanceMode(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/maintenance`, { headers });
    }

    public updateStartArticle(userId: number, articleId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/knowledge-entry/${articleId}/start`, {}, { headers });
    }

    public finishedArticle(userId: number, articleId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/knowledge-entry/${articleId}/finish`, {}, { headers });
    }
}