import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import Quill from 'quill';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CertificatePreviewModalComponent } from '../certificate-preview-modal/certificate-preview-modal.component';
const QuillBlock = Quill.import('blots/block');
class QuillBlockDivBlock extends QuillBlock {
    static blockName = 'div';
    static tagName = 'div';
}
Quill.register(QuillBlockDivBlock);

import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

@Component({
    selector: 'app-config-certificate-dialog',
    templateUrl: './config-certificate-dialog.component.html',
    styleUrls: ['./config-certificate-dialog.component.scss']
})
export class ConfigCertificateDialogComponent implements OnInit, OnDestroy {
    public certificates: any[] = [];
    private editor: Quill = null;
    form: FormGroup;

    toolbarOptions = [
        ['bold', 'italic'],
        ['underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],

        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }]
    ];
    subscriptions: Subscription = new Subscription();

    constructor(public dialogRef: MatDialogRef<ConfigCertificateDialogComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private api: ApiService,
                private _dialog: MatDialog) {
        this.form = this.initForm();
    }

    ngOnInit(): void {
        const options = {
            modules: {
                toolbar: [...this.toolbarOptions],
                imageResize: {
                    modules: [ 'Resize', 'DisplaySize' ]
                }
            },
            theme: 'snow',
            scrollingContainer: '.mat-horizontal-content-container',
            clipboard: {
                matchVisual: false
            }
        };
        this.getCertificates();

        this.subscriptions.add(this.form.get('twoSidesCertificate').valueChanges.subscribe(value => {
            if (value) {
                setTimeout(() => {
                    document.getElementById('quill-editor').innerHTML = this.form.get('certificateContent').value.replaceAll('ql-cursor', '');
                    this.editor = new Quill('#quill-editor', options);
                }, 400);
            }
        }));

        if (this.data) {
            this.form.patchValue(this.data);
            this.data.group = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getCertificates() {
        this.subscriptions.add(this.api.Certificate.getCertificateTemplates('name', 'asc', 'active[]=active').subscribe((res) => {
            this.certificates = [...res.elements];
        }));
    }

    initForm(): FormGroup {
        return this.fb.group({
            relatedObjectType: ['training'],
            twoSidesCertificate: [false],
            selectedCertificate: [null, Validators.required],
            certificateContent: [''],
            group: false
        });
    }

    previewCertificate(): void {

        if (this.form.get('twoSidesCertificate').value) {
            const content = (document.getElementById('quill-editor').firstChild as HTMLElement).innerHTML.replaceAll('ql-cursor', '');
            this.form.get('certificateContent').setValue(content === '<p><br></p>' ? null : content);
        } else {
            this.form.get('certificateContent').setValue(null);
        }

        this.subscriptions.add(this.api.Certificate.getTemplate(this.form.get('selectedCertificate').value.id).subscribe(value => {
            this._dialog.open(CertificatePreviewModalComponent, {
                disableClose: true,
                data: {
                    dataSend: true,
                    previewCreator: false,
                    certData: this.previewData(value)
                }
            })
        }));
    }

    previewData(response) {
        const data = {
            backgroundBase64: response.backgroundImage,
            dolineoLogoPosition: response.dolineoLogoPosition,
            orgLogoPosition: response.orgLogoPosition,
            text: response.content,
            secondPage: false,
            secondPageContent: ''
        };

        if (this.form.get('twoSidesCertificate').value) {
            data.secondPage = true;
            data.secondPageContent = this.form.get('certificateContent').value
        }

        return data;
    }

    addCertificate() {
        this.form.get('certificateContent').setValue(this.form.get('twoSidesCertificate').value ?
            (document.getElementById('quill-editor').firstChild as HTMLElement).innerHTML.replaceAll('ql-cursor', '') : null);
        this.form.markAllAsTouched();

        if ((this.form.get('selectedCertificate').value && this.form.get('certificateContent').value !== '<p><br></p>') && this.form.valid) {

            this.dialogRef.close({
                relatedObjectType: this.form.get('relatedObjectType').value,
                certificateName: this.form.get('selectedCertificate').value.name,
                certificateTemplateId: this.form.get('selectedCertificate').value.id,
                twoSidesCertificate: this.form.get('twoSidesCertificate').value,
                certificateContent: this.form.get('certificateContent').value
            });
        }
    }

    compareCertificatesObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }
}
