import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { HelperService } from '../../services/helper.service';
import { RequestInterface } from '../../intefaces/pagination.interface';
import { humanizeMinutes } from '../../helpers';

@Component({
    selector: 'app-add-from-library',
    templateUrl: './add-from-library.component.html',
    styleUrls: ['./add-from-library.component.scss']
})
export class AddFromLibraryComponent implements OnInit, OnDestroy {

    step = -1;
    isInvalid = false;
    hasKnowledgeBase: boolean = false;

    hasTests: boolean = null;
    hasPaths: boolean = null;
    hasSurveys: boolean = null;
    hasTraining: boolean = null;
    hasKnowledgeBaseEntry: boolean = null;

    waiting: boolean;

    selectedSectionName = 'paths';

    chosenPaths = [];
    chosenTraining = [];
    chosenTests = [];
    chosenSurveys = [];
    chosenKnowledgeBaseEntry = [];

    filtersStringPath = '';
    filtersStringTraining = '';
    filtersStringTest = '';
    filtersStringSurvey = '';
    filtersStringKnowledgeBaseEntry = '';

    paths = [];
    trainings = [];
    tests = [];
    surveys = [];
    knowledgeBaseEntry = [];

    pathsColumns = [];
    trainingsColumns = [];
    testsColumns = [];
    surveysColumns = [];
    knowledgeBaseEntryColumns = [];

    public searchRequestPath: any = null;
    public searchRequestTraining: any = null;
    public searchRequestTest: any = null;
    public searchRequestSurvey: any = null;
    public searchRequestKnowledgeBaseEntry: any = null;

    public requestTest: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    public requestSurvey: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    public requestTrainings: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    public requestPaths: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    public requestKnowledgeBaseEntry: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };


    constructor(public dialogRef: MatDialogRef<AddFromLibraryComponent>,
                @Inject(MAT_DIALOG_DATA,) public data: any,
                private _api: ApiService,
                private datePipe: DatePipe,
                private _translate: TranslateService,
                private _helper: HelperService) {
        this.hasKnowledgeBase = this._helper.getUser().subscriptions.indexOf('knowledge_base') > -1
    }

    ngOnInit(): void {
        this.createPathTable();
        this.createTrainingTable();
        this.createTestTable();
        this.createSurveyTable();

        this.getTrainings();
        this.getPaths();
        this.getTests();
        this.getSurveys();

        if (this.hasKnowledgeBase) {
            this.createKnowledgeBaseTable();
            this.getKnowledgeBase();
        }
    }

    ngOnDestroy() {
        // document.body.classList.add('nav-collapsed');
    }

    setStep(index: number, name: string) {
        this.selectedSectionName = name;
        this.step = this.step === index ? -1 : index;
        // document.body.classList.add('nav-collapsed');
    }

    addElements() {
        if (this.isInvalid) {
            return;
        }

        if (!this.chosenPaths.length && !this.chosenTraining.length && !this.chosenTests.length && !this.chosenSurveys.length
            && (!this.chosenKnowledgeBaseEntry.length && this.hasKnowledgeBase)) {
            this.isInvalid = true;
            return;
        }

        if (this.chosenPaths.length) {
            const pathsIds = [];
            const pathsIdsIndex = [];
            this.chosenPaths.forEach((path, index) => {
                pathsIds.push(path.idPath);
                pathsIdsIndex[path.idPath] = index;
            });

            const req = this.requestPaths;

            if (this.searchRequestPath) {
                this.searchRequestPath.unsubscribe()
            }

            this.searchRequestPath = this._api.Admin.selfPathsByIds(pathsIds).subscribe(res => {
                res.forEach( r => {
                    this.chosenPaths[pathsIdsIndex[r.idPath]] = r;
                })

                this.dialogRef.close({
                    chosenPaths: this.chosenPaths,
                    chosenTraining: this.chosenTraining,
                    chosenTests: this.chosenTests,
                    chosenSurveys: this.chosenSurveys,
                    chosenKnowledgeBaseEntry: this.chosenKnowledgeBaseEntry.map(value => {
                        return {
                            ...value,
                            duration: value.durationTime
                        }
                    }),
                })
            })
        } else {
            this.dialogRef.close({
                chosenPaths: this.chosenPaths,
                chosenTraining: this.chosenTraining,
                chosenTests: this.chosenTests,
                chosenSurveys: this.chosenSurveys,
                chosenKnowledgeBaseEntry: this.chosenKnowledgeBaseEntry.map(value => {
                    return {
                        ...value,
                        duration: value.durationTime
                    }
                }),
            })
        }


    }

    createPathTable() {
        this.pathsColumns = [
            {
                index: 'name',
                name: 'Common.PathName',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                },
                class: 'ft-auto'
            },
            {
                index: 'authorName',
                name: 'Common.Author',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '162px',
                    'width': '162px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return `${v.authorName} ${v.authorSurname} `;
                }
            },
            {
                index: 'totalElementsCount',
                name: 'Common.Elements',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'min-width': '100px',
                    'width': '100px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'duration',
                name: 'Common.DurationM',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: true,
                css: {
                    'min-width': '140px',
                    'width': '140px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return humanizeMinutes(v.duration);
                }
            }
        ];
    }

    createTrainingTable() {
        this.trainingsColumns = [
            {
                index: 'name',
                name: 'Common.TrainingName',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                },
                class: 'ft-auto'
            },
            {
                index: 'authorName',
                name: 'Common.Author',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '162px',
                    'width': '162px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return `${v.authorName} ${v.authorSurname} `;
                }
            },
            {
                index: 'duration',
                name: 'Common.DurationM',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: true,
                css: {
                    'min-width': '140px',
                    'width': '140px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return humanizeMinutes(v.duration);
                }
            }
        ];
    }

    createTestTable() {
        this.testsColumns = [
            {
                index: 'name',
                name: 'Common.PoolName',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                class: 'ft-auto',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px'
                }
            },
            {
                index: 'author',
                name: 'Common.Author',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '142px',
                    'width': '142px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'timeLimit',
                name: 'Common.DurationM',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: true,
                css: {
                    'min-width': '140px',
                    'width': '140px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return humanizeMinutes(v.timeLimit);
                }
            }
        ];
    }

    createSurveyTable() {
        this.surveysColumns = [
            {
                index: 'name',
                name: 'Common.SurveyName',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                class: 'ft-auto',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px'
                }
            },
            {
                index: 'authorName',
                name: 'Common.Author',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '142px',
                    'width': '142px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'isAnonymous',
                name: 'Common.Anonymous',
                value: null,
                defaultValue: null,
                visible: true,
                type: 'ARRAY',
                sortable: true,
                searchable: true,
                multiple: false,
                options: [
                    { id: '1', value: 'Common.Yes' },
                    { id: '0', value: 'Common.No' }
                ],
                css: {
                    'min-width': '120px',
                    'width': '120px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.isAnonymous ? this._translate.instant('Common.Yes') : this._translate.instant('Common.No');
                }
            }
        ];
    }

    createKnowledgeBaseTable() {
        this.knowledgeBaseEntryColumns = [
            {
                index: 'name',
                name: 'KnowledgeBase.ArticleName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                class: 'ft-auto',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px'
                }
            },
            {
                index: 'author',
                name: 'Common.Author',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '142px',
                    'width': '142px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'durationTime',
                name: 'KnowledgeBase.ReadingTime',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: true,
                css: {
                    'min-width': '160px',
                    'width': '160px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return humanizeMinutes(v.durationTime);
                }
            }
        ];
    }

    getTrainings(filtersString = ''): void {
        const req = this.requestTrainings;

        if (this.searchRequestTraining) {
            this.searchRequestTraining.unsubscribe()
        }

        this.filtersStringTraining = filtersString !== '' ?
            'status[]=active&status[]=inactive&isCreator=1&withDolineo=1&' + filtersString :
            'status[]=active&status[]=inactive&isCreator=1&withDolineo=1';

        this.requestTrainings.waitingForData = true;
        this.searchRequestTraining = this._api.Admin.selfTrainings(
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.filtersStringTraining,
            this.data.exclude.trainings
        ).pipe(
            map(res => {
                res.elements = res.elements.filter(item => {
                    item.objectType = 'training';
                    item.objectId = item.idTraining;
                    item.trainingType = item.type;
                    item.trainingAction = item.action;

                    return true;
                });
                return res;
            })
        ).subscribe(res => {
                if (this.filtersStringTraining === 'status[]=active&status[]=inactive&isCreator=1&withDolineo=1') {
                    this.hasTraining = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1 || this.hasTraining;
                    this.waiting = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1;
                }

                this.trainings = req.pagination.pageNumber === 1 ? [...res.elements] : this.trainings.concat(res.elements);
                this.requestTrainings.pagination.totalElementsCount = res.totalElementsCount
                this.requestTrainings.pagination.totalPages = res.totalPages
                this.requestTrainings.waitingForData = false;
            },
            () => {
                this.requestTrainings.waitingForData = false;
            }
        )
    }

    getPaths(filtersString = ''): void {
        const req = this.requestPaths;

        if (this.searchRequestPath) {
            this.searchRequestPath.unsubscribe()
        }

        this.filtersStringPath = filtersString !== '' ?
            'status[]=active&status[]=inactive&isCreator=1&withDolineo=1&' + filtersString :
            'status[]=active&status[]=inactive&isCreator=1&withDolineo=1';
        this.requestPaths.waitingForData = true;

        this.searchRequestPath = this._api.Admin.selfPaths(
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.filtersStringPath,
            0,
            this.data.exclude.paths
        ).subscribe(res => {
                if (this.filtersStringPath === 'status[]=active&status[]=inactive&isCreator=1&withDolineo=1') {
                    this.hasPaths = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1 || this.hasPaths;
                    this.waiting = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1;
                }
                this.paths = req.pagination.pageNumber === 1 ? [...res.elements] : this.paths.concat(res.elements);
                this.requestPaths.pagination.totalElementsCount = res.totalElementsCount;
                this.requestPaths.pagination.totalPages = res.totalPages;
                this.requestPaths.waitingForData = false;
                this.waiting = true;
            },
            () => {
                this.requestPaths.waitingForData = false;
            }
        )
    }

    getTests(filtersString = ''): void {
        const req = this.requestTest;

        if (this.searchRequestTest) {
            this.searchRequestTest.unsubscribe()
        }

        this.filtersStringTest = filtersString !== '' ?
            'status[]=active&status[]=inactive&isCreator=1&withDolineo=1&' + filtersString :
            'status[]=active&status[]=inactive&isCreator=1&withDolineo=1';
        this.requestTest.waitingForData = true;
        this.searchRequestTest = this._api.Admin.selfPools(
            'TEST',
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.filtersStringTest,
            this.data.exclude.pools
        ).pipe(
            map(res => {
                res.elements = res.elements.filter(item => {
                    item.objectType = 'pools';
                    item.objectId = item.id;

                    return true;
                });

                return res;
            })
        ).subscribe(res => {
                if (this.filtersStringTest === 'status[]=active&status[]=inactive&isCreator=1&withDolineo=1') {
                    this.hasTests = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1 || this.hasTests;
                    this.waiting = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1;
                }

                this.tests = req.pagination.pageNumber === 1 ? res.elements : this.tests.concat(res.elements);

                this.requestTest.pagination.totalElementsCount = res.totalElementsCount
                this.requestTest.pagination.totalPages = res.totalPages
                this.requestTest.waitingForData = false;
                this.waiting = true;
            },
            () => {
                this.requestTest.waitingForData = false;
            });
    }

    getSurveys(filtersString = ''): void {
        const req = this.requestSurvey;

        if (this.searchRequestSurvey) {
            this.searchRequestSurvey.unsubscribe()
        }

        this.filtersStringSurvey = filtersString !== '' ?
            `status[]=active&status[]=inactive&isCreator=1&withDolineo=1&${filtersString}` :
            `status[]=active&status[]=inactive&isCreator=1&withDolineo=1`;
        this.requestSurvey.waitingForData = true;
        this._api.Survey.selfSurvey(
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.filtersStringSurvey,
            this.data.exclude.survey
        ).pipe(
            map(res => {
                res.elements = res.elements.filter(item => {
                    item.objectType = 'survey';
                    item.objectId = item.id;

                    return true;
                });

                return res;
            })
        ).subscribe(res => {
                if (this.filtersStringSurvey === 'status[]=active&status[]=inactive&isCreator=1&withDolineo=1') {
                    this.hasSurveys = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1 || this.hasSurveys;
                    this.waiting = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1;
                }

                this.surveys = req.pagination.pageNumber === 1 ? res.elements : this.surveys.concat(res.elements);

                this.requestSurvey.pagination.totalElementsCount = res.totalElementsCount
                this.requestSurvey.pagination.totalPages = res.totalPages
                this.requestSurvey.waitingForData = false;
                this.waiting = true;
            },
            () => {
                this.requestSurvey.waitingForData = false;
            });
    }

    getKnowledgeBase(filtersString = ''): void {
        const req = this.requestKnowledgeBaseEntry;

        if (this.searchRequestKnowledgeBaseEntry) {
            this.searchRequestKnowledgeBaseEntry.unsubscribe()
        }

        this.filtersStringKnowledgeBaseEntry = filtersString !== '' ? 'status[]=active&status[]=inactive&isCreator=1&' + filtersString : 'status[]=active&status[]=inactive&isCreator=1';
        this.requestSurvey.waitingForData = true;
        this._api.KnowledgeBaseService.getArticles(
            'knowledge-base',
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.filtersStringKnowledgeBaseEntry,
            this.data.exclude.knowledgeBaseEntry
        ).pipe(
            map(res => {
                res.elements = res.elements.filter(item => {
                    item.objectType = 'knowledge-base-entry';
                    item.objectId = item.id;

                    return true;
                });

                return res;
            })
        ).subscribe(res => {
                this.hasKnowledgeBaseEntry = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1 || this.hasKnowledgeBaseEntry;
                this.waiting = (req.pagination.pageNumber === 1 && res.elements.length !== 0) || req.pagination.pageNumber > 1;

                this.knowledgeBaseEntry = req.pagination.pageNumber === 1 ? res.elements : this.knowledgeBaseEntry.concat(res.elements);
                this.requestKnowledgeBaseEntry.pagination.totalElementsCount = res.totalElementsCount;
                this.requestKnowledgeBaseEntry.pagination.totalPages = res.totalPages;
                this.requestKnowledgeBaseEntry.waitingForData = false;
            },
            () => {
                this.requestSurvey.waitingForData = false;
            });
    }

    selectedAddItems(items: [], name: string) {
        this.isInvalid = false;

        switch (name) {
            case 'paths': {
                this.chosenPaths = [...items];
                break;
            }
            case 'trainings': {
                this.chosenTraining = [...items];
                break;
            }
            case 'tests': {
                this.chosenTests = [...items];
                break;
            }
            case 'surveys': {
                this.chosenSurveys = [...items];
                break;
            }
            case 'knowledgeBaseEntry': {
                this.chosenKnowledgeBaseEntry = [...items];
                break;
            }
        }
    }
}
