import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Pipe({
    name: 'translateTo'
})
export class TranslateToPipe implements PipeTransform {

    constructor(private _translate: TranslateService, private http: HttpClient) {
    }

    transform(nameKey: string, lang: string): Observable<string> {
        return this.http.get(`/assets/i18n/${lang}.json`).pipe(
            map((res) => {
                const translate = Object.entries(res).find(([key]) => nameKey === key);
                return translate ? translate[1] as string : nameKey;
            })
        );
    }
}