<div class="widget-container"
     *ngIf="items && defaultItems && !waiting"
     [ngClass]="('widget-container-gamification' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name"
         [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''"
    >{{ 'DashboardCreator.Gamification' | translate}}</div>

    <div class="widget-container__content">
        <div class="widget-container__content--description">
            <owl-carousel-o [options]="gamificationCarousel"
                            appShowHiddenCarousel
                            lengthTile="{{ gamifications.length }}">
                <ng-template carouselSlide *ngFor="let gamification of gamifications">
                    <div class="slide">
                        <div class="widget-container__content--description--text"
                             [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''">{{ 'DashboardWidgetGamification.Ranking' | translate}}
                        <span class="completed" [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''">{{ gamification.rankingPlace }}</span>
                        <span [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''">/ {{ gamification.ranking.length }}</span>
                        </div>
                        <div class="widget-container__content--description--gamification-name"
                             (click)="goTo(gamification.id)"
                             [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''">{{ gamification.name }}</div>
                    </div>
                    <div class="widget-container--gamification">
                        <div class="widget-container--gamification--realization">
                            <div class="progress-chart">
                                <ngx-charts-pie-chart
                                        [view]="viewPie"
                                        [scheme]="colorScheme"
                                        [doughnut]="true"
                                        [tooltipDisabled]="true"
                                        [arcWidth]="0.3"
                                        [results]="gamification.pieChartData">
                                </ngx-charts-pie-chart>

                                <div class="progress-chart__number" [style.background-color]="defaultElmentsColor !== widget.elementsColor ? hexToRGB('#' + widget.backgroundColor,0.75) : ''">
                                    <span class="progress-chart__number--result" [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''">
                                        {{ gamification.elementsFinishedCount / gamification.totalElementsCount * 100 | number: '1.0-0'}}
                                        <span [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''"> % </span>
                                    </span>
                                    <span class="progress-chart__number--points" [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''">
                                        <div class="number" [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''">{{ gamification.totalPoints}}</div>
                                        <div class="label" [style.color]="defaultElmentsColor !== widget.elementsColor ? '#' + widget.elementsColor : ''">{{ ('GamificationCreator.PointsShortcut' | translate) }}</div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>

