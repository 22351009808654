<div class="table-wrapper" tableWrapper>
    <div class="box box-default table-box">
        <div class="table-header" #tableHeader style="display: none;">
        </div>

        <div class="flex-table">
            <div #flexTableHead class="ft-head">
                <div class="ft-cell ft-center"
                     style="width: 45px; min-width: 45px; align-items: center; flex-direction: column; justify-content: center;"
                     *ngIf="selection">
                    <mat-checkbox (change)="selectAll($event)" [checked]="_selectedAll" color="primary"
                                  style="margin-left: 5px">&nbsp;
                    </mat-checkbox>
                    <div class="number-of-selected"
                         matTooltip="{{'Common.SelectedElements' | translate}}"
                         matTooltipPosition="below"
                         matTooltipClass='below-top'>
                        {{selectedItems}}
                    </div>
                </div>

                <div class="ft-cell ft-center" style="width: 45px;min-width: 45px;" *ngIf="radioSelect">
                </div>

                <ng-container *ngFor="let column of _columns; let idx  = index;">
                    <div *ngIf="column.visible" [className]="'ft-cell ' + (column.class || '')"
                         [ngStyle]="column.css || null">

                        <ng-container *ngIf="column.searchable && column.visible">
                            <!-- STRING -->
                            <mat-form-field *ngIf="column.type === 'STRING'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [(ngModel)]="column.value"
                                       matInput
                                       placeholder="{{ 'Common.Enter' | translate}}"
                                       (keyup)="search()">
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; search()"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'>
                                        close
                                    </i>
                                </button>
                            </mat-form-field>

                            <!-- NUMBER -->
                            <mat-form-field *ngIf="column.type === 'NUMBER'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [(ngModel)]="column.value"
                                       matInput
                                       readonly="readonly"
                                       [matMenuTriggerFor]="numberDialog"
                                       placeholder="{{ 'Common.Choose' | translate}}">
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; column.valueRange=''; search()"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <mat-menu #numberDialog="matMenu">
                                <app-selection-of-numbers-dialog
                                        [title]="(column.name || column.index) | translate"
                                        [hasMinutes]="column.hasMinutes"
                                        [isClearInput]="column.value"
                                        (saveNumber)="setNumber(column, $event); search()">
                                </app-selection-of-numbers-dialog>
                            </mat-menu>

                            <!-- ARRAY -->
                            <mat-form-field *ngIf="column.type === 'ARRAY'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value && column.valueRange.length}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <mat-select [(ngModel)]="column.value"
                                            placeholder="{{'Common.Choose' | translate}}"
                                            [multiple]="column.multiple"
                                            (selectionChange)="setArray(column, $event);">
                                    <mat-option value="novalue" *ngIf="column.novalue">{{ 'Common.NoValue' | translate }}</mat-option>
                                    <mat-option *ngFor="let option of column.options || []"
                                                [value]="option.id">{{ option.value | translate}}</mat-option>
                                </mat-select>
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="(column.value !=='' || column.value !== null ) && column.valueRange?.length"
                                        (click)="column.value=''; column.valueRange=''; search()"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- DATE_RANGE -->
                            <mat-form-field *ngIf="column.type === 'DATE_RANGE'" appearance="fill"
                                            [floatLabel]="'always'" [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [id]="'dt_date_range' + idx"
                                       matInput
                                       ngxDaterangepickerMd
                                       showCancel="true"
                                       showClearButton="true"
                                       placeholder="{{'Common.Choose' | translate}}"
                                       readonly="readonly"
                                       (click)="openDatePicker('dt_date_range' + idx)"
                                       [timePicker]="column.timePicker || false"
                                       [timePicker24Hour]="true"
                                       [locale]="getLocale(column)"
                                       [(ngModel)]="column.valueRange"
                                       [showDropdowns]="true"
                                       [lockStartDate]="false"
                                       [customRangeDirection]="false"
                                       (ngModelChange)="search()">
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.valueRange"
                                        (click)="column.value=''; column.valueRange=''; search()"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- DATE_RANGE_ARRAY -->
                            <mat-form-field *ngIf="column.type === 'DATE_RANGE_ARRAY'" appearance="fill"
                                            [floatLabel]="'always'" [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [id]="'dt_date_range' + idx"
                                       matInput
                                       ngxDaterangepickerMd
                                       showCancel="true"
                                       showClearButton="true"
                                       placeholder="{{'Common.Choose' | translate}}"
                                       readonly="readonly"
                                       (click)="openDatePicker('dt_date_range' + idx)"
                                       [timePicker]="column.timePicker || false"
                                       [timePicker24Hour]="true"
                                       [locale]="getLocale(column)"
                                       [(ngModel)]="column.valueRange"
                                       [showDropdowns]="true"
                                       [lockStartDate]="false"
                                       [customRangeDirection]="false"
                                       (ngModelChange)="search()">
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.valueRange"
                                        (click)="column.value=''; column.valueRange=''; search()"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>
                        </ng-container>

                        <span class="header-text" *ngIf="!column.searchable"
                              [innerHTML]="((column.type !== 'TOGGLE' && column.type !== 'CHECKBOX'|| column.type === 'AVATAR' && column.name) ? column.name || column.index : '') | translate">
                        </span>

                        <div *ngIf="column.sortable" (click)="changeSort(column.index, column.type)" class="ft-sort">
                            <i [ngClass]="(sort.field !== column.index || sort.field === column.index && sort.direction === 'asc') ?
                            'arrow_downward' : 'arrow_upward'"
                               matTooltip="{{'Common.Sort' | translate}}"
                               matTooltipPosition="below"
                               matTooltipClass='below-top'>
                            </i>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="ft-body" appDataGridScroll>
                <ng-container *ngIf="!!_items">
                    <div class="ft-row" *ngFor="let item of _items; let rowIdx = index;">
                        <div class="ft-cell ft-center" style="width: 45px;min-width: 45px;" *ngIf="selection">
                            <mat-checkbox [(ngModel)]="item.selected"
                                          (change)="selectedCheckbox($event, rowIdx)"
                                          *ngIf="item.disabled !== undefined || item.disabled !== null ? !item.disabled : true"
                                          [disabled]="item.disabled ? item.disabled : false"
                                          color="primary">
                            </mat-checkbox>
                        </div>

                        <div class="ft-cell ft-center" style="width: 45px;min-width: 45px;" *ngIf="radioSelect"
                             [ngClass]="{'selectedRow': rowIdx === selectedIndex}">
                            <mat-radio-button (change)="selectRadio(item, rowIdx)" color="primary">&nbsp;
                            </mat-radio-button>
                        </div>

                        <ng-container *ngFor="let column of _columns; let colIdx = index;">
                            <div *ngIf="column.visible" [className]="'ft-cell ' + (column.class || '')"
                                 [ngClass]="{'ft-cell-menu': column.type === 'MENU'}" [ngStyle]="column.css || null">
                                <ng-container *ngIf="column.type !== 'MENU' && column.type !== 'TOGGLE' &&
                                     column.type !== 'CHECKBOX' && column.type !== 'AVATAR' &&
                                     column.type !== 'SELECT'">

                                    <div *ngIf="!column.click && column.render"
                                         style="width: 90%"
                                         [innerHTML]="column.render(item)"></div>

                                    <div *ngIf="column.click && column.render"
                                         style="width: 90%"
                                         (click)="column.click(item)"
                                         [innerHTML]="column.render(item)"></div>

                                    <span *ngIf="!column.render && column.click" (click)="column.click(item)"
                                          class="cut-text-table">{{ item[column.index] | translate}}</span>
                                    <span *ngIf="!column.render && !column.click"
                                          class="cut-text-table">{{ item[column.index] | translate}}</span>
                                </ng-container>

                                <ng-container *ngIf="column.type === 'MENU'">
                                    <ng-container *ngFor="let menuItem of column.items; let menuIdx = index;">
                                        <a *ngIf="!menuItem.items && (menuItem.visible === undefined || menuItem.visible(item) === true)"
                                           class="ft-button"
                                           matTooltip="{{menuItem.name | translate}}"
                                           matTooltipPosition="below"
                                           matTooltipClass='below-top'
                                           (click)="menuItem.click(item)"
                                           [innerHTML]="menuItem.icon"></a>
                                        <ng-container
                                                *ngIf="menuItem.items?.length && (menuItem.visible === undefined || menuItem.visible(item) === true)">
                                            <a class="ft-button"
                                               [matMenuTriggerFor]="menu"
                                               [innerHTML]="menuItem.icon"
                                               matTooltip="{{menuItem.name | translate}}"
                                               matTooltipPosition="below"
                                               matTooltipClass='below-top'
                                               style="justify-content: center"></a>
                                            <mat-menu #menu="matMenu" class="mat-menu-small">
                                                <ng-container *ngFor="let subMenuItem of menuItem.items;">
                                                    <button *ngIf="subMenuItem.name !== '-' && (subMenuItem.visible === undefined || subMenuItem.visible(item) === true)"
                                                            mat-menu-item (click)="subMenuItem.click(item)">
                                                        {{ subMenuItem.name | translate }}
                                                    </button>
                                                    <mat-divider *ngIf="subMenuItem.name === '-'"></mat-divider>
                                                </ng-container>
                                            </mat-menu>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
