<div class="widget-container"
     *ngIf="items && defaultItems && !waiting"
     [ngClass]="('widget-container-new-paths-trainings' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__content">
        <div class="widget-container__content--description">
            <owl-carousel-o [options]="newPathsTrainingsCarousel"
                            appShowHiddenCarousel
                            lengthTile="{{ newPathsTrainingsObj.length }}">
                <ng-template carouselSlide *ngFor="let newPathsTrainings of newPathsTrainingsObj">
                    <div class="slide" [ngStyle]="{'background-image': 'url(' + newPathsTrainings.image + ')'}">
                        <div class="widget-container__content--description--new-paths-trainings-name"
                             (click)="goTo(newPathsTrainings)">
                            <span>{{ newPathsTrainings.name }}</span>
                        </div>
                        </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>

