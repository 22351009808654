<div *ngIf="layout" class="subDomain">
    <div class="subDomain__left">
        <div class="subDomain__left__header">
            <div class="logo-container">
                <img src="{{apiUrl + layout.logoPath}}" alt="logo" [ngStyle]="{'height': layout.logoHeight+'px'}"
                     class="logo"
                     *ngIf="layout.logoPath">
            </div>
            <div class="lang-container" *ngIf="layout.showLanguages">
                <div [matMenuTriggerFor]="lang" class="cursor-pointer">
                    <span class="current-lang">{{(currentLang === 'uk' ? 'ua' : currentLang) | uppercase}}</span>
                    <span>
                        <img src="/assets/icons/angle-down.svg" alt="angle-down">
                    </span>
                </div>
                <mat-menu #lang="matMenu">
                    <button mat-menu-item *ngFor="let lang of layout.languages"
                            (click)="setLang(lang.shortName)"
                            class="language-button">
                        <span class="lang-check"><i *ngIf="currentLang === lang.shortName" class="dolineo-icon dolineo-icon-check"></i></span>
                        <span class="lang-name">{{ 'Language.'+lang.shortName | translate}}</span>
                        <span class="lang-code">{{(lang.shortName === 'uk' ? 'ua' : lang.shortName)  | uppercase}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="subDomain__left__container">
            <form *ngIf="tokenIsValid"
                  [formGroup]="loginForm"
                  (ngSubmit)="submit()"
                  class="form-validation">
                <fieldset>
                    <div class="form__header" *ngIf="activation">
                        <h1>{{'ResetPassword.ActivateYourAccount' | translate}}</h1>
                        <p>{{'ResetPassword.ToActivateTheAccountEnterTheNewPasswordTwice' | translate}}</p>
                    </div>
                    <div class="form__header" *ngIf="!activation">
                        <h1>{{'ResetPassword.SetAPassword' | translate}}</h1>
                        <p>{{'ResetPassword.EnterTheNewPasswordTwice' | translate}}</p>
                    </div>

                    <div class="form-group">
                        <i class="dolineo-icon dolineo-icon-lock2"></i>
                        <mat-form-field class="full-width">
                            <input required matInput type="password" name="password"
                                   blockWhiteSpace
                                   placeholder="{{'ResetPassword.EnterANewPassword' | translate}}" formControlName="password">
                            <mat-error>{{ validation.password }}</mat-error>
                            <mat-error
                                    *ngIf="loginForm.get('password').hasError('required')">
                                {{ 'Common.FiledCanNotBeEmpty' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <i class="dolineo-icon dolineo-icon-lock2"></i>

                        <mat-form-field class="full-width">
                            <input required matInput type="password" name="confirmPassword"
                                   placeholder="{{'ResetPassword.RepeatNewPassword' | translate}}"
                                   blockWhiteSpace
                                   formControlName="confirmPassword">
                            <mat-error>{{ validation.confirmPassword }}</mat-error>
                            <mat-error
                                    *ngIf="loginForm.get('confirmPassword').hasError('required')">
                                {{ 'Common.FiledCanNotBeEmpty' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <span class="text-muted" style="font-size: 12px; text-align: center;color: #959D9F;">{{'ResetPassword.AtLeast8Characters1Digit1LowercaseLetter1UppercaseLetter1SpecialCharacter' | translate}}</span>

                    <button mat-raised-button
                            type="submit"
                            color="primary"
                            class="float-right">{{ (activation ? 'Account.Activate' : 'Account.ChangePass') | translate }}</button>

                    <div class="forgot-password">
                        <a [routerLink]="['/login']">{{'Login.GoBackToTheLoginPage' | translate}}</a>
                    </div>
                </fieldset>
            </form>

            <form class="form-validation"  *ngIf="tokenIsValid !== null && tokenIsValid === false">
                <fieldset>
                    <div class="form__header">
                        <h1>{{'ResetPassword.InvalidLink' | translate}}</h1>
                        <p>{{'Account.InvalidTokenActivation' | translate}}</p>
                    </div>

                    <button mat-raised-button [routerLink]="['/login']" color="primary">
                        {{'Login.GoBackToTheLoginPage' | translate}}
                    </button>
                </fieldset>
            </form>
        </div>
        <div class="subDomain__left__footer">
            <div class="footer-logo">
                <img [src]="logoUrl" [alt]="logoAlt">
            </div>
            <div class="footer-documents">
                <a href="{{apiUrl + policyUrl}}" target="_blank" *ngIf="policyUrl">
                    {{'Login.PrivacyPolicy' | translate}}
                </a>
                <a href="{{apiUrl + termsUrl}}" target="_blank" *ngIf="termsUrl">
                    {{'Login.Regulations' | translate}}
                </a>
                <a *ngIf="layout.helpdeskEmail" href="mailto:{{layout.helpdeskEmail}}">{{'Help desk:'}} {{layout.helpdeskEmail}}</a>
            </div>
        </div>
    </div>
    <div class="subDomain__right"
         [ngStyle]="{'background-image': layout.imagePath? 'url(' + apiUrl + layout.imagePath + ')': '', 'color': layout.color }"
         style="text-align: {{layout.textAlign}};">
        <div class="header">
            {{header}}
        </div>
        <div class="description" [innerHTML]="description">
        </div>
    </div>
</div>
