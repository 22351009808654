import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-selection-of-numbers-dialog',
    templateUrl: './selection-of-numbers-dialog.component.html',
    styleUrls: ['./selection-of-numbers-dialog.component.scss']
})
export class SelectionOfNumbersDialogComponent {

    @Input() title: string;
    @Input() hasMinutes: boolean = false;

    @Input() set isClearInput(val) {
        if (!val) {
            this.clearInput()
        }
    };

    @Output() saveNumber = new EventEmitter();

    numberFrom: number | string;
    numberTo: number | string;

    constructor() {
        //
    }

    save() {
        this.saveNumber.emit({
            from: this.numberFrom,
            to: this.numberTo
        });
    }

    clearInput() {
        this.numberTo = '';
        this.numberFrom = '';
    }
}
