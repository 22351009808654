import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import {
    AddRemoveVisibilityDialogComponent
} from '../add-remove-visibility-dialog/add-remove-visibility-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { UsersVisibilityDialogComponent } from '../users-visibility-dialog/users-visibility-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as he from 'he';

@Component({
    selector: 'app-training-path-visibility-dialog',
    templateUrl: './training-path-visibility-dialog.component.html',
    styleUrls: ['./training-path-visibility-dialog.component.scss']
})
export class TrainingPathVisibilityDialogComponent implements OnInit, OnDestroy {
    step = 0;

    public availabilityGroups = [];
    public availabilityPositions = [];
    public availabilityUsers = [];

    public usersSearchRequest: any = null;
    public positionsSearchRequest: any = null;
    public groupsSearchRequest: any = null;

    usersColumns: any[] = [];
    positionsColumns: any[] = [];
    groupsColumns: any[] = [];

    usersColumnsAvailability: any[] = [];
    positionsColumnsAvailability: any[] = [];
    groupsColumnsAvailability: any[] = [];

    usersItems: any[] = [];
    positionsItems: any[] = [];
    groupsItems: any[] = [];

    usersFilters = '';
    positionsFilters = '';
    groupsFilters = '';

    hasUsers: boolean;
    hasPositions: boolean;
    hasGroups: boolean;

    positions: any[] = [];

    public usersRequest = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: -159,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    public positionsRequest = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: -159,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    public groupsRequest = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: -159,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    usersSelectedToAdd: any[] = [];
    positionsSelectedToAdd: any[] = [];
    groupsSelectedToAdd: any[] = [];
    usersSelectedToRemove: any[] = [];
    positionsSelectedToRemove: any[] = [];
    groupsSelectedToRemove: any[] = [];
    selectedSectionName: string = 'users';

    users = [];
    userToPreview = [];

    translations = {
        title: 'ShareUsers',
        accessAdded: 'AccessAdded',
        accessRemoved: 'AccessRemoved'
    };

    constructor(public dialogRef: MatDialogRef<TrainingPathVisibilityDialogComponent>,
                private _toastrService: ToastrService,
                private api: ApiService,
                private _helper: HelperService,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.availabilityGroups = this.data.availabilityGroups;
        this.availabilityUsers = this.data.availabilityUsers;
        this.availabilityPositions = this.data.availabilityPositions;

        this.availabilityUsers.forEach(u => {
            u.positionsArray = [];
            if (u.position) {
                u.positionsArray = [u.position];
            }
        });
        this.availabilityPositions.forEach(u => {
            u.positionsArray = [];
            if (u.name) {
                u.positionsArray = [u.name];
            }
        });

        let transDict = '';
        if (this.data.type === 'path') {
            transDict = 'PathCreator.';
        } else if (this.data.type === 'training') {
            transDict = 'TrainingCreator.';
        }
        this.translations.title = this._translate.instant(transDict + this.translations.title);
        this.translations.accessAdded = this._translate.instant(transDict + this.translations.accessAdded);
        this.translations.accessRemoved = this._translate.instant(transDict + this.translations.accessRemoved);
    }

    ngOnInit(): void {
        this.getUsers();

        this.getGroups();

        this.getPositions();
    }

    ngOnDestroy() {
        // document.body.classList.add('nav-collapsed');
    }

    setStep(index: number, name: string) {
        this.selectedSectionName = name;
        this.step = this.step === index ? -1 : index;
        // document.body.classList.add('nav-collapsed');
    }

    createUsersColumn() {
        this.usersColumns = [
            {
                index: 'name',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '121px',
                },
                class: 'ft-auto'
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '119px',
                    'width': '119px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'min-width': '117px',
                    'width': '117px'
                },
                novalue: true
            }
        ];

        this.usersColumnsAvailability = [
            {
                index: 'name',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '121px',
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + he.encode(v.name) + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewUser')} </span>`;
                    } else {
                        return he.encode(v.name);
                    }
                }
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '119px',
                    'width': '119px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'min-width': '117px',
                    'width': '117px'
                },
                novalue: true
            }
        ];
    }

    createPositionsColumn() {
        this.positionsColumns = [
            {
                index: 'name',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                },
                class: 'ft-auto',
                render: (v) => {
                    return v.name;
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                hasMinutes: false,
                type: 'NUMBER',
                css: {
                    'width': '140px',
                    'padding-right': '20px'
                }
            }
        ];

        this.positionsColumnsAvailability = [
            {
                index: 'name',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + he.encode(v.name) + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewPosition')} </span>`;
                    } else {
                        return he.encode(v.name);
                    }
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                hasMinutes: false,
                type: 'NUMBER',
                css: {
                    'width': '140px',
                    'padding-right': '20px'
                }
            }
        ];
    }

    createGroupsColumn() {
        this.groupsColumns = [
            {
                index: 'groupName',
                name: 'Common.Group',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                class: 'ft-auto',
                css: {
                    'min-width': '250px'
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                hasMinutes: false,
                type: 'NUMBER',
                css: {
                    'width': '140px',
                    'padding-right': '20px'
                }
            }
        ];
        this.groupsColumnsAvailability = [
            {
                index: 'groupName',
                name: 'Common.Group',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                class: 'ft-auto',
                css: {
                    'min-width': '250px'
                },
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + he.encode(v.groupName) + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewGroup')} </span>`;
                    } else {
                        return he.encode(v.groupName);
                    }
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                hasMinutes: false,
                type: 'NUMBER',
                css: {
                    'width': '140px',
                    'padding-right': '20px'
                }
            }
        ];
    }

    getUsers(filtersString = ''): void {
        const req = this.usersRequest;

        if (this.usersSearchRequest) {
            this.usersSearchRequest.unsubscribe()
        }

        this.usersFilters = filtersString ? filtersString + '&isActive=1' : 'isActive=1';
        this.usersRequest.waitingForData = true;
        this.usersSearchRequest = this.api.Organization.users(
            this._helper.getUser().companyId,
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.usersFilters).subscribe(res => {
                res.elements.forEach(u => {
                    u.positionsArray = [];
                    if (u.position) {
                        u.positionsArray = [u.position];
                    }
                    u.groups = [...u.groups, this._translate.instant('Common.AllUsers')];
                });

                if (!filtersString) {
                    this.users = req.pagination.pageNumber === 1 ? res.elements : this.users.concat(res.elements);
                    this.prepareUserToPreview();
                }

                if (res.elements.length !== 0 && req.pagination.pageNumber === 1) {
                    this.hasUsers = true;
                }
                this.usersItems = req.pagination.pageNumber === 1 ?
                    res.elements.filter(item => !this.availabilityUsers.find(ele => ele.name === item.name)) :
                    this.usersItems.concat(res.elements).filter(item => !this.availabilityUsers.find(ele => ele.name === item.name))
                this.usersRequest.pagination.totalElementsCount = res.totalElementsCount;
                this.usersRequest.pagination.totalPages = res.totalPages;
                this.usersRequest.waitingForData = false;
            },
            () => {
                this.usersRequest.waitingForData = false;
            }
        )
    }

    getGroups(filtersString = ''): void {
        const req = this.groupsRequest;

        if (this.groupsSearchRequest) {
            this.groupsSearchRequest.unsubscribe()
        }

        this.groupsFilters = filtersString !== '' ? 'hidden=1&' + filtersString : 'hidden=1';
        this.groupsRequest.waitingForData = true;
        this.groupsSearchRequest = this.api.Company.groups(
            this._helper.getUser().companyId,
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.groupsFilters
        ).subscribe(res => {
                if (res.length !== 0 && req.pagination.pageNumber === 1) {
                    this.hasGroups = true;
                }
                this.groupsItems = [...res].map(value => {
                    return {
                        ...value,
                        groupName: this._translate.instant(value.groupName)
                    }
                }).filter(item => !this.availabilityGroups.find(ele => ele.groupName === item.groupName));
            },
            () => {
                this.groupsRequest.waitingForData = false;
            }
        )
    }

    getPositions(filtersString = ''): void {
        const req = this.positionsRequest;

        if (this.positionsSearchRequest) {
            this.positionsSearchRequest.unsubscribe()
        }

        this.positionsFilters = filtersString;
        this.positionsRequest.waitingForData = true;
        this.positionsSearchRequest = this.api.Company.positions(
            this._helper.getUser().companyId,
            req.pagination.pageNumber,
            req.pagination.pageSize,
            req.sort.field,
            req.sort.direction,
            this.positionsFilters
        ).subscribe(res => {
                if (res.length !== 0 && req.pagination.pageNumber === 1) {
                    this.hasPositions = true;
                }

                res.forEach(u => {
                    u.positionsArray = [];
                    if (u.name) {
                        u.positionsArray = [u.name];
                    }
                });

                this.positions = res.map(obj => {
                    return {
                        id: obj.name,
                        value: obj.name
                    };
                });

                this.createUsersColumn();

                this.createPositionsColumn();

                this.createGroupsColumn();

                this.positionsItems = [...res].filter(item => !this.availabilityPositions.find(ele => ele.name === item.name));
            },
            () => {
                this.positionsRequest.waitingForData = false;
            }
        )
    }

    close() {
        this.dialogRef.close({
            availabilityGroups: this.availabilityGroups.map(value => {
                return {
                    ...value,
                    isNew: false
                }
            }),

            availabilityPositions: this.availabilityPositions.map(value => {
                return {
                    ...value,
                    isNew: false
                }
            }),

            availabilityUsers: this.availabilityUsers.map(value => {
                return {
                    ...value,
                    isNew: false
                }
            })
        })
    }

    addAvailable(content: string) {
        this._dialog.open(AddRemoveVisibilityDialogComponent, {
            disableClose: true,
            width: '486px',
            data: {
                type: 'add',
                number: this[content + 'SelectedToAdd'].length,
                selectedTable: this.selectedSectionName
            }
        }).afterClosed().subscribe(val => {
            this[content + 'SelectedToAdd'] = this[content + 'SelectedToAdd'].map(value => {
                return {
                    ...value,
                    selected: false,
                    isNew: true
                }
            });

            if (val) {
                if (this.selectedSectionName === 'users') {
                    this.availabilityUsers = [...this.usersSelectedToAdd, ...this.availabilityUsers];
                    this.usersItems = this.usersItems.filter(item => !this.availabilityUsers.find(ele => ele.name === item.name));
                    this.usersSelectedToAdd = [];
                }

                if (this.selectedSectionName === 'positions') {
                    this.availabilityPositions = [...this.positionsSelectedToAdd, ...this.availabilityPositions];
                    this.positionsItems = this.positionsItems.filter(item => !this.availabilityPositions.find(ele => ele.name === item.name))
                    this.positionsSelectedToAdd = [];
                }

                if (this.selectedSectionName === 'groups') {
                    this.availabilityGroups = [...this.groupsSelectedToAdd, ...this.availabilityGroups];
                    this.groupsItems = this.groupsItems.filter(item => !this.availabilityGroups.find(ele => ele.groupName === item.groupName))
                    this.groupsSelectedToAdd = [];
                }

                if (this.data.shared) {
                    this.sharedElement();
                } else {
                    this._toastrService.success(this.translations.accessAdded);
                }
                this.prepareUserToPreview();
            }
        })
    }

    removeAvailable(content: string) {
        this._dialog.open(AddRemoveVisibilityDialogComponent, {
            disableClose: true,
            width: '486px',
            data: {
                type: 'remove',
                number: this[content + 'SelectedToRemove'].length,
                selectedTable: this.selectedSectionName
            }
        }).afterClosed().subscribe(val => {
            this[content + 'SelectedToRemove'] = this[content + 'SelectedToRemove'].map(value => {
                return {
                    ...value,
                    selected: false,
                    isNew: false
                }
            });

            if (val) {
                if (this.selectedSectionName === 'users') {
                    this.usersItems.push(...this.usersSelectedToRemove);
                    this.availabilityUsers = this.availabilityUsers.filter(item => !this.usersSelectedToRemove.find(ele => ele.name === item.name));
                    this.usersItems = this.usersItems.filter(item => !this.availabilityUsers.find(ele => ele.name === item.name));
                    this.usersSelectedToRemove = [];
                }

                if (this.selectedSectionName === 'positions') {
                    this.positionsItems.push(...this.positionsSelectedToRemove);
                    this.availabilityPositions = this.availabilityPositions.filter(item => !this.positionsSelectedToRemove.find(ele => ele.name === item.name))
                    this.positionsItems = this.positionsItems.filter(item => !this.availabilityPositions.find(ele => ele.name === item.name))
                    this.positionsSelectedToRemove = [];
                }

                if (this.selectedSectionName === 'groups') {
                    this.groupsItems.push(...this.groupsSelectedToRemove);
                    this.availabilityGroups = this.availabilityGroups.filter(item => !this.groupsSelectedToRemove.find(ele => ele.groupName === item.groupName))
                    this.groupsItems = this.groupsItems.filter(item => !this.availabilityGroups.find(ele => ele.groupName === item.groupName));
                    this.groupsSelectedToRemove = [];
                }

                if (this.data.shared) {
                    this.sharedElement('remove');
                } else {
                    this._toastrService.success(this.translations.accessRemoved);
                }

                this.prepareUserToPreview();
            }
        })
    }

    selectedAddItems(items: [], content: string) {
        this[content + 'SelectedToAdd'] = [...items];
    }

    selectedRemoveItems(items: [], content: string) {
        this[content + 'SelectedToRemove'] = [...items];
    }

    prepareUserToPreview() {
        this.userToPreview = this.users.map(value => {
            const assigned = [];

            const user = this.availabilityUsers.find(val => value.name === val.name);
            if (user) {
                assigned.push({
                    method: 'user',
                    user
                })
            }

            const position = this.availabilityPositions.find(val => value.position === val.name);
            if (position) {
                assigned.push({
                    method: 'position',
                    name: position.name
                })
            }

            const group = value.groups ? value.groups.filter(item => {
                const isInside = this.availabilityGroups.find(ele => ele.groupName === item)
                if (isInside) {
                    assigned.push({
                        method: 'group',
                        name: isInside.groupName
                    })
                }
                return isInside
            }) : [];

            return user || position || group.length ? {
                ...value,
                assigned
            } : null
        }).filter(value => value);
    }

    showUsers() {
        if (this.userToPreview.length) {
            this.userToPreview.forEach(value => {
                value.assigned.sort((a, b) => {
                    const dateA = a.expiryDate ? new Date(a.expiryDate) : new Date(8640000000000000)
                    const dateB = b.expiryDate ? new Date(b.expiryDate) : new Date(8640000000000000)
                    return dateA.getTime() - dateB.getTime();
                });
            })

            this._dialog.open(UsersVisibilityDialogComponent, {
                    disableClose: true,
                    width: '90vw',
                    maxWidth: '90vw',
                    height: '90vh',
                    data: {
                        headerText: 'AssignDialog.UsersWithAccess',
                        name: this.data.name,
                        items: this.userToPreview
                    }
                }
            );
        }
    }

    sharedElement(action = 'add') {
        const groups = this.availabilityGroups.map(val => val.groupId)
        const positions = this.availabilityPositions.map(val => val.id)
        const users = this.availabilityUsers.map(val => val.idUser)

        const successMessage = action === 'add' ? this.translations.accessAdded : this.translations.accessRemoved;

        if (this.data.type === 'path') {
            this.api.Admin.saveAvailabilityPathUsers(this.data.id, {
                group: [...groups],
                position: [...positions],
                user: [...users]
            }).subscribe(() => {
                this._toastrService.success(successMessage);
            }, () => {
                this._toastrService.error(this._translate.instant('Common.ErrRetry'));
            });
        } else if (this.data.type === 'training') {
            this.api.Admin.saveAvailabilityTrainingUsers(this.data.id, {
                group: [...groups],
                position: [...positions],
                user: [...users]
            }).subscribe(() => {
                this._toastrService.success(successMessage);
            }, () => {
                this._toastrService.error(this._translate.instant('Common.ErrRetry'));
            });
        }
    }
}
