import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'multiStatusView'
})
export class MultiStatusViewPipe implements PipeTransform {

    constructor(private _translate: TranslateService) {
    }

    transform(value: any[], ...args: unknown[]): unknown {
        const selected = value.filter(val => val.selected);
        if (selected.length > 1) {
            return `${this._translate.instant('Common.Chosen')} ${selected.length}`
        } else {
            return this._translate.instant(selected[0].name)
        }
    }
}
