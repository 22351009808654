<div class="no-permission">
    <div class="page-title">
        <back-button text="Common.AccessDenied"
                     [event]="true"
                     (onBackEvent)="goToMainPage()"></back-button>
    </div>

    <div class="no-permission__container">
        <img src="/assets/images/no-permission-background.png" alt="">
        <span>{{'NoPermission.YouDoNotHaveAccessToViewTheContentOfThisPage' | translate}}</span>
        <button (click)="goToMainPage()"
                mat-button
                color="primary"
                class="dolineo-button blue-button">
            {{'Common.GoBackToTheHomePage' | translate}}
        </button>
    </div>
</div>