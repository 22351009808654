import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Sub modules
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeFr from '@angular/common/locales/fr';
import localeUk from '@angular/common/locales/uk';
import localeRo from '@angular/common/locales/ro';
import localeBg from '@angular/common/locales/bg';
import localeDe from '@angular/common/locales/de';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule } from '@angular/common/http';
import { HelperService } from './shared/services/helper.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BackButtonService } from './shared/services/back-button.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { LangTranslateLoader } from './core/langTranslateLoader';
import { TranslateLoader } from '@ngx-translate/core';
import { AppLocaleService } from './core/services/locale.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ToastrModule } from 'ngx-toastr';
import { TreeModule } from '@circlon/angular-tree-component';
import { SharedModule } from './shared/shared.module';
import { TranslationLoaderResolver } from './shared/services/translation-loader-resolver.service';
import { GlobalErrorHandler } from './api/global-error-handler.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {CookieService} from './shared/services/cookie.service';

registerLocaleData(localeFr);
registerLocaleData(localePl);
registerLocaleData(localeUk);
registerLocaleData(localeRo);
registerLocaleData(localeBg);
registerLocaleData(localeDe);

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ImageCropperModule,
        PdfViewerModule,

        // Core
        CoreModule,
        LoginModule,
        ApiModule,
        DragDropModule,
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useClass: LangTranslateLoader }
        }),
        TreeModule,
        NgxDaterangepickerMd.forRoot(),
        ToastrModule.forRoot({
            timeOut: 3000,
            // disableTimeOut: true,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        SharedModule,
        ServiceWorkerModule.register('custom-ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerImmediately'
        }),
        // RecaptchaModule,
        // RecaptchaFormsModule
    ],
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent],
    entryComponents: [],
    providers: [
        CookieService,
        HelperService,
        BackButtonService,
        AppLocaleService,
        TranslationLoaderResolver,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: LOCALE_ID,
            deps: [AppLocaleService],
            useFactory: (appLocaleService) => appLocaleService.getLanguage()
        }
    ]
})

export class AppModule {
//
}
