<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>
<div class="filter-status-dialog">
    <div mat-dialog-content>
        <span class="filter-status-dialog__header">{{'FilterStatus.Filters' |translate | uppercase}}</span>
        <div class="filter-status-dialog__container">
            <div class="filter-status-dialog__status" *ngIf="data.statuses">
                <div *ngFor="let status of data.statuses"
                     (click)="setStatus(status)"
                     class="filter-status-dialog__status__content cursor-pointer">
                    <div style="display: flex">
                        <img src="assets/icons/checked.svg" alt="checked" *ngIf="statues.includes(status)">
                    </div>
                    <span>{{status.name |translate}}</span>
                </div>
            </div>
        </div>

        <div class="filter-status-dialog__footer">
            <button (click)="dialogRef.close()"
                    mat-button
                    class="cancel-button">
                {{'Common.Cancel' | translate}}
            </button>
            <button mat-button
                    color="primary"
                    (click)="accept()"
                    class="dolineo-button blue-button">
                {{'Common.Apply' | translate}}
            </button>
        </div>
    </div>
</div>
