import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Quill from 'quill';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '../../validators/noWhitespaceValidator';
import {environment} from '../../../../environments/environment';
const QuillBlock = Quill.import('blots/block');
class QuillBlockDivBlock extends QuillBlock {
    static blockName = 'div';
    static tagName = 'div';
}
Quill.register(QuillBlockDivBlock);


@Component({
    selector: 'app-send-manual-email-dialog',
    templateUrl: './send-manual-email-dialog.component.html',
    styleUrls: ['./send-manual-email-dialog.component.scss']
})
export class SendManualEmailDialogComponent implements OnInit {
    markers: any[] = [
        {
            id: 'Fullname',
            name: this._translate.instant('NotificationCreator.Fullname'),
        },
        {
            id: 'Firstname',
            name: this._translate.instant('NotificationCreator.Firstname'),
        },
    ]

    public simpleForm: FormGroup;

    lastVisitedElement: string = 'title';
    content: string = '';
    contentError: boolean = false;
    setSender: boolean = true;

    private editor: Quill = null;

    toolbarOptions = [
        ['bold', 'italic'],
        ['underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }]
    ];
    setSelectionRange: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<SendManualEmailDialogComponent>,
                private _translate: TranslateService,
                private fb: FormBuilder) {
        if (environment.appName === 'dolineo') {
            this.markers.push({
                id: 'DolineoLink',
                name: this._translate.instant('NotificationCreator.DolineoLink'),
            });
        } else if (environment.appName === 'ontigo') {
            this.markers.push({
                id: 'OntigoLink',
                name: this._translate.instant('NotificationCreator.OntigoLink'),
            });
        }
    }

    ngOnInit(): void {
        this.editor = new Quill('#quill-editor', {
            modules: {
                toolbar: [...this.toolbarOptions]
            },
            theme: 'snow',
            scrollingContainer: '.mat-dialog-content',
            clipboard: {
                matchVisual: false
            }
        });

        this.simpleForm = this.initForm();
    }

    initForm(): FormGroup {
        return this.fb.group({
            title: ['', [Validators.required, noWhitespaceValidator]],
        });
    }

    addContentPlaceholder(marker) {
        const element = this.lastVisitedElement ? this.lastVisitedElement : 'title';

        if (element === 'title') {
            const title = this.simpleForm.get('title').value;
            if ((title.length + marker.length) < 150) {
                this.simpleForm.get('title').setValue(this.setSelectionRange ? title.slice(0, this.setSelectionRange) + ' ' +  marker + ' ' +  title.slice(this.setSelectionRange) : title + ' ' +  marker);
                this.setSelectionRange = null;
            }
        } else {
            this.editor.insertText(this.editor.getSelection(true).index, marker);
        }
    }

    changeLastVisitedElement(elementName, place: any = null) {
        if (elementName === 'title' && place) {
            this.setSelectionRange = place.selectionStart
        }

        this.lastVisitedElement = elementName;
    }
    
    sendEmails() {
        this.simpleForm.markAllAsTouched();

        const contentEditor: HTMLElement = document.getElementById('quill-editor').firstChild as HTMLElement;

        if (contentEditor.innerHTML.toString().replace(/<\/?[^>]+(>|$)/g, '').replace(/\s/g, '').replaceAll('ql-cursor', '') === '') {
            this.contentError = true;
            return '';
        }

        if (this.simpleForm.get('title').value.replace(/<\/?[^>]+(>|$)/g, '') === '') {
            return '';
        }

        let content = contentEditor.innerHTML.toString().replaceAll('ql-cursor', '');
        let title = this.simpleForm.get('title').value;
        this.markers.forEach(mark => {
            content = content.indexOf(mark.name) > -1 ? content.replaceAll(mark.name, `[${mark.id}]`) : content;
            title = title.indexOf(mark.name) > -1 ? title.replaceAll(mark.name, `[${mark.id}]`) : title;
        })

        const payload = {
            title,
            content,
            setSender: this.setSender
        };

        this.dialogRef.close(payload)
    }
}
