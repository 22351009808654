import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CertificateService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    public baseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public getCertificateTemplates(sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/certificate-template-list?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getTemplate(id): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/certificate-template/${id}`, { headers });
    }

    public editTemplate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/edit-certificate-template`, body, { headers });
    }

    public validateName(name: string, id = null): Observable<any> {
        const headers = this.defaultHeaders;
        let url = `${this.basePath}/certificate-template-name-validate?name=${name}`;
        if (id) {
            url = url + `&id=${id}`;
        }
        return this.http.get<any>(url, { headers });
    }

    public downloadPreview(body: any): Observable<HttpResponse<Blob>> {
        // @ts-disable
        // @ts-ignore
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/certificate-preview/no-obj`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public deleteTemplate(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            id
        };
        return this.http.post<any>(`${this.basePath}/delete-certificate-template`, body, { headers });
    }

    public duplicateTemplate(id: number, name: string): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            id,
            name
        };
        return this.http.post<any>(`${this.basePath}/duplicate-certificate-template`, body, { headers });
    }

    public getUsage(id): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/certificate-template-usage/${id}`, { headers });
    }

    public getUserCertificates(object, sort, userid = 0): Observable<any> {
        const headers = this.defaultHeaders;
        const url = `${this.basePath}/user-certificates?object=${object}&sort=${sort}&userid=${userid}`;
        return this.http.get<any>(url, { headers });
    }

    public getUserCertificateList(sortBy: string, order: string, params: string, userid = 0): Observable<any> {
        const headers = this.defaultHeaders;
        const url = `${this.basePath}/user-certificates-list?sortBy=${sortBy}&order=${order}&${params}&userid=${userid}`;
        return this.http.get<any>(url, { headers });
    }

    public downloadCertificate(certificateId: number, userid = 0): Observable<HttpResponse<Blob>> {
        const body = {
            certificate_id: certificateId,
            userid
        };
        // @ts-disable
        // @ts-ignore
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/certificate-download`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public previewCertificate(certificateId: number, userid = 0): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            certificate_id: certificateId,
            userid
        };
        // @ts-disable
        // @ts-ignore
        return this.http.post<any>(`${this.basePath}/certificate-preview`, body, {headers});
    }

    public certificateXLS(sortBy: string, order: string, params: string, columns: string): string {
        return `/certificate/xls?sortBy=${sortBy}&order=${order}&columns=${columns}&${params}`;
    }

    public userCertificateXLS(sortBy: string, order: string, params: string, userid = 0): string {
        return `/user-certificates-list/xls?sortBy=${sortBy}&order=${order}&${params}&userid=${userid}`;
    }

    public userCertificateZIP(ids, userid = 0): Observable<any> {
        const headers = this.defaultHeaders;
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/certificates-zip-download?ids=${ids}&userid=${userid}`, { headers }, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }
}
