import { Directive, ElementRef, HostListener } from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[appLowerCase]'
})
export class LowerCaseDirective {

    constructor(private el: ElementRef, private control : NgControl) {
    }

    @HostListener('blur') onBlur() {
        if (this.el.nativeElement.value) {
            const value = this.el.nativeElement.value.toLowerCase();
            this.el.nativeElement.value = value;
            this.control.control.setValue(value);
        }
    }
}
