<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>

<div class="onboarding-coordinator-task-confirmation">
    <h1 mat-dialog-title tabIndex="0">
        <span>{{ (data.hasMany ?  'Onboarding.CoordinatorSetTasksAsFinishedHeader' : 'Onboarding.CoordinatorSetTaskAsFinishedHeader') | translate}}</span>
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="many" *ngIf="data.hasMany">
            <div class="many--bold">{{ 'Onboarding.CoordinatorSetTasksAsFinishedConfirmation' | translate }}</div>
            <div class="many--green-circle">{{ data.count }}</div>
        </div>
        <div *ngIf="data.task && data.task.attachmentRequired === 1" [innerHTML]="'Onboarding.CoordinatorSetTaskAsFinishedConfirmationAttachmentRequired' | translate"></div>
        <div *ngIf="data.task && data.task.attachmentRequired === null" [innerHTML]="'Onboarding.CoordinatorSetTaskAsFinishedConfirmationAttachmentOptional' | translate"></div>
        <div *ngIf="!data.task || (data.task && data.task.attachmentRequired === 0)" [innerHTML]="'Onboarding.CoordinatorSetTaskAsFinishedConfirmation' | translate"></div>

        <div *ngIf="data.task && (data.task.attachmentRequired || data.task.attachmentRequired === null)" class="task-player">
            <div class="task-player__upload">
                <div class="flex-items">
                    <button class="download-button" mat-flat-button
                            type="button"
                            [ngClass]="{'opacity-30 cursor-default': !!selectedFile || !!filenamePreview}"
                            [disabled]="!!selectedFile || !!filenamePreview"
                            (click)="selectFile()">
                        <i class="dolineo-icon dolineo-icon-download2"></i>
                        {{ 'Common.AddFile' | translate }}
                    </button>
                    <input type="file" #file style="display: none;" (change)="onFileChange(null)"/>
                </div>

                <div class="drop-down-container">
                    <ngx-file-drop
                            dropZoneLabel="{{'PathCreator.DragAndDropTheFilePDFDOCDOCXPPTPPTXXLSXLSXOrOther' | translate}}"
                            (onFileDrop)="dropped($event)"
                            (click)="selectFile()"
                            multiple="false"
                            *ngIf="!filenamePreview"
                            directory="false"
                            class="cursor-pointer">
                    </ngx-file-drop>
                </div>

                <div *ngIf="fileError" style="margin-top:22px; font-size: 12px;">
                    <div>
                        <mat-error>{{ fileErrorMessage }}</mat-error>
                    </div>
                </div>

                <div class="file-container" *ngIf="selectedFile || filenamePreview">
                    <div class="flex-items">
                        <span> {{ filenamePreview }} </span>
                        <div>
                            <i (click)="downloadInputFile()"
                               matTooltip="{{'Common.Download' | translate}}"
                               matTooltipPosition="below"
                               matTooltipClass='below-top'
                               class="dolineo-icon dolineo-icon-download2"></i>
                            <i (click)="removeFile()"
                               *ngIf="!data.task.finishedAt"
                               matTooltip="{{'Common.Delete' | translate}}"
                               matTooltipPosition="below"
                               matTooltipClass='below-top'
                               class="dolineo-icon dolineo-icon-trash"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close(false)" mat-button
                class="cancel-button">{{(data.cancelText || 'Common.Cancel') | translate}}</button>

        <button (click)="confirm()" [disabled]="data.task && data.task.attachmentRequired === 1 && !selectedFile" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.IConfirm' | translate}}</button>
    </div>
</div>
