import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-approve-reject-task-dialog',
    templateUrl: './approve-reject-task-dialog.component.html',
    styleUrls: ['./approve-reject-task-dialog.component.scss']
})
export class ApproveRejectTaskDialogComponent {

    comment: string = '';
    error: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ApproveRejectTaskDialogComponent>) {
    }

    close() {
        if(this.data.devTips && this.data.showTextArea && !this.comment){
            console.log('error');
            this.error = true;
        }else{
            this.dialogRef.close({
                comment: this.comment
            })
        }
    }
}
