<div class="assign-unassign">
    <h1 mat-dialog-title tabIndex="0">
        {{ data.headerText | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="assign-unassign--length">
            <span>
                {{ data.contentText | translate}}
            </span>
            <div class="number-selected">
                {{data.selected.length}}
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button mat-flat-button (click)="dialogRef.close()" color="primary"
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button (click)="assign()"
                mat-button color="primary"
                class="dolineo-button blue-button">{{(data.status ? 'Common.Assign' : 'Common.IConfirm') | translate}}</button>
    </div>
</div>
