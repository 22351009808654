import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageInterface } from '../../shared/intefaces/language.interface';

@Injectable()
export class CompanyService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public groups(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/group?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public positions(id: number, pageNumber?: any, pageSize?: any, sortBy?: any, order?: any, params?: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/position?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public language(params: string = ''): Observable<LanguageInterface[]> {
        const headers = this.defaultHeaders;
        return this.http.get<LanguageInterface[]>(`${this.basePath}/language?${params}`, { headers });
    }

    public categories(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/category`, { headers });
    }

    public h5pSave(body: any): Observable<any> {
        const headers = new HttpHeaders();
        return this.http.post<any>(`${this.basePath}/h5p/save`, body, { headers });
    }

    public trainingUploadVideo(body: any): Observable<any> {
        const headers = new HttpHeaders();

        return this.http.post<any>(`${this.basePath}/training/upload-video`, body, { headers, reportProgress: true, observe: 'events' });
    }

    public trainingUploadThumb(body: any): Observable<any> {
        const headers = new HttpHeaders();
        return this.http.post<any>(`${this.basePath}/training/upload-thumb`, body, { headers, reportProgress: true, observe: 'events' });
    }

    public trainingUploadMedia(body: any): Observable<any> {
        const headers = new HttpHeaders();

        return this.http.post<any>(`${this.basePath}/training/upload-media`, body, { headers, reportProgress: true, observe: 'events' });
    }

    public trainingUploadScorm(body: any): Observable<any> {
        const headers = new HttpHeaders();

        return this.http.post<any>(`${this.basePath}/training/upload-scorm`, body, { headers, reportProgress: true, observe: 'events' });
    }

    public groupsTree(idCompany: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${idCompany}/group-tree`, { headers });
    }

    public groupsList(idCompany: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${idCompany}/group`, { headers });
    }

    public addScorm(file: any): Observable<any> {
        const formParams = new FormData();
        formParams.append('scorm', file);
        return this.http.post<any>(`${this.basePath}/moodle/scorm`, formParams, { reportProgress: true, observe: 'events' });
    }

    public addFile(file: any) {
        const formParams = new FormData();
        formParams.append('file', file);
        return this.http.post<any>(`${this.basePath}/moodle/media`, formParams, { reportProgress: true, observe: 'events' });
    }
}
