import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class NotificationService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    public baseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public getNotificationList(sortBy: string, order: string, pageSize: any, pageNumber: any, params: string, service = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/notification-list?sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&pageNumber=${pageNumber}&service=${service}&${params}`, { headers });
    }

    public setNotificationEmailState(id: number, state: number): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            id,
            state,
        };
        return this.http.post<any>(`${this.basePath}/notification/state/email`, body, { headers });
    }

    public setNotificationSystemState(id: number, state: number): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            id,
            state,
        };
        return this.http.post<any>(`${this.basePath}/notification/state/system`, body, { headers });
    }

    public changeDeadlines(id: number, days): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            id,
            days,
        };
        return this.http.post<any>(`${this.basePath}/notification/deadlines`, body, { headers });
    }

    public getNotification(id): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/notification/${id}`, { headers });
    }

    public editNotification(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/edit-notification`, body, { headers });
    }

    public getAutomaticNotificationsState(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/notification/automatic-state`, { headers });
    }

    public setAutomaticNotificationsState(state: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            state,
        };
        return this.http.post<any>(`${this.basePath}/notification/automatic-state`, body, { headers });
    }

    public notificationsXLS(sortBy: string, order: string, params: string, columns: string, service = ''): string {
        return `/notification/xls?sortBy=${sortBy}&order=${order}&service=${service}&columns=${columns}&${params}`;
    }

    public updateNotificationType(body: {}): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/notification/update-type`, body, { headers });
    }

    public propagateChanges(body: {}): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/notification/propagate-changes`, body, { headers });
    }

    public sendManualEmails(body: {}): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/notification/manual/email`, body, { headers });
    }

    public sendManualSystemNotification(body: {}): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/notification/manual/system`, body, { headers });
    }

    public getLogs(sortBy: string, order: string, pageSize: any, pageNumber: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        const url = `${this.basePath}/notification/logs?sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&pageNumber=${pageNumber}&${params}`;
        return this.http.get<any>(url, { headers });
    }

    public getLogsXLS(sortBy: string, order: string, params: string, columns: string): string {
        return `/notification/logs/xls?sortBy=${sortBy}&order=${order}&columns=${columns}&${params}`;
    }
}
