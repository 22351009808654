import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CertificatePreviewComponent } from '../../components/certificate-preview/certificate-preview.component';

@Component({
    selector: 'app-certificate-preview-modal',
    templateUrl: './certificate-preview-modal.component.html',
    styleUrls: ['./certificate-preview-modal.component.scss']
})
export class CertificatePreviewModalComponent implements OnInit {

    @ViewChild(CertificatePreviewComponent) previewComponent: CertificatePreviewComponent;

    constructor(public dialogRef: MatDialogRef<CertificatePreviewModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.previewComponent.patchValues(this.data);
        }, 0);
    }
}