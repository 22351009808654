<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>
<div class="users-visibility">
    <h1 mat-dialog-title tabIndex="0">
        <span>
            {{data.headerText | translate}}:
            <span style="color: #7D8485">{{data.name | capitalize}}</span>
        </span>
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.ComeOut' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="flex-item">
            {{'AssignDialog.ViewingTheFullListOfAllAssignedUsersAndSavingMethods' | translate}}
            <div class="number-selected">
                {{data.items.length}}
            </div>
        </div>
        <app-table-with-data
                [columns]="columns"
                [items]="data.items"
                [selection]="false">
        </app-table-with-data>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()"
                mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.ComeOut' | translate}}</button>
    </div>
</div>