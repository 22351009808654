import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({ selector: '[app-text-cap]' })

export class TextCapDirective implements AfterViewInit {
    el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;
    }

    ngAfterViewInit() {
        const $el = $(this.el.nativeElement);
        const container = $el[0];
        const p = container.querySelector('div');
        const divh = container.clientHeight;
        while (p.offsetHeight > divh) { // Check if the paragraph's height is taller than the container's height. If it is:
            p.textContent = p.textContent.replace(/\W*\s(\S)*$/, '...'); // add an ellipsis at the last shown space
        }
    }
}


