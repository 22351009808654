import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ReportService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    productGroupName = '';

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public create(area: string, url: string, method: string, filename: string, type: string, postData: any = null): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/report`, { area, url, method, filename, type, postData }, { headers });
    }

    public report(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/report/${id}`, {headers});
    }


    public reports(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, showOnlyLast = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/report?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&showOnlyLast=${showOnlyLast ? 1 : 0}&${params}`, {headers});
    }

    public remove(id: any): Observable<any> {
        return this.http.delete<any>(`${this.basePath}/report/${id}`);
    }
}
