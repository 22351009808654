import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appUpperCaseFirstLetter]'
})
export class UpperCaseFirstLetterDirective {

    constructor(private el: ElementRef,
                private control: NgControl) {
    }

    @HostListener('blur') onBlur() {
        if (this.el.nativeElement.value) {
            const arr: string[] = this.el.nativeElement.value.trim().split('');
            arr[0] = arr[0].toUpperCase();
            this.control.control.setValue(arr.join(''), { onlySelf: true, emitEvent: false });
        }
    }
}
