<div class="creator-info-dialog">
    <h1 mat-dialog-title tabIndex="0">
        <span>
            {{'Common.Tips' | translate}}: <span class="creator-info-dialog--header">{{data.head | translate | uppercase}}</span>
        </span>
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div *ngFor="let info of data.info">
            <div class="creator-info-dialog--box-header">{{info.head | translate}}</div>
            <div class="creator-info-dialog--box-content">{{info.content | translate}}</div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Close' | translate}}</button>
    </div>
</div>