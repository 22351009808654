import { Injectable } from '@angular/core';
import { TeamService } from './services/team.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './services/user.service';
import { CompanyService } from './services/company.service';
import { OrganizationService } from './services/organization.service';
import { ManagerService } from './services/manager.service';
import { LibraryService } from './services/library.service';
import { AdminService } from './services/admin.service';
import { SuperAdminService } from './services/super-admin.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CertificateService } from './services/certificate.service';
import { NotificationService } from './services/notification.service';
import { SurveyService } from './services/survey.service';
import { KnowledgeBaseService } from './services/knowledge-base.service';
import { OnboardingService } from './services/onboarding.service';
import { FeedbackService } from './services/feedback.service';
import {SettingsService} from './services/settings.service';
import {IntegrationService} from './services/integration.service';
import {GamificationService} from './services/gamification.service';
import {ReportService} from './services/report.service';
import {ApiAppService} from './services/api-app.service';
import {CustomFieldsService} from './services/custom-fields.service';
import {DashboardService} from './services/dashboard.service';

@Injectable()
export class ApiService {
    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    public layout: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(protected httpClient: HttpClient) {
        //
    }

    private _teamService: TeamService;

    get Team(): TeamService {

        if (this._teamService == null) {
            this._teamService = new TeamService(this.httpClient);
        }
        return this._teamService;
    }

    private _userService: UserService;

    get User(): UserService {

        if (this._userService == null) {
            this._userService = new UserService(this.httpClient);
        }
        return this._userService;
    }

    private _companyService: CompanyService;

    get Company(): CompanyService {

        if (this._companyService == null) {
            this._companyService = new CompanyService(this.httpClient);
        }
        return this._companyService;
    }

    private _organizationService: OrganizationService;

    get Organization(): OrganizationService {

        if (this._organizationService == null) {
            this._organizationService = new OrganizationService(this.httpClient);
        }
        return this._organizationService;
    }

    private _managerService: ManagerService;

    get Manager(): ManagerService {

        if (this._managerService == null) {
            this._managerService = new ManagerService(this.httpClient);
        }
        return this._managerService;
    }

    private _libraryService: LibraryService;

    get Library(): LibraryService {

        if (this._libraryService == null) {
            this._libraryService = new LibraryService(this.httpClient);
        }
        return this._libraryService;
    }

    private _adminService: AdminService;

    get Admin(): AdminService {

        if (this._adminService == null) {
            this._adminService = new AdminService(this.httpClient);
        }
        return this._adminService;
    }

    private _superAdminService: SuperAdminService;

    get SuperAdmin(): SuperAdminService {

        if (this._superAdminService == null) {
            this._superAdminService = new SuperAdminService(this.httpClient);
        }
        return this._superAdminService;
    }

    private _surveyService: SurveyService;

    get Survey(): SurveyService {

        if (this._surveyService == null) {
            this._surveyService = new SurveyService(this.httpClient);
        }
        return this._surveyService;
    }

    private _certificateService: CertificateService;

    get Certificate(): CertificateService {

        if (this._certificateService == null) {
            this._certificateService = new CertificateService(this.httpClient);
        }
        return this._certificateService;
    }

    private _notificationService: NotificationService;

    get Notification(): NotificationService {

        if (this._notificationService == null) {
            this._notificationService = new NotificationService(this.httpClient);
        }
        return this._notificationService;
    }

    private _knowledgeBaseService: KnowledgeBaseService;

    get KnowledgeBaseService(): KnowledgeBaseService {

        if (this._knowledgeBaseService == null) {
            this._knowledgeBaseService = new KnowledgeBaseService(this.httpClient);
        }
        return this._knowledgeBaseService;
    }

    private _onboardingService: OnboardingService;

    get Onboarding(): OnboardingService {

        if (this._onboardingService == null) {
            this._onboardingService = new OnboardingService(this.httpClient);
        }
        return this._onboardingService;
    }

    private _gamificationService: GamificationService;
    get Gamification(): GamificationService {

        if (this._gamificationService == null) {
            this._gamificationService = new GamificationService(this.httpClient);
        }
        return this._gamificationService;
    }

    private _feedbackService: FeedbackService;

    get Feedback(): FeedbackService {

        if (this._feedbackService == null) {
            this._feedbackService = new FeedbackService(this.httpClient);
        }
        return this._feedbackService;
    }

    private _settingsService: SettingsService;
    get Settings(): SettingsService {

        if (this._settingsService == null) {
            this._settingsService = new SettingsService(this.httpClient);
        }
        return this._settingsService;
    }

    private _integrationService: IntegrationService;
    get Integration(): IntegrationService {

        if (this._integrationService == null) {
            this._integrationService = new IntegrationService(this.httpClient);
        }
        return this._integrationService;
    }

    private _reportService: ReportService;
    get Report(): ReportService {

        if (this._reportService == null) {
            this._reportService = new ReportService(this.httpClient);
        }
        return this._reportService;
    }

    private _customFieldsService: CustomFieldsService;
    get CustomFields(): CustomFieldsService {

        if (this._customFieldsService == null) {
            this._customFieldsService = new CustomFieldsService(this.httpClient);
        }
        return this._customFieldsService;
    }

    public getLoginPage(url: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.httpClient.post<any>(`${this.basePath}/company/login-page`, { url }, { headers });
    }

    public getGlobalLoginPage(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.httpClient.get<any>(`${this.basePath}/login-page`, { headers });
    }

    public download(url: string): Observable<Blob> {
        // @ts-ignore
        // @ts-disable
        return this.httpClient.get(url, { responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    private _apiAppService: ApiAppService;

    get ApiApp(): ApiAppService {

        if (this._apiAppService == null) {
            this._apiAppService = new ApiAppService(this.httpClient);
        }
        return this._apiAppService;
    }

    private _dashboardService: DashboardService;

    get Dashboard(): DashboardService {

        if (this._dashboardService == null) {
            this._dashboardService = new DashboardService(this.httpClient);
        }
        return this._dashboardService;
    }
}
