<div class="choice-method-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{header | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <div class="choice-method-dialog__content">
            <div class="choice-method-dialog__box" (click)="closeWithEmpty()">
                <img src="assets/icons/plus-circle.svg" alt="plus-circle"/>
                <span class="choice-method-dialog__box__text" [innerHTML]="stepOneText | translate">
                </span>
            </div>

            <div class="choice-method-dialog__box"
                 [ngClass]="{'disabled': hasBaseObjects === false}"
                 (click)="openChosePopup()">
                <img src="assets/icons/document.svg" alt="document"/>
                <span class="choice-method-dialog__box__text" [innerHTML]="stepTwoText | translate">
                </span>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="close()" [mat-dialog-close]="false" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
    </div>
</div>
