import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-empty-tile',
    templateUrl: './empty-tile.component.html',
    styleUrls: ['./empty-tile.component.scss']
})
export class EmptyTileComponent {

    @Input() type = null;
    @Input() link = '';
    @Input() text = '';

    constructor(private router: Router) {
    }

    goToPage() {
        this.router.navigate([this.link]);
    }
}
