import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Directive({ selector: '[myHighlightActiveItems]' })

export class HighlightActiveItemsDirective implements AfterViewInit {
    constructor(private el: ElementRef,
                private location: Location,
                private router: Router) {
    }

    ngAfterViewInit() {
        const $el = $(this.el.nativeElement);
        const $links = $($el.find('a')).toArray();

        this.highlightActive($links);

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.highlightActive($links);
        });
    }

    highlightActive(links: any[]) {
        const path = this.router.url;

        this.removeActive(links);
        this.setActive(links, path)
        document.body.classList.remove('open-nav-mobile')
    }

    removeActive(links: any[]) {
        links.forEach(link => {
            const $li = $(link).parent('li');

            if ($li.hasClass('active')) {
                $li.removeClass('active');
            }
        });
    }

    setActive(links: any[], path: string) {
        links.forEach(link => {
            const $link = $(link);
            const $li = $link.parent('li');
            const activeUrlContains = $link.data('active-url-contains') !== undefined ? $link.data('active-url-contains').split(';') : [];
            activeUrlContains.forEach((aurl: string) => {
                if (path.indexOf(aurl) !== -1 || (aurl !== undefined && path.indexOf(aurl) !== -1 && path.indexOf('?b=') === -1)) {
                    $li.addClass('active');
                }
            });
        });
    }
}
