import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'parseUrl', pure: false })
export class ParseUrlPipe implements PipeTransform {

    urls: any = /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;


    transform(text: string) {
        return this.parseUrl(text);
    }

    private parseUrl(text: string) {
        if (text.match(this.urls)) {
            text = text.replace(this.urls, function replacer($1, $2, $3) {
                const url: any = $1;
                let urlClean: any = url.replace('' + $3 + '://', '');
                if (urlClean.length > 40) {
                    urlClean = urlClean.slice(0, 40) + '...';
                }

                return '<a href=\"' + url + '\" target=\"_blank\">' + urlClean + '</a>';
            });
        }

        return text;
    }
}
